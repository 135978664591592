import React from 'react';

import styles from './ZodiacCopyItem.module.scss';

type ZodiacCopyItemProps = {
  header: string;
  body: string[];
};

const ZodiacCopyItem = ({ header, body }: ZodiacCopyItemProps) => (
  <div className={styles.container}>
    <div className={styles.copyHeader}>
      <h3>{header}</h3>
    </div>
    <div className={styles.copyBody}>
      {header.includes('seduce') ? (
        <ul>
          {body.map((el, i) => (
            <li key={i}>
              <span>{el}</span>
            </li>
          ))}
        </ul>
      ) : (
        body.map((el, i) => <p key={`p-${i}`}>{el}</p>)
      )}
    </div>
  </div>
);

export default ZodiacCopyItem;
