import React from 'react';
import { Helmet } from 'react-helmet';
import qs from 'qs';
import { useLocation } from 'react-router';

import { getMobileOperatingSystem, redirectToAppropriateAppstore } from 'utils';

import PageLayout from 'landing-page/views/PageLayout';
import {
  ITUNES_CAMPAIGN_TOKENS,
  itunesStoreURL,
  playStoreUrl,
  PLAY_STORE_CAMPAIGN_TOKENS,
} from '../constants';
import { Features } from '../features';
import { PressFeatures } from '../press-features';
import FooterForm from 'landing-page/components/FooterForm';
import { sendClickEvent } from 'analytics';

const DownloadPage = () => {
  const location = useLocation();
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const { referrer } = document;

  const isInstagram = referrer.includes('instagram');
  const isTwitter = referrer.includes('twitter');
  const isTikTok = referrer.includes('tiktok');

  const { utm_medium, utm_source, utm_campaign } = queryParams;

  const isWeeklyEmail =
    utm_campaign === 'weekly' && utm_source === 'newsltr' && utm_medium === 'email';

  let itunesCampaign;
  let playStoreCampaign;

  if (isInstagram) {
    itunesCampaign = ITUNES_CAMPAIGN_TOKENS.IG_LINK_IN_BIO;
    playStoreCampaign = PLAY_STORE_CAMPAIGN_TOKENS.IG_LINK_IN_BIO;
  } else if (isTwitter) {
    itunesCampaign = ITUNES_CAMPAIGN_TOKENS.TWITTER_LINK_IN_BIO;
    playStoreCampaign = PLAY_STORE_CAMPAIGN_TOKENS.TWITTER_LINK_IN_BIO;
  } else if (isTikTok) {
    itunesCampaign = ITUNES_CAMPAIGN_TOKENS.TIK_TOK_LINK_IN_BIO;
    playStoreCampaign = PLAY_STORE_CAMPAIGN_TOKENS.TIK_TOK_LINK_IN_BIO;
  } else if (isWeeklyEmail) {
    itunesCampaign = ITUNES_CAMPAIGN_TOKENS.WEEKLY_EMAIL;
    playStoreCampaign = PLAY_STORE_CAMPAIGN_TOKENS.WEEKLY_EMAIL;
  } else {
    itunesCampaign = ITUNES_CAMPAIGN_TOKENS.SITE_MARKETING;
    playStoreCampaign = PLAY_STORE_CAMPAIGN_TOKENS.SITE_MARKETING;
  }

  const scrollAway = () => {
    window.scroll(0, window.innerHeight);
  };

  const downloadClick = (action: string) => {
    sendClickEvent('Download', action);
  };
  const APP_STORE_URL = itunesStoreURL(itunesCampaign);
  const PLAY_STORE_URL = playStoreUrl(playStoreCampaign);

  redirectToAppropriateAppstore(getMobileOperatingSystem(), APP_STORE_URL, PLAY_STORE_URL);

  return (
    <div>
      <Helmet>
        <title>Co – Star: Hyper-Personalized, Real-Time Horoscopes</title>
        <meta
          name="description"
          content="Most horoscopes ask what month you were born. Co–Star asks what minute. Powered by AI that merges NASA data with the insight of human astrologers."
        />
        <meta property="og:image" content={require('../../../images/launch-hero-desktop.png')} />
        <meta property="og:title" content="Co – Star: Hyper-Personalized, Real-Time Horoscopes" />
      </Helmet>
      <div className="launch-hero">
        <h4>The hyper-personalized, social astrology experience.</h4>
        <h1>Co – Star.</h1>
        <p className="subtitle body-font-basics">
          Most horoscopes ask what month you were born. Co–Star asks what minute.&nbsp;
          <span className="hide-on-mobile">
            <br />
          </span>
          Powered by AI that merges NASA data with the insight of human astrologers.
        </p>

        <div className="header-btns">
          <a
            href={APP_STORE_URL}
            className="btn sign-up white"
            onClick={() => downloadClick('downloadios')}
          >
            DOWNLOAD iOS
          </a>
          <a
            href={PLAY_STORE_URL}
            className="btn sign-up black"
            onClick={() => downloadClick('downloadandroid')}
          >
            DOWNLOAD ANDROID
          </a>
          <div>
            <a tabIndex={1} onClick={scrollAway} className="btn white learn-more">
              <span className="or">or&nbsp;</span>
              <span>LEARN MORE</span>
            </a>
          </div>
        </div>

        <img
          className="launch-hero-image-desktop"
          src={require('../../../images/launch-hero-desktop.png')}
        />
        <img
          className="launch-hero-image-mobile"
          src={require('../../../images/launch-hero-mobile.gif')}
        />
      </div>
      <div className="container dark-container">
        <div className="container-inner">
          <div className="section-title">WHAT IS THIS</div>
          <div className="what-is-this">
            <div className="image-desc">
              <img src={require('../../../images/moon.png')} />
            </div>
            <p>
              Access to astrology this accurate has historically been restricted to those with
              access to personal astrologers—now these predictions can be anyone's.
            </p>
          </div>
          <div className="what-is-this">
            <div className="image-desc">
              <img src={require('../../../images/hand.png')} />
            </div>
            <p>
              Our powerful natural-language engine uses NASA data, coupled with the methods of
              professional astrologers, to algorithmically generate insights about your personality
              and your future.
            </p>
          </div>
          <div className="what-is-this">
            <div className="image-desc">
              <img src={require('../../../images/skull.png')} />
            </div>
            <p>
              Astrology puts our temporary bodies in context with our universe's vastness, allowing
              irrationality to invade our techno-rationalist ways of living.
            </p>
          </div>
        </div>
      </div>
      <Features />
      <PressFeatures />
      <FooterForm formLocation="Download" />
    </div>
  );
};

export default PageLayout(DownloadPage);
