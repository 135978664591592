import { sendClickEvent } from 'analytics';
import * as React from 'react';
import Slider from 'react-slick';

export class PressFeatures extends React.Component<{}, {}> {
  mobileCarouselSettings: any;

  desktopCarouselSettings: any;

  tabletCarouselSettings: any;

  constructor(props) {
    super(props);
    this.desktopCarouselSettings = {
      centerMode: true,
      slidesToShow: 5,
      infinite: true,
      arrows: true,
    };
    this.mobileCarouselSettings = {
      slidesToShow: 1,
      centerMode: true,
      centerPadding: '60px',
    };
    this.tabletCarouselSettings = { slidesToShow: 3, centerMode: true };
  }

  scrollAway = (_) => {
    window.scroll(0, window.innerHeight);
  };

  pressFeaturesClick(action: string) {
    return () => {
      sendClickEvent('Press', 'Quotes');
    };
  }

  render() {
    return (
      <div>
        <div className="quotes-container">
          <div className="carousel-main-container" onClick={this.pressFeaturesClick('quotes')}>
            <Slider
              {...this.desktopCarouselSettings}
              responsive={[
                { breakpoint: 740, settings: this.mobileCarouselSettings },
                { breakpoint: 1165, settings: this.tabletCarouselSettings },
              ]}
            >
              <div>
                <div className="quote-box">
                  <h3 className="quote">“Part self-help advice, part real-talk sound bite.”</h3>
                  <h4 className="quote-attribution">
                    <span className="name underline">
                      <a href="https://www.cosmopolitan.com/lifestyle/a28241507/co-star-astrology-app-push-notifications-banu-guler/">
                        Cosmo
                      </a>
                    </span>
                  </h4>
                </div>
              </div>
              <div>
                <div className="quote-box">
                  <h3 className="quote">“I check the Co-Star astrology app every day.”</h3>
                  <h4 className="quote-attribution">
                    <span className="name underline">
                      <a href="https://www.wsj.com/articles/how-musician-phoebe-bridgers-is-listening-to-taylor-swifts-folklore-11597335782">
                        Phoebe Bridgers
                      </a>
                    </span>
                  </h4>
                </div>
              </div>

              <div>
                <div className="quote-box">
                  <h3 className="quote">“Everyone already has the Co–Star app.”</h3>
                  <h4 className="quote-attribution">
                    <span className="name underline">
                      <a href="https://www.newyorker.com/humor/daily-shouts/national-park-safety-rules-for-millennials">
                        New Yorker
                      </a>
                    </span>
                  </h4>
                </div>
              </div>
              <div>
                <div className="quote-box">
                  <h3 className="quote">“Something of a godsend.”</h3>
                  <h4 className="quote-attribution">
                    <span className="name underline">
                      <a href="https://www.vanityfair.com/style/2019/05/co-star-astrology-app-notifications-founder">
                        Vanity Fair
                      </a>
                    </span>
                  </h4>
                </div>
              </div>
              <div>
                <div className="quote-box">
                  <h3 className="quote">“The very best app.”</h3>
                  <h4 className="quote-attribution">
                    <span className="name underline">
                      <a href="https://gizmodo.com/android-users-can-finally-get-in-on-co-star-s-brutally-1841067701">
                        Gizmodo
                      </a>
                    </span>
                  </h4>
                </div>
              </div>
              <div>
                <div className="quote-box">
                  <h3 className="quote">“Good advice.”</h3>
                  <h4 className="quote-attribution">
                    <span className="name underline">
                      <a href="https://www.nytimes.com/2018/01/01/arts/how-astrology-took-over-the-internet.html?_r=0">
                        New York Times
                      </a>
                    </span>
                  </h4>
                </div>
              </div>
              <div>
                <div className="quote-box">
                  <h3 className="quote">“Disconcertingly accurate and reflective.”</h3>
                  <h4 className="quote-attribution">
                    <span className="name underline">
                      <a href="http://quebec.huffingtonpost.ca/2017/10/18/cette-nouvelle-application-dastrologie-personnalisee-est-hallucinante_a_23247853/">
                        HuffPo
                      </a>
                    </span>
                  </h4>
                </div>
              </div>
              <div>
                <div className="quote-box">
                  <h3 className="quote">“Millenials ditch religion for witchcraft, astrology.”</h3>
                  <h4 className="quote-attribution">
                    <span className="name underline">
                      <a href="https://twitter.com/DRUDGE_REPORT/status/921486228280741889">
                        Drudge Report
                      </a>
                    </span>
                  </h4>
                </div>
              </div>
              <div>
                <div className="quote-box">
                  <h3 className="quote">“Can feel like a free therapy session.”</h3>
                  <h4 className="quote-attribution">
                    <span className="name underline">
                      <a href="https://gizmodo.com/android-users-can-finally-get-in-on-co-star-s-brutally-1841067701">
                        Gizmodo
                      </a>
                    </span>
                  </h4>
                </div>
              </div>
              <div>
                <div className="quote-box">
                  <h3 className="quote">“Download Co – Star right now.”</h3>
                  <h4 className="quote-attribution">
                    <span className="name underline">
                      <a href="https://www.bustle.com/p/these-astrology-apps-can-take-your-daily-horoscope-check-to-the-next-level-2918817">
                        Bustle
                      </a>
                    </span>
                  </h4>
                </div>
              </div>
              <div>
                <div className="quote-box">
                  <h3 className="quote">“Wildly popular.”</h3>
                  <h4 className="quote-attribution">
                    <span className="name underline">
                      <a href="https://www.nytimes.com/2020/01/16/arts/music/concerts-new-york.html">
                        New York Times
                      </a>
                    </span>
                  </h4>
                </div>
              </div>
              <div>
                <div className="quote-box">
                  <h3 className="quote">
                    “Minimalist, genderless, slick, and of-the-moment, Co–Star is currently the app
                    to beat.”
                  </h3>
                  <h4 className="quote-attribution">
                    <span className="name underline">
                      <a href="https://www.ft.com/content/2816a0ec-000c-11ea-be59-e49b2a136b8d">
                        Financial Times
                      </a>
                    </span>
                  </h4>
                </div>
              </div>
              <div>
                <div className="quote-box">
                  <h3 className="quote">
                    “The astrology app that’s intriguing millennials everywhere.”
                  </h3>
                  <h4 className="quote-attribution">
                    <span className="name underline">
                      <a href="https://www.vogue.com/article/whats-co-star-astrology-app-technology-spirituality">
                        Vogue
                      </a>
                    </span>
                  </h4>
                </div>
              </div>
              <div>
                <div className="quote-box">
                  <h3 className="quote">“Nothing has been a more useful tool than Co–Star.”</h3>
                  <h4 className="quote-attribution">
                    <span className="name underline">
                      <a href="https://www.buzzfeednews.com/article/scaachikoul/astrology-co-star-astrology-memes">
                        Buzzfeed
                      </a>
                    </span>
                  </h4>
                </div>
              </div>
              <div>
                <div className="quote-box">
                  <h3 className="quote">“The perfect app for the current moment.”</h3>
                  <h4 className="quote-attribution">
                    <span className="name underline">
                      <a href="https://www.theverge.com/2019/10/4/20879631/co-star-astrology-app-zodiac-signs">
                        The Verge
                      </a>
                    </span>
                  </h4>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}
