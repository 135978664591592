import PageLayout from 'landing-page/views/PageLayout';
import React from 'react';

const TermsPage = () => (
  <div>
    <div className="container static-container">
      <h4>Terms of Service</h4>
      <h4>Last Updated: March 31st, 2025</h4>
      <p>PLEASE READ THESE TERMS OF SERVICE CAREFULLY. THESE TERMS OF SERVICE (“TERMS”) CONSTITUTE A LEGAL AGREEMENT BETWEEN YOU AND CO-STAR ASTROLOGY SOCIETY.</p>
      <p>SECTION 20 OF THESE TERMS CONTAINS PROVISIONS THAT GOVERN HOW CLAIMS THAT YOU AND WE HAVE AGAINST EACH OTHER ARE RESOLVED, INCLUDING, WITHOUT LIMITATION, ANY CLAIMS THAT AROSE OR WERE ASSERTED BEFORE THE EFFECTIVE DATE OF THESE TERMS. IN PARTICULAR, SECTION 20 SETS FORTH OUR ARBITRATION AGREEMENT WHICH WILL, WITH LIMITED EXCEPTIONS, REQUIRE DISPUTES BETWEEN US TO BE SUBMITTED TO BINDING AND FINAL ARBITRATION. UNLESS YOU OPT OUT OF THE ARBITRATION AGREEMENT: (1) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AND SEEK RELIEF AGAINST US ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING; AND (2) YOU ARE WAIVING YOUR RIGHT TO SEEK RELIEF IN A COURT OF LAW AND TO HAVE A JURY TRIAL ON YOUR CLAIMS. PLEASE SEE SECTION 20 FOR MORE INFORMATION REGARDING THIS ARBITRATION AGREEMENT, THE POSSIBLE EFFECTS OF THIS ARBITRATION AGREEMENT, AND HOW TO OPT OUT OF THE ARBITRATION AGREEMENT.</p>
      <h4>Accessibility of our Services</h4>
      <p>Before we dive into our Terms, we want to take a moment to express our commitment to providing you with Websites and Apps that are accessible to the widest possible audience, regardless of ability. Supporting these choices and requirements includes striving for substantial conformance with Web Content Accessibility Guidelines (WCAG) 2.1 Levels A and AA.</p>
      <p>While using our Services, you may be given access to third-party sites and services, and we accept no responsibility for the content or accessibility of any third-party sites or services accessible through our Services.</p>
      <p>If you have questions or want to provide feedback on our accessibility efforts, please contact us using the contact information at the end of these Terms.</p>
      <h3>Purpose</h3>
      <p>These Terms apply to your access and use of: (1) our websites located at  {' '}<a href="https://costarastrology.com">costarastrology.com</a> and  {' '}<a href="https://shop.costarastrology.com">shop.costarastrology.com</a> (the “Websites”); (2) our mobile applications (“Apps”); and (3) any services, content, and features made available by us through the Websites or the Apps (together with the Website and the Apps, our “Services”). In these Terms, “Co-Star,” the “Company,” “we,” “us,” and “our” refer to Co-Star Astrology Society and our affiliates, successors, and assigns; and “you” and “your” refer to any user of our Services.</p>
      <h4>Acceptance of these Terms</h4>
      <p>Your access and use of our Services are subject at all times to these Terms and our Privacy Policy located at  {' '}<a href="https://costarastrology.com/privacy">costarastrology.com/privacy</a>, incorporated herein by reference. Please read these Terms carefully. By using the Services, you represent that you are legally able to enter into this agreement. By accessing or using our Services in any way or by clicking to accept or agree to the Terms when this option is made available to you, you agree to be bound by these Terms. If you do not agree to all the terms and conditions of these Terms, do not access or use our Services. While we do our best to enforce these Terms, we cannot warrant or represent that other users will in fact adhere to these Terms and cannot act as insurers or accept any liability for their failure to do so.</p>
      <h4>Accessibility Statement for our Services</h4>
      <p>We are committed to providing you with Websites and Apps that are accessible to the widest possible audience, regardless of ability. </p>
      <h3>Eligibility</h3>
      <p>By accessing or using our Services, clicking a button or taking a similar action to signify your affirmative acceptance of these Terms, you hereby represent that:</p>
      <ol>
        <li>You have read, understand, and agree to be bound by these Terms and any future amendments or additions to these Terms as published from time to time at this link or through our Services;</li>
        <li>You are 16 or older;</li>
        <li>You have the authority to enter these Terms personally. Except as otherwise provided herein, if you do not agree to be bound by these Terms, you may not access or use our Services; and,</li>
        <li>You will comply with all applicable laws, including those of the country, state, and city in which you are present while using our Services.</li>
      </ol>
      <h3>Modification of the Terms</h3>
      <p>We may revise these Terms from time to time at our sole discretion and without prior notice, subject to applicable law. The date of the last update is reflected at the beginning of these Terms. When we revise these Terms, we will post the revised version on the Services. You are free to decide whether or not to accept a revised version of these Terms, but accepting these Terms, as revised, is required for you to continue accessing or using the Services. If you do not agree to these Terms or any revised version of these Terms, your sole recourse is to terminate your access or use of the Services. Except as otherwise expressly stated by us, your access and use of the Services are subject to, and constitute your acceptance of, the version of these Terms in effect at the time of your access or use.</p>
      <h3>Access</h3>
      <p>By entering into these Terms, you will be granted a revocable license to access our Services. Your access privileges, however, are conditioned on your adherence to these Terms. We reserve the right to temporarily deny you access to the Services or permanently terminate your access privileges at any time if, in our sole discretion, you have failed to abide by these Terms or appear to us likely to do so. By agreeing to grant you access to our Services, we do not obligate ourselves to do so or to maintain the Services, or to maintain them in their present form, and we expressly reserve the right to modify, suspend, or terminate your access privileges.</p>
      <p>You understand, acknowledge, and agree that any access or use of the Services shall be for your personal, non-commercial use only, and that you will not commercially exploit any portion of our Services, unless you expressly receive our written consent to do so.</p>
      <p>Your access privileges may not be transferred by you to any third parties.</p>
      <p>We’re relentlessly improving our Services and creating new ones all the time. This means that we may add, stop updating, or remove features, products, content, or functionality, and we may also suspend or stop the Services altogether. We may take any of these actions at any time, and when we do, we will try to notify you beforehand - but this won’t always be possible.</p>
      <p>To access and use some of the Services, you may be required to provide certain information (“User Information”) and register for an account (“Account”). Our Privacy Policy governs our collection, use, storage, and disclosure of any personal information contained within User Information. You represent and warrant that all User Information you provide us from time to time in connection with the Services is truthful, accurate, current, and complete. You agree to promptly notify us of changes to your User Information by updating your Account on the Website or through the Apps.</p>
      <p>You are responsible for any activity that occurs in your Co–Star Account, whether authorized or unauthorized by you. So it’s important that you take appropriate steps to keep your Account secure (e.g., using strong passwords, keeping your password private, exercising caution when using public or shared devices). We will not be liable for any loss that you incur as a result of someone else using your Account or password, either with or without your knowledge. You may be held liable for any losses incurred by us, our affiliates, officers, directors, employees, consultants, agents, and representatives due to someone else’s use of your Account or password. Except as otherwise expressly stated in these Terms or required by applicable law, we are not responsible for any losses or damages arising out of the loss or theft of your username, password, or other security information. If you think that someone has gained access to your account, please reach out immediately to {' '}<a href="mailto:horoscopes@costarastrology.com">horoscopes@costarastrology.com</a>.</p>
      <h3>Text Messaging</h3>
      <p>By consenting to these Terms, you authorize us and our service providers to contact and/or text you at the phone number you provided to us. This authorization includes using automated dialing technology or other technology to text you for non-marketing purposes, such as identity or Account login verification. You understand and agree that by consenting to receive non-marketing text messages, we may text you at the phone number you provided to use even if your number is registered on any state or federal do-not-call list.</p>
      <p>We may modify or cancel our text messaging services or any of its features without notice.</p>
      <p>We do not charge for our text messaging services, but you are responsible for all charges and fees associated with text messaging imposed by your wireless provider. Message frequency varies. Message and data rates may apply. Check your mobile plan and contact your wireless provider for details. You are solely responsible for all charges related to SMS/text messages, including charges from your wireless provider.</p>
      <p>We may change any short code or telephone number we use to operate the Service at any time and will notify you of these changes. You acknowledge that any messages you send to a short code or telephone number we have changed may not be received and we will not be responsible for honoring requests made in such messages.</p>
      <p>The wireless carriers supported by the Service are not liable for delayed or undelivered messages. You agree to provide us with a valid mobile number. If you get a new mobile number, you will need to sign up for the program with your new number.</p>
      <p>To the extent permitted by applicable law, you agree that we will not be liable for failed, delayed, or misdirected delivery of any information sent through the Service, any errors in such information, and/or any action you may or may not take in reliance on the information or Service.</p>
      <h3>Intellectual Property Rights</h3>
      <p>The Services and all related content, features, and functionality (including, but not limited to, all information, software, text, displays, images, video and audio, and the design, selection, and arrangement thereof), are owned by Co-Star, its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws as applicable. These Terms are not a sale and does not convey to you any rights of ownership in or related to the Services, or any intellectual property rights owned by us.</p>
      <p>Subject to these Terms, you are permitted to access and use the Services for your personal, non-commercial use only.</p>
      <p>The Co-Star name, the Co-Star logo and all related names, logos, product and service names, designs, and slogans are trademarks of Co–Star. You must not use such marks without our prior written permission. You may not do any of the following (or enable anyone else to do so):</p>
      <ul>
        <li>copy, archive, download, upload, distribute, syndicate, broadcast, perform, display, print, monitor, make available, modify, or otherwise use any portion of the Services or the content on the Services except as set forth in these Terms;</li>
        <li>use the Services, any tools provided by the Services, or any content on the Services for any commercial purposes without obtaining our advance written consent; or</li>
        <li>violate or infringe Co–Star’s copyrights, trademarks, or other intellectual property rights.</li>
      </ul>
      <p>If you’d like to use our brand assets in a way not covered by these guidelines, please reach out to us at {' '}<a href="mailto:horoscopes@costarastrology.com">horoscopes@costarastrology.com</a>.</p>
      <p>If you violate these Terms, your right to access and use the Services will cease immediately, and you must, at our option, return or destroy any copies of the materials you have made. No right, title, or interest in or to the Services is transferred to you, and all rights not expressly granted are reserved by the Company. Any access or use of the Services not expressly permitted by these Terms is a breach of these Terms and may violate copyright, trademark, and other laws.</p>
      <h3>Acceptable Use Policy</h3>
      <p>By using our Services, you agree that:</p>
      <ul>
        <li>You will use the Services only for lawful purposes and in accordance with these Terms. If your access or use of the Services is prohibited by applicable law, then you are not authorized to access or use the Services. We are not responsible if you access or use the Services in any manner that violates applicable law.</li>
        <li>You will not use the Services to cause nuisance, annoyance, or inconvenience;</li>
        <li>You will not use the Services, or any content accessible through the Services, for any commercial purpose, including but not limited to contacting, advertising to, soliciting, or selling to any users;</li>
        <li>You will not violate the publicity or privacy rights of another individual;</li>
        <li>You will not copy or distribute any content displayed through the Services;</li>
        <li>You will not create or compile, directly or indirectly, any collection, compilation, or other directory from any content displayed through the Services, except for your personal, noncommercial use;</li>
        <li>Any information you provide to us or otherwise communicate to us is accurate;</li>
        <li>You will report any errors, bugs, unauthorized access methodologies, or any breach of our intellectual property rights that you uncover in your use of our Services;</li>
        <li>You will not use the Services in any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the United States or other countries);</li>
        <li>You will not use the Services for the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information or otherwise;</li>
        <li>You will not use the Services in a manner that violates the content standards set out in these Terms when you send, knowingly receive, upload, download, use, or re-use any material that violates these Terms or applicable law;</li>
        <li>You will not transmit, or procure the sending of, any advertising or promotional material without our prior written consent, including any junk mail, spam or other solicitation;</li>
        <li>You will not impersonate or attempt to impersonate Co-Star, a Co-Star employee, another user, or any other person or entity (including, without limitation, by using e-mail addresses or usernames associated with any of the foregoing); and</li>
        <li>You will not engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Services, or which, as determined by us, may harm Co-Star or users of the Services or expose them to liability.</li>
      </ul>
      <p>Additionally, you agree not to: </p>
      <ul>
        <li>Use the Services in any manner that could disable, overburden, damage, or impair the Services or interfere with any other party’s use of the Services, including their ability to engage in real time activities through the Services;</li>
        <li>Use any robot, spider, or other automatic device, process, or means to access the Services for any purpose, including monitoring or copying any of the material available through the Services;</li>
        <li>Use any device, software, or routine that interferes with the proper working of the Services;</li>
        <li>Introduce any viruses, trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful;</li>
        <li>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Services, the servers on which the Services are stored, or any server, computer, or database connected to the Services;</li>
        <li>Attack the Services via a denial-of-service attack or a distributed denial-of-service attack;</li>
        <li>Otherwise attempt to interfere with the proper working of the Services;</li>
        <li>Use any part of our Services, or any information available through our Services, to create, train, or otherwise develop any artificial intelligence (or similar) models.</li>
      </ul>
      <h3>Uploaded Content</h3>
      <p>Aspects of our Services let you create, upload, post, send, receive, and store content (collectively, “Uploaded Content”). You represent and warrant that you are the owner of, or otherwise have the right to provide, all Uploaded Content that you submit, post, or otherwise transmit through our Services. You hereby grant us a perpetual, irrevocable, transferable, fully paid, royalty-free, non-exclusive, worldwide, fully sublicensable right and license to use, copy, display, publish, modify, remove, publicly perform, translate, create derivative works, distribute, and/or otherwise use the Uploaded Content in connection with our business and in all forms now known or hereafter invented, without notification to and/or approval by you, except as otherwise required by law.</p>
      <p>Uploaded Content must not:</p>
      <ul>
        <li>violate or infringe someone else’s rights of publicity, privacy, copyright, trademark, or other intellectual property right;</li>
        <li>violate any state or federal law designed to regulate electronic advertising;</li>
        <li>contain pictures, data, audio or visual files, or any other content that is excessive in size, as determined by us in our sole discretion;</li>
        <li>contain any material which is false, defamatory, libelous, obscene, harassing, threatening, discriminatory, bigoted, hateful, violent, vulgar, profane, pornographic or otherwise offensive, inappropriate, damaging, unlawful, disruptive or harmful;</li>
        <li>seek to harm or exploit children by exposing them to inappropriate content, asking for personally identifiable information or otherwise;</li>
        <li>bully, harass, intimidate, defame, dox, spam, or solicit our users; or</li>
        <li>be otherwise objectionable or offensive as determined by us at our sole discretion.</li>
      </ul>
      <p>You understand and acknowledge that you are responsible for any Uploaded Content you post, and you, not Co-Star, have full responsibility for such Uploaded Content, including its legality, reliability, accuracy, and appropriateness.</p>
      <p>We are not responsible, or liable to any third party, for the content or accuracy of any Uploaded Content posted by you or any other user of the Services.</p>
      <h3>Good Samaritan Policy</h3>
      <p>It is our policy to not tolerate any acts of intellectual property infringement or violations of U.S. law or to allow for any child pornography or obscene or defamatory material to be posted at our Services. We will do our best, in good faith, to purge or otherwise restrict the availability of material that is infringing, racist, sexist, obscene, harassing, or otherwise objectionable. The provisions of this Section are intended to implement this policy but are not intended to impose a contractual obligation on us to undertake, or refrain from undertaking, any particular course of conduct.</p>
      <p>If you believe that someone has posted material at our Services which infringes the intellectual property or other rights of third parties or which is in violation of U.S. law or which is racist, sexist, obscene, harassing, defamatory, or otherwise objectionable or inappropriate, or which constitutes child pornography, we ask you to email us at {' '}<a href="mailto:legal@costarastrology.com">legal@costarastrology.com</a>.</p>
      <p>When emailing us to complain about inappropriate or infringing content, please provide as much detail as possible, including:</p>
      <ol>
        <li>the nature of the right infringed or violated (including the registration numbers of any registered copyrights, trademarks or patents allegedly infringed);</li>
        <li>all facts which lead you to believe that a right has been violated or infringed;</li>
        <li>the precise location where the offending material is located;</li>
        <li>any grounds to believe that the person who posted the material was not authorized to do so or did not have a valid defense (including the defense of fair use); and</li>
        <li>if known, the identity of the person or persons who posted the infringing or offending material.</li>
      </ol>
      <p>By lodging a complaint, you agree that the substance of your complaint shall be deemed to constitute a representation made under penalty of perjury under the laws of the State of Delaware. In addition, you agree, at your own expense, to defend us and indemnify us against any liability which we may incur by our response to your complaint.</p>
      <p>We expect visitors to take responsibility for their own actions, and, as set forth below, cannot assume liability for any acts of users or third parties which take place on our Services. By these Terms, you acknowledge that in establishing a complaint procedure we are taking on the role of a Good Samaritan and, in order to allow us to do our best, in good faith, to purge or otherwise restrict the availability of material that is infringing, racist, sexist, obscene, harassing, or otherwise objectionable, you agree to waive any claims or remedies which you might otherwise be able to make against us under any theory of law (including, but not limited to, intellectual property laws) arising out of or relating in any way to the content at these Services or our response, or failure to respond, to a complaint.</p>
      <p>You agree that we have the right (but not the obligation) to investigate any complaint received and, at any time and for any reason, to remove any material which you post on our Services, with or without your permission, and with or without cause, in our sole discretion. By reserving this right, we do not undertake any responsibility in fact to remove content posted online, whether or not a complaint has been received.</p>
      <h3>Copyright Policy</h3>
      <p>We respect the copyright and other intellectual property rights of others and expect users of our Services to do the same. In accordance with the United States Digital Millennium Copyright Act (the "DMCA") and other applicable law, we have a policy of terminating, in appropriate circumstances and at our sole discretion, users of the Services who are deemed to be repeat infringers. We also may, in our sole discretion, limit access to our Services and terminate the Accounts of any users of the Services who infringe any intellectual property rights of others, whether or not there is any repeat infringement.</p>
      <h4>Notification of Alleged Copyright Infringement </h4>
      <p>If you believe that content available on or through our Services infringes one or more of your copyrights, please immediately notify our Copyright Agent (“Notification”) by providing the information described below, which Notification is pursuant to DMCA 17 U.S.C. § 512(c)(3). A copy of your Notification will be sent to the person who posted or stored the material addressed in the Notification. Please be advised that pursuant to federal law you may be held liable for damages if you make material misrepresentations in a Notification. Thus, if you are not sure that content located on or linked to from our Services infringes your copyright, you should consider first contacting an attorney. </p>
      <h4>All Notifications should include the following: </h4>
      <ul>
        <li>A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
        <li>Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online website are covered by a single notification, a representative list of such works at that website.</li>
        <li>Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material (e.g. the URL link of the material).</li>
        <li>Information reasonably sufficient to permit us to contact the complaining party, such as the name, account name, address, telephone number, and e-mail address at which the complaining party may be contacted.</li>
        <li>A statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.</li>
        <li>A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
      </ul>
      <p>Submit your notice to our designated DMCA agent by email as set forth below: </p>
      <p>{' '}<a href="mailto:legal@costarastrology.com">legal@costarastrology.com</a>  </p>
      <p>Please note that you may be liable for damages, including court costs and attorney's fees, if you materially misrepresent that content on the Services is copyright infringing.</p>
      <p>Upon receiving a proper Notification of alleged copyright infringement, we will remove or disable access to the allegedly infringing material and promptly notify the alleged infringer of your claim. We also will advise the alleged infringer of the DMCA statutory counter-notification procedure described below by which the alleged infringer may respond to your claim and request that we restore this material. </p>
      <p>Please note that our furnishing your claim to the alleged infringer will include the personal information you provide in your Notification, which the alleged infringer may use to contact you directly. As such, by submitting a Notification of alleged copyright infringement, you consent to disclosure of your information in the aforementioned manner. </p>
      <h4>Counter Notification</h4>
      <p>If you believe your copyrighted material has been removed from the Services as a result of a mistake or misidentification, you may submit a written counter-notification letter to us (“Counter Notification). To be an effective Counter Notification under the DMCA, your letter must include substantially the following: </p>
      <ul>
        <li>Identification of the material that has been removed or disabled and the location at which the material appeared before it was removed or disabled. </li>
        <li>A statement that you consent to the jurisdiction of the Federal District Court in which your address is located, or if your address is outside the United States, for any judicial district in which our Company is located. </li>
        <li>A statement that you will accept service of process from the party that filed the Notification or the party's agent. </li>
        <li>Your name, address and telephone number. </li>
        <li>A statement under penalty of perjury that you have a good faith belief that the material in question was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled.</li>
        <li>Your physical or electronic signature.</li>
        <li>You may submit your Counter Notification to our Copyright Agent by mail, or email as set forth above.</li>
      </ul>
      <p>If you send us a valid, written Counter Notification meeting the requirements described above, we will restore your removed or disabled material after 10 business days but no later than 14 business days from the date we receive your Counter Notification, unless our Copyright Agent first receives notice from the party filing the original Notification informing us that such party has filed a court action to restrain you from engaging in infringing activity related to the material in question. Please note that if you materially misrepresent that the disabled or removed content was removed by mistake or misidentification, you may be liable for damages, including costs and attorney's fees. Filing a false Counter Notification constitutes perjury.</p>
      <h3>Purchases and Subscriptions</h3>
      <p>We offer products and services for purchase through the Apple App Store, Google Play, Shopify and other external services authorized by Co-Star (each, an “External Service”). Any purchase made on an External Service will be processed through your account (“External Service Account”) on that External Service in accordance with the terms disclosed to you at the time of purchase and the general terms applicable to your External Service Account. Some External Services may charge you sales tax, depending on where you live, which may at times change.</p>
      <p>Any offer for any product or service made on our Services is void where prohibited.</p>
      <h4>In-App Fees</h4>
      <p>The Company may, now or in the future, charge subscription or service fees for the use of the Services or certain Service features. You agree to pay us all fees for products or services purchased on or through the Services under your Account at the then-current prices and rates (including any applicable taxes), and to abide by our payment terms in effect at the time of such purchase. We may change the fees for use of the Services or any Service features or add new fees or charges, at any time. For any change in fees for products or services purchased under your Account, we will send you a notice of such change in advance of the time such change takes effect (via a message on or through the Services). </p>
      <ul>
        <li>To request a refund from Apple you can follow these instructions.</li>
        <li>To request a refund from Google, you can follow these instructions.</li>
      </ul>
      <h4>Subscriptions</h4>
      <p>To access or use some of our Services, you may need to subscribe to recurring payments (our “Subscription Services”). If you sign up for our Subscription Services, you must provide us with valid payment information.</p>
      <ol>
        <li><span style={{ fontWeight: 'bold' }}>Free Trial.</span> You may have access to a free trial period for a Subscription Service. <span style={{ fontWeight: 'bold' }}>IF YOU SIGN UP FOR A FREE TRIAL AND DO NOT CANCEL BEFORE THE TRIAL ENDS, YOUR TRIAL MAY CONVERT INTO A PAID SUBSCRIPTION AND THE PAYMEND METHOD YOU SET UP IN YOUR EXTERNAL SERVICE ACCOUNT WILL BE AUTOMATICALLY CHARGED.</span> You will continue to be charged until you cancel the subscription through the External Service Account methods below in the Cancellation subsection. If you have signed up for a free trial in the subscription through the Apple Store or Google Play Store previously, you will not be eligible for another free trial—in this case you will be automatically signed up for a paid subscription and charged as described in these Terms.</li>
        <li>Subscription Term; Auto Renewal. If you purchase a Subscription Service, it will automatically renew at the price you agreed to when subscribing until you cancel, in accordance with the terms disclosed to you at the time of purchase and the general terms applicable to your External Service Account.
          <p><span style={{ fontWeight: 'bold' }}>PAYMENTS AUTOMATICALLY RENEW FOR INDEFINITE SUCCESSIVE RENEWAL TERMS FOR THE SAME PERIOD AS THE INITIAL TERM, UNTIL CANCELED BY YOU OR BY US IN ACCORDANCE WITH THESE TERMS.</span></p></li>
        <li>Right to Modify Pricing. We reserve the right to raise or lower the cost of our Subscription Services, products, or subscription fees, and to create additional tiers or types of Subscription Services, including new subscription tiers, at any time.</li>
        <li>Cancellation. Either we or you may cancel your subscription at any time and for any reason. In the event of a cancellation by us or you, all fees due to us up to the end of the then-current billing cycle at the time shall remain payable to us. </li>
      </ol>
      <p>To cancel your subscription, you must log into your External Service Account and follow the relevant instructions.</p>
      <ul>
        <li>To cancel your subscription in Apple, you can follow these instructions.</li>
        <li>To cancel your subscription in Google, you can follow these instructions.</li>
      </ul>
      <p>If you cancel your subscription, you will continue to have access to your subscription benefits until the end of your subscription period, at which point it will expire. Because our Services may be used without a subscription, canceling your subscription does not remove your profile from our Services. If you wish to fully delete your profile, you must do so as outlined in our Privacy Policy.</p>
      <p>If at any time we believe, in our sole discretion, that a user has violated any provision of these Terms, we may immediately terminate that user’s Account and all other access to the Services without any refund or other remedy. Such termination will not limit any other right by us under contract, tort, or any other legal theory to pursue any claim or cause of action against the user for violating the Terms, including, without limitation, monetary damages, injunctive relief, attorney’s fees, and court costs.</p>
      <h4>Online Stores</h4>
      <p>As part of our Services, we may, now or in the future, make certain products or services available exclusively online through the Websites (the “Online Stores”). These products or services may have limited quantities and are subject to return or exchange only according to our policies outlined in our FAQ.</p>
      <p>We have made every effort to display as accurately as possible the colors and images of our products that appear at the store, but we cannot guarantee that your device's display of any color will be accurate.</p>
      <p>We try to ensure that our online pricing and product information are accurate and complete. However, from time to time, mistakes occur, and we reserve the right to correct any errors, inaccuracies, or omissions, and to change or update information or cancel orders if any information in the Service is inaccurate at any time without prior notice (including after you have submitted your order). We reserve the right to limit the quantities of, change prices or descriptions of, or discontinue any products or services that we offer at any time, without notice. We undertake no obligation to update, amend or clarify information in the Service, including without limitation, pricing information, except as required by law. We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations.</p>
      <p>We reserve the right to limit, block, or cancel any order you place with us. We may exercise this right on a case-by-case basis and at our sole discretion. If we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail, billing address, and/or phone number provided at the time the order was made.</p>
      <p>We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in our Services with regard to those products, services, information, or material will be corrected.</p>
      <h3>User Comments, Feedback, and Other Submissions</h3>
      <p>If you send us ideas, suggestions, proposals, feedback, plans, or other materials, whether online, by email, by postal mail, or otherwise (“Comments”), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate, and otherwise use such Comments. We are and shall be under no obligation to maintain any Comments in confidence; to pay compensation for any Comments; or to respond to any Comments.</p>
      <p>You agree that your Comments will not violate any right of a third-party, including copyright, trademark, privacy, or other personal or proprietary right. You further agree that your Comments will not contain libelous or otherwise unlawful, abusive, or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Services. You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third parties as to the origin of any Comments. You are solely responsible for any Comments you make and their accuracy. We take no responsibility and assume no liability for any Comments you send us.</p>
      <h3>Terminating or Suspending an Account</h3>
      <p>We may, for any reason and in our sole discretion, suspend, limit, or terminate your Account and your access to or use of the Services, without notice or liability to you. You may terminate your Account and stop using the Services at any time by notifying us at {' '}<a href="mailto:horoscopes@costarastrology.com">horoscopes@costarastrology.com</a> and providing sufficient information for us to verify your identity. Upon the termination of your Account, you must cease all use of the Services. Termination of your Account will not affect any of our rights or your obligations arising under these Terms prior to such termination. Provisions of these Terms that, by their nature, should survive termination of your Account will survive such termination.</p>
      <h3>Reliance on Information</h3>
      <p>The information presented through the Services is made available solely for general information purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other user of the Services or by anyone who may be informed of information presented through the Services.</p>
      <p>The Services may include content provided by third parties, including materials provided by other users, bloggers and third-party licensors, syndicators, aggregators, and/or reporting services. All statements and/or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by Co-Star, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect the opinion of Co-Star. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.</p>
      <h3>Privacy</h3>
      <p>Please review our Privacy Policy for details on how we collect, use, store, and disclose information in connection with the Services. You can find our Privacy Policy here:  {' '}<a href="https://costarastrology.com/privacy">costarastrology.com/privacy</a>. By using the Services, you consent to all actions taken by us with respect to your information in compliance with our Privacy Policy. </p>
      <h4>Linking to the website and social media features</h4>
      <p>You may link to the Services only in a way that is fair and legal and does not seek to damage Co-Star’s reputation or take advantage of it. You must not: </p>
      <ul>
        <li>establish a link in such a way as to suggest any form of association, approval, or endorsement on our part without our express written consent;</li>
        <li>cause the Website or portions of it to be displayed, or appear to be displayed by, any other website except those owned by you or as permitted in writing by Co-Star. Examples of the display of the Website include framing, deep linking, or in-line linking; or,</li>
        <li>otherwise take any action with respect to the materials on the Services that is inconsistent with any other provision of these Terms.</li>
      </ul>
      <h4>Links from the Services</h4>
      <p>If the Services contain links to other websites provided by third parties (“Linked Sites”), these links are provided for your convenience only. Our inclusion of such links does not imply our endorsement of any Linked Sites or any association with their owners or operators, and we expressly disclaim responsibility and liability of any such Linked Sites. We have no control over the contents of Linked Sites and accept no responsibility for them. If you decide to access any Linked Sites, you do so entirely at your own risk and subject to the terms and conditions of use for such Linked Sites. Any interactions you have with Linked Sites are between you and such site, and you agree that we are not liable for any damage or loss you may suffer as a result of any interactions with any Linked Sites or any claims that you may have against Linked Sites.</p>
      <h3>Disclaimer of Warranties</h3>
      <p>WE DO NOT WARRANT THAT THE SERVICES WILL OPERATE ERROR-FREE OR THAT THE SERVICES ARE FREE OF COMPUTER VIRUSES AND OTHER HARMFUL MALWARE. IF YOUR USE OF THE SERVICES RESULTS IN THE NEED FOR SERVICING OR REPLACING EQUIPMENT OR DATA, WE SHALL NOT BE RESPONSIBLE FOR THOSE ECONOMIC COSTS.</p>
      <p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICES AND ALL INFORMATION, PRODUCTS, AND OTHER CONTENT (INCLUDING THAT OF THIRD PARTIES) INCLUDED IN OR ACCESSIBLE FROM THE SERVICES IS AT YOUR OWN RISK. THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY, NOR ANYONE ASSOCIATED WITH THE COMPANY, REPRESENTS OR WARRANTS THAT THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT ERRORS IN THE SERVICES WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES THEM AVAILABLE MAY NOT CONTAIN VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</p>
      <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO, ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
      <h3>Limitation on liability</h3>
      <p>UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER IN CONTRACT, TORT, OR OTHERWISE) SHALL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR (A) ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING LOST PROFITS, LOST SALES OR BUSINESS, LOST DATA OR DATA BREACH, OR (B) FOR ANY DIRECT DAMAGES, COSTS, LOSSES OR LIABILITIES (INCLUDING ATTORNEYS’ FEES) IN EXCESS OF THE FEES ACTUALLY PAID BY YOU IN THE TWO (2) MONTHS PRECEDING THE EVENT GIVING RISE TO YOUR CLAIM OR, IF NO FEES APPLY, ONE HUNDRED ($100) U.S. DOLLARS. THE PROVISIONS OF THIS SECTION ALLOCATE THE RISKS UNDER THESE TERMS BETWEEN THE PARTIES, AND THE PARTIES HAVE RELIED ON THESE LIMITATIONS IN DETERMINING WHETHER TO ENTER INTO THESE TERMS. Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply to you. IN THESE STATES, OUR LIABILITY WILL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW. </p>
      <p>WE MAKE NO REPRESENTATIONS OR WARRANTIES WITH RESPECT TO THE SERVICES, OR ANY OTHER ITEMS OR SERVICES PROVIDED BY US, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY ARISING BY USAGE OF TRADE, COURSE OF DEALING OR COURSE OF PERFORMANCE, ANY IMPLIED WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE AND ANY IMPLIED WARRANTY OF NON-INFRINGEMENT. YOU ACKNOWLEDGE THAT THE SERVICES (INCLUDING ANY SERVERS OR OTHER HARDWARE, SOFTWARE AND ANY OTHER ITEMS USED OR PROVIDED BY US IN CONNECTION WITH THE SERVICES) ARE PROVIDED "AS IS" AND THAT WE MAKE NO WARRANTY THAT THE SERVICES WILL BE FREE FROM BUGS, FAULTS, DEFECTS OR ERRORS OR THAT ACCESS TO THE SERVICES WILL BE UNINTERRUPTED.</p>
      <h3>Indemnification</h3>
      <p>You agree to indemnify and hold harmless Co-Star and its officers, directors, employees, agents and affiliates (each, an “Indemnified Party”), from and against any losses, claims, actions, costs, damages, penalties, fines and expenses, including without limitation attorneys’ fees and expenses, that may be incurred by an Indemnified Party arising out of, relating to or resulting from (a) your Uploaded Content; (b) your misuse of the Services; (c) your violation of these Terms; or (d) your violation of any applicable laws, rules or regulations through or related to the use of the Services. In the event of any claim, allegation, suit or proceeding alleging any matter potentially covered by the agreements in this Section, you agree to pay for the defense of the Indemnified Party, including reasonable costs and attorneys’ fees incurred by the Indemnified Party. We reserve the right, at our own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with us in asserting any available defenses. This provision does not require you to indemnify any Indemnified Party for any unconscionable commercial practice by such party, or for such party’s negligence, fraud, deception, false promise, misrepresentation or concealment, suppression or omission of any material fact in connection with the Services. You agree that the provisions in this Section will survive any termination of your Account, these Terms, or your access to the Services.</p>
      <p>California Residents: you expressly waive CA Civil Code §1542, which states: “A general release does not extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release, which if known by him must have materially affected his settlement with the debtor.”</p>
      <h3>Governing Law</h3>
      <p>All matters relating to the Services and these Terms and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), will be governed by and construed in accordance with the internal laws of the State of Delaware without giving effect to any choice or conflict of law provision or rule (whether of the State of Delaware or any other jurisdiction).</p>
      <h3>Dispute Resolution</h3>
      <p>PLEASE READ THE FOLLOWING SECTION CAREFULLY. IT REQUIRES YOU TO ARBITRATE DISPUTES WITH US AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF. THIS SECTION OF THE TERMS SHALL BE REFERRED TO AS THE “ARBITRATION AGREEMENT.”</p>
      <ol>
        <li>Scope of Arbitration Agreement. You agree that any dispute or claim relating in any way to your access or use of the Services or as a consumer of our services, to any advertising or marketing communications regarding us or our Services, to any products or services sold or distributed through the Services that you received as a consumer, or to any aspect of your relationship or transactions with us as a consumer of our services will be resolved by binding arbitration, rather than in court, except that (1) you may assert claims in small claims court if your claims qualify, so long as the matter remains in such court and advances only on an individual (non-class, non-representative) basis; and (2) you or Co-Star may seek equitable relief in court for infringement or other misuse of intellectual property rights (such as trademarks, trade dress, domain names, trade secrets, copyrights, and patents). This Arbitration Agreement shall apply, without limitation, to all claims that arose or were asserted before the Effective Date of this Agreement. IF YOU AGREE TO ARBITRATION WITH US, YOU ARE AGREEING IN ADVANCE THAT YOU WILL NOT PARTICIPATE IN OR SEEK TO RECOVER MONETARY OR OTHER RELIEF IN ANY SUCH CLASS, COLLECTIVE, AND/OR REPRESENTATIVE LAWSUIT. INSTEAD, BY AGREEING TO ARBITRATION, YOU MAY BRING YOUR CLAIMS AGAINST US IN AN INDIVIDUAL ARBITRATION PROCEEDING. IF SUCCESSFUL ON SUCH CLAIMS, YOU COULD BE AWARDED MONEY OR OTHER RELIEF BY AN ARBITRATOR.</li>
        <li>Informal Resolution. You and Co-Star agree that good-faith informal efforts to resolve disputes often can result in a prompt, low-cost and mutually beneficial outcome. You and Co-Star therefore agree that, before either you or Co-Star demands arbitration against the other, we will personally meet and confer, via telephone or videoconference, in a good-faith effort to resolve informally any claim covered by this mutual Arbitration Agreement. If you are represented by counsel, your counsel may participate in the conference, but you shall also fully participate in the conference. The party initiating the claim must give notice to the other party in writing of its, his, or her intent to initiate an informal dispute resolution conference, which shall occur within 60 days after the other party receives such notice, unless an extension is mutually agreed upon by the parties. To notify us that you intend to initiate an informal dispute resolution conference, email {' '}<a href="mailto:legal@costarastrology.com">legal@costarastrology.com</a>, providing your username associated with your Account (if any), the email address associated with your Account (if any), and a description of your claim. In the interval between the party receiving such notice and the informal dispute resolution conference, the parties shall be free to attempt to resolve the initiating party’s claims. Engaging in an informal dispute resolution conference is a requirement that must be fulfilled before commencing arbitration. The statute of limitations and any filing fee deadlines shall be tolled while the parties engage in the informal dispute resolution process required by this paragraph.</li>
        <li>Arbitration Rules and Forum. This Arbitration Agreement is governed by the Federal Arbitration Act in all respects. To begin an arbitration proceeding, you must send a letter requesting arbitration and describing your claim to Co–Star, Attn: Arbitration, 401 Broadway #610, New York, NY 10013. The arbitration will be conducted by JAMS under its rules and pursuant to the Terms. Disputes involving claims and counterclaims under $250,000, not inclusive of attorneys’ fees and interest, shall be subject to JAMS’s most current version of the Streamlined Arbitration Rules and procedures available at {' '}<a href="http://www.jamsadr.com/rules-streamlined-arbitration/">http://www.jamsadr.com/rules-streamlined-arbitration/</a>; all other claims shall be subject to JAMS’s most current version of the Comprehensive Arbitration Rules and Procedures, available at {' '}<a href="http://www.jamsadr.com/rules-comprehensive-arbitration/">http://www.jamsadr.com/rules-comprehensive-arbitration/</a>. JAMS’s rules are also available at {' '}<a href="http://www.jamsadr.com">www.jamsadr.com</a> (under the Rules/Clauses tab) or by calling JAMS at 800-352-5267. Payment of all filing, administration, and arbitration fees will be governed by JAMS’s rules. If the arbitrator finds that you cannot afford to pay JAMS’s filing, administrative, hearing and/or other fees and cannot obtain a waiver of fees from JAMS, we will pay them for you. In addition, we will reimburse all such JAMS’s filing, administrative, hearing and/or other fees for claims with an amount in controversy totaling less than $10,000. If JAMS is not available to arbitrate, the parties will select an alternative arbitral forum. You may choose to have the arbitration conducted by telephone, video conference, based on written submissions, or in person in the county where you live or at another mutually agreed location.</li>
        <li>Arbitrator Powers. The arbitrator, and not any federal, state, or local court or agency, shall have exclusive authority to resolve any dispute relating to the interpretation, applicability, enforceability or formation of this Arbitration Agreement including, but not limited to any claim that all or any part of this Arbitration Agreement is void or voidable. The arbitration will decide the rights and liabilities, if any, of you and Co-Star. The arbitration proceeding will not be consolidated with any other matters or joined with any other proceedings or parties. The arbitrator will have the authority to grant motions dispositive of all or part of any claim or dispute. The arbitrator will have the authority to award monetary damages and to grant any non-monetary remedy or relief available to an individual under applicable law, the arbitral forum’s rules, and these Terms (including this Arbitration Agreement). The arbitrator will issue a written statement of decision describing the essential findings and conclusions on which any award (or decision not to render an award) is based, including the calculation of any damages awarded. The arbitrator shall follow the applicable law. The arbitrator has the same authority to award relief on an individual basis that a judge in a court of law would have. The arbitrator’s decision is final and binding on you and Co-Star.</li>
        <li>Waiver of Jury Trial. YOU AND CO-STAR WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE IN COURT AND RECEIVE A JUDGE OR JURY TRIAL. You and Co-Star are instead electing to have claims and disputes resolved by arbitration, except as specified in subsection (a) above. There is no judge or jury in arbitration, and court review of an arbitration award is limited.</li>
        <li>Waiver of Class or Consolidated Actions. YOU AND CO-STAR AGREE TO WAIVE ANY RIGHT TO RESOLVE CLAIMS WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT ON A CLASS, COLLECTIVE, OR REPRESENTATIVE BASIS. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If, however, this waiver of class or consolidated actions is deemed invalid or unenforceable with respect to a particular claim or dispute, neither you nor Co-Star is entitled to arbitration of such claim or dispute. Instead, all such claims and disputes will then be resolved in a court as set forth in Section 21.</li>
        <li>Batch Arbitrations. To increase efficiency of resolution, in the event 100 or more similar arbitration demands against Co-Star, presented by or with the assistance of the same law firm or organization, are submitted to an arbitration provider selected in accordance with the rules described above within a 30-day period, the arbitration provider shall (i) group the arbitration demands into batches of no more than 100 demands per batch (plus, to the extent there are less than 100 arbitration demands left over after the batching described above, a final batch consisting of the remaining demands); and (ii) provide for resolution of each batch as a single arbitration with one set of filing and administrative fees and one arbitrator assigned per batch. You agree to cooperate in good faith with Co-Star and the arbitration provider to implement such a batch approach to resolution and fees.</li>
        <li>Opt Out. You may opt out of this Arbitration Agreement. If you do so, neither you nor Co-Star can force the other to arbitrate as a result of these Terms. To opt out, you must notify Co-Star in writing no later than 30 days after first becoming subject to this Arbitration Agreement. Your notice must include your name and address, your email address (if you have one), and a CLEAR statement that you want to opt out of this Arbitration Agreement. You must send your opt-out notice to: Co–Star, Attn: Arbitration Opt-Out, 401 Broadway #610, New York, NY 10013. If you opt out of this Arbitration Agreement, all other parts of these Terms will continue to apply to you. Opting out of this Arbitration Agreement has no effect on any other arbitration agreements that you may have entered into with us or may enter into in the future with us. </li>
        <li>Survival. This Arbitration Agreement will survive any termination of your relationship with us.</li>
        <li>Modification. Notwithstanding any provision in the Terms to the contrary, we agree that if we make any future material change to this Arbitration Agreement, it will not apply to any individual claim(s) that you had already provided notice of to us.</li>
      </ol>
      <h3>Exclusive Venue</h3>
      <p>To the extent the parties are permitted under these Terms to initiate litigation in a court, both you and Co-Star agree that all claims and disputes arising out of or relating to the Terms will be litigated exclusively within the State of New York.</p>
      <h3>Waiver and Severability</h3>
      <p>No waiver by us of any term or condition set forth in these Terms will be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure by us to assert a right or provision under these Terms will not constitute a waiver of such right or provision.</p>
      <p>If any provision of these Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision will be eliminated or limited to the minimum extent such that the remaining provisions of the Terms will continue in full force and effect.</p>
      <h3>Notices</h3>
      <p>We will send all notices and other communications regarding the Services to you using the contact information you provided during your Account registration, as it may be updated by you from time to time or by posting the notice on the Services. You may change your contact information by revising your Account profile through the Services. Except as otherwise provided by applicable law, you will be considered to have received a notice from us regarding the Services when we send it to you via the contact information we have in our records for you or when we post such notice on the Services. All notices to us that are intended to have a legal effect must be in writing and delivered by a means evidenced by a delivery receipt, to the following address: Co–Star, 401 Broadway #610, New York, NY 10013. All such notices are deemed effective upon documented receipt by us.</p>
      <h3>Entire Agreement and Other General Terms</h3>
      <p>These Terms and any policies or operating rules posted by us on the Apps, Websites or in respect to the Service constitute the entire agreement between you and the Company with respect to the Services and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, with respect to the Services. These Terms and any rights hereunder may not be transferred or assigned by you without our prior written consent but may be assigned by us without restriction and without your prior consent. Any attempted transfer or assignment by you without our prior written consent will be null and void. No agency, joint venture, partnership, trust, or employment relationship is created between you and us by way of these Terms. The section headings used herein are for convenience only and will not be given any legal import.</p>
      <h3>California Electronic Commerce Disclosure to Consumers</h3>
      <p>Under California Civil Code § 1789.3, California consumers are entitled to the following disclosures. The name of the provider of these Services is:</p>
      <p>Co-Star Astrology Society</p>
      <p>401 Broadway #610</p>
      <p>New York, NY 10013</p>
      <p>To resolve any complaints about your use of this Website and our Services, please contact us using the available contact methods in the “Contact us” section below. </p>
      <p>You may also contact the Complaint Assistance Unit of the Division of Consumer Services of the Department of Consumer Affairs at:</p>
      <p>Department of Consumer Affairs
        <br />Consumer Information Center
        <br />1625 North Market Blvd., Suite N 112
        <br />Sacramento, CA 95834</p>
      <h3>Contact us</h3>
      <p>If you have any questions regarding these Terms or the Services, please contact us at {' '}<a href="mailto:horoscopes@costarastrology.com">horoscopes@costarastrology.com</a>.</p>
    </div>
  </div>
);

export default PageLayout(TermsPage);
