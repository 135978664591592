import * as React from 'react';
import { Helmet } from 'react-helmet';

import PageLayout from 'landing-page/views/PageLayout';

class ComingSoonPage extends React.Component<{}, {}> {
  render() {
    return (
      <div className="floating-container">
        <Helmet>
          <meta name="prerender-status-code" content="404" />
        </Helmet>
        <div className="floating-container-inner">
          <div>
            <p>
              <img
                width="500"
                src={require('../../../images/comingsoon.gif')}
              />
            </p>
            <h1 className="subtitle">COMING SOON...</h1>
            <p>
              <br />
              <img
                width="500"
                src={require('../../../images/comingsoon.gif')}
              />
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default PageLayout(ComingSoonPage);
