export const planets = [
  '☉  SUN',
  '☽  MOON',
  '↑  ASCENDANT',
  '☿  MERCURY',
  '♀  VENUS',
  '♂  MARS',
  '♃  JUPITER',
  '♄  SATURN',
  '♅  URANUS',
  '♆  NEPTUNE',
  'P  PLUTO',
];

export const planetDescriptors = [
  'I am fundamentally...',
  'I experience emotion...',
  'I seem...',
  'I communicate...',
  'I love...',
  'I take action...',
  'I expand through...',
  'I discipline myself...',
  'My generation innovates...',
  'I dream...',
  'My generation experiences power...',
];

export const signs = [
  '♈ ARIES',
  '♉ TAURUS',
  '♊ GEMINI',
  '♋ CANCER',
  '♌ LEO',
  '♍ VIRGO',
  '♎ LIBRA',
  '♏ SCORPIO',
  '♐ SAGITTARIUS',
  '♑ CAPRICORN',
  '♒ AQUARIUS',
  '♓ PISCES',
];

export const signDescriptors = [
  'assertive, competitive, independent',
  'practical, stubborn, sensual, reliable',
  'curious, chaotic, witty',
  'sensitive, nurturing, gentle',
  'bold, proud, attention-loving, charming',
  'responsible, meticulous, wholesome',
  'fair, just, relativist',
  'intense, deep, power-hungry',
  'restless, intellectual',
  'responsible, serious, efficient, rational',
  'unconventional, anti, boundary-pushing',
  'insightful, intuitive, empathetic',
];

export const planetsForImageOne = [{ name: 'Ascendant' }, { name: 'Sun' }, { name: 'Moon' }];

export const planetsForImageTwo = [
  { name: 'Ascendant' },
  { name: 'Sun' },
  { name: 'Moon' },
  { name: 'Mercury' },
  { name: 'Venus' },
];

export const planetObjsForImageOne = {
  Ascendant: {
    imageHeight: 21,
    imageWidth: 12,
    x: -180,
    y: -10.500000000000028,
  },
  Sun: {
    imageHeight: 21,
    imageWidth: 21,
    x: 162,
    y: -50,
  },
  Moon: {
    imageHeight: 21,
    imageWidth: 16,
    x: -180,
    y: -50,
  },
};

export const planetObjsForImageTwo = {
  Ascendant: {
    imageHeight: 21,
    imageWidth: 12,
    x: -180,
    y: -10.500000000000028,
  },
  Sun: {
    imageHeight: 21,
    imageWidth: 21,
    x: 162,
    y: -50,
  },
  Moon: {
    imageHeight: 21,
    imageWidth: 16,
    x: -180,
    y: -50,
  },
  Mercury: {
    imageHeight: 21,
    imageWidth: 12,
    x: 168,
    y: 10,
  },
  Venus: {
    imageHeight: 21,
    imageWidth: 13,
    x: 155,
    y: 55,
  },
};
