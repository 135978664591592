import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Planet, planetData } from 'astrology';
import { Analysis } from 'api';

import ACBuyButton from '../ACBuyButton';
import { sendClickEvent } from 'analytics';

export interface AnalysisBlockProps {
  planet: Planet;
  analysis: Analysis;
  showAC: boolean | undefined;
}

const PlanetAnalysisItem = ({ planet, analysis, showAC }: AnalysisBlockProps) => {
  const navigate = useNavigate();
  const handleClick = () => {
    sendClickEvent(`NatalChart_${planet}`, 'AdvChart');
    navigate('/advanced-readings/you-in-love');
  };
  const ordinals: string[] = [
    'first',
    'second',
    'third',
    'fourth',
    'fifth',
    'sixth',
    'seventh',
    'eighth',
    'ninth',
    'tenth',
    'eleventh',
    'twelfth',
  ];

  const showACButton = showAC === true && (planet === 'Mars' || planet === 'Venus');
  return (
    <div id={planet} className="analysis-block">
      <div className="analysis-symbol">
        <img src={planetData[planet].imgDataUrl} alt={`${planet} icon`} />
      </div>
      <div className="analysis-stats">
        <h3 className="analysis-title">
          {planet} in {analysis.position.sign}
        </h3>
        <div className="analysis-name">{planet}</div>
        <div className="analysis-position">
          <span className="analysis-sign">
            {analysis.position.sign}
            ,&nbsp;
          </span>
          {analysis.position.degreesInSign.degree}
          &deg;
          {analysis.position.degreesInSign.minute}'{analysis.position.degreesInSign.second}"
        </div>
        <div className="analysis-house">{ordinals[analysis.position.house - 1]} house</div>
      </div>
      <div className="analysis-analysis">{analysis.text}</div>
      {showACButton && <ACBuyButton onClick={handleClick} />}
    </div>
  );
};

export default PlanetAnalysisItem;
