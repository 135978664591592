import React from 'react';

import OracleResourcesSectionItem from './OracleResourcesSectionItem';

import styles from './OracleResourcesPage.module.scss';

type OracleResourcesSectionProps = {
  id: string;
  name: string;
  items: {
    title: string;
    url: string;
    phoneNumber?: string;
  }[];
};

const OracleResourcesSection = ({ id, name, items }: OracleResourcesSectionProps) => (
  <div>
    <div className={styles.anchorSection} id={id} />
    <div className={styles.sectionHeader}>
      <h4>{name}</h4>
    </div>
    <ul>
      {items.map((item, idx) => {
        const { title, url, phoneNumber } = item;
        return (
          <OracleResourcesSectionItem title={title} url={url} phoneNumber={phoneNumber} key={idx} />
        );
      })}
    </ul>
  </div>
);

export default OracleResourcesSection;
