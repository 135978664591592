import React, { useState } from 'react';
import Modal from 'react-modal';
import cn from 'classnames';

import SampleReading from './SampleReading';

import styles from './../YouInLove.module.scss';

type ACSampleModalProps = {
  isOpen: boolean;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const ACSampleModal = ({ isOpen, closeModal }: ACSampleModalProps) => {
  Modal.setAppElement('#app');
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={(e) => closeModal()}
      portalClassName="ACModalPortal"
      style={{ content: { inset: '50% 0 auto' } }}
    >
      <div className={styles.modalContainer}>
        <div className={styles.sampleHeading}>
          <p>*This is a sample reading for Rihanna*</p>
        </div>
        <div className={styles.readingContainer}>
          <SampleReading />
        </div>
      </div>
      <h4 onClick={closeModal} className={styles.close}>
        close
      </h4>
    </Modal>
  );
};

export default ACSampleModal;
