/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import Modal from 'react-modal';

import { randomCaptcha } from '../../../captcha';
import { CaptchaModalProps, CaptchaModalState } from './CaptchaModalTypes';

/* Randomly generate a boolean value. */
const randomBool = (): boolean => Math.round(Math.random()) === 1;

enum ImagePlacement {
  TopLeft,
  TopRight,
  BottomLeft,
  BottomRight,
}

/* Is the placement on the top or bottom? */
function placementIsTop(placement: ImagePlacement): boolean {
  return placement === ImagePlacement.TopLeft || placement === ImagePlacement.TopRight;
}

/* Is the placement to the left or right? */
function placementIsLeft(placement: ImagePlacement): boolean {
  return placement === ImagePlacement.TopLeft || placement === ImagePlacement.BottomLeft;
}

/* Where do we put the valid image? */
function getValidImagePlacement({ validOnLeft, leftOnTop }: CaptchaModalState): ImagePlacement {
  if (validOnLeft && leftOnTop) {
    return ImagePlacement.TopLeft;
  }
  if (validOnLeft) {
    return ImagePlacement.BottomLeft;
  }
  if (leftOnTop) {
    return ImagePlacement.BottomRight;
  }
  return ImagePlacement.TopRight;
}

/* Get the position diagonal to the argument */
function getDiagonalPosition(placement: ImagePlacement) {
  switch (placement) {
    case ImagePlacement.TopLeft:
      return ImagePlacement.BottomRight;
    case ImagePlacement.TopRight:
      return ImagePlacement.BottomLeft;
    case ImagePlacement.BottomLeft:
      return ImagePlacement.TopRight;
    case ImagePlacement.BottomRight:
      return ImagePlacement.TopLeft;
    default:
  }
}

const CaptchaModal = ({ onSuccess, closeModal }: CaptchaModalProps) => {
  Modal.setAppElement('#app');
  const [captchaData, setCaptchaData] = useState(randomCaptcha());
  const [validOnLeft, setValidOnLeft] = useState(randomBool());
  const [leftOnTop, setLeftOnTop] = useState(randomBool());
  const [showErrorState, setShowErrorState] = useState(false);

  /* Randomize the state & set the error state to true. */
  const onInvalidClick = () => {
    let newCaptcha = randomCaptcha();
    while (newCaptcha.validDescription === captchaData.validDescription) {
      newCaptcha = randomCaptcha();
    }

    setCaptchaData(newCaptcha);
    setValidOnLeft(randomBool());
    setLeftOnTop(randomBool());
    setShowErrorState(true);
  };

  /* Display the modal's title or error message */
  const renderTitle = (): JSX.Element => {
    if (showErrorState) {
      return <h4 className="red uppercase center">Please Try Again.</h4>;
    }
    return <h4 className="uppercase center">Please Prove You&apos;re Human.</h4>;
  };

  /* Display the captcha's description as a prompt. */
  const renderPrompt = (): JSX.Element => (
    <h3 className="center">Click the {captchaData.validDescription} to continue.</h3>
  );

  /* Display either the valid or invalid image */
  const renderImage = (isValidImage: boolean): JSX.Element => {
    const imgUrl = isValidImage ? captchaData.validImg : captchaData.invalidImg;
    const clickHandler = isValidImage ? onSuccess : onInvalidClick;
    return (
      <img className="captcha-image" alt="captcha" src={imgUrl} onClick={clickHandler.bind(this)} />
    );
  };

  /* Render a single row of the image grid */
  const renderRow = (image: JSX.Element, placement: ImagePlacement): JSX.Element => {
    if (placementIsLeft(placement)) {
      return (
        <tr>
          <td>{image}</td>
          <td />
        </tr>
      );
    }
    return (
      <tr>
        <td />
        <td>{image}</td>
      </tr>
    );
  };

  /* Display the valid/invalid image grid according to the placement state. */
  const renderImageGrid = (): JSX.Element => {
    const validImage = renderImage(true);
    const invalidImg = renderImage(false);
    const validPlacement = getValidImagePlacement({
      validOnLeft,
      leftOnTop,
      captchaData,
      showErrorState,
    });
    const invalidPlacement = getDiagonalPosition(validPlacement);
    if (placementIsTop(validPlacement)) {
      return (
        <table>
          <tbody>
            {renderRow(validImage, validPlacement)}
            {renderRow(invalidImg, invalidPlacement)}
          </tbody>
        </table>
      );
    }
    return (
      <table>
        <tbody>
          {renderRow(invalidImg, invalidPlacement)}
          {renderRow(validImage, validPlacement)}
        </tbody>
      </table>
    );
  };

  return (
    <Modal
      isOpen
      onRequestClose={closeModal}
      portalClassName="CaptchaModalPortal"
      style={{ content: { inset: '50% 0 auto' } }}
    >
      {renderTitle()}
      {renderPrompt()}
      {renderImageGrid()}
    </Modal>
  );
};

export default CaptchaModal;
