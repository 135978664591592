import { fromNullable } from 'fp-ts/lib/Option';

export type AppRequestInit = {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  headers?: any;
  queryParams?: { [key: string]: string | undefined };
  allow404?: boolean;
  body?: any;
};

export abstract class ApiBase {
  private base: string;

  constructor(base: string) {
    this.base = base;
  }

  abstract defaultHeaders(): HeadersInit;

  protected jsonReq<T>(endpoint: string, options?: AppRequestInit): Promise<T> {
    const headers = {
      ...(options && options.headers),
      'Content-Type': 'application/json',
      ...this.defaultHeaders(),
    };

    const url: URL = new URL(`${this.base}${endpoint}`);
    fromNullable(options)
      .chain((o) => fromNullable(o.queryParams))
      .map((qp) =>
        Object.keys(qp).map((k) => ({
          paramName: k,
          paramValue: fromNullable(qp[k]),
        })),
      )
      .foldL(
        () => {},
        (kvs) => {
          kvs.forEach((kv) => {
            kv.paramValue.foldL(
              () => {},
              (pv) => url.searchParams.append(kv.paramName, pv),
            );
          });
        },
      );

    return fetch(url.toString(), {
      ...options,
      headers,
      ...(options && { body: JSON.stringify(options.body) }),
    })
      .then((response) => {
        if (response.status > 300) {
          return Promise.reject(response);
        }

        return Promise.resolve(response);
      })
      .then((response: Response) => response.json().catch(() => ({} as any)));
  }
}

export interface Entity<T> {
  id: any;
  value: T;
}
