import { Line3D, Plane, Point3D } from 'geometry';
import Cursor from 'cursor';
import { fromNullable } from 'fp-ts/lib/Option';

import '../style/grid.scss';

export class Grid {
  private plane!: Plane;

  private canvas: HTMLCanvasElement;

  private ctx!: CanvasRenderingContext2D;

  private camera!: Point3D;

  constructor() {
    this.canvas = document.createElement('canvas');
    this.canvas.className = 'gridCanvas';
    fromNullable(this.canvas.getContext('2d')).foldL(
      () => {
        console.error('Could not get canvas');
      },
      (canv) => (this.ctx = canv),
    );

    window.onresize = this.resize.bind(this);

    this.resize();
    requestAnimationFrame(this.draw.bind(this));
    document.body.appendChild(this.canvas);
  }

  resize() {
    this.camera = new Point3D(innerWidth / 2, innerHeight / 2, -81);

    const upperZ = 100;
    const upperY =
      this.camera.y -
      ((innerHeight / 2 - this.camera.y) * (this.camera.z - upperZ)) /
        this.camera.z;

    this.canvas.width = innerWidth;
    this.canvas.height = innerHeight;

    this.plane = new Plane(
      new Point3D(innerWidth * 2, upperY, upperZ), // upper left
      new Point3D(-innerWidth, upperY, upperZ), // upper right
      new Point3D(innerWidth * 2, innerHeight, -80), // lower left
      new Point3D(-innerWidth, innerHeight, -80), // lower right
    );
  }

  draw(timestamp: number) {
    this.ctx.clearRect(0, 0, innerWidth, innerHeight);
    this.ctx.strokeStyle = '#e7e7e7';
    this.ctx.lineWidth = 0.9;

    this.plane
      .getLines(timestamp, Cursor.smoothPercent, 15)
      .forEach((ln: Line3D) => {
        ln.project(this.camera).draw(this.ctx);
        ln.project(this.camera).invert(innerHeight).draw(this.ctx);
      });

    this.ctx.beginPath();
    this.ctx.moveTo(0, innerHeight / 2);
    this.ctx.lineTo(innerWidth, innerHeight / 2);
    this.ctx.stroke();

    requestAnimationFrame(this.draw.bind(this));
  }
}
