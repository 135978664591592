import React from 'react';

import styles from './AdvancedReadingCardContainer.module.scss';

type CardContainerProps = {
  card: JSX.Element;
  description: string;
};

const AdvancedReadingsCardContainer = ({ card, description }: CardContainerProps) => (
  <div className={styles.cardContainer}>
    {card}
    <div className={styles.cardTextContainer}>
      <p>{description}</p>
    </div>
  </div>
);

export default AdvancedReadingsCardContainer;
