import React from 'react';

import SectionPadding from 'landing-page/components/SectionPadding';

import TransitsZodiacRing from './TransitsZodiacRing';

import styles from './Transits.module.scss';
import { planetObjsForImageOne, planetsForImageOne } from './TransitsTypes';

const LeftSideTransit = () => (
  <div className={styles.hideOnDesktop}>
    <div className={styles.transitsContainer}>
      <div className={styles.leftSideTransitContainer}>
        <TransitsZodiacRing
          planetObjs={planetObjsForImageOne}
          planets={planetsForImageOne}
          orbitAnimation="black"
          startingSign="Leo"
          className={styles.leftSideTransit}
        />
      </div>
    </div>
    <div className={styles.rightSideText}>
      <div className={styles.rightCaption}>
        <h4>
          <span>&larr;</span> TRANSITING SUN CONJUNCT NATAL SUN,
        </h4>
        <h4>OR SOLAR RETURN</h4>
        <ul>
          <li>
            Your birthday!
            <br />
            Every June ~19
          </li>
          <li>Ego activation</li>
        </ul>
      </div>

      <div className={styles.rightCaption}>
        <h4>
          <span>&larr;</span> Transiting Sun
        </h4>
        <h4>Square Natal Sun</h4>
        <ul>
          <li>Every March ~19</li>
          <li>Ego troubles</li>
        </ul>
      </div>
    </div>
  </div>
);

export default LeftSideTransit;
