import React from 'react';
import { Link } from 'react-router-dom';

import PageLayout from 'landing-page/views/PageLayout';
import { ProspectiveUserForm } from '../../components/form';
import { PressFeatures } from '../press-features';
import FooterForm from 'landing-page/components/FooterForm';

const scrollAway = () => {
  window.scroll(0, window.innerHeight);
};
class AboutPage extends React.Component {
  render() {
    return (
      <div>
        <div className="floating-container">
          <div className="floating-container-inner">
            <h1>Astrology has been helping people reflect and connect for thousands of years.</h1>
            <h4>
              <br />
              Co–Star deciphers it for today.
            </h4>
          </div>
          <a
            tabIndex={1}
            onClick={scrollAway}
            className="hero-arrow about-hero-arrow body-font-basics"
          >
            &darr;
          </a>
        </div>

        <div className="about-containers-parent">
          <div className="container about">
            <img
              className="about-image"
              src={require('../../../images/cutouts/cutout-peony.png')}
            />
            <img
              className="about-image"
              src={require('../../../images/cutouts/cutout-crystal.png')}
            />
            <img className="about-image" src={require('../../../images/cutouts/cutout-twig.png')} />

            <div className="about-box">
              <h2> Reality is unraveling faster than we can scroll. </h2>
              <p>
                {' '}
                Screens shape our daily lives. We consider climate change another notification to
                check. We ignore threats of nuclear war for answers in our DMs. We check our phones
                a combined 8 billion times a day. The same technology that makes us the most
                advanced generation renders us the most anxious in history.{' '}
              </p>
              <p>
                {' '}
                In a world where instability is justified beyond comprehension, we force everything
                that doesn’t fit through a filter of likes, swipes, and follows. But rationality
                suffocates. The human in us craves connection; the dreamer yearns for mystery. So we
                search for meaning in the madness, if only for the sign that makes us take a leap of
                faith. Where technorationalism ends, we begin.{' '}
              </p>
            </div>
          </div>
          <div className="container about">
            <img className="about-image" src={require('../../../images/cutouts/cutout-moth.png')} />
            <img
              className="about-image"
              src={require('../../../images/cutouts/cutout-magpie.png')}
            />
            <img
              className="about-image"
              src={require('../../../images/cutouts/cutout-tulip.png')}
            />

            <div className="about-box">
              <h2> Astrology is an exit. </h2>
              <p>
                {' '}
                Based on 2,500 years of reflection on rhythmic orbit and planetary motion, astrology
                is an advanced art for the modern world. By correlating human experiences across
                space and time, astrology takes us beyond the walls of our bedrooms and ceilings of
                our day jobs. It adds mystery and magnitude to a clinical and confusing reality. It
                transforms daily routines into something in the context of a vast universe.{' '}
              </p>
              <p>
                {' '}
                But it’s not all supernatural. Astrology is a complete system based on the exact
                positions of stars and planets in our sky. Every element is significant. In
                combination, they tell a complex story of who we are. What appears ambiguous in
                isolation is clear in symphony: time reveals pattern, pattern presents context,
                context fosters connection. Astrology illuminates the unknown.{' '}
              </p>
            </div>
          </div>
          <div className="container about">
            <img
              className="about-image"
              src={require('../../../images/cutouts/cutout-stone2.png')}
            />
            <img
              className="about-image"
              src={require('../../../images/cutouts/cutout-fruit.png')}
            />

            <div className="about-box">
              <h2> Co–Star is a key for decoding the cosmos. </h2>
              <p>
                {' '}
                Imagine yourself in conversation with the universe. How does it go? If astrology
                puts your temporal body in context of the solar system, Co–Star deciphers your
                reality without hiring a personal astrologer.{' '}
              </p>
              <p>
                {' '}
                Once we make sense of ourselves, Co–Star facilitates new ways to relate to others.
                It’s a filter to express what can be hard to say of emotions and reactions,
                strengths and weaknesses, pasts and futures. It’s a tool to connect meaningfully
                with friends and lovers, or to tread lightly with strangers and enemies. And it’s a
                spark for real talk in a sea of small talk: mindless weather musings become mindful
                conversation about who’s likely to think big picture, work themselves dry, or stop
                short of commitment.{' '}
              </p>
              <p>
                {' '}
                Co–Star is a catalyst for collectivity in a chaotic world: to see and be seen frees
                us to find our place in the universe.{' '}
              </p>
            </div>
          </div>
          <div className="container about">
            <img className="about-image" src={require('../../../images/cutouts/cutout-wing.png')} />
            <img
              className="about-image"
              src={require('../../../images/cutouts/cutout-pluto.png')}
            />

            <div className="about-box">
              <h2> Powered by AI that merges NASA data with the insight of human astrologers. </h2>
              <p>
                {' '}
                Most horoscopes are created from our sun sign alone — one small part of a massive
                solar system. The moon, the planets, and the twelve houses of the zodiac are
                essential to our whole. Without them, our horoscope barely skims the surface. It
                leaves out key information about how we love, think, and react. Predictions are
                obsolete. Think of it as giving a book report on the title alone.
              </p>
              <p>
                {' '}
                Using NASA data interpreted by humans, Co–Star delivers personalized readings in
                real time. It starts by using the day, time, and place we were born to create an
                astronomical snapshot of the sky. This picture creates an in-depth personality
                analysis on character traits, behavioral tendencies, and emotional leanings: our
                natal chart. This chart is mapped against the current locations of the planets, to
                show what our chart means today: our horoscope. Then it’s compared to the charts of
                our friends to reveal how we get along: our compatibility. Using artificial
                intelligence, Co–Star translates data into language we can read, understand, and
                share.{' '}
              </p>
            </div>
          </div>

          <div className="container about">
            <div className="about-finale">
              <h2>
                {' '}
                We’re hacking tech, culture, and consciousness to build new practices for reflection
                & collectivity.{' '}
              </h2>
              <h4>
                <Link to="/jobs" className="smallcaps center">
                  {' '}
                  <br />
                  We're hiring. Join us. &rarr;
                </Link>{' '}
              </h4>
            </div>
          </div>
          <PressFeatures />
          <FooterForm formLocation="About" />
        </div>
      </div>
    );
  }
}

export default PageLayout(AboutPage);
