export type Sign =
  | 'Aries'
  | 'Taurus'
  | 'Gemini'
  | 'Cancer'
  | 'Leo'
  | 'Virgo'
  | 'Libra'
  | 'Scorpio'
  | 'Sagittarius'
  | 'Capricorn'
  | 'Aquarius'
  | 'Pisces';

export type Planet =
  | 'Moon'
  | 'Sun'
  | 'Mars'
  | 'Mercury'
  | 'Venus'
  | 'Jupiter'
  | 'Neptune'
  | 'Saturn'
  | 'Uranus'
  | 'Pluto'
  | 'Ascendant';

export type Aspect =
  | 'Conjunction'
  | 'Opposition'
  | 'Trine'
  | 'Square'
  | 'Sextile'
  | 'Semi-square'
  | 'Sesqui-quadrate'
  | 'Semi-sextile'
  | 'Quincunx'
  | 'Quintile';

export var planets = [
  'Moon',
  'Sun',
  'Mars',
  'Mercury',
  'Venus',
  'Jupiter',
  'Neptune',
  'Saturn',
  'Uranus',
  'Pluto',
  'Ascendant',
];

export var signs = [
  'Aries',
  'Taurus',
  'Gemini',
  'Cancer',
  'Leo',
  'Virgo',
  'Libra',
  'Scorpio',
  'Sagittarius',
  'Capricorn',
  'Aquarius',
  'Pisces',
];

// this order is significat to the natal remoteAnalysis generator, don't change it, don't remember why sry
export var natalChartOrderedSigns = [
  'Virgo',
  'Leo',
  'Cancer',
  'Gemini',
  'Taurus',
  'Aries',
  'Pisces',
  'Aquarius',
  'Capricorn',
  'Sagittarius',
  'Scorpio',
  'Libra',
];

export function randomChart(): any {
  return {
    Moon: signs[Math.floor(Math.random() * signs.length)],
    Sun: signs[Math.floor(Math.random() * signs.length)],
    Mars: signs[Math.floor(Math.random() * signs.length)],
    Mercury: signs[Math.floor(Math.random() * signs.length)],
    Venus: signs[Math.floor(Math.random() * signs.length)],
    Jupiter: signs[Math.floor(Math.random() * signs.length)],
    Neptune: signs[Math.floor(Math.random() * signs.length)],
    Saturn: signs[Math.floor(Math.random() * signs.length)],
    Uranus: signs[Math.floor(Math.random() * signs.length)],
    Pluto: signs[Math.floor(Math.random() * signs.length)],
    Ascendant: signs[Math.floor(Math.random() * signs.length)],
  };
}

export interface PlanetData {
  symbol: string;
  imgDataUrl: string;
  whiteImgDataUrl: string;
  order: number;
  scores: { [S in Sign]: number };
}

function defaultScores(defaultScore: number): { [S in Sign]: number } {
  return {
    Aries: defaultScore,
    Taurus: defaultScore,
    Gemini: defaultScore,
    Cancer: defaultScore,
    Leo: defaultScore,
    Virgo: defaultScore,
    Libra: defaultScore,
    Scorpio: defaultScore,
    Sagittarius: defaultScore,
    Capricorn: defaultScore,
    Aquarius: defaultScore,
    Pisces: defaultScore,
  };
}

export const planetData: { [Key in Planet]: PlanetData } = {
  Sun: {
    symbol: '☉',
    imgDataUrl: require('!!url-loader!../images/sun.planet.svg'),
    whiteImgDataUrl: require('!!url-loader!../images/sun.planet.white.svg'),
    order: 1,
    scores: {
      ...defaultScores(8),
      Leo: 9,
    },
  },
  Ascendant: {
    symbol: '↑',
    imgDataUrl: require('!!url-loader!../images/ascendant.planet.svg'),
    whiteImgDataUrl: require('!!url-loader!../images/ascendant.planet.white.svg'),
    order: 2,
    scores: { ...defaultScores(8) },
  },
  Moon: {
    symbol: '☽',
    imgDataUrl: require('!!url-loader!../images/moon.planet.svg'),
    whiteImgDataUrl: require('!!url-loader!../images/moon.planet.white.svg'),
    order: 3,
    scores: {
      ...defaultScores(8),
      Cancer: 9,
    },
  },
  Mercury: {
    symbol: '☿',
    imgDataUrl: require('!!url-loader!../images/mercury.planet.svg'),
    whiteImgDataUrl: require('!!url-loader!../images/mercury.planet.white.svg'),
    order: 4,
    scores: {
      ...defaultScores(6),
      Gemini: 7,
      Virgo: 7,
    },
  },
  Venus: {
    symbol: '♀',
    order: 5,
    imgDataUrl: require('!!url-loader!../images/venus.planet.svg'),
    whiteImgDataUrl: require('!!url-loader!../images/venus.planet.white.svg'),
    scores: {
      ...defaultScores(6),
      Taurus: 7,
      Libra: 7,
    },
  },
  Mars: {
    symbol: '♂',
    imgDataUrl: require('!!url-loader!../images/mars.planet.svg'),
    whiteImgDataUrl: require('!!url-loader!../images/mars.planet.white.svg'),
    order: 6,
    scores: {
      ...defaultScores(6),
      Aries: 7,
    },
  },
  Jupiter: {
    symbol: '♃',
    imgDataUrl: require('!!url-loader!../images/jupiter.planet.svg'),
    whiteImgDataUrl: require('!!url-loader!../images/jupiter.planet.white.svg'),
    order: 7,
    scores: {
      ...defaultScores(2),
      Sagittarius: 3,
    },
  },
  Saturn: {
    symbol: '♄',
    imgDataUrl: require('!!url-loader!../images/saturn.planet.svg'),
    whiteImgDataUrl: require('!!url-loader!../images/saturn.planet.white.svg'),
    order: 8,
    scores: {
      ...defaultScores(2),
      Capricorn: 3,
    },
  },
  Uranus: {
    symbol: '♅',
    imgDataUrl: require('!!url-loader!../images/uranus.planet.svg'),
    whiteImgDataUrl: require('!!url-loader!../images/uranus.planet.white.svg'),
    order: 9,
    scores: {
      ...defaultScores(1),
      Aquarius: 2,
    },
  },
  Neptune: {
    symbol: '♆',
    imgDataUrl: require('!!url-loader!../images/neptune.planet.svg'),
    whiteImgDataUrl: require('!!url-loader!../images/neptune.planet.white.svg'),
    order: 10,
    scores: {
      ...defaultScores(1),
      Pisces: 2,
    },
  },
  Pluto: {
    symbol: '♇',
    imgDataUrl: require('!!url-loader!../images/pluto.planet.svg'),
    whiteImgDataUrl: require('!!url-loader!../images/pluto.planet.white.svg'),
    order: 11,
    scores: {
      ...defaultScores(1),
      Scorpio: 2,
    },
  },
};

export interface AspectData {
  importance: number;
  goodness: number;
}

export const aspectData: { [Key in Aspect]: AspectData } = {
  Conjunction: {
    importance: 3,
    goodness: 2,
  },
  Opposition: {
    importance: 3,
    goodness: 2,
  },
  Trine: {
    importance: 2,
    goodness: 3,
  },
  Square: {
    importance: 3,
    goodness: 1,
  },
  Sextile: {
    importance: 3,
    goodness: 3,
  },
  'Semi-square': {
    importance: 1,
    goodness: 1,
  },
  'Sesqui-quadrate': {
    importance: 1,
    goodness: 1,
  },
  'Semi-sextile': {
    importance: 1,
    goodness: 3,
  },
  Quincunx: {
    importance: 2,
    goodness: 1,
  },
  Quintile: {
    importance: 1,
    goodness: 2,
  },
};

// solid (important), dashed (medium), dotted (not important)
export function importanceToDash(aspect) {
  if (aspect.importance == 3) {
    return 0;
  }
  if (aspect.importance == 1) {
    return 2;
  }
  return 8;
}

// black (bad), dark grey (complicated), light grey (good)
export function goodnessToColor(aspect) {
  if (aspect.goodness == 3) {
    return 'rgb(200,200,200)';
  }
  if (aspect.goodness == 1) {
    return 'rgb(0,0,0)';
  }
  return 'rgb(120,120,120)';
}

/** 3 == good, 2 == complicated, 1 == bad */
export function goodnessToChordClass(aspect: AspectData): string {
  if (aspect.goodness === 3) {
    return 'chord-good';
  }
  if (aspect.goodness === 1) {
    return 'chord-bad';
  }
  return 'chord-complicated';
}
