import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import cn from 'classnames';
import PageLayout from 'landing-page/views/PageLayout';
import { AnchorLink, scrollToLocationHash } from '../../components/anchor-link';

import OracleResourcesSection from './OracleResourcesSection';
import OracleResourcesPageBottomBar from './OracleResourcesPageBottomBar';

import { resourceItems, resourceSections } from './OracleResourcesPageTypes';

import styles from './OracleResourcesPage.module.scss';

const OracleResourcesPage = () => {
  const kebabCase = (str) =>
    str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .join('-')
      .toLowerCase();

  const camelCase = (str) =>
    str
      .replace(/(?:^.|[A-Z]|\b.)/g, (letter, index) =>
        index === 0 ? letter.toLowerCase() : letter.toUpperCase(),
      )
      .replace(/\s+/g, '');

  useEffect(() => {
    scrollToLocationHash();
  }, []);

  const anchorLinks = resourceSections.map((el) => {
    const id = kebabCase(el);
    return (
      <AnchorLink targetId={id} key={id}>
        <h4>{el}</h4>
      </AnchorLink>
    );
  });
  const sections = resourceSections.map((el) => {
    const key = camelCase(el);
    const id = kebabCase(el);
    return <OracleResourcesSection name={el} id={id} items={resourceItems[key]} />;
  });
  return (
    <div className={styles.backgroundContainer}>
      <Helmet>
        <title>Mental Health Resources | Co-Star</title>
        <meta
          name="description"
          content="Co–Star has collected a list of mental health and other key resources for our users who are struggling."
        />
        <meta property="og:title" content="Mental Health Resources collected by Co-Star." />
        <meta property="og:url" content="https://www.costarastrology.com/mental-health-resources" />
        <meta property="og:type" content="article" />
        <meta
          property="og:description"
          content="If you’re struggling or in crisis, we hope these resources will help."
        />
        <meta
          property="og:image"
          content={require('../../../../images/mental-health-resources-og.png')}
        />
        <style>{'canvas { display: none; }'}</style>
      </Helmet>
      <div className={cn(styles.pageContainer, 'floating-container')}>
        <div className={styles.headerConatiner}>
          <h1>Mental Health Resources</h1>
          <p>
            If you or a loved one are struggling, these resources can provide information and
            connect you with professional help. Be well. 🤍
            <br />
            <br />
            If you’re in immediate danger, call 911.
          </p>
          <div className={styles.anchorLinks}>{anchorLinks}</div>
        </div>
        <div>
          <img
            src={require('../../../../images/cutouts/cutout-peony.png')}
            className={styles.cutout}
            alt=""
          />
          <img
            className={styles.cutout}
            src={require('../../../../images/cutouts/cutout-twig.png')}
            alt=""
          />
          <img
            className={styles.cutout}
            src={require('../../../../images/cutouts/cutout-moth.png')}
            alt=""
          />
          <img
            className={styles.cutout}
            src={require('../../../../images/cutouts/cutout-tulip.png')}
            alt=""
          />
          {sections}
        </div>
      </div>
      <OracleResourcesPageBottomBar />
    </div>
  );
};

export default PageLayout(OracleResourcesPage);
