import * as d3 from 'd3';
import * as _ from 'underscore';
import { Analysis, AstroAnalysis } from 'api';
import {
  aspectData,
  goodnessToColor,
  importanceToDash,
  natalChartOrderedSigns,
  PlanetData,
  planetData,
  goodnessToChordClass,
} from 'astrology';
import '../style/natal-chart.scss';
import { fromNullable } from 'fp-ts/lib/Option';
import { curry } from 'fp-ts/lib/function';


// this might have issues - woff is most supported, but still not 100% - can we call via the fonts.css file?
const font = require('!!url-loader!../images/fonts/Akkurat-Mono.ttf');

function signToAngle(sign) {
  const i = natalChartOrderedSigns.indexOf(sign) * toRad(30);
  return i;
}

function toRad(angle) {
  return (angle * Math.PI) / 180;
}

function toDeg(rad) {
  return (rad * 180) / Math.PI;
}

function rotateBy90(angle) {
  return angle - Math.PI / 2;
}

interface PlanetObject {
  name: string;
  analysis: Analysis;
}

interface ProcessedHouseCusp {
  startAngle: number;
  endAngle: number;
}

interface ProcessedSign {
  name: string;
  startAngle: number;
  endAngle: number;
}

interface ProcessedNatalAspect {
  source: number[];
  target: number[];
  aspectName: string;
}

interface PlanetPositions {
  [key: string]: {
    planetPositionRadians: number;
    x: number;
    y: number;
    imageWidth: number;
    imageHeight: number;
    overlap: string[];
    overlapArea?: number[];
    repositioned: boolean;
    sign: string;
  };
}

interface ProcessedNatalChart {
  ascendant: Analysis;
  processedHouseCusps: ProcessedHouseCusp[];
  processedSigns: ProcessedSign[];
  processedPlanets: PlanetObject[];
  processedNatalAspects: ProcessedNatalAspect[];
}

type Axis = 'x' | 'y';

export class AspectFocusedNatalChartGenerator {
  offsetAngle: number;

  mainAngle: number;

  data: ProcessedNatalChart;

  width: number;

  height: number;

  radius: number;

  svgOuter: any; // d3.Selection<d3.BaseType, {}, null, undefined>;

  svg: any; // d3.Selection<d3.BaseType, {}, d3.BaseType, {}>;

  redirectOnPlanetImageClick: boolean;

  whitePlanetImages: boolean;

  planetPositions: PlanetPositions;

  isAC: boolean;

  planetOverlap: string[][];

  constructor(el: Element, props: NatalChartProps) {
    this.width = 600;
    this.height = 600;
    this.radius = Math.min(this.width, this.height) / 2;

    this.redirectOnPlanetImageClick = props.redirectOnPlanetImageClick;
    this.whitePlanetImages = props.whitePlanetImages;
    this.isAC = props.isAC;
    const natalChart: AstroAnalysis = props.chartData;

    this.offsetAngle =
      natalChart.planets.Ascendant.position.radians -
      signToAngle(natalChart.planets.Ascendant.position.sign) -
      toRad(30 - natalChart.planets.Ascendant.position.degreesInSign.degree);
    this.mainAngle = natalChart.planets.Ascendant.position.radians - this.offsetAngle;

    this.data = this.processNatalChart(natalChart);
    this.create(el, props);
    this.planetPositions = {};
    this.planetOverlap = [];
  }

  processNatalChart = (natalChart: AstroAnalysis): ProcessedNatalChart => ({
    ascendant: natalChart.planets.Ascendant,
    processedHouseCusps: this.processHouseAngles(natalChart),
    processedSigns: _.map(natalChartOrderedSigns, (sign) => ({
      name: sign,
      startAngle: this.shiftAscendant(rotateBy90(signToAngle(sign))),
      endAngle: this.shiftAscendant(rotateBy90(signToAngle(sign) + toRad(30))),
    })),
    processedPlanets: this.processPlanets(natalChart),
    processedNatalAspects: _.map(natalChart.natalAspects, (data) => ({
      source: this.radToCoord(
        natalChart.planets[data.planetA].position.radians,
        natalChart.planets.Ascendant,
      ),
      target: this.radToCoord(
        natalChart.planets[data.planetB].position.radians,
        natalChart.planets.Ascendant,
      ),
      aspectName: data.aspect.name,
    })),
  });

  processPlanets = (natalChart: AstroAnalysis): PlanetObject[] => {
    const planetObjects = Object.keys(natalChart.planets).map((p) => ({
      name: p,
      analysis: natalChart.planets[p],
    }));
    return planetObjects;
  };

  /* takes in number to subtract from radius, x or y as axis, image height or width, and planet position radians
   to calculate planet coords */
  generatePlanetCoords = (
    radiusDifference: number,
    axis: Axis,
    imageDivisor: number,
    planetPositionRadians: number,
  ): number => {
    const cosOrSin = axis === 'x' ? Math.cos : Math.sin;
    return (
      (this.radius - radiusDifference) *
        cosOrSin(-1 * (planetPositionRadians + (Math.PI - this.data.ascendant.position.radians))) -
      imageDivisor / 2
    );
  };

  /* iterates through planet positions object and 
    1) checks if there is any overlap
    2) after it tracks any overlap, adjusts planet positions as needed
  */
  findPlanetOverlap = (): void => {
    const sortedByRadians = Object.keys(this.planetPositions)
      .map((planet) => ({
        planet,
        planetPositionRadians: this.planetPositions[planet].planetPositionRadians,
        foundOverlap: false,
      }))
      .sort((a, b) => a.planetPositionRadians - b.planetPositionRadians);

    let idx = 0;

    while (idx < sortedByRadians.length) {
      const { planet, planetPositionRadians } = sortedByRadians[idx];
      let range = planetPositionRadians + 0.15;
      const overlapArray: string[] = [];

      for (let i = idx + 1; i < sortedByRadians.length; i += 1) {
        const nextPlanet = sortedByRadians[i];

        if (nextPlanet.planetPositionRadians <= range) {
          overlapArray.push(nextPlanet.planet);
          range = nextPlanet.planetPositionRadians + 0.15;
        }
      }

      if (overlapArray.length > 0) {
        overlapArray.unshift(planet);
        idx = sortedByRadians.findIndex(
          (el) => el.planet === overlapArray[overlapArray.length - 1],
        );
        this.planetOverlap.push(overlapArray);
      } else {
        idx += 1;
      }
    }
    
    this.planetOverlap.forEach((overlap) => {
      const overlapPlanets = overlap.map((el) => this.planetPositions[el]);
      const ascRadians = this.planetPositions.Ascendant.planetPositionRadians;
      const ascendantIdx = overlapPlanets.findIndex(
        (el) => el.planetPositionRadians === ascRadians,
      );
      const containsAsc = ascendantIdx !== -1;

      // specific logic for overlap containing asc to preserve asc location
      if (containsAsc) {
        overlapPlanets[ascendantIdx].repositioned = true;

        for (let i = 0; i < overlapPlanets.length; i += 1) {
          if (i !== ascendantIdx) {
            const ascDiff = ascendantIdx - i;
            const ascRadDiff = 0.1 * Math.abs(ascDiff);

            const { imageHeight, imageWidth } = overlapPlanets[i];

            const offset = ascDiff > 0 ? ascRadians - ascRadDiff : ascRadians + ascRadDiff;

            overlapPlanets[i].x = this.generatePlanetCoords(75, 'x', imageWidth, offset);
            overlapPlanets[i].y = this.generatePlanetCoords(75, 'y', imageHeight, offset);
            overlapPlanets[i].repositioned = true;
          }
        }
      } else {
        const radianMidpoint =
          (overlapPlanets[0].planetPositionRadians +
            overlapPlanets[overlapPlanets.length - 1].planetPositionRadians) /
          2;

        const midpoint = overlapPlanets.length / 2;

        let radDiff;

        if (!Number.isInteger(midpoint)) {
          const { imageHeight, imageWidth } = overlapPlanets[midpoint - 0.5];
          overlapPlanets[midpoint - 0.5].x = this.generatePlanetCoords(
            75,
            'x',
            imageWidth,
            radianMidpoint,
          );
          overlapPlanets[midpoint - 0.5].y = this.generatePlanetCoords(
            75,
            'y',
            imageHeight,
            radianMidpoint,
          );

          overlapPlanets[midpoint - 0.5].repositioned = true;
          radDiff = 0.1 * Math.floor(midpoint);
        }

        const radDiffMultiplier = overlapPlanets.length > 2 ? midpoint + 1 : midpoint;

        radDiff = radDiff || 0.05 * Math.floor(radDiffMultiplier);

        for (let i = 0; i < Math.floor(midpoint); i += 1) {
          const lastIdx = overlapPlanets.length - 1 - i;

          const { imageWidth, imageHeight } = overlapPlanets[i];
          const secondImageWidth = overlapPlanets[lastIdx].imageWidth;
          const secondImageHeight = overlapPlanets[lastIdx].imageHeight;

          overlapPlanets[i].x = this.generatePlanetCoords(
            75,
            'x',
            imageWidth,
            radianMidpoint - radDiff,
          );
          overlapPlanets[i].y = this.generatePlanetCoords(
            75,
            'y',
            imageHeight,
            radianMidpoint - radDiff,
          );
          overlapPlanets[i].repositioned = true;

          overlapPlanets[lastIdx].x = this.generatePlanetCoords(
            75,
            'x',
            secondImageWidth,
            radianMidpoint + radDiff,
          );
          overlapPlanets[lastIdx].y = this.generatePlanetCoords(
            75,
            'y',
            secondImageHeight,
            radianMidpoint + radDiff,
          );
          overlapPlanets[lastIdx].repositioned = true;

          if (Number.isInteger(midpoint) && i === Math.floor(midpoint - 2)) radDiff -= 0.1;
          else {
            radDiff -= 0.1;
          }
        }
      }
    });
  };

  shiftAscendant = (angle) => angle - this.mainAngle;

  processHouseAngles = (natalChart) => {
    const { houseCusps } = natalChart;
    return houseCusps.map((v, i) => {
      const endAngle = houseCusps[i + 1] ? houseCusps[i + 1] : houseCusps[0];

      let diff = endAngle - v;
      if (diff < 0) {
        diff = endAngle + Math.PI * 2 - v;
      }
      const sa = this.shiftAscendant(rotateBy90(v - this.offsetAngle));
      const ea = this.shiftAscendant(rotateBy90(v + diff - this.offsetAngle));

      return { startAngle: -1 * sa, endAngle: -1 * ea };
    });
  };

  drawHouses = (svg): Promise<void> => {
    const promise = new Promise<void>((resolve, _) => {
      const arc = d3
        .arc()
        .innerRadius(this.radius - 90)
        .outerRadius(this.radius - 60)
        .startAngle((d, _) => d.startAngle)
        .endAngle((d) => d.endAngle);

      const path = svg.selectAll('.house-path').data(this.data.processedHouseCusps).enter();

      path
        .append('path')
        .attr('class', 'house-path')
        .attr('d', arc)
        .style('fill', '#fff')
        .style('stroke', '#d6d6d6');

      resolve();
    });

    return promise;
  };

  drawSigns = (svg): Promise<void> => {
    const promise = new Promise<void>((resolve, _) => {
      const outerArc = d3
        .arc()
        .innerRadius(this.radius - 55)
        .outerRadius(this.radius - 20)
        .startAngle((d, _) => d.startAngle)
        .endAngle((d) => d.endAngle);

      function isUpsideDown(endAngle): boolean {
        // js mod is kinda busted for negative numbers?
        const modAngle = endAngle % (Math.PI * 2);
        const newAngle = (toDeg(modAngle) + 360) % 360;
        return newAngle > 90 && newAngle < 270;
      }

      svg
        .selectAll('.sign-path')
        .data(this.data.processedSigns)
        .enter()
        .append('path')
        .attr('class', 'sign-path')
        .attr('d', outerArc)
        .style('fill', '#000')
        .style('stroke', '#d6d6d6')
        .each(function (d, i) {
          // A regular expression that captures all in between the start of a string (denoted by ^)
          // and the first capital letter L
          const firstArcSection = /(^.+?)L/;

          // The [1] gives back the expression between the () (thus not the L as well)
          // which is exactly the arc statement

          /* TODO: I have no idea how to fix this particular case of
                                          "'this' implicitly has type 'any' because it does not have a type annotation."
                                          So for now suppressing with TS-Ignore
                                        */
          // @ts-ignore
          let newArc = firstArcSection.exec(d3.select(this).attr('d'))[1];
          // Replace all the commas so that IE can handle it -_-
          // The g after the / is a modifier that "find all matches rather than stopping after the first match"
          newArc = newArc.replace(/,/g, ' ');
          if (isUpsideDown(d.endAngle)) {
            const startLoc = /M(.*?)A/; // Everything between the capital M and first capital A
            const middleLoc = /A(.*?)0 0 1/; // Everything between the capital A and 0 0 1
            const endLoc = /0 0 1 (.*?)$/; // Everything between the 0 0 1 and the end of the string (denoted by $)
            // Flip the direction of the arc by switching the start and end point (and sweep flag)
            const newStart = fromNullable(endLoc.exec(newArc)).map((v) => v[1]);
            const newEnd = fromNullable(startLoc.exec(newArc)).map((v) => v[1]);
            const middleSec = fromNullable(middleLoc.exec(newArc)).map((v) => v[1]);

            const mkNewArc = (start: string, end: string, middleSec: string) =>
              `M${start}A${middleSec}0 0 0 ${end}`;

            newArc = newStart.map(curry(mkNewArc)).ap_(newEnd).ap_(middleSec).getOrElse(newArc);

            // Build up the new arc notation, set the sweep-flag to 0
          }
          // Create a new invisible arc that the text can flow along
          svg
            .append('path')
            .attr('class', 'hidden-donut-arc')
            .attr('id', `signArc_${i}`)
            .attr('d', newArc)
            .style('fill', 'none');
        });

      svg
        .selectAll('.sign-text')
        .data(this.data.processedSigns)
        .enter()
        .append('text')
        .attr('class', 'sign-text')
        .attr('dy', (d, _) => {
          const pos = isUpsideDown(d.endAngle) ? -12 : 23;
          return pos;
        })
        .style('fill', '#f7f7f7')
        .style('font-family', 'Akkurat-Mono')
        .style('font-size', '14px')
        .style('letter-spacing', '.1em')
        .append('textPath')
        .attr('startOffset', '50%')
        .style('text-anchor', 'middle')
        .attr('xlink:href', (_, i) => `#signArc_${i}`)
        .text((d) => d.name.toUpperCase());

      resolve();
    });
    return promise;
  };

  drawPlanetPts = (svg): Promise<void> => {
    const promise = new Promise<void>((resolve, _) => {
      const ptColor = this.whitePlanetImages ? 'white' : 'black';
      // these are already oriented properly but counterclockwise
      svg
        .selectAll('.planet-point')
        .data(this.data.processedPlanets)
        .enter()
        .append('line')
        .attr('class', 'planet-point')
        .attr(
          'x1',
          (d) =>
            (this.radius - 55) *
            Math.cos(
              -1 * (d.analysis.position.radians + (Math.PI - this.data.ascendant.position.radians)),
            ),
        )
        .attr(
          'y1',
          (d) =>
            (this.radius - 55) *
            Math.sin(
              -1 * (d.analysis.position.radians + (Math.PI - this.data.ascendant.position.radians)),
            ),
        )
        .attr(
          'x2',
          (d) =>
            (this.radius - 95) *
            Math.cos(
              -1 * (d.analysis.position.radians + (Math.PI - this.data.ascendant.position.radians)),
            ),
        )
        .attr(
          'y2',
          (d) =>
            (this.radius - 95) *
            Math.sin(
              -1 * (d.analysis.position.radians + (Math.PI - this.data.ascendant.position.radians)),
            ),
        )
        .attr('stroke', ptColor);
      resolve();
    });
    return promise;
  };

  drawPlanets = (svg): Promise<void> => {
    const planetIconPromisesObj = _.mapObject(planetData, (value: PlanetData, key: string) => {
      const promise = new Promise<any>((resolve, _) => {
        const iconImage: HTMLImageElement = new Image();
        iconImage.src = this.whitePlanetImages ? value.whiteImgDataUrl : value.imgDataUrl;

        iconImage.onload = (_) => {
          resolve([key, iconImage]);
        };
      });
      return promise;
    });

    const { whitePlanetImages } = this;

    return Promise.all(_.values(planetIconPromisesObj)).then((r) => {
      const planetResolvedImages = _.object(r);

      // object of all planets and their positions and needed info from this promise return
      this.planetPositions = this.data.processedPlanets.reduce((obj, planet) => {
        const imageWidth = planetResolvedImages[planet.name].width;
        const imageHeight = planetResolvedImages[planet.name].height;
        const planetPositionRadians = planet.analysis.position.radians;
        const { sign } = planet.analysis.position;

        const planetObj = {
          planetPositionRadians,
          x: this.generatePlanetCoords(75, 'x', imageWidth, planetPositionRadians),
          y: this.generatePlanetCoords(75, 'y', imageHeight, planetPositionRadians),
          imageWidth,
          imageHeight,
          overlap: [],
          repositioned: false,
          sign,
        };
        obj[planet.name] = planetObj;
        return obj;
      }, {});

      this.findPlanetOverlap();

      let div;

      if (!this.isAC) {
    

      div = d3.select('.tooltip').node()
        ? d3.select('.tooltip')
        : d3
            .select('body')
            .append('div')
            .attr('class', 'tooltip')
            .style('visibility', 'hidden')
            .style('opacity', 0.9);
      }

      svg
        .selectAll('.planet-labels')
        .data(this.data.processedPlanets)
        .enter()
        .append('image')
        .attr('class', 'planet-labels')
        .attr('x', (d) => this.planetPositions[d.name].x)
        .attr('y', (d) => this.planetPositions[d.name].y)
        .attr('width', (d) => planetResolvedImages[d.name].width)
        .attr('height', (d) => planetResolvedImages[d.name].height)
        .attr('xlink:href', (d) => {
          const str = whitePlanetImages
            ? planetData[d.name].whiteImgDataUrl
            : planetData[d.name].imgDataUrl;
          return str;
        })
        .on('mouseenter', (d) => {
          if (!this.redirectOnPlanetImageClick && window.location.hash !== `#${d.name}` && !this.isAC) {
            div.transition().duration(200).style('visibility', 'visible');
            div
              .html(`<a href="#${d.name}" name="${d.name}">${d.name}</span>`)
              .style('left', `${d3.event.pageX - 25}px`)
              .style('top', `${d3.event.pageY}px`);

            // hacky way to hanlde glitches of tooltip
            div.on('mouseenter', () => {
              div.transition().duration(200).style('visibility', 'visible');
            });
            div.on('mouseout', () => {
              div.transition().style('visibility', 'hidden');
            });
            d3.event.stopPropagation();
          }
        })
        .on('mouseout', (d) => {
          div.transition().delay(600).style('visibility', 'hidden');
        })
        .on('click', (d) => {
          if (this.redirectOnPlanetImageClick && !this.isAC) {
            window.location.hash = d.name;
          }
          div.style('visibility', 'hidden');
          window.location.hash = d.name;
        });
      
    });
  };

  drawBackground = () => {
    this.svg
      .selectAll('rect')
      .data([1])
      .enter()
      .append('rect')
      .attr('x', 0 - this.width / 2)
      .attr('y', 0 - this.height / 2)
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('fill', '#f7f7f7');
  };

  drawAspectChords = (svg): Promise<void> => {
    const promise = new Promise<void>((resolve, _) => {
      const lines = svg.selectAll('.chord-line').data(this.data.processedNatalAspects).enter();

      lines
        .append('line')
        .attr('class', (d) => {
          const goodnessClass = goodnessToChordClass(aspectData[d.aspectName]);
          return `chord-line ${goodnessClass}`;
        })
        .attr('x1', (d) => d.source[0])
        .attr('y1', (d) => d.source[1])
        .attr('x2', (d) => d.target[0])
        .attr('y2', (d) => d.target[1])
        .attr('stroke', (d) => goodnessToColor(aspectData[d.aspectName]))
        .attr('stroke-dasharray', (d) => importanceToDash(aspectData[d.aspectName]));
      resolve();
    });
    return promise;
  };

  create = (el: Element, props: NatalChartProps) => {
    this.svgOuter = d3
      .select(el)
      .append('svg')
      .attr(
        'viewBox',
        `${(-1 * this.width) / 2} ${(-1 * this.height) / 2} ${this.width} ${this.height}`,
      );

    this.svgOuter
      .append('defs')
      .append('style')
      .attr('type', 'text/css')
      .text(`@font-face {font-family: 'Akkurat-Mono'; src: url(${font});}`);

    const svg = this.svgOuter.append('g');

    this.svg = svg;
    this.update();
  };

  destroy = () => {
    this.svgOuter.remove();
  };

  update = (): Promise<void> => {
    this.drawBackground();
    return this.drawPlanetPts(this.svg)
      .then(() => this.drawHouses(this.svg))
      .then(() => this.drawSigns(this.svg))
      .then(() => this.drawPlanets(this.svg))
      .then(() => this.drawAspectChords(this.svg));
  };

  radToCoord = (rad, ascendant) => {
    const a = [
      (this.radius - 95) * Math.cos(-1 * (rad + (Math.PI - ascendant.position.radians))),
      (this.radius - 95) * Math.sin(-1 * (rad + (Math.PI - ascendant.position.radians))),
    ];
    return a;
  };
}

export interface NatalChartProps {
  // onAwsUrl: (url: string) => void;
  chartData: AstroAnalysis;
  // change the URL when the planet's image is clicked, instead of when the
  // popover is clicked. Hides the popover.
  // For use in the Apps' Webviews.
  redirectOnPlanetImageClick: boolean;
  // Use the white versions of the planet images(for dark mode)
  whitePlanetImages: boolean;
  isAC: boolean;
}
