import React from 'react';

import SectionPadding from 'landing-page/components/SectionPadding';

import TransitsZodiacRing from './TransitsZodiacRing';

import styles from './Transits.module.scss';
import { planetObjsForImageOne, planetsForImageOne } from './TransitsTypes';

const RightSideTransit = () => (
  <div className={styles.hideOnDesktop}>
    <SectionPadding verticalPadding="2rem" />
    <div className={styles.transitsContainer}>
      <TransitsZodiacRing
        planetObjs={planetObjsForImageOne}
        planets={planetsForImageOne}
        orbitAnimation="black"
        startingSign="Leo"
        className={styles.rightSideTransit}
      />
    </div>
    <div className={styles.leftSideText}>
      <div className={styles.leftCaption}>
        <h4>
          Transiting Sun <span>&rarr;</span>
        </h4>
        <h4>square natal sun</h4>
        <ul>
          <li>Every Sept ~19</li>
          <li>Ego troubles</li>
        </ul>
      </div>
      <div className={styles.leftCaption}>
        <h4>
          Transiting Sun <span>&rarr;</span>
        </h4>
        <h4>Opposite Natal Sun</h4>
        <ul>
          <li>Every Dec ~19</li>
          <li>Reflecting about being</li>
        </ul>
      </div>
    </div>
  </div>
);

export default RightSideTransit;
