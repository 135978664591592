import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  ITUNES_CAMPAIGN_TOKENS,
  itunesStoreURL,
  playStoreUrl,
  PLAY_STORE_CAMPAIGN_TOKENS,
} from '../../constants';
import { sendClickEvent } from 'analytics';

const HeaderNav = ({
  showMenuModal,
  handleModal,
}: {
  showMenuModal: boolean;
  handleModal: () => void;
}) => {
  const [showAstroDropDown, setAstroDropDown] = useState(false);
  const [showShopDropDown, setShopDropDown] = useState(false);

  const { pathname } = useLocation();

  const toggleAstroDropdown = () => {
    setAstroDropDown(!showAstroDropDown);
  };

  const toggleShopDropDown = () => {
    setShopDropDown(!showShopDropDown);
  };

  const formatAction = (action) =>
    action
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join('');

  const headerClick = (action: string) => () => {
    sendClickEvent('Header', formatAction(action));
  };

  let className = 'header';
  if (pathname === '/mental-health-resources') className += ' dark';

  return (
    <header className={className}>
      <Link
        to="/"
        onClick={() => {
          headerClick('home');
        }}
      >
        <h3 className="logo">Co – Star</h3>
      </Link>
      <div className="right">
        <a
          href={itunesStoreURL(ITUNES_CAMPAIGN_TOKENS.SITE_MARKETING)}
          onClick={headerClick('download')}
        >
          <h4>
            <span className="hide-on-mobile">Download iOS</span>
          </h4>
        </a>
        <a
          href={playStoreUrl(PLAY_STORE_CAMPAIGN_TOKENS.SITE_MARKETING)}
          onClick={headerClick('downloadandroid')}
        >
          <h4>
            <span className="hide-on-mobile">Download Android</span>
          </h4>
        </a>
        <Link to="/natal-chart" onClick={headerClick('natal chart')}>
          <h4>
            <span className="hide-on-mobile">Natal chart</span>
          </h4>
        </Link>
        <span
          className="hide-on-mobile"
          id={showAstroDropDown ? 'astroDropDown' : 'astroDropDown_toggle'}
          onMouseEnter={toggleAstroDropdown}
          onMouseLeave={toggleAstroDropdown}
          style={{
            fontFamily: 'Akkurat-Mono, Andale Mono, sans-serif',
            fontSize: '12px',
            height: '10rem',
            lineHeight: '16px',
            textTransform: 'uppercase',
            marginLeft: '20px',
            marginRight: '5px',
            color: '#57565A',
            cursor: 'pointer',
          }}
        >
          <Link to="/how-does-astrology-work" onClick={headerClick('how does astrology work')}>
            <h4>
              <span className="hide-on-mobile">Learn Astrology</span>
            </h4>
          </Link>

          {showAstroDropDown ? (
            <ul>
              <li>
                <Link
                  to="/how-does-astrology-work/what-is-astrology"
                  onClick={headerClick('what is astrology')}
                >
                  <h4>
                    <span className="hide-on-mobile sublist">&bull; What is Astro?</span>
                  </h4>
                </Link>
              </li>
              <li>
                <Link
                  to="/how-does-astrology-work/what-is-a-natal-chart"
                  onClick={headerClick('astro intuition natal chart')}
                >
                  <h4>
                    <span className="hide-on-mobile sublist">&bull; Natal Chart</span>
                  </h4>
                </Link>
              </li>
              <li>
                <Link to="/zodiac-signs" onClick={headerClick('zodiac signs')}>
                  <h4>
                    <span className="hide-on-mobile sublist">&bull; Zodiac signs</span>
                  </h4>
                </Link>
              </li>
              <li>
                <Link
                  to="/how-does-astrology-work/rising-sign"
                  onClick={headerClick('rising sign')}
                >
                  <h4>
                    <span className="hide-on-mobile sublist">&bull; Rising Sign</span>
                  </h4>
                </Link>
              </li>
              <li>
                <Link to="/how-does-astrology-work/houses" onClick={headerClick('houses')}>
                  <h4>
                    <span className="hide-on-mobile sublist">&bull; Houses</span>
                  </h4>
                </Link>
              </li>
              <li>
                <Link
                  to="/how-does-astrology-work/house-systems"
                  onClick={headerClick('house-systems')}
                >
                  <h4>
                    <span className="hide-on-mobile sublist">&bull; House Systems</span>
                  </h4>
                </Link>
              </li>
              <li>
                <Link to="/how-does-astrology-work/aspects" onClick={headerClick('aspects')}>
                  <h4>
                    <span className="hide-on-mobile sublist">&bull; Aspects</span>
                  </h4>
                </Link>
              </li>
              <li>
                <Link
                  to="/how-does-astrology-work/transits-orbs"
                  onClick={headerClick('transits orbs')}
                >
                  <h4>
                    <span className="hide-on-mobile sublist">&bull; Transits & Orbs</span>
                  </h4>
                </Link>
              </li>
            </ul>
          ) : (
            <></>
          )}
        </span>
        <span
          className="hide-on-mobile"
          id={showShopDropDown ? 'shopDropDown' : 'shopDropDown_toggle'}
          onMouseEnter={toggleShopDropDown}
          onMouseLeave={toggleShopDropDown}
          style={{
            fontFamily: 'Akkurat-Mono, Andale Mono, sans-serif',
            fontSize: '12px',
            height: '10rem',
            lineHeight: '16px',
            textTransform: 'uppercase',
            marginLeft: '20px',
            marginRight: '5px',
            color: '#57565A',
            cursor: 'pointer',
          }}
        >
          <h4>
            <span className="hide-on-mobile">Shop</span>
          </h4>
          {showShopDropDown ? (
            <ul>
              <li>
                <Link to="/advanced-readings" onClick={headerClick('advanced readings')}>
                  <h4>
                    <span className="hide-on-mobile sublist">&bull; Readings</span>
                  </h4>
                </Link>
              </li>
              <li>
                <a href="https://co-star-astrology.myshopify.com/" onClick={headerClick('shopify')}>
                  <h4>
                    <span className="hide-on-mobile sublist">&bull; Merch</span>
                  </h4>
                </a>
              </li>
            </ul>
          ) : (
            <></>
          )}
        </span>
      </div>
      <div
        className={
          showMenuModal
            ? 'hide-on-tablet hide-on-desktop hamburger'
            : 'hide-on-tablet hide-on-desktop hamburger active'
        }
        onClick={handleModal}
      >
        <span className="bar" />
        <span className="bar" />
        <span className="bar" />
        <span className="bar" />
      </div>
    </header>
  );
};

export default HeaderNav;
