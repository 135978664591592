export type PageData = {
  title: string;
  blurb?: string;
  path: string;
};

export const pageData: PageData[] = [
  {
    title: 'What is astrology?',
    blurb:
      'Everything in the solar system is constantly moving. Astrology describes our lived experience in the context of this motion.',
    path: '/how-does-astrology-work/what-is-astrology',
  },
  {
    title: 'Natal Chart',
    blurb:
      'The natal chart (a.k.a. birth chart) is a map of the universe at the exact place and time that someone was born.',
    path: '/how-does-astrology-work/what-is-a-natal-chart',
  },
  {
    title: 'Zodiac Sun Signs',
    blurb: "The part of astrology that you're probably most familiar with is the Sun Sign.",
    path: '/zodiac-signs',
  },
  {
    title: 'The rising sign, or ascendant',
    blurb:
      'The rising sign is the zodiac sign that was on the eastern horizon when and where you were born.',
    path: '/how-does-astrology-work/rising-sign',
  },
  {
    title: 'Houses',
    blurb: 'The houses indicate where something plays out – the context',
    path: '/how-does-astrology-work/houses',
  },
  {
    title: 'House Systems',
    blurb:
      'There are many different ways of calculating houses. Porphyry is our favorite way of doing it.',
    path: '/how-does-astrology-work/house-systems',
  },
  {
    title: 'Aspects',
    blurb: 'Aspects are special angles (like 0°, 180°, etc) between any two planets.',
    path: '/how-does-astrology-work/aspects',
  },
  {
    title: 'Transits & Orbs',
    blurb:
      'Transits happen when there’s an aspect between where a planet is right now and where a planet was at the time of your birth. An orb is the buffer zone around an angle to define what counts as an aspect',
    path: '/how-does-astrology-work/transits-orbs',
  },
];
