import * as d3 from 'd3';
import React from 'react';
import cn from 'classnames';
import useD3 from 'hooks/useD3';

import styles from './NatalChart.module.scss';

const OrbitAnimation = () => {
  const ref = useD3((svg) => {
    const height = 500;
    const width = 500;
    const x = 0 - width / 2;
    const y = 0 - height / 2;
    const radius = Math.min(width, height) / 2;
    const t0 = Date.now();

    svg.attr('viewBox', `${(-1 * width) / 2} ${(-1 * height) / 2} ${width} ${height}`);
    svg.append('defs').append('style').attr('type', 'text/css');

    svg = svg.append('g');

    svg
      .selectAll('rect')
      .data([1])
      .enter()
      .append('rect')
      .attr('x', 0 - width / 2)
      .attr('y', 0 - height / 2)
      .attr('width', width)
      .attr('height', height)
      .attr('fill', 'transparent');

    svg
      .append('image')
      .attr('x', 0 - 25)
      .attr('y', 0 - 25)
      .attr('xlink:href', require('../../../../../images/icons/stylized-sun-icon.png'))
      .attr('height', 50)
      .attr('width', 50);

    const container = svg.append('g').attr('class', 'container');

    const mercury = container.append('g').attr('id', 'mercury');
    mercury
      .append('circle')
      .attr('class', 'orbit')
      .attr('r', 57)
      .attr('stroke', '#ccc')
      .attr('fill', 'transparent');

    mercury
      .append('circle')
      .attr('r', 5)
      .attr('cx', 57)
      .attr('cy', 0)
      .attr('stroke', '#666')
      .attr('fill', '#fff');

    const venus = container.append('g').attr('id', 'venus');
    venus
      .append('circle')
      .attr('class', 'orbit')
      .attr('r', 97)
      .attr('stroke', '#ccc')
      .attr('fill', 'transparent');

    venus
      .append('circle')
      .attr('r', 5)
      .attr('cx', 97)
      .attr('cy', 0)
      .attr('stroke', '#666')
      .attr('fill', '#fff');

    const earth = container.append('g').attr('id', 'earth');
    earth
      .append('circle')
      .attr('class', 'orbit')
      .attr('r', 169)
      .attr('stroke', '#ccc')
      .attr('fill-opacity', '.15')
      .attr('fill', '#ccc');

    d3.timer(() => {
      const delta = Date.now() - t0;

      svg.selectAll('#mercury').attr('transform', (d) => `rotate(${-110}${(delta * 25) / 200})`);

      svg.selectAll('#venus').attr('transform', (d) => `rotate(${-80}${(delta * 10) / 200})`);
    });
  }, []);
  return (
    <svg ref={ref} className={cn(styles.orbit)}>
      <g className="container" />
    </svg>
  );
};

export default OrbitAnimation;
