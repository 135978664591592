import React from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet';

import PageLayout from 'landing-page/views/PageLayout';
import AstroIntuitionNavItem from './AstroIntuitionNavItem';

import { pageData } from './AstroIntuitionTypes';

import styles from './AstroIntuition.module.scss';

const AstroIntuition = () => {
  const navItems = pageData.map((item, i) => {
    const { title, blurb, path } = item;
    return <AstroIntuitionNavItem title={title} blurb={blurb} path={path} key={title} index={i} />;
  });

  return (
    <>
      <Helmet>
        <title>How does astrology work? | Co-Star</title>
        <meta
          name="description"
          content="The astronomy of astrology: a 101 from your friends at Co-Star."
        />
      </Helmet>
      <div className="floating-container">
        <div className={styles.pageContainer}>
          <div className={cn(styles.parentPageHeader, 'floating-container-inner')}>
            <h1>How does astrology work?</h1>
            <br></br>
            <br></br>
            <h4>The Astronomy of Astrology: A 101 from your friends at Co–Star</h4>
          </div>
          <div className={cn('container', styles.navItemsContainer)}>
            <img
              className={styles.cutoutImage}
              src={require('../../../../images/cutouts/cutout-twig.png')}
            />
            <img
              className={styles.cutoutImage}
              src={require('../../../../images/cutouts/cutout-peony.png')}
            />

            <img
              className={styles.cutoutImage}
              src={require('../../../../images/cutouts/cutout-tulip.png')}
            />

            <img
              className={styles.cutoutImage}
              src={require('../../../../images/cutouts/cutout-moth.png')}
            />
            {navItems}
          </div>
        </div>
      </div>
    </>
  );
};

export default PageLayout(AstroIntuition);
