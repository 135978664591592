export class Point {
  public x: number;

  public y: number;

  constructor(x, y) {
    this.x = x;
    this.y = y;
  }
}

export class Point3D extends Point {
  public z: number;

  constructor(x, y, z) {
    super(x, y);
    this.z = z;
  }

  project(camera: Point3D): Point {
    const x = ((camera.x - this.x) * camera.z) / (camera.z - this.z) + camera.x;
    const y = ((camera.y - this.y) * camera.z) / (camera.z - this.z) + camera.y;

    return new Point(x, y);
  }
}

export class Rectangle {
  public upperLeft: Point;

  public lowerRight: Point;

  constructor(ul, lr) {
    this.upperLeft = ul;
    this.lowerRight = lr;
  }

  intersects(other: Rectangle): boolean {
    return !(
      other.upperLeft.x > this.lowerRight.x ||
      other.lowerRight.x < this.upperLeft.x ||
      other.upperLeft.y > this.lowerRight.y ||
      other.lowerRight.y < this.upperLeft.y
    );
  }

  width(): number {
    return this.lowerRight.x - this.upperLeft.x;
  }

  height(): number {
    return this.lowerRight.y - this.upperLeft.y;
  }

  center(): Point {
    return new Point(
      this.upperLeft.x + this.width() / 2,
      this.upperLeft.y + this.height() / 2,
    );
  }
}

export class Line<P extends Point> {
  public start: P;

  public end: P;

  constructor(start, end) {
    this.start = start;
    this.end = end;
  }
}

export class Line3D extends Line<Point3D> {
  public project(camera: Point3D): Line2D {
    return new Line2D(this.start.project(camera), this.end.project(camera));
  }

  public interpolate(percent: number): Point3D {
    const x = this.start.x + (this.end.x - this.start.x) * percent;
    const y = this.start.y + (this.end.y - this.start.y) * percent;
    const z = this.start.z + (this.end.z - this.start.z) * percent;

    return new Point3D(x, y, z);
  }
}

export class Line2D extends Line<Point> {
  public invert(screenHeight): Line2D {
    return new Line2D(
      new Point(this.start.x, screenHeight - this.start.y),
      new Point(this.end.x, screenHeight - this.end.y),
    );
  }

  public draw(ctx: CanvasRenderingContext2D): void {
    ctx.beginPath();
    ctx.moveTo(this.start.x, ctx.canvas.height - this.start.y);
    ctx.lineTo(this.end.x, ctx.canvas.height - this.end.y);
    ctx.stroke();
  }
}

export class Plane {
  public upperLeft: Point3D;

  public upperRight: Point3D;

  public lowerLeft: Point3D;

  public lowerRight: Point3D;

  private left: Line3D;

  private right: Line3D;

  private top: Line3D;

  private bottom: Line3D;

  constructor(ul, ur, ll, lr) {
    this.upperLeft = ul;
    this.upperRight = ur;
    this.lowerLeft = ll;
    this.lowerRight = lr;

    this.top = new Line3D(this.upperLeft, this.upperRight);
    this.bottom = new Line3D(this.lowerLeft, this.lowerRight);
    this.left = new Line3D(this.upperLeft, this.lowerLeft);
    this.right = new Line3D(this.upperRight, this.lowerRight);
  }

  getLines(timestamp, cursorPercent, n): Line3D[] {
    const lines = [];

    // vertical lines
    const vOffsetPercent = ((cursorPercent.x * 300) % 100) / 100;
    for (var i = 0; i <= n; i++) {
      var shift = i / n + vOffsetPercent / n;

      var start = this.top.interpolate(shift);
      var end = this.bottom.interpolate(shift);

      // @ts-ignore
      lines.push(new Line3D(start, end));
    }

    // horizontal lines
    const hOffsetPercent =
      ((timestamp / 30 + cursorPercent.y * 300) % 100) / 100;
    for (var i = 0; i <= n; i++) {
      var shift = i / n + hOffsetPercent / n;

      var start = this.left.interpolate(shift);
      var end = this.right.interpolate(shift);

      // @ts-ignore
      lines.push(new Line3D(start, end));
    }

    return lines;
  }
}
