export const planetsForImageOne = [{ name: 'Ascendant' }, { name: 'Sun' }, { name: 'Moon' }];

export const planetObjsForImageOne = {
  Ascendant: {
    imageHeight: 21,
    imageWidth: 12,
    x: -180,
    y: -10.500000000000028,
  },
  Sun: {
    imageHeight: 21,
    imageWidth: 21,
    x: 157,
    y: -70,
  },
  Moon: {
    imageHeight: 21,
    imageWidth: 16,
    x: -182,
    y: 12,
  },
};

export const planetsForImageTwo = [{ name: 'Ascendant' }, { name: 'Sun' }];

export const planetObjsForImageTwo = {
  Ascendant: {
    imageHeight: 21,
    imageWidth: 12,
    x: -180,
    y: -10.500000000000028,
  },
  Sun: {
    imageHeight: 21,
    imageWidth: 21,
    x: 157,
    y: -70,
  },
};

export const aspectsForImageTwo = [
  [146, -52, -146, 52],
  [146, -52, 55, 145],
  [146, -52, -35, -155],
];

export const planetsForImageThree = [{ name: 'Ascendant' }, { name: 'Saturn' }];

export const planetObjsForImageThree = {
  Ascendant: {
    imageHeight: 21,
    imageWidth: 12,
    x: -180,
    y: -10.500000000000028,
  },
  Saturn: {
    imageHeight: 20,
    imageWidth: 14,
    x: -115,
    y: 128,
  },
};

export const aspectsForImageThree = [[-97, 122, 97, -122]];
