import * as React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PageLayout from 'landing-page/views/PageLayout';
import { TEAM_SIZE } from 'landing-page/pages/TeamPage/teamData';
import { PressFeatures } from '../../press-features';
import FooterForm from 'landing-page/components/FooterForm';
import {
  itunesStoreURL,
  playStoreUrl,
  ITUNES_CAMPAIGN_TOKENS,
  PLAY_STORE_CAMPAIGN_TOKENS,
} from '../../constants';
import { AnchorLink, scrollToLocationHash } from '../../components/anchor-link';

import './FAQPage.scss';

class FAQPage extends React.Component {
  componentDidMount() {
    scrollToLocationHash();
  }

  render() {
    return (
      <div>
        <Helmet>
          <html itemScope itemType="https://schema.org/FAQPage" />
          <title>Frequently Asked Questions about Co–Star Astrology Society</title>
          <meta
            name="description"
            content="What is Co–Star? What is astrology? These and many more questions are answered in our collection of frequently asked questions."
          />
        </Helmet>
        <div className="container static-container">
          <h1>Frequently asked questions</h1>
          <div className="anchorLinks">
            <AnchorLink targetId="about-app">
              <h4>About the app</h4>
            </AnchorLink>
            <AnchorLink targetId="about-ask-the-stars">
              <h4>Ask the stars</h4>
            </AnchorLink>
            <AnchorLink targetId="about-company">
              <h4>About the company</h4>
            </AnchorLink>
            <AnchorLink targetId="about-astro">
              <h4>About astrology</h4>
            </AnchorLink>
            <AnchorLink targetId="about-shop">
              <h4>About the shop</h4>
            </AnchorLink>
          </div>

          <div id="about_app">
            <h2>
              <br />
              The app
            </h2>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">What is Co–Star?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                Co–Star Astrology is the hyper-personalized, social astrology experience.
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">How do I use the Co–Star app?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                <ul>
                  <li>
                    Download Co–Star for free in the{' '}
                    <a href={itunesStoreURL(ITUNES_CAMPAIGN_TOKENS.SITE_MARKETING)}>iTunes</a> or{' '}
                    <a href={playStoreUrl(PLAY_STORE_CAMPAIGN_TOKENS.SITE_MARKETING)}>
                      {' '}
                      Google Play
                    </a>{' '}
                    stores.
                  </li>
                  <li>Enter your birth time, date, and location</li>
                  <li>Link your phone number or Apple account.</li>
                  <li>
                    Enter the six-digit SMS code to verify your account —{' '}
                    <a href="mailto:horoscopes@costarastrology.com">email us</a> if you’re having
                    trouble receiving the code.
                  </li>
                  <li>
                    Add friends to see your compatibility and read their daily updates. To un-add
                    someone, swipe left on their name and tap the red “remove” button that appears.
                  </li>
                  <li>
                    Use the Settings tab to update your username, birth date, time, and place. Just
                    tap whichever detail you want to change, and it will become editable.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">How does it work? </h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                Co–Star’s proprietary technology couples insightful humans with data from NASA’s Jet
                Propulsion Laboratory and cutting-edge natural language-AI to give people
                personalized context for their lives.
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">How does Co–Star utilize user data?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text" className="multi-lined">
                <p>
                  We use your date, time, and place of birth to use astrology to generate the
                  content that powers your astrology experience.
                </p>
                <p>
                  We choose which transits to surface on your home screen based on your past
                  behavior within the app. For example, if you always look at your 6th/10th house or
                  Saturn transits, we assume that’s because you’re a workaholic and show you more of
                  those transits – and sometimes show you less because you should go outside more.
                  ;)
                </p>
                <p>
                  We do not use or have access to any third party data, including but not limited to
                  your texts, your mic, or other trackers. We don't track your activity or usage on
                  any other app or any other website.
                </p>
                <p>
                  We do use Google Analytics (same as 89% of other top websites) to see how people
                  interact with the app, such as what they click on and how many screens or pages
                  they visit and if the app crashes, so we can fix bugs and make the app better.{' '}
                </p>
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">Does Co–Star sell user data?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                Co–Star does not sell people’s data and will not sell anyone's data in the future.
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">How does Co–Star make money?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                Co–Star runs on a freemium model. 100% of our revenue comes from people paying for a
                la carte in-app purchases like manually adding friends and advanced chart readings.{' '}
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">Where do I find the reading I paid for?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                All premium readings are accessible at any time from your Settings tab. You can also
                find your own readings—or the readings of your friend—via links from both the Mars
                and Venus cards in your chart or in theirs.
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">How do I delete my account?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                Go to your profile by tapping YOUR CHART or ALL UPDATES on the home screen. Then go
                to Settings in the top right. Scroll to the bottom and tap "delete account."
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">Can I suggest features?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                Please <Link to="/contact">send us</Link> any suggestions or comments you have.{' '}
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">How can I contact you?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                Email us at{' '}
                <a href="mailto:horoscopes@costarastrology.com">horoscopes@costarastrology.com</a>{' '}
                for help verifying your account, changing your phone number, or any other
                app-related questions.
              </div>
            </div>
          </div>

          <div id="about-ask-the-stars">
            <h2>
              <br />
              Ask the stars & get answers to your deepest, darkest questions
            </h2>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">What is this?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                <p>
                  Astrology is an incredible tool to gain a deeper understanding of ourselves and
                  our place in the world. We built this feature to make it easier and more
                  accessible to use astrology to understand the patterns of your life, get insight
                  into why you’re feeling the way you are, and find ways to reflect and connect more
                  deeply. This feature answers your questions directly based on your specific chart
                  and the position of the stars today. It’s a simple way to use astrology in your
                  daily life, without needing to know how to read charts or transits. 
                </p>
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">Why did Co–Star build this now?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text" className="multi-lined">
                <p>
                  {' '}
                  When we first created Co–Star 5 years ago, we focused on providing users the
                  personalized insights we think they need to hear based on their astrology. The
                  demand for our content quickly outgrew the features we’d built. More than
                  anything, we noticed that you, our users, wanted to be able to directly ask
                  Co–Star for further guidance about specific situations in your lives. You found
                  creative ways to spill your souls into the void, confessing your most vulnerable
                  secrets and fears via emails to us, social media DMs, and anonymous feedback
                  forms. Our small team and nascent AI didn’t have the capacity to thoughtfully
                  answer so many personal questions back then, but as more and more poured in, we
                  knew it was important to get there. 
                </p>
                <p>
                  {' '}
                  Now, we’re excited to be able to provide a way for you to ask Co–Star all of your
                  questions—from the niche and mundane to the profound and existential—and get
                  insightful, personalized answers exactly when you need them.{' '}
                </p>
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">How do I ask a question? </h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                <p>
                  To enter the void, open your Co–Star app and tap the
                  portal in the center of the navigation bar at the bottom of the screen.  During
                  their first visit, you can interact with the feature for free. To ask additional
                  questions, you purchase credits. The number of credits available will be clearly
                  displayed in the app. If you run out of credits, you can type a question, but you
                  won’t be able to submit it without buying credits. You can purchase multiple
                  credits at once.
                </p>
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">What can I ask?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text" className="multi-lined">
                <p>We can currently answer questions like: </p>
                <ul>
                  <li>Will I ever fall in love?</li>
                  <li>Why am I self-sabotaging?</li>
                  <li>Should I quit my job and move to a different country?</li>
                  <li>How can I meet new people?</li>
                  <li>What should I do if I don’t love my partner anymore?</li>
                </ul>
                <p>Soon, we will also be able to answer questions like:  </p>
                <ul>
                  <li>When should I go on vacation?</li>
                  <li>Where should I move after college?</li>
                  <li>Which astrological house is Mercury transiting now?</li>
                </ul>
                <p>
                  There are some questions we don’t answer in an effort to keep our community safe.
                  Co–Star is committed to providing a safe experience for its users. We do not
                  answer questions we deem unsafe, toxic, harmful, or hurtful. {' '}
                </p>{' '}
                <p>
                  You will never lose your question credits if you ask a question we are unable to
                  answer. Instead, you’ll be prompted to ask again.
                </p>
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">What does my answer mean? Where do the answers come from?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text" className="multi-lined">
                <p>
                  When you enter the void and ask the stars, you get in-depth insights and advice
                  based on your natal chart, the real-time movements of the planets in the sky, and
                  the 4,000-year-old system of astrology.
                </p>{' '}
                <p>
                  More specifically, every answer is based on Co–Star’s proprietary database of
                  astrological interpretations, custom built over five years by poets, astrologers,
                  and technologists. Questions are parsed to determine your most relevant natal
                  placements and transits using precise NASA data and the corresponding astrology.
                  We then deliver an answer with the intention of helping you cultivate a deeper
                  understanding of self and the world around you. 
                </p>{' '}
                <p>
                  While our answers are astrologically accurate, do not follow the stars blindly.
                  Astrology describes the conditions. No star can compromise the human faculty of
                  will.
                </p>
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">My question did not produce an answer, what does that mean?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text" className="multi-lined">
                <p>
                  If you are in crisis, please{' '}
                  <Link to="/mental-health-resources">
                    visit this page for mental health and other resources
                  </Link>{' '}
                  that we recommend. To prioritize your safety, this product will not answer
                  questions that appear to pose a risk to you or others.
                </p>{' '}
                <p>
                  There are a few other reasons we may be unable to return an answer. Sometimes, the
                  service is down or we are unable to parse your question. Also, for now, we only
                  accept questions written in English. In these cases, please rephrase your question
                  and try again.
                </p>{' '}
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">How do I get a refund for an in-app purchase?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text" className="multi-lined">
                <p>
                  For all readings, custom charts and answers purchased in the Co–Star app, users
                  need to reach out directly to the App Store where they made their initial
                  purchase. For all readings purchased here on the Co–Star website, please{' '}
                  <Link to="/contact">message us directly</Link>.
                </p>
                <ul type="1">
                  <li>
                    App Store/iOS: Please contact Apple Support to process the refund for you (App
                    Store refund info <a href="https://support.apple.com/en-us/HT204084">here</a>).
                    Apple tends to issue the refunds immediately.
                  </li>
                  <li>
                    Play Store/Android: Please contact Google Play Help with your order number,
                    which you can find in your emailed receipt or Google Play order history (info{' '}
                    <a href="https://support.google.com/googleplay/answer/2850369?hl=en">here</a>).
                    It looks like this: GPA.0000-0000-0000-00000.
                  </li>
                </ul>
                <p>
                  While we are not authorized to request a refund from the App Stores on your
                  behalf, please reach out to us at{' '}
                  <a href="mailto:horoscopes@costarastrology.com">horoscopes@costarastrology.com</a>{' '}
                  if you have any questions.
                </p>
              </div>
            </div>
          </div>

          <div id="about-company">
            <h2>
              <br />
              The company
            </h2>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">Who is the Co–Star CEO?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text" className="multi-lined">
                <p>Banu Guler is the founder and CEO of Co–Star.</p>
                <p>
                  {' '}
                  A child of Pakistani and Turkish immigrants, Banu forged her identity walking a
                  tightrope between analytic pragmatism and old traditions of coffee ground
                  readings, palmistry, and astrology.{' '}
                </p>
                <p>
                  {' '}
                  A veteran of the fashion industry, Banu created immersive, tech experiences for
                  brands such as Diane von Furstenberg, Alex &amp; Ani, Ann Taylor, and Michael
                  Kors, before serving as Director of Product + Design at VFILES.
                </p>
                <p>Banu studied psychology at NYU. She is a Scorpio.</p>
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">Do astrologers work at Co–Star?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                Co–Star has full-time staff astrology experts and works with consulting astrologers{' '}
                <a href="https://loroconnor.com/astrology/" target="_blank" rel="noreferrer">
                  Lor O'Connor
                </a>
                ,{' '}
                <a href="https://www.jenniferfreed.com/" target="_blank" rel="noreferrer">
                  Dr. Jennifer Freed
                </a>
                , and{' '}
                <a href="http://www.alicesparklykat.com/" target="_blank" rel="noreferrer">
                  Alice Sparkly Kat
                </a>
                .
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">How do I apply for a job at Co–Star?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                If you’re interested in applying for a job at Co–Star, visit our{' '}
                <Link to="/jobs">jobs</Link> page.
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">
              Is Co–Star Astrology Society affiliated with CoStar Commercial Real Estate?
            </h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">No.</div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">How do I submit a media inquiry?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                Email us at <a href="mailto:press@costarastrology.com">press@costarastrology.com</a>{' '}
                and check our press kit{' '}
                <a href="https://www.dropbox.com/sh/nf19nqm16shwakk/AAA3M7Wov5uNx2suvCJJA74Ba?dl=0">
                  here
                </a>
                .
              </div>
            </div>
          </div>

          <div id="about-astro">
            <h2>
              <br />
              Astrology
            </h2>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">What is astrology?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                Astrology is a language based on the exact positions of the stars and planets in our
                sky, providing humans with tools for reflection, connection, and community for 4,000
                years.
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">What's a natal chart? </h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text" className="multi-lined">
                <p>
                  A natal chart is a map of the sky from when and where you were born that shows
                  which constellation (sign) a planet was in front of when you were born. This is
                  the first known celestial coordinate system. It’s been a way of telling time for
                  four thousand years.
                </p>
                <p>
                  In astrology, a natal chart indicates your character traits, behavioral
                  tendencies, hidden desires, and the directions your life might take. It shows the
                  location of each planet, along with the sign and house it appeared in at that
                  moment.
                </p>
                <p>
                  {' '}
                  On the app, we have a simplified version of your chart. View the traditional wheel
                  on our website by casting your chart below, or (iOS only) navigate to Settings,
                  turn Public Profile on, and then tap the link.{' '}
                </p>
                <Link to="/natal-chart">
                  <h4>Cast your chart &rarr;</h4>
                </Link>
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">What are aspects? </h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                <p>
                  At the moment you were born, all the stars were in particular places in the sky.
                  Since then, everything’s moved. In astrology, specific angles (or “aspects”)
                  between where the stars were when you were born and where they are now indicate
                  specific kinds of energy. For instance, a conjunction happens when a planet is in
                  the exact same place another (or the same) planet was when you were born – the
                  angle is 0°. Each of the aspects has a kind of energy associated with it:{' '}
                </p>
                <ul>
                  <li>Conjunction (0°): transformation</li>
                  <li>Opposition (180°): conflict and challenges</li>
                  <li>Square (90°): unlucky accidents</li>
                  <li>Semi-Square (45°): irritation and obstacles</li>
                  <li>Sesqui-Quadrate (135°): frustrations and delays</li>
                  <li>Trine (120°): acceleration </li>
                  <li>Sextile (60°): opportunity </li>
                  <li>Semi-Sextile (30°): luck</li>
                  <li>Quincunx (a.k.a. inconjunct)(150°): change and separation </li>
                </ul>
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">What do the planets stand for in astrology? </h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                <p>
                  Typical horoscopes are written only to a person’s sun sign. But that’s only one
                  part of your astrological chart. It doesn’t take into account the moon and the
                  other planets in the solar system, each of which represents a different kind of
                  energy:
                </p>
                <ul>
                  <li>☉&nbsp;&nbsp;Sun: ego, identity, and role in life </li>
                  <li>☽&nbsp;&nbsp;Moon: emotions, moods, and feelings </li>
                  <li>
                    ☿&nbsp;&nbsp;Mercury: how you communicate, talk, think, and process information{' '}
                  </li>
                  <li>♀&nbsp;&nbsp;Venus: how and what you love </li>
                  <li>♂&nbsp;&nbsp;Mars: sex and aggression </li>
                  <li>♃&nbsp;&nbsp;Jupiter: growth, expansion, progress, and philosophy </li>
                  <li>
                    ♄&nbsp;&nbsp;Saturn: responsibility, restrictions, limits, boundaries, fears,
                    and self-discipline{' '}
                  </li>
                  <li>♅&nbsp;&nbsp;Uranus: innovation, progression, rebellion, and change </li>
                  <li>♆&nbsp;&nbsp;Neptune: dreams, imagination, and the unconscious </li>
                  <li>
                    ♇&nbsp;&nbsp;Pluto: power, intensity, obsession, control, and transformation{' '}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">What do all the houses stand for in astrology? </h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                <p>
                  Beginning from the sign that was rising from the east when and where you were
                  born, the sky is divided into 12 sections, which symbolize areas of your life.{' '}
                  <Link to="/how-does-astrology-work/houses">These also map to the signs.</Link>
                </p>
                <ul>
                  <li>1st house: Self-image</li>
                  <li>2nd house: Your personal resources</li>
                  <li>3rd house: What you know</li>
                  <li>4th house: Home life</li>
                  <li>5th house: Pleasure &amp; creativity</li>
                  <li>6th house: Productivity</li>
                  <li>7th house: Significant relationships</li>
                  <li>8th house: Beginnings and endings</li>
                  <li>9th house: Open-mindedness</li>
                  <li>10th house: How people see you</li>
                  <li>11th house: Friends &amp; acquaintances</li>
                  <li>12th house: Unconscious</li>
                </ul>
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">What's the relationship between houses, planets, and signs?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                <p>
                  It can be helpful to think of the relationship between houses, planets, and signs
                  as questions of “where,” “what,” and “how,” respectively. The{' '}
                  <Link to="/how-does-astrology-work/houses">houses</Link> represent specific areas
                  in which situations might arise. The{' '}
                  <Link to="/how-does-astrology-work/what-is-a-natal-chart">planets</Link> represent
                  the kinds of energy that you could bring to those situations. The{' '}
                  <Link to="/zodiac-signs">signs</Link>, finally, represent how that energy is
                  expressed.
                </p>
                <br />
                <p>
                  It’s possible to analyze the meaning of a house from the perspective of the
                  planets that occupy it, or the sign(s) it intersects. You can also understand a
                  planet through the sign and house it’s in, or the sign through the planets and
                  houses. For example, if you want to understand how you relate to your personal
                  resources, you can look at what planets show up in your 2nd house, or what sign
                  that house is in. If you want to understand your emotional self, you can look at
                  what house and sign your Moon is in.
                </p>
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">Are all the houses equally important?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                No single house is more important than the others by default. However, if you have
                more than one planet in a particular house in your natal chart, that house might
                take on extra significance for you personally.
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">Are some houses better than others?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                No house is inherently bad. Some houses, however, symbolize more challenging areas
                of life. For instance, the 8th house — the house of birth, death, transformation and
                crisis — and the 12th house — the house of the unconscious, solitary pursuits, and
                fantasy — are often perceived as designating negative experiences. This is not
                necessarily the case. Both the 8th and the 12th house offer the opportunity for
                significant personal growth and development. They represent powerful energies that
                can be incredibly rewarding if properly handled and integrated. It’s all a matter of
                attitude.
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">
              What method does Co–Star use to determine birth chart placements?{' '}
            </h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                <p>
                  We use <Link to="/how-does-astrology-work/house-systems#porphyry">Porphyry</Link>,
                  the oldest system of quadrant style house division (far older than{' '}
                  <Link to="/how-does-astrology-work/house-systems#placidus">Placidus</Link>, but
                  slightly newer than{' '}
                  <Link to="/how-does-astrology-work/house-systems#whole-sign">Whole Sign</Link>
                  ), which uses the ascendant as the 1st house cusp and midheaven as the 10th house
                  cusp.
                </p>
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">What is a cusp?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                The cusp is the beginning of a house. On your circular natal chart, they are
                represented as spokes on the wheel. Look at the line running horizontally across
                your chart. The left-hand side of that line marks the beginning, or cusp, of your
                1st house. Follow the chart around counterclockwise, and each subsequent spoke marks
                the cusp of the next house.
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">What is a house ruler?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                <p>
                  The sign that lies on the cusp of each house is the ruling sign of that house.
                  Each sign is associated with a planet as follows:
                </p>
                <ul>
                  <li>Aries – Mars</li>
                  <li>Taurus and Libra – Venus</li>
                  <li>Gemini and Virgo – Mercury</li>
                  <li>Cancer – the Moon</li>
                  <li>Leo – the Sun</li>
                  <li>Scorpio – Pluto (traditionally Mars)</li>
                  <li>Sagittarius – Jupiter</li>
                  <li>Capricorn – Saturn</li>
                  <li>Aquarius – Uranus (traditionally Saturn)</li>
                  <li>Pisces – Neptune (traditionally Jupiter)</li>
                </ul>
                <br />
                <p>
                  Whatever planet is associated with the sign on the cusp of a house, we say that
                  planet is the house ruler. If, for example, Virgo is the sign on the cusp of your
                  1st house (i.e., your rising sign or Ascendant), your 1st house is ruled by the
                  planet Mercury. This means that the way you present to people when you first meet
                  them is probably as a deft, quick-witted communicator.
                </p>
                <br />
                <p>
                  You can add to the picture by then locating the position of the house’s ruling
                  planet in your natal chart. Imagine that in addition to your ascendant being in
                  Virgo, your Mercury is located in the sign of Cancer. That means that you appear
                  to others as someone who speaks in an emotionally sensitive and perceptive way,
                  because Cancer, in turn, is ruled by the Moon, the planet that symbolizes your
                  emotional core.
                </p>
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">What does it mean that a sign is intercepted by a house?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                <p>
                  We say that a sign is intercepted by a house if it does not touch the cusp of the
                  house in which it begins, or the cusp of the house that follows.
                </p>
                <br />
                <p>
                  Because of the horizontal symmetry of natal charts, if you have one intercepted
                  sign, the opposite sign will also be intercepted. For instance, if your Cancer is
                  intercepted by the 4th house, your Capricorn will also be intercepted by the 10th
                  house. This is a phenomenon that only occurs in house systems where the angles of
                  the houses are not always equal, and is more common if you were born somewhere
                  particularly far north or south. If you calculate your natal chart using the Whole
                  Sign house system, which divides each house into 30° slices, none of your signs
                  will be intercepted.
                </p>
                <br />
                <p>
                  If a sign is intercepted by a house, it might mean that you have a difficult time
                  accessing or expressing the energy of that sign in your life in a positive way.
                  For example, if Cancer is intercepted by the fourth house and Capricorn by the
                  10th house, you may find that it’s hard for you to process emotions and let go of
                  the past, and that you sometimes lack or misdirect the drive to achieve your
                  goals.
                </p>
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">Does Co–Star use the tropical or the sidereal zodiac? </h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                <p>
                  Like most western astrology systems, our app exclusively uses the tropical zodiac
                  (as opposed to the Hindu system of astrology Jyotisha, or Vedic astrology, which
                  use the sidereal zodiac). We implicitly reference the sidereal system in some of
                  the images on our{' '}
                  <Link to="/how-does-astrology-work">How does astrology work?</Link> pages, because
                  it makes the physical connection between planets and stars more obvious than
                  talking about solstices and equinoxes. But all of the personalized content in our
                  app is based on the tropical zodiac.
                </p>
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">How popular is astrology?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                Astrology has been used by humans for four thousand years. According to the{' '}
                <a
                  href="https://www.nsf.gov/statistics/2018/nsb20181/assets/404/tables/at07-12.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  National Science Foundation
                </a>
                , belief in astrology has been consistent for the past 50 years&mdash;1 in 2 young
                people and 40% of adults.
              </div>
            </div>
          </div>
          <div id="about-shop">
            <h2>
              <br />
              Shop
            </h2>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">No returns</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text" className="multi-lined">
                <p>We don’t accept returns, except in the case of damages or defective items.</p>
                <p>
                  Please check the size measurements listed before ordering and pick your best size.
                  If you’re unsure, pick a shirt you own and like the fit of and measure it.
                </p>

                <p>
                  We pack all orders by hand and do our best to inspect for damaged items. If you
                  still received damaged merchandise, please contact us via shop@costarastrology.com
                  and we will work out a replacement or refund ASAP.
                </p>
              </div>
            </div>
          </div>

          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">Free Shipping within the US</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text" className="multi-lined">
                <p>Free USPS shipping on all orders within the United States.</p>
                <p>Priority UPS shipping is available at additional cost. </p>
                <p>
                  Please double check your address. If the postal service cannot deliver your
                  package and has to send it back to us, we will automatically refund your purchase,
                  subtracting any shipping costs we have incurred.
                </p>
              </div>
            </div>
          </div>
          <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">International Shipping</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text" className="multi-lined">
                <p>
                  We ship internationally with USPS First Class International. Tracking is available
                  in{' '}
                  <a href="https://pe.usps.com/text/imm/immc2_022.htm#ep2899642">
                    certain countries
                  </a>
                  . If your country is not listed, it will not be trackable once it leaves the US.{' '}
                  <b>
                    Ordering to non-supported countries is at your own risk and we cannot issue
                    refunds in the event of a non-deliverable package
                  </b>
                  . For supported countries, we will automatically refund your purchase, subtracting
                  any shipping costs we have incurred.
                </p>
                <p>
                  Some countries are subject to import duties and / or fees — please know your own
                  country's requirements before ordering. Packages returned due to duties not paid
                  will be automatically refunded less shipping charges and your merchandise will be
                  returned to stock. All packages are marked as merchandise and for the full retail
                  price, we cannot mark orders as gifts or devalue them.
                </p>
              </div>
            </div>
          </div>

          <h3>Anything else? </h3>
          <p>
            <Link to="/contact">Contact us</Link>.
          </p>
        </div>
        <PressFeatures />
        <FooterForm formLocation="FAQ" />
      </div>
    );
  }
}

export default PageLayout(FAQPage);
