import React from 'react';
import { Helmet } from 'react-helmet';

import PageLayout from 'landing-page/views/PageLayout';
import ContactForm from 'landing-page/components/ContactForm/ContactForm';

const ContactPage = () => (
  <>
    {' '}
    <Helmet>
      <title>Contact | Co – Star: Hyper-Personalized, Real-Time Horoscopes</title>
      <meta
        name="description"
        content="We'd love any feedback you have about what you love, what you hate, or your thoughts on astrology and the twenty-first century in general."
      />
    </Helmet>
    <ContactForm darkMode={false} />
  </>
);

export default PageLayout(ContactPage);
