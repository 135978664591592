import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useParams, Link } from 'react-router-dom';
import Api, { NewProspectiveUserReading } from 'api';
import Sticky from 'react-stickynode';
import { NatalChart } from 'landing-page/components/natal-chart';
import PageLayout from 'landing-page/views/PageLayout';
import { SelfManagingProgressBar } from 'components/progress';
import useWindowSize from '../../../hooks/useWindowSize';
import AdvancedChartHeader from './AdvancedChartHeader';
import AdvancedChartReadingItem from './AdvancedChartReadingItem';

import styles from './AdvancedChart.module.scss';

const AdvancedChartPage = () => {
  const { id } = useParams() as { id: string };
  const [reading, setReading] = useState<NewProspectiveUserReading | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [cutouts, setCutouts] = useState<number[]>([0, 1, 2, 3, 4]);
  const { width } = useWindowSize();

  useEffect(() => {
    Api.getProspectiveUserReading(id)
      .then((res) => {
        setReading(res);
      })
      .catch((err) => {
        setError(true);
      });
  }, [id]);
  // so cutouts don't change if any other state changes i.e. window width
  useEffect(() => {
    const shuffle = (arr) => {
      let i = arr.length;
      let j = 0;
      let temp;

      while (i--) {
        j = Math.floor(Math.random() * (i + 1));
        temp = arr[i];
        arr[i] = arr[j];
        arr[j] = temp;
      }
      return arr;
    };

    setCutouts(shuffle([0, 1, 2, 3, 4, 5, 6]));
  }, []);

  if (error)
    return (
      <div className="floating-container">
        <div className={cn(styles.error, 'floating-container-inner')}>
          <h4>
            Something went wrong! Please try again. <br />
            <br />
            If you paid for an advanced reading and are unable to access it via the link in your
            order email, <Link to="/contact">click here</Link> to reach out so that we can help
          </h4>
        </div>
      </div>
    );

  if (reading) {
    const readingItems = reading?.paidReading.map((el, i) => (
      <AdvancedChartReadingItem content={el} key={i} imageIdx={cutouts[i]} />
    ));

    const { birthDay, birthTime, birthPlace } = reading;
    const birthData = { birthDay, birthPlace, birthTime };

    return (
        <div className="chart-boundary">
          <div className={styles.container}>
            <div className={styles.chartContainer}>
              <div className="chart-container">
                <Sticky top={50} bottomBoundary=".chart-boundary" enabled={width > 980}>
                  <NatalChart
                    chartData={reading.analysis}
                    whitePlanetImages={false}
                    redirectOnPlanetImageClick={false}
                    isAC
                  />
                </Sticky>
              </div>
            </div>

            <div className={styles.headerReadingContainer}>
              <AdvancedChartHeader analysis={reading.analysis} birthData={birthData} />
              <div className={styles.readingItemsContainer}>{readingItems}</div>
              <div className={styles.footerContainer}>
                <p>Permalinks:</p>
                <br />
                <p>
                  You in Love Reading:{' '}
                  <a
                    href={`https://www.costarastrology.com/advanced-readings/${reading.readingUuid}`}
                  >
                    https://www.costarastrology.com/advanced-readings/{reading.readingUuid}
                  </a>
                </p>
                <br />
                {reading.prospectiveUserId && 
                <p>
                  Natal Chart:{' '}
                  <a
                    href={`https://www.costarastrology.com/natal-chart/${reading.prospectiveUserId}`}
                  >
                    https://www.costarastrology.com/natal-chart/{reading.prospectiveUserId}
                  </a>
                </p>
                }
              </div>
            </div>
          </div>
        </div>
    );
  }

  return <SelfManagingProgressBar message="Loading..." />;
};

export default PageLayout(AdvancedChartPage);
