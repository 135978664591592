import { sendClickEvent } from 'analytics';
import React from 'react';
import { FacebookShareButton, FacebookIcon } from 'react-share';

const SocialShareLinksAndWelcomeMessage = () => {
  function shareClick(platform: string) {
    return () => {
      sendClickEvent('Chart_Share', platform);
    };
  }

  return (
    <div>
      <h6 className="form-info">Here's your chart. Bookmark this page to save it!</h6>
      <h6 className="form-info">
        If your super serious astrology friend asks to see your chart, send them this. They can see
        the relationships between all your planets, along with the houses.
      </h6>
      <div className="chart-share">
        <div className="sharable">
          <FacebookShareButton url={window.location.href} beforeOnClick={shareClick('Facebook')}>
            <FacebookIcon iconFillColor="black" bgStyle={{ fill: 'white' }} size={32} />
            <h4 className="social-text"> SHARE </h4>
          </FacebookShareButton>
        </div>
      </div>
    </div>
  );
};

export default SocialShareLinksAndWelcomeMessage;
