import React from 'react';
import { ProspectiveUserForm } from 'components/form';

const FooterForm = ({ formLocation }) => (
  <div className="container dark-container">
    <div className="container-inner">
      <div className="section-title">
        looking 4 a sign? cast ur chart &amp; get ur horoscope by email {'>'}
      </div>
      <ProspectiveUserForm
        className="black"
        formName="footer"
        formLocation={formLocation}
        learnMore={false}
        natalChart={false}
      />
    </div>
  </div>
);

export default FooterForm;
