import { sendClickEvent } from 'analytics';
import * as React from 'react';
import * as Modal from 'react-modal';
import { Link } from 'react-router-dom';

interface MenuModalProps {
  isOpen: boolean;
  closeModal: () => void;
}
export class MenuModal extends React.Component<MenuModalProps, {}> {
  constructor(props) {
    super(props);
  }

  headerClick(action: string) {
    sendClickEvent('Header', action);
  }

  handleClick(action: string) {
    this.props.closeModal();
    this.headerClick(action);
  }
  render() {
    const styles = {
      overlay: {
        backgroundColor: 'rgb(253, 253, 253)',
      },
      content: {
        inset: '50% 0 auto',
        border: 'none',
        paddingLeft: '16px',
        backgroundColor: 'rgb(253,253,253)',
      },
    };
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.closeModal}
        portalClassName="MenuModalPortal"
        style={styles}
      >
        {this.renderNavigation()}
      </Modal>
    );
  }

  renderNavigation() {
    return (
      <div className="menuModalComponents">
        <div className="menuModalSiteNavigation">
          <Link to="/natal-chart" onClick={() => this.handleClick('NatalChart')}>
            <h4>
              <span> Natal Chart</span>
            </h4>
          </Link>
          <Link
            to="/how-does-astrology-work"
            onClick={() => this.handleClick('HowDoesAstrologyWork')}
          >
            <h4>
              <span> LEARN ASTROLOGY</span>
            </h4>
          </Link>
          <Link
            to="/how-does-astrology-work/what-is-astrology"
            onClick={() => this.handleClick('WhatIsAstrology')}
          >
            <h5>
              <span> - What is Astro?</span>
            </h5>
          </Link>
          <Link
            to="/how-does-astrology-work/what-is-a-natal-chart"
            onClick={() => this.handleClick('AstroIntuitionNatalChart')}
          >
            <h5>
              <span> - Natal Chart</span>
            </h5>
          </Link>
          <Link to="/zodiac-signs" onClick={() => this.handleClick('ZodiacSigns')}>
            <h5>
              <span>- Zodiac Signs </span>
            </h5>
          </Link>
          <Link
            to="/how-does-astrology-work/rising-sign"
            onClick={() => this.handleClick('RisingSign')}
          >
            <h5>
              <span> - Rising Sign</span>
            </h5>
          </Link>
          <Link to="/how-does-astrology-work/houses" onClick={() => this.handleClick('Houses')}>
            <h5>
              <span> - Houses</span>
            </h5>
          </Link>
          <Link
            to="/how-does-astrology-work/house-systems"
            onClick={() => this.handleClick('HouseSystems')}
          >
            <h5>
              <span> - House Systems</span>
            </h5>
          </Link>

          <Link to="/how-does-astrology-work/aspects" onClick={() => this.handleClick('Aspects')}>
            <h5>
              <span> - Aspects</span>
            </h5>
          </Link>
          <Link
            to="/how-does-astrology-work/transits-orbs"
            onClick={() => this.handleClick('TransitsOrbs')}
          >
            <h5>
              <span> - Transits & Orbs</span>
            </h5>
          </Link>

          <h4>
            <span>Shop</span>
          </h4>
          <a
            href="https://co-star-astrology.myshopify.com/"
            onClick={() => {
              this.headerClick('shopify');
              this.props.closeModal;
            }}
          >
            <h5>
              <span>- Merch</span>
            </h5>
          </a>
          <Link to="/advanced-readings" onClick={() => this.handleClick('AdvancedReadings')}>
            <h5>
              <span> - Readings</span>
            </h5>
          </Link>
        </div>

        <div className="menuModalAppStoreLinks">
          <a
            href="https://apps.apple.com/us/app/co-star/id1264782561"
            onClick={() => this.handleClick('download')}
          >
            <h4>
              <span> Download iOS APP </span>
            </h4>
          </a>

          <a
            href="https://play.google.com/store/apps/details?id=com.costarastrology&utm_source=costarwebsite"
            onClick={() => this.handleClick('downloadandroid')}
          >
            <h4>
              <span> Download ANDROID APP </span>
            </h4>
          </a>
        </div>

        <div className="menuModalFooterLinks">
          <Link to="/faq" onClick={() => this.handleClick('FAQ')}>
            FAQ
          </Link>
          &nbsp;—&nbsp;
          <Link to="/about" onClick={() => this.handleClick('About')}>
            About
          </Link>
          &nbsp;—&nbsp;
          <Link to="/jobs" onClick={() => this.handleClick('Jobs')}>
            Jobs
          </Link>
          &nbsp;—&nbsp;
          <Link to="/team" onClick={() => this.handleClick('Team')}>
            Team
          </Link>
          &nbsp;—&nbsp;
          <Link to="/why-haskell" onClick={() => this.handleClick('Haskell')}>
            Haskell
          </Link>
          <br />
          <br />
          <Link to="/contact" onClick={() => this.handleClick('Contact')}>
            Contact
          </Link>
          &nbsp;—&nbsp;
          <Link to="/privacy" onClick={() => this.handleClick('Privacy')}>
            Privacy
          </Link>
        </div>

        <div className="menuModalSocialLinks">
          <a
            className="menuModalInsta"
            href="https://instagram.com/costarastrology/"
            onClick={() => this.handleClick('Instagram')}
          >
            <img src={require('../../../images/insta.svg')} />
          </a>
        </div>
      </div>
    );
  }
}
