import React, { useRef } from 'react';
import Slider from 'react-slick';
import cn from 'classnames';

import HouseSystemsCarouselItem from './HouseSystemsCarouselItem';
import { HouseSystemsQuotesObj } from '../HouseSystems/HouseSystemsTypes';

import styles from './HouseSystemsCarousel.module.scss';

type HouseSystemsCarouselProps = {
  quotes: HouseSystemsQuotesObj[];
  theme?: 'light' | 'dark';
};

const HouseSystemsCarousel = ({ quotes, theme }: HouseSystemsCarouselProps) => {
  const carouselRef = useRef();
  const sliderSettings = {
    slidesToShow: 3,
    arrows: true,
    infinite: true,
    centerMode: true,
    centerPadding: '20px',
  };
  const tabletSliderSettings = {
    slidesToShow: 2,
    centerMode: true,
    arrows: true,
  };
  const mobileSliderSettings = {
    slidesToShow: 1,
    centerMode: true,
    arrows: true,
    centerPadding: '10px',
  };
  return (
    <div
      className={cn(styles.container, {
        [styles.lightContainer]: theme === 'light',
        [styles.darkContainer]: theme === 'dark',
      })}
    >
      <div className={styles.carouselTitle}>
        <h4>WHO USES IT?</h4>
        <span onClick={() => carouselRef.current.slickNext()}>&#10141;</span>
      </div>
      <Slider
        {...sliderSettings}
        ref={carouselRef}
        responsive={[
          { breakpoint: 740, settings: mobileSliderSettings },
          { breakpoint: 1165, settings: tabletSliderSettings },
        ]}
      >
        {quotes.map((item) => (
          <HouseSystemsCarouselItem
            quote={item.quote}
            name={item.name}
            link={item.link}
            image={item.image}
            key={item.image}
          />
        ))}
      </Slider>
    </div>
  );
};

HouseSystemsCarousel.defaultProps = {
  theme: 'white',
};

export default HouseSystemsCarousel;
