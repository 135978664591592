import React from 'react';
import { useNavigate, useLocation } from 'react-router';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import {
  itunesStoreURL,
  playStoreUrl,
  ITUNES_CAMPAIGN_TOKENS,
  PLAY_STORE_CAMPAIGN_TOKENS,
} from 'landing-page/constants';

import AstroIntuitionContainer from '../AstroIntuitionContainer';

import { AstroIntuitionPages } from './AstroIntuitionFooterTypes';
import { pageData } from '../AstroIntuitionTypes';

import styles from './AstroIntuitionFooter.module.scss';
import buttonStyles from '../AstroIntuitionNavItem/AstroIntuitionNavItem.module.scss';

const AstroIntuitionFooter: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const location =
    pathname.split('/')[1] === 'zodiac-signs' ? 'zodiac-sun-signs' : pathname.split('/')[2];

  const links = AstroIntuitionPages.map((page, i) => {
    let name = page.replace(/-/g, ' ');
    if (name === 'what is astrology') name += '?';
    if (name === 'transits orbs') name = 'transits & orbs';
    if (name === 'what is a natal chart') name = 'natal chart';

    if (page === location) {
      return (
        <li className={styles.grey} key={page}>
          <h4>{name}</h4>
        </li>
      );
    }

    const path = page === 'zodiac-sun-signs' ? '/zodiac-signs' : `/how-does-astrology-work/${page}`;

    return (
      <li key={page}>
        <Link to={path}>
          <h4 className={styles.link}>{name}</h4>
        </Link>
      </li>
    );
  });

  const nextIndex = AstroIntuitionPages.indexOf(location) + 1;

  let nextPage = AstroIntuitionPages[nextIndex]?.replace(/-/g, ' ');
  if (nextPage === 'transits orbs') nextPage = 'transits & orbs';
  if (nextPage === 'what is a natal chart') nextPage = 'natal chart';

  const nextPageBlurb = pageData[nextIndex]?.blurb;

  const nextPageLink =
    nextPage === 'zodiac sun signs'
      ? '/zodiac-signs'
      : `/how-does-astrology-work/${AstroIntuitionPages[nextIndex]}`;

  return (
    <AstroIntuitionContainer theme="footer">
      {nextPage ? (
        <div className={styles.buttonGroup}>
          <h4 className={styles.grey}>NEXT SECTION</h4>
          <div
            role="button"
            className={styles.button}
            onClick={() => navigate(nextPageLink)}
            tabIndex={0}
          >
            <div className={cn(buttonStyles.title, styles.footerTitle)}>
              <div className={styles.titleGroup}>
                <span className={styles.buttonIndex}>{nextIndex + 1}</span>
                <h1>{nextPage} </h1>
              </div>
              <span className={styles.arrow}>&#10141;</span>
            </div>
            <p className="body-font-basics">{nextPageBlurb}</p>
          </div>
        </div>
      ) : (
        <div className={styles.buttonGroup}>
          <h3>Download our app to experience astrology real-time with your own natal chart.</h3>
          <a
            className={cn('btn', 'black', styles.button, styles.appDownloadButtons)}
            href={itunesStoreURL(ITUNES_CAMPAIGN_TOKENS.SITE_EDUCATION)}
          >
            DOWNLOAD IOS <span>&#10141;</span>
          </a>
          <a
            className={cn('btn', 'black', styles.button, styles.appDownloadButtons)}
            href={playStoreUrl(PLAY_STORE_CAMPAIGN_TOKENS.SITE_EDUCATION)}
          >
            DOWNLOAD ANDROID <span>&#10141;</span>
          </a>
        </div>
      )}
      <div className={styles.linkList}>
        <h4 className={cn(styles.grey, styles.linksHeader)}> ALL SECTIONS</h4>
        <ol>{links}</ol>
      </div>
    </AstroIntuitionContainer>
  );
};

export default AstroIntuitionFooter;
