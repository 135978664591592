import React from 'react';
import { Sign } from 'astrology';
import styles from './ZodiacConstellation.module.scss';

type ZodiacConstellationProps = {
  sign: Sign;
};

const ZodiacConstellation = ({ sign }: ZodiacConstellationProps) => (
  <div className={styles.container}>
    <img src={require(`../../../../../../images/zodiac-signs/white/${sign}.svg`)} />
  </div>
);

export default ZodiacConstellation;
