import React from 'react';
import { ITUNES_CAMPAIGN_TOKENS, itunesStoreURL } from '../../../constants';

const AddUserInfoSection: React.FC<{ username: string }> = ({ username }) => (
  <div className="add-user-section">
    <a
      href={itunesStoreURL(ITUNES_CAMPAIGN_TOKENS.SITE_USER_PROFILE)}
      className="public-chart-page__explanation-section___add-user-link"
    >
      Download Co–Star
    </a>
    <h6 className="form-info add-user-info">
      {`See your compatibility with @${username} on `}
      <a href={itunesStoreURL(ITUNES_CAMPAIGN_TOKENS.SITE_USER_PROFILE)}>Co-Star</a>. You will also
      be able to save their chart, get astrological updates, and read horoscopes that shift as the
      stars change position in the sky.
    </h6>
  </div>
);

export default AddUserInfoSection;
