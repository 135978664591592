import * as React from 'react';
import * as qs from 'qs';
import { useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { initial, success, failure, pending } from '@devexperts/remote-data-ts';

import Api, { AstroAnalysis } from 'api';
import { BadUrl } from 'elm-ts/lib/Http';
import { NatalChart } from 'landing-page/components/natal-chart';
import NotFoundPage from 'landing-page/pages/NotFoundPage/404';
import { SelfManagingProgressBar } from 'components/progress';
import { WebData } from 'server/request';

// we wrap the class component in a functional component, because the routing
// library will only accept functional components.
function NatalChartWebviewPage(props) {
  const location = useLocation();
  const params = useParams();
  return <NatalChartWebview params={params} location={location} {...props} />;
}

// a quick prop shape for mocking RouteComponentProps
interface NatalChartWebviewProps {
  params: {
    birthedEntityId?: any;
  };
  location: {};
}

interface NatalChartWebviewState {
  // The target user's astro data
  chartData: WebData<AstroAnalysis>;
  // Is the `key` query parameter defined?
  hasAuth: boolean;
  // Does the app have dark-mode enabled?
  enableDarkmode: boolean;
}

/* This page is used by WebViews in the app to show a user's natal chart.
 *
 * Note that unlike the ProspectiveUser NatalChart, this is freely accessible
 * when hit for a birthedEntityId that the authToken in the query parameter has
 * access to.
 *
 * On initial render, we show a blank page.
 * During load, we should a progress bar.
 * On error, we show the site header & 404 page.
 * On success we show the circle diagram of the natal chart.
 */
class NatalChartWebview extends React.Component<NatalChartWebviewProps, NatalChartWebviewState> {
  constructor(props) {
    super(props);

    const queryParams = qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
    });
    const authToken = queryParams.key;

    this.state = {
      chartData: initial,
      hasAuth: authToken !== undefined,
      enableDarkmode: queryParams.style === 'dark',
    };

    if (typeof authToken === 'string') {
      this.setState({
        chartData: pending,
      });
      Api.getBirthedEntityAstroAnalysis(this.props.params.birthedEntityId, authToken)
        .then((aa) => {
          this.setState({
            chartData: success(aa),
          });
        })
        .catch((e) => {
          this.setState({
            chartData: failure(new BadUrl(e)),
          });
        });
    } else {
      this.setState({
        chartData: failure(new BadUrl('invalid authtoken')),
      });
    }
  }

  render() {
    if (!this.state.hasAuth) {
      return <NotFoundPage />;
    }
    const hideHeaderJSX = (
      <Helmet>
        <style>{'header.header, canvas { display: none; }'}</style>
      </Helmet>
    );
    const darkModeJSX: React.ReactNode = this.state.enableDarkmode ? (
      <Helmet>
        <style>{'#app { background-color: #141414; }'}</style>
      </Helmet>
    ) : null;
    return (
      <div className={`${this.state.enableDarkmode ? 'dark-mode' : ''}`}>
        {this.state.chartData.fold(
          hideHeaderJSX,
          <>
            {hideHeaderJSX}
            {darkModeJSX}
            <SelfManagingProgressBar message="Drawing" />
          </>,
          (_) => (
            <NotFoundPage />
          ),
          (aa) => (
            // success
            <div>
              {hideHeaderJSX}
              {darkModeJSX}
              <NatalChart
                onAwsUrl={(_) => {}}
                chartData={aa}
                redirectOnPlanetImageClick
                whitePlanetImages={this.state.enableDarkmode}
              />
            </div>
          ),
        )}
      </div>
    );
  }
}

export default NatalChartWebviewPage;
