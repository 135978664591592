import React from 'react';
import { AstroAnalysis } from 'api';
import Sticky from 'react-stickynode';
import { NatalChart } from 'landing-page/components/natal-chart';
import { WebData } from '../../../../server/request';
import ConnectToAppInfoSection from './ConnectToAppInfoSection';
import PlanetExplanations from '../PlanetAnalysisList';

type PublicChartPageProps = {
  remoteAnalysis: AstroAnalysis;
  username: string;
};

const PublicChartPage = ({ remoteAnalysis, username }: PublicChartPageProps) => (
  <>
    <div className="chart-container">
      <div className="chart-info">
        <div className="chart-message">
          <ConnectToAppInfoSection username={username} remoteAnalysis={remoteAnalysis} />
        </div>
        <div className="hero-arrow">&darr;</div>
      </div>
      <div className="chart-container-outer">
        <Sticky top={50} bottomBoundary=".chart-container" enabled={window.innerWidth > 980}>
          <div className="chart-picture-container" id="chartbox">
            <NatalChart
              chartData={remoteAnalysis}
              redirectOnPlanetImageClick={false}
              whitePlanetImages={false}
            />
          </div>
        </Sticky>
      </div>
      <PlanetExplanations showAC={false} analysis={remoteAnalysis.planets} />
    </div>
    {/* <PublicChartPageFooter /> */}
  </>
);

export default PublicChartPage;
