import React from 'react';
import { sendOutboundLinkEvent } from 'analytics';
import { ResourceItem } from './OracleResourcesPageTypes';

import styles from './OracleResourcesPage.module.scss';

type OracleResourcesSectionItemProps = ResourceItem;

const OracleResourcesSectionItem = ({
  title,
  url,
  phoneNumber,
}: OracleResourcesSectionItemProps) => {
  const captureOutboundEvent = (loc: string) => {
    sendOutboundLinkEvent('MentalHealthResources', loc, () => {});
  };
  return (
    <div className={styles.section}>
      <p>{title}</p>
      <div>
        <a href={url} onClick={() => captureOutboundEvent(url)}>
          Visit Site
        </a>
        {phoneNumber && (
          <>
            <span> / </span>
            <a href={`tel: ${phoneNumber}`}>{phoneNumber}</a>
          </>
        )}
      </div>
    </div>
  );
};

export default OracleResourcesSectionItem;
