import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { sendClickEvent } from 'analytics';
import styles from './AskTheStarsProductCard.module.scss';

const AskTheStarsProductCard = () => {
  const buttonClick = () => {
    sendClickEvent('Shop', 'AskTheStars');
  };
  return (
    <div className={styles.backgroundContainer}>
      <div className={styles.contentContainer}>
        <h3>Enter the void and ask the stars</h3>
        <img src={require('../../../../../../images/ask-the-stars-img.png')} />
        <Link to="/advanced-readings/ask-the-stars">
          <button className={cn(styles.button, 'btn', 'white')} type="button" onClick={buttonClick}>
            VIEW DETAILS
          </button>
        </Link>
      </div>
    </div>
  );
};

export default AskTheStarsProductCard;
