import React from 'react';
import styles from './AskTheStars.module.scss';

const AskTheStarsImg = ({ mobile = false }: { mobile: boolean }) => {
  if (mobile)
    return (
      <div className={styles.mobileImgContainer}>
        <img src={require('../../../../../images/ask-the-stars-img.png')} />
      </div>
    );
  return (
    <div className={styles.desktopImgContainer}>
      <img src={require('../../../../../images/ask-the-stars-card-desktop.png')} />
    </div>
  );
};

export default AskTheStarsImg;
