import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import FooterForm from 'landing-page/components/FooterForm';
import PageLayout from 'landing-page/views/PageLayout';
import { PressFeatures } from 'landing-page/press-features';
import { scrollToLocationHash, AnchorLink } from 'landing-page/components/anchor-link';
import Bio from 'landing-page/pages/TeamPage/Bio';
import PullQuote from 'landing-page/pages/TeamPage/PullQuote';
import {
  advisoryBoard,
  bios,
  leadership,
  staffAstrologicalExperts,
  type Bio as BioType,
} from './teamData';
import styles from './index.module.scss';
import '../../../../style/team.scss';

const TeamMemberLink = ({ person }: { person: BioType }) => {
  return (
    <div className={styles.teamIndexCell}>
      <AnchorLink targetId={person.hyphenName}>{person.name}</AnchorLink>
      <br />
      <span className="smallcaps">{person.title}</span>
    </div>
  );
};

const TeamBiographyPage = () => {
  useEffect(() => {
    scrollToLocationHash();
  }, []);

  return (
    <>
      <Helmet>
        <title>Team | Co – Star: Hyper-Personalized, Real-Time Horoscopes</title>
      </Helmet>
      <div className="container static-container">
        <div className="floating-container-inner">
          <div className="about-finale">
            <h2>We’re making astrology accessible to the entire world.</h2>
            <p className={styles.subHeader}>
              <br />
              Astrology has been helping people reflect and connect for thousands of years. Co–Star
              deciphers it for today.
            </p>
          </div>

          <PullQuote />

          <div className={styles.teamHeader}>Meet our team</div>

          <div className={styles.teamSubHeader}>THE PEOPLE WHO MAKE MAGIC HAPPEN</div>

          <div className={styles.teamIndexContainer}>
            {bios
              .filter(
                (b) =>
                  !advisoryBoard.includes(b.hyphenName),
              )
              .sort((a, b) => (a.name < b.name ? -1 : 1))
              .map((person) => (
                <TeamMemberLink key={person.hyphenName} person={person} />
              ))}
            <div className={styles.teamIndexCell}>
              You?
              <br />
              <Link to="/jobs" className="smallcaps">
                See openings →
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="about-containers-parent">
        {bios
          .sort((a, b) => {
            if (a.hyphenName === 'banu-guler') return -1;
            if (b.hyphenName === 'banu-guler') return 1;
            return a.name < b.name ? -1 : 1;
          })
          .map((person) => (
            <Bio key={person.hyphenName} person={person} />
          ))}

        <div className="container about">
          <div className="about-finale">
            <h2>
              We’re hacking tech, culture, and consciousness to build new practices for reflection &
              collectivity.
            </h2>
            <h4>
              <Link to="/jobs" className="smallcaps center">
                <br />
                We're hiring. Join us. &rarr;
              </Link>
            </h4>
          </div>
        </div>

        <PressFeatures />

        <FooterForm formLocation="Team" />

      </div>
    </>
  );
};

export default PageLayout(TeamBiographyPage);
