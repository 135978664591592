import React from 'react';
import { AstroAnalysis } from 'api';
import { useLocation } from 'react-router-dom';
import Sticky from 'react-stickynode';
import { NatalChart } from 'landing-page/components/natal-chart';
import BirthInfoFormArea from './BirthInfoFormArea';
import SocialShareWelcomeMessage from './SocialShareWelcomeMessage';
import ProspectiveUserFooter from './ProspectiveUserFooter';
import PlanetExplanations from '../PlanetAnalysisList';

type ProspectiveUserChartPageProps = {
  prospectiveUserId: number;
  remoteAnalysis: AstroAnalysis;
};
const ProspectiveUserChartPage = ({
  prospectiveUserId,
  remoteAnalysis,
}: ProspectiveUserChartPageProps) => {
  const location = useLocation();
  const shouldShowForm = !location.state;
  const showAC = prospectiveUserId !== 19;

  return (
    <>
      <div className="chart-container">
        <div className="chart-info">
          <div className="chart-message">
            {shouldShowForm ? <BirthInfoFormArea /> : <SocialShareWelcomeMessage />}
          </div>
          <div className="hero-arrow">↓</div>
        </div>
        <div className="chart-container-outer">
          <Sticky top={50} bottomBoundary=".chart-container" enabled={window.innerWidth > 980}>
            <div className="chart-picture-container" id="chartbox">
              <NatalChart
                chartData={remoteAnalysis}
                redirectOnPlanetImageClick={false}
                whitePlanetImages={false}
                isAC={false}
              />
            </div>
          </Sticky>
        </div>
        <PlanetExplanations showAC={showAC} analysis={remoteAnalysis.planets} />
      </div>
      <ProspectiveUserFooter
        shouldShowForm={shouldShowForm}
        userId={prospectiveUserId}
        activeSimple
      />
    </>
  );
};

export default ProspectiveUserChartPage;
