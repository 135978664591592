import React from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

// components
import PageLayout from 'landing-page/views/PageLayout';
import SectionPadding from 'landing-page/components/SectionPadding';

import Orbs from '../Orbs';

import AstroIntuitionHeader from '../AstroIntuitionHeader';
import AstroIntuitionContainer from '../AstroIntuitionContainer';
import AstroIntuitionFooter from '../AstroIntuitionFooter';

import RightSideTransit from './RightSideTransit';

// styles
import styles from './Transits.module.scss';
import TransitsZodiacRing from './TransitsZodiacRing';
import {
  aspectsForImageThree,
  aspectsForImageTwo,
  planetObjsForImageOne,
  planetObjsForImageThree,
  planetObjsForImageTwo,
  planetsForImageOne,
  planetsForImageThree,
  planetsForImageTwo,
} from './TransitsTypes';
import LeftSideTransit from './LeftSideTransit';

const Transits = () => (
  <>
    <Helmet>
      <title>Transits & Orbs: How does astrology work? | Co-Star</title>
      <meta
        name="description"
        content="Transits show the relationship between where a planet is currently and where a planet was at the time of your birth (in your natal chart). an Orb is a buffer zone around an angle between two planets that defines what is close enough to count as an aspect."
      />
    </Helmet>
    <div className="floating-container">
      <AstroIntuitionHeader>
        <h1>Transits & Orbs</h1>
      </AstroIntuitionHeader>
      <AstroIntuitionContainer theme="transparent">
        <h3>
          Transits are time-based patterns that take place at regular intervals, based on your
          chart. An orb is a buffer zone around an angle to define what we count as an aspect.
        </h3>
        <SectionPadding verticalPadding="1rem" />
        <p>
          They are based on the relationship between where a planet is{' '}
          <span className={styles.italic}>right now</span> and where a planet was{' '}
          <span className={styles.italic}>at the time of your birth</span> (in your natal chart).
        </p>
      </AstroIntuitionContainer>
      <AstroIntuitionContainer theme="dark">
        <h3>Since you were born, the planets have continued to move.</h3>
        <SectionPadding verticalPadding="2rem" />
        <TransitsZodiacRing
          planetObjs={planetObjsForImageOne}
          planets={planetsForImageOne}
          orbitAnimation="white"
          startingSign="Leo"
          className={styles.transitOrbits}
        />
        <SectionPadding verticalPadding="2rem" />

        <h3>
          When the current position of a planet forms an{' '}
          <Link to="/how-does-astrology-work/aspects">
            <span style={{ textDecoration: 'underline' }}>aspect</span>
          </Link>{' '}
          with where a planet was when you were born, we call that a transit.
        </h3>
      </AstroIntuitionContainer>
      <AstroIntuitionContainer theme="light">
        <h3>
          For instance, the Sun moves through the zodiac once every year and has big transits every
          three months (every time it completes one-quarter of its 12 month cycle).
        </h3>
        <SectionPadding verticalPadding="2rem" />

        <TransitsZodiacRing
          planetObjs={planetObjsForImageTwo}
          planets={planetsForImageTwo}
          orbitAnimation="black"
          fillInBackground
          startingSign="Leo"
          className={styles.transitOrbits}
          aspects={aspectsForImageTwo}
        />
        <h4>SUN: 365 DAY CYCLE</h4>
        {/* MOBILE/TABLET Transits */}

        <RightSideTransit />
        <LeftSideTransit />

        {/* DESKTOP CAPTIONS */}
        <div className={cn(styles.hideOnMobile, styles.hideOnTablet, styles.desktopTransitText)}>
          <div className={styles.transitText}>
            <h4>
              Transiting Sun <span>&rarr;</span>
            </h4>
            <h4>Square Natal Sun </h4>
            <ul>
              <li>Every Sept ~19</li>
              <li>Ego troubles</li>
            </ul>
          </div>
          <div className={styles.transitText}>
            <h4>
              Transiting Sun <span>&rarr;</span>
            </h4>
            <h4> Opposite Natal Sun</h4>
            <ul>
              <li>Every Dec ~19</li>
              <li>Reflecting about being</li>
            </ul>
          </div>
          <div className={styles.transitText}>
            <h4>
              <span>&larr;</span> TRANSITING SUN CONJUNCT NATAL SUN, OR SOLAR RETURN
            </h4>

            <ul>
              <li>
                Your birthday!
                <br />
                Every June ~19
              </li>
              <li>Ego activation</li>
            </ul>
          </div>
          <div className={styles.transitText}>
            <h4>
              <span>&larr;</span> Transiting Sun Square Natal Sun
            </h4>
            <ul>
              <li>Every March ~19</li>
              <li>Ego troubles</li>
            </ul>
          </div>
        </div>
      </AstroIntuitionContainer>
      <AstroIntuitionContainer theme="dark">
        <h3>All the planets have individual cycles of different durations.</h3>
        <SectionPadding verticalPadding="2rem" />
        <div className={styles.transitTextDark}>
          <h4>
            <span className={cn(styles.hideOnMobile, styles.hideOnTablet)}>&larr;</span> TRANSITING
            Saturn
          </h4>
          <h4> opposite natal saturn</h4>
          <ul>
            <li>Age ~14, ~44, ~75</li>
            <li>Reflecting about discipline</li>
          </ul>
        </div>

        <TransitsZodiacRing
          planetObjs={planetObjsForImageThree}
          planets={planetsForImageThree}
          orbitAnimation="white"
          startingSign="Leo"
          fillInBackground
          aspects={aspectsForImageThree}
          className={styles.transitOrbits}
        />

        <div className={styles.transitTextDark}>
          <h4>
            Transiting Saturn{' '}
            <span className={cn(styles.hideOnMobile, styles.hideOnTablet)}>&rarr;</span> conjunct
            natal saturn, or saturn return
          </h4>
          <ul>
            <li>Age ~29, ~58, ~87</li>
            <li>Activation of responsibility</li>
          </ul>
        </div>
        <h4 className={styles.white}>SATURN: 28 YEAR CYCLE</h4>
      </AstroIntuitionContainer>
      <AstroIntuitionContainer theme="white">
        <h3>Each aspect between a pair of planets has a meaning.</h3>
        <img
          src={require('../../../../../images/transits.gif')}
          className={styles.transitsGif}
          alt="animation of planets transiting"
        />
      </AstroIntuitionContainer>
      <Orbs />
    </div>
    <AstroIntuitionFooter />
  </>
);

export default PageLayout(Transits);
