import React from 'react';
import { useNavigate } from 'react-router';

import { PageData } from '../AstroIntuitionTypes';

import styles from './AstroIntuitionNavItem.module.scss';

type AstroIntuitionNavItemProps = PageData & {
  index: number;
};

const AstroIntuitionNavItem = ({ title, blurb, path, index }: AstroIntuitionNavItemProps) => {
  const navigate = useNavigate();

  const handleClick = () => navigate(path);

  return (
    <div onClick={handleClick} className={styles.navItem} role="button" tabIndex={0}>
      <p>{index + 1}</p>
      <div className={styles.title}>
        <h5>{title}</h5> <p>&#10141;</p>
      </div>
      <p className="body-font-basics">{blurb}</p>
    </div>
  );
};

export default AstroIntuitionNavItem;
