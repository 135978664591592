/* Contains the image URLs & valid-image descriptions for the form captchas.
 *
 */

export interface CaptchaData {
  validImg: string;
  invalidImg: string;
  validDescription: string;
}

export const captchaData: Array<CaptchaData> = [
  {
    validImg: require('!!url-loader!../images/captcha/flower-valid.png'),
    invalidImg: require('!!url-loader!../images/captcha/flower-invalid.png'),
    validDescription: 'flower in bloom',
  },
  {
    validImg: require('!!url-loader!../images/captcha/fruit-valid.png'),
    invalidImg: require('!!url-loader!../images/captcha/fruit-invalid.png'),
    validDescription: 'peeled fruit',
  },
  {
    validImg: require('!!url-loader!../images/captcha/bird-valid.png'),
    invalidImg: require('!!url-loader!../images/captcha/bird-invalid.png'),
    validDescription: 'bird in flight',
  },
  {
    validImg: require('!!url-loader!../images/captcha/crystal-valid.png'),
    invalidImg: require('!!url-loader!../images/captcha/crystal-invalid.png'),
    validDescription: 'pyramid-shaped crystal',
  },
  {
    validImg: require('!!url-loader!../images/captcha/insect-valid.png'),
    invalidImg: require('!!url-loader!../images/captcha/insect-invalid.png'),
    validDescription: 'winged insect',
  },
  {
    validImg: require('!!url-loader!../images/captcha/sculpture-valid.png'),
    invalidImg: require('!!url-loader!../images/captcha/sculpture-invalid.png'),
    validDescription: 'headless sculpture',
  },
  {
    validImg: require('!!url-loader!../images/captcha/feather-valid.png'),
    invalidImg: require('!!url-loader!../images/captcha/feather-invalid.png'),
    validDescription: 'single feather',
  },
];

/* Return a random CaptchaData for displaying in a modal. */
export function randomCaptcha(): CaptchaData {
  return captchaData[Math.floor(Math.random() * captchaData.length)];
}
