import React from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet';

// components
import PageLayout from 'landing-page/views/PageLayout';
import SectionPadding from 'landing-page/components/SectionPadding';

import AstroIntuitionHeader from '../AstroIntuitionHeader';
import AstroIntuitionContainer from '../AstroIntuitionContainer';
import AstroIntuitionFooter from '../AstroIntuitionFooter';
import RisingSignZodiacRing from './RisingSignZodiacRing';

import styles from './RisingSign.module.scss';

import {
  planetObjsForImageOne,
  planetsForImageOne,
  planetImageInfoOne,
  planetImageInfoTwo,
  planetObjsForImageTwo,
} from './RisingSignTypes';

const RisingSign = () => (
  <>
    <Helmet>
      <title>Rising Sign: How does astrology work? | Co-Star</title>
      <meta
        name="description"
        content="The rising sign is the zodiac sign that was on the eastern horizon when and where you were born."
      />
    </Helmet>
    <div className="floating-container">
      <AstroIntuitionHeader>
        <h1>The rising sign, or ascendant</h1>
      </AstroIntuitionHeader>
      <AstroIntuitionContainer theme="transparent">
        <h3>
          The “rising sign” or “ascendant” is the zodiac sign that was on the eastern horizon when
          and where you were born.
        </h3>
      </AstroIntuitionContainer>
      <AstroIntuitionContainer theme="dark">
        <h4 className={styles.white}>
          It's called the Rising Sign because that zodiac constellation is rising, or ascending,
          into the sky over the eastern horizon.
        </h4>
        <br />
        <p className={styles.grey}>In Nic’s case, that sign was Scorpio.</p>
        <SectionPadding verticalPadding="2rem" />

        <img
          src={require('../../../../../images/astro-baby-2.png')}
          className={cn(styles.endImage, styles.wideImage)}
          id="astro-baby"
          alt="horizon with baby and scorpio constellation"
        />
      </AstroIntuitionContainer>
      <AstroIntuitionContainer theme="light">
        <SectionPadding verticalPadding="3rem" />
        <h3>The rising sign is an important concept in astrology.</h3>
        <p className={styles.darkText}>
          <br />
          Some astrologers think of the rising sign as how you present yourself in the world. Others
          think of it as your lens on the world.
        </p>
        <SectionPadding verticalPadding="2rem" />
      </AstroIntuitionContainer>
      <AstroIntuitionContainer theme="dark">
        <h3>The rising sign changes every two hours.</h3>
        <p>
          <br />
          Each day, the entire zodiac appears to move across the sky. This means that every two
          hours, another one of the twelve zodiac signs becomes visible from below the horizon. But
          they aren’t actually moving around us; Earth itself is moving. Every 24 hours the Earth
          rotates 360°.
        </p>
        <SectionPadding verticalPadding="2rem" />
        <img
          src={require('../../../../../images/Earth-Rotating.png')}
          className={styles.medImage}
          alt="earth rotating with zodaic ring signs"
        />
        <SectionPadding verticalPadding="3rem" />
        <div className={styles.line} />
        <SectionPadding verticalPadding="3rem" />
        <h3>
          It does this while the rest of the solar system continues its orbit. So two types of
          movement are happening:
        </h3>
        <SectionPadding verticalPadding="2rem" />
        <p>
          1. The Earth is orbiting the Sun (one revolution in a year)
          <br />
          2. The Earth is rotating on its own axis (one rotation in a day)
        </p>
        <SectionPadding verticalPadding="1rem" />
        <img
          src={require('../../../../../images/ZodiacRing_White.png')}
          className={styles.zodiacRingWhite}
          alt="white zodiac ring with planets"
        />
      </AstroIntuitionContainer>
      <AstroIntuitionContainer theme="transparent">
        <h3>
          Your <span className={styles.darkHighlight}>planet</span> signs (like Mercury in Aquarius)
          are based on the Earth’s revolution around the Sun.
        </h3>
        <SectionPadding verticalPadding="1.5rem" />
        <h3>
          Your <span className={styles.darkHighlight}>rising</span> sign is based on the Earth's
          rotation on its own axis.
        </h3>
        <SectionPadding verticalPadding="3rem" />
        <div className={styles.darkLine} />
        <SectionPadding verticalPadding="3rem" />
        <h3>Imagine two people born at the same time, but on opposite sides of Earth.</h3>
        <RisingSignZodiacRing
          centerImage
          planetImages={planetImageInfoOne}
          fillInBackground
          startingSign="Leo"
          className={styles.wideImage}
          baby1
          baby2
          planetRing={false}
        />
        <SectionPadding verticalPadding="1rem" />
        <h3>
          Their charts are mostly the same, because from the perspective of the Earth, the planets
          are in front of the same constellations of stars.
        </h3>
        <SectionPadding verticalPadding="1rem" />
        <RisingSignZodiacRing
          centerImage
          planetImages={planetImageInfoOne}
          fillInBackground
          startingSign="Leo"
          className={styles.wideImage}
          baby1
          baby2
          pathLines
          planetRing={false}
        />
        <SectionPadding verticalPadding="1rem" />
        <h3>What changes? Their relative horizons – literally what they see to the east.</h3>
        <SectionPadding verticalPadding="1rem" />
        <RisingSignZodiacRing
          centerImage
          planetImages={planetImageInfoOne}
          fillInBackground
          startingSign="Leo"
          className={cn(styles.wideImage, styles.svgWithCaption)}
          baby1
          pathLines
          horizonBottom
          planetRing={false}
        />
        <img
          src={require('../../../../../images/rising-arrow-1.png')}
          className={styles.risingArrow}
          alt="arrow pointing to sagittarius rising sign"
        />
        <h4>RISING SIGN = SAGITTARIUS</h4>
        <SectionPadding verticalPadding="2rem" />
        <h3>All that changes is the rising sign.</h3>
        <RisingSignZodiacRing
          centerImage
          planetImages={planetImageInfoOne}
          fillInBackground
          startingSign="Leo"
          className={cn(styles.wideImage, styles.svgWithCaption)}
          baby2
          horizonTop
          planetRing={false}
        />{' '}
        <img
          src={require('../../../../../images/rising-arrow-2.png')}
          className={cn(styles.risingArrow, styles.hideOnMobile)}
          alt="arrow pointing to gemini rising sign"
        />
        <img
          src={require('../../../../../images/rising-arrow-shorter-02.png')}
          className={cn(styles.risingArrow, styles.hideOnDesktop, styles.hideOnTablet)}
          alt="arrow pointing to gemini rising sign"
        />
        <h4>RISING SIGN = GEMINI</h4>
      </AstroIntuitionContainer>
      <AstroIntuitionContainer theme="dark">
        <h3>
          If two people are born two hours apart, their charts are basically the same (e.g. Sag
          Moon, Gem Sun), but their rising signs are one sign different.
        </h3>
        <SectionPadding verticalPadding="1rem" />
        <div className={styles.sideBySideImages}>
          <RisingSignZodiacRing
            centerImage
            planetImages={planetImageInfoOne}
            fillInBackground
            startingSign="Leo"
            className={styles.wideImage}
            baby1
            babyInfo="BABY NIC AT 2p"
            planetObjs={planetObjsForImageOne}
            planets={planetsForImageOne}
            planetRing
          />
          <RisingSignZodiacRing
            centerImage
            planetImages={planetImageInfoTwo}
            fillInBackground
            startingSign="Virgo"
            className={styles.wideImage}
            baby1
            babyInfo="BABY HANNA AT 4p"
            planetObjs={planetObjsForImageTwo}
            planets={planetsForImageOne}
            planetRing
          />
        </div>
      </AstroIntuitionContainer>
    </div>
    <AstroIntuitionFooter />
  </>
);

export default PageLayout(RisingSign);
