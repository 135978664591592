export type HouseSystemsQuotesObj = {
  quote: string;
  name: string;
  image: string;
  link: string;
};

const astrologerImages = {
  aliceSparklyKat: 'alice2',
  astroTwins: 'astro-twins',
  bearRyver: 'bear-ryver',
  bruceScofield: 'bruce-scofield',
  cameronAllen: 'cameron-allen',
  chrisBrennan: 'chris-brennan',
  cleoNeptune: 'cleo-neptune',
  eyWashington: 'ey-washington',
  rebeccaGordon: 'rebecca-gordon',
  ryhanButler: 'ryhan-butler',
  sabrinaMonarch: 'sabrina-monarch',
  samuelReynolds: 'samuel-reynolds',
  vanessaMassera: 'vanessa-massera',
};

export const porphyryQuotes: HouseSystemsQuotesObj[] = [
  {
    quote:
      'With Porphyry, so long as you know where the Sun rose and where the ecliptic culminates, you can face south, wave your hands around in the air, and see the cusps emerge up there. Anything that brings the stars down to Earth is A+ in my book.',
    name: 'Bear Ryver',
    image: astrologerImages.bearRyver,
    link: 'https://psycheandsoulastrology.com/',
  },
  {
    quote:
      'At moderate latitudes, the sizes of the houses with Porphyry are reasonable and the house cusps are very sensitive to transits, progression and directions, something not true of some other methods. Since nearly all of astrology takes place on the ecliptic, the minimalism of the Porphyry method makes perfect sense as a go-to house system.',
    name: 'Bruce Scofield',
    image: astrologerImages.bruceScofield,
    link: 'https://www.alabe.com/text/Scofield-Porphyry.pdf',
  },
  {
    quote:
      'Porphyry is a window to the soul! Reflect on your Porphyry house placements as a way to ring a bell deep inside yourself.',
    name: 'Sabrina Monarch',
    image: astrologerImages.sabrinaMonarch,
    link: 'https://monarchastrology.com/',
  },
  {
    quote:
      'The Porphyry house system first interested me because it was the original quadrant house system used to our current knowledge. It was the most accurate house system and seemed to fit my style of astrology best.',
    name: 'Cameron Allen',
    image: astrologerImages.cameronAllen,
    link: 'https://www.cadreamplanet.com/',
  },
  {
    quote:
      'The hundreds of one-on-one readings I have had confirm that Porphyry speaks to their experiences and elegantly follows the divine timing of our astrological cycles.',
    name: 'Vanessa Massera',
    image: astrologerImages.vanessaMassera,
    link: 'http://witchcosmique.com/',
  },
];

export const wholeSignQuotes: HouseSystemsQuotesObj[] = [
  {
    quote:
      'I got into using Whole Sign houses about 16 years ago. I mostly did so because I saw my own chart as a better match to my life circumstances through that house system than Placidus, the one I started with from the beginning of my studies.',
    name: 'Samuel Reynolds',
    image: astrologerImages.samuelReynolds,
    link: 'https://unlockastrology.com/',
  },
  {
    quote:
      'Whole Sign houses should always form the foundation and starting point because of the important historical and conceptual role it played in the creation of the twelve houses.',
    name: 'Chris Brennan',
    image: astrologerImages.chrisBrennan,
    link: 'https://theastrologypodcast.com/',
  },
  {
    quote:
      'I think of Whole Sign as the most rudimentary system, and simplicity is very important to my practice since people and their experiences are already so complicated.',
    name: 'Alice Sparkly Kat',
    image: astrologerImages.aliceSparklyKat,
    link: 'http://www.alicesparklykat.com/',
  },
  {
    quote:
      'Whole Sign is the easiest to understand initially, since each zodiac sign falls neatly into the twelve houses.',
    name: 'Cleo Neptune',
    image: astrologerImages.cleoNeptune,
    link: 'https://snipfeed.co/cleoneptune',
  },
  {
    quote:
      'I use Whole Sign houses for two reasons. They work quite well for quickly identifying the external reality of the nativity as lived out by the individual and because of their role in Hellenistic doctrines (Thema Mundi) and techniques (annual profections).',
    name: 'Bear Ryver',
    image: astrologerImages.bearRyver,
    link: 'https://psycheandsoulastrology.com/',
  },
  {
    quote:
      'I first revelled in Whole Sign when we hit the open road and flew beneath an equally divided sky. I soon realized that we’re responsible for everything we see and that everything we see is connected.',
    name: 'E.Y. Washington',
    image: astrologerImages.eyWashington,
    link: 'https://www.letsembodyheaven.com/',
  },
];

export const placidusQuotes: HouseSystemsQuotesObj[] = [
  {
    quote:
      'With Placidus, my favorite system, placements can be broken down by the house and sign in a slightly more detailed way than Whole Sign. Also, Placidus is the best system to utilize degree theory, making interpretations that much more specific!',
    name: 'Cleo Neptune',
    image: astrologerImages.cleoNeptune,
    link: 'https://mmm.page/cleoneptune.main',
  },
  {
    quote:
      'While the mathematics behind Placidus calculation are more complicated than other systems, it’s more accurate in its depiction of astronomical positioning. The Placidus system also allows the sky to be used like a clock as its calculation method lines up exactly with the system of planetary hours.',
    name: 'Ryhan Butler',
    image: astrologerImages.ryhanButler,
    link: 'https://www.medievalastrologyguide.com/',
  },
  {
    quote:
      'Since we do a lot of time-oriented and predictive horoscopes, the Placidus system has proven to be the most accurate one for our star-mapping. Maybe it’s our Capricorn rising?',
    name: 'The Astro Twins',
    image: astrologerImages.astroTwins,
    link: 'https://astrostyle.com/',
  },
  {
    quote:
      'When I elect dates, I use Placidus because it allows me to pay more attention to the angles of the chart. The uneven houses also somehow make it easier for me to imagine the length of a day from sunrise to sunset.',
    name: 'Alice Sparkly Kat',
    image: astrologerImages.aliceSparklyKat,
    link: 'http://www.alicesparklykat.com/',
  },
  {
    quote:
      'Since Placidus has a special relationship with the planetary hours that other houses don’t, I use it specifically for magical and talismanic elections.',
    name: 'Bear Ryver',
    image: astrologerImages.bearRyver,
    link: 'https://psycheandsoulastrology.com/',
  },
  {
    quote:
      'In my readings, I enjoy the level of detail and accuracy the Placidus system allows for, especially utilizing house cusps and the four angles.',
    name: 'Rebecca Gordon',
    image: astrologerImages.rebeccaGordon,
    link: 'https://www.rebeccagordonastrology.com/',
  },
];
