import React from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet';

import PageLayout from 'landing-page/views/PageLayout';
import SectionPadding from 'landing-page/components/SectionPadding';
import ZodiacRing from '../../../components/ZodiacRing';

import AstroIntuitionHeader from '../AstroIntuitionHeader';
import AstroIntuitionContainer from '../AstroIntuitionContainer';
import AstroIntuitionFooter from '../AstroIntuitionFooter';
import AstroIntuitionTable from '../AstroIntuitionTable';

import HousesItem from '../HousesItem';
import HousesZodiacRing from './HousesZodiacRing';

import {
  columnOne,
  columnTwo,
  housesItemsCopy,
  planetsForImageTwo,
  planetObjsForImageTwo,
  aspectsForImageTwo,
  housePathsForImageTwo,
  housePathsForImageThree,
  planetsForImageThree,
  planetObjsForImageThree,
} from './HousesTypes';
import { planetsForImageOne, planetObjsForImageOne } from '../NatalChart/NatalChartTypes';

import styles from './Houses.module.scss';

const Houses = () => (
  <>
    <Helmet>
      <title>Houses: How does astrology work? | Co-Star</title>
      <meta
        name="description"
        content="The houses indicate where an astrological placement plays out - the context."
      />
    </Helmet>
    <div className="floating-container">
      <AstroIntuitionHeader>
        <h1>Houses</h1>
      </AstroIntuitionHeader>
      <AstroIntuitionContainer theme="transparent">
        <h3>The houses indicate where something plays out - the context.</h3>
        <SectionPadding verticalPadding="1rem" />
        <p>Houses are strange and special in that they are not based on astronomical phenomena.</p>
      </AstroIntuitionContainer>
      <AstroIntuitionContainer theme="white">
        <h2>So what are they, and how do we calculate them?</h2>
        <SectionPadding verticalPadding="1rem" />
        <p>
          Houses are based on the Earth’s 24-hour rotation around its own axis.
        </p>
        <img
          src={require('../../../../../images/houses/HouseSnap.png')}
          className={styles.wideImage}
          alt="wheel depicting houses"
        />
        <p>
          Imagine a snapshot taken of the sky at the moment of your birth. The entire sky, above and
          below the horizon lines, is divided into twelve sections, starting from the Rising Sign.
          These sections are called the houses. They each symbolize different areas of your life.{' '}
        </p>
      </AstroIntuitionContainer>
      <AstroIntuitionContainer theme="dark">
        <h3>Let's come back to baby Nic</h3>
        <img
          src={require('../../../../../images/houses-baby.png')}
          className={styles.wideImage}
          alt="baby with horizon line and constellations to depic midheaven and moon"
        />
        <div className={cn(styles.hideOnMobile, styles.hideOnTablet)}>
          <SectionPadding verticalPadding="2.5rem" />
        </div>
        <h3>The houses are based on the rising sign & what’s overhead.</h3>

        <div>
          <div className={cn(styles.arrowCaptionDark, styles.firstDarkCaption)}>
            <img
              src={require('../../../../../images/houses-arrow-one-mobile.png')}
              className={cn(styles.arrowMobile, styles.firstDarkArrow)}
            />
            <h4>VIRGO CONSTELLATION</h4>
            <img
              src={require('../../../../../images/houses-arrow-desktop-one.png')}
              className={cn(styles.arrowDesktop, styles.firstDarkArrow)}
            />
          </div>
          <div className={cn(styles.arrowCaptionDark, styles.secondDarkCaption)}>
            <img
              src={require('../../../../../images/houses-arrow-two-mobile.png')}
              className={cn(styles.arrowMobile, styles.secondDarkArrow)}
            />
            <div>
              <h4>DIRECTLY OVERHEAD</h4>
              <span>Midheaven</span>
            </div>
            <img
              src={require('../../../../../images/houses-arrow-desktop-two.png')}
              className={cn(styles.arrowDesktop, styles.secondDarkArrow)}
            />
          </div>
          <ZodiacRing
            startingSign="Leo"
            className={styles.housesSVGs}
            planets={planetsForImageOne}
            planetObjs={planetObjsForImageOne}
            housesBaby
          />
          <div className={cn(styles.arrowCaptionDark, styles.thirdDarkCaption)}>
            <img
              src={require('../../../../../images/houses-arrow-three-mobile.png')}
              className={cn(styles.arrowMobile, styles.thirdDarkArrow)}
            />
            <h4>SCORPIO CONSTELLATION</h4>
            <img
              src={require('../../../../../images/houses-arrow-desktop-three.png')}
              className={cn(styles.arrowDesktop, styles.thirdDarkArrow)}
            />
          </div>
          <div className={cn(styles.arrowCaptionDark, styles.fourthDarkCaption)}>
            <img
              src={require('../../../../../images/houses-arrow-four-mobile.png')}
              className={cn(styles.arrowMobile, styles.fourthDarkArrow)}
            />
            <div>
              <h4>EASTERN HORIZON</h4>
              <span>Rising or Ascendant</span>
            </div>
            <img
              src={require('../../../../../images/houses-arrow-desktop-four.png')}
              className={cn(styles.arrowDesktop, styles.fourthDarkArrow)}
            />
          </div>
        </div>
      </AstroIntuitionContainer>
      <AstroIntuitionContainer theme="light">
        <h3>
          These two points are extended through the center to the other side of the circle, forming
          a cross.
          <br />
          <br />
          The four points of the cross define the start of the four angular houses.
        </h3>
        <SectionPadding verticalPadding="1.5rem" />
        <div className={styles.housesContainer}>
          <div className={cn(styles.arrowCaptionLight, styles.firstLightCaption)}>
            <h4>DIRECTLY OVERHEAD</h4>
            <span>Midheaven</span>
            <img
              src={require('../../../../../images/houses-arrow-five-mobile.png')}
              className={cn(styles.arrowMobile, styles.firstLightArrow)}
            />
            <img
              src={require('../../../../../images/houses-arrow-desktop-five.png')}
              className={cn(styles.arrowDesktop, styles.firstLightArrow)}
            />
          </div>
          <HousesZodiacRing
            planets={planetsForImageTwo}
            planetObjs={planetObjsForImageTwo}
            aspects={aspectsForImageTwo}
            housePaths={housePathsForImageTwo}
            startingSign="Leo"
            className={styles.housesSVGs}
          />
          <div className={cn(styles.arrowCaptionLight, styles.secondLightCaption)}>
            <img
              src={require('../../../../../images/houses-arrow-six-mobile.png')}
              className={cn(styles.arrowMobile, styles.secondLightArrow)}
            />
            <div>
              <h4>EASTERN HORIZON</h4>
              <span>Rising or Ascendant</span>
            </div>
            <img
              src={require('../../../../../images/houses-arrow-desktop-six.png')}
              className={cn(styles.arrowDesktop, styles.secondLightArrow)}
            />
          </div>
          <div className={cn(styles.arrowCaptionLight, styles.thirdLightCaption)}>
            <img
              src={require('../../../../../images/houses-arrow-seven-mobile.png')}
              className={cn(styles.arrowMobile, styles.thirdLightArrow)}
            />
            <img
              src={require('../../../../../images/houses-arrow-desktop-seven.png')}
              className={cn(styles.arrowDesktop, styles.thirdLightArrow)}
            />
            <h4>DIRECTLY OPPOSITE MIDHEAVEN</h4>
            <span>Imum Coeli</span>
          </div>
          <div className={cn(styles.arrowCaptionLight, styles.fourthLightCaption)}>
            <img
              src={require('../../../../../images/houses-arrow-six-mobile.png')}
              className={cn(styles.arrowMobile, styles.fourthLightArrow)}
            />
            <img
              src={require('../../../../../images/houses-arrow-desktop-eight.png')}
              className={cn(styles.arrowDesktop, styles.fourthLightArrow)}
            />
            <div>
              <h4>DIRECTLY OPPOSITE ASCENDANT</h4>
              <span>Descendant</span>
            </div>
          </div>
        </div>
        <div>
          <div className={cn(styles.arrowCaptionLight, styles.fifthLightCaption)}>
            <h4>Beginning of 10th House</h4>
            <img
              src={require('../../../../../images/houses-arrow-five-mobile.png')}
              className={cn(styles.arrowMobile, styles.firstLightArrow)}
            />
            <img
              src={require('../../../../../images/houses-arrow-desktop-five.png')}
              className={cn(styles.arrowDesktop, styles.firstLightArrow)}
            />
          </div>
          <HousesZodiacRing
            planets={planetsForImageTwo}
            planetObjs={planetObjsForImageTwo}
            aspects={aspectsForImageTwo}
            housePaths={housePathsForImageThree}
            startingSign="Leo"
            className={styles.housesSVGs}
          />
          <div className={cn(styles.arrowCaptionLight, styles.sixthLightCaption)}>
            <img
              src={require('../../../../../images/houses-arrow-six-mobile.png')}
              className={cn(styles.arrowMobile, styles.sixthLightArrow)}
            />
            <h4>Beginning of 1st House</h4>
            <img
              src={require('../../../../../images/houses-arrow-desktop-six.png')}
              className={cn(styles.arrowDesktop, styles.secondLightArrow)}
            />
          </div>
          <div className={cn(styles.arrowCaptionLight, styles.seventhLightCaption)}>
            <img
              src={require('../../../../../images/houses-arrow-seven-mobile.png')}
              className={cn(styles.arrowMobile, styles.thirdLightArrow)}
            />
            <img
              src={require('../../../../../images/houses-arrow-desktop-seven.png')}
              className={cn(styles.arrowDesktop, styles.thirdLightArrow)}
            />

            <h4>Beginning of 4th House</h4>
          </div>
          <div
            className={cn(
              styles.arrowCaptionLight,
              styles.eighthLightCaption,
              styles.housesContainer,
            )}
          >
            <img
              src={require('../../../../../images/houses-arrow-six-mobile.png')}
              className={cn(styles.arrowMobile, styles.sixthLightArrow)}
            />
            <img
              src={require('../../../../../images/houses-arrow-desktop-eight.png')}
              className={cn(styles.arrowDesktop, styles.fourthLightArrow)}
            />
            <h4>Beginning of 7th House</h4>
          </div>
        </div>
      </AstroIntuitionContainer>
      <AstroIntuitionContainer theme="dark">
        <h3>Each of the four quadrants is divided into three to form twelve houses.</h3>
        <HousesZodiacRing
          planets={planetsForImageThree}
          planetObjs={planetObjsForImageThree}
          aspects={aspectsForImageTwo}
          startingSign="Leo"
          fillInBackground
          className={styles.housesSVGs}
        />
        <div className={cn(styles.hideOnMobile, styles.hideOnTablet)}>
          <SectionPadding verticalPadding="2.5rem" />
        </div>
        <h3>
          You'll notice those lines don't line up perfectly with the zodiac sign lines.
          <br />
          <br />
          That's because your ascendant being 10% vs 90% into Sagittarius matters.
        </h3>
        <div className={styles.sideBySideHouses}>
          <HousesZodiacRing
            planets={planetsForImageThree}
            planetObjs={planetObjsForImageThree}
            aspects={aspectsForImageTwo}
            startingSign="Leo"
            fillInBackground={true}
            className={styles.housesSVGs}
          />
          <h3>≠</h3>
          <HousesZodiacRing
            planets={planetsForImageThree}
            planetObjs={planetObjsForImageThree}
            aspects={aspectsForImageTwo}
            startingSign="Virgo"
            fillInBackground={true}
            shiftZodiac={-4}
            className={styles.housesSVGs}
          />
        </div>
      </AstroIntuitionContainer>
      <AstroIntuitionContainer theme="transparent">
        <h3>The houses indicate where something plays out.</h3>
        <img
          src={require('../../../../../images/houses/pageTwo/house_symbolize_img.png')}
          className={styles.housesWheel}
          alt="wheel with all houses and their contexts"
        />
      </AstroIntuitionContainer>
      <AstroIntuitionContainer theme="dark">
        <h3>They're the context, or the nouns.</h3>
        <SectionPadding verticalPadding="1.75rem" />
        <AstroIntuitionTable columnOneData={columnOne} columnTwoData={columnTwo} theme="dark" />
        <div className={styles.housesHighlight}>
          <p>
            <span className={styles.blue}>Scorpio</span> rules my{' '}
            <span className={styles.red}>12th house</span>
          </p>
          <p>=</p>
          <p>
            "I approach <span className={styles.red}>secrets, dreams, and the unconscious</span>{' '}
            with <span className={styles.blue}>intensity and depth</span>."
          </p>
        </div>
        <p className={cn(styles.italic, styles.mediumGrey)}>
          sign (adverb); scorpio = intense, deep
        </p>
      </AstroIntuitionContainer>
      <AstroIntuitionContainer theme="white">
        <h3>You can think of the houses in polarities.</h3>
        <SectionPadding verticalPadding="2.5rem" />
        <p>
          Like sister signs, the houses that are in opposition to each other are thematically
          connected in some way, and often symbolize a balance that needs to be struck between your
          private self and your public persona.
        </p>
        <div className={styles.housesItemsContainer}>
          {housesItemsCopy.map((el) => (
            <HousesItem header={el.header} houses={el.houses} blurbs={el.blurbs} key={el.header} />
          ))}
        </div>
      </AstroIntuitionContainer>
    </div>
    <AstroIntuitionFooter />
  </>
);

export default PageLayout(Houses);
