// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ruwtyp46UbfEWLd\\+aypExA\\=\\={background:#f7f7f7;border:.5px solid #c7c7c7;display:flex;flex-direction:column;text-align:left;max-width:335px}.\\+eHt-GnL76zU5Z6OtJFnyw\\=\\={background-color:#141414;padding:1rem 1.25rem}.\\+eHt-GnL76zU5Z6OtJFnyw\\=\\= h3{color:#fbfbfb;font-size:22px}.GTC6eYo4X-2\\+7xcU55rxDw\\=\\={display:flex;flex-direction:row;padding:1rem 1.25rem}._11Z1Di8HxXDJZEF8kqNv0A\\=\\={max-width:50%}._11Z1Di8HxXDJZEF8kqNv0A\\=\\= h4{color:#a6a6a6;line-height:18px;padding-bottom:15px}._11Z1Di8HxXDJZEF8kqNv0A\\=\\= span{background-color:#e8e8e8;padding:.25rem .5rem}._11Z1Di8HxXDJZEF8kqNv0A\\=\\= p:last-child{padding-top:1rem}._11Z1Di8HxXDJZEF8kqNv0A\\=\\=:first-child{padding-right:1.25rem}", "",{"version":3,"sources":["webpack://./src/landing-page/pages/AstroIntuition/HousesItem/HousesItem.module.scss"],"names":[],"mappings":"AAAA,6BACE,kBAAA,CACA,yBAAA,CACA,YAAA,CACA,qBAAA,CACA,eAAA,CACA,eAAA,CAGF,6BACE,wBAAA,CACA,oBAAA,CACA,gCACE,aAAA,CACA,cAAA,CAIJ,6BACE,YAAA,CACA,kBAAA,CACA,oBAAA,CAGF,6BACE,aAAA,CACA,gCACE,aAAA,CACA,gBAAA,CACA,mBAAA,CAEF,kCACE,wBAAA,CACA,oBAAA,CAEF,0CACE,gBAAA,CAEF,yCACE,qBAAA","sourcesContent":[".container {\n  background: #f7f7f7;\n  border: 0.5px solid #c7c7c7;\n  display: flex;\n  flex-direction: column;\n  text-align: left;\n  max-width: 335px;\n}\n\n.header {\n  background-color: #141414;\n  padding: 1rem 1.25rem;\n  h3 {\n    color: #fbfbfb;\n    font-size: 22px;\n  }\n}\n\n.innerContainer {\n  display: flex;\n  flex-direction: row;\n  padding: 1rem 1.25rem;\n}\n\n.innerColumn {\n  max-width: 50%;\n  h4 {\n    color: #a6a6a6;\n    line-height: 18px;\n    padding-bottom: 15px;\n  }\n  span {\n    background-color: #e8e8e8;\n    padding: 0.25rem 0.5rem;\n  }\n  p:last-child {\n    padding-top: 1rem;\n  }\n  &:first-child {\n    padding-right: 1.25rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Ruwtyp46UbfEWLd+aypExA==",
	"header": "+eHt-GnL76zU5Z6OtJFnyw==",
	"innerContainer": "GTC6eYo4X-2+7xcU55rxDw==",
	"innerColumn": "_11Z1Di8HxXDJZEF8kqNv0A=="
};
export default ___CSS_LOADER_EXPORT___;
