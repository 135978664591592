import React from 'react';
import cn from 'classnames';
import { buttonCopy } from './ACBuyButtonTypes';
import styles from './ACBuyButton.module.scss';

type ACBuyButtonProps = {
  onClick: () => void;
  transparent?: boolean;
  zodiac?: boolean;
};

const ACBuyButton = ({ onClick, transparent, zodiac = false }: ACBuyButtonProps) => {
  const onKeyDown = (e) => {
    if (['keydown', 'keypress'].includes(e.type) && ['Enter', ' '].includes(e.key)) {
      onClick();
    }
  };

  return (
    <div
      className={cn(styles.button, 'btn', {
        black: !transparent,
        [styles.zodiac]: zodiac === true,
      })}
      onClick={() => onClick()}
      role="button"
      tabIndex={0}
      onKeyDown={onKeyDown}
    >
      {buttonCopy}
    </div>
  );
};

export default ACBuyButton;
