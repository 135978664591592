import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {
  ITUNES_CAMPAIGN_TOKENS,
  itunesStoreURL,
  playStoreUrl,
  PLAY_STORE_CAMPAIGN_TOKENS,
} from '../../constants';
import styles from './Footer.module.scss';

const Footer = ({ onFooterClick }) => {
  return (
    <footer className={styles.container}>
      <div className={styles.line}></div>
      <div className={styles.content}>
        <div className={styles.logo}>
          <img src={require('../../../../images/white-logo.png')} />
        </div>
        <div className={styles.linksContainer}>
          <div className={styles.linksColumn}>
            <Link to="/natal-chart" onClick={() => onFooterClick('NatalChart')}>
              <h4>Natal Chart</h4>
            </Link>
            <Link to="/how-does-astrology-work" onClick={() => onFooterClick('LearnAstrology')}>
              <h4>Learn Astrology</h4>
            </Link>
          </div>
          <div className={styles.linksColumn}>
            <Link to="/about" onClick={() => onFooterClick('About')}>
              <h4>About</h4>
            </Link>
            <Link to="/faq" onClick={() => onFooterClick('FAQ')}>
              <h4>FAQ</h4>
            </Link>
            <Link to="/advanced-readings" onClick={() => onFooterClick('AdvancedReadings')}>
              <h4>Shop</h4>
            </Link>
            <Link to="/contact" onClick={() => onFooterClick('Contact')}>
              <h4>Contact</h4>
            </Link>
            <Link to="/privacy" onClick={() => onFooterClick('Privacy')}>
              <h4>Privacy</h4>
            </Link>
          </div>
          <div className={styles.linksColumn}>
            <Link to="/jobs" onClick={() => onFooterClick('Jobs')}>
              <h4>Jobs</h4>
            </Link>
            <Link to="/team" onClick={() => onFooterClick('Team')}>
              <h4>Team</h4>
            </Link>
            <Link to="/why-haskell" onClick={() => onFooterClick('Haskell')}>
              <h4>Haskell</h4>
            </Link>
          </div>

          <div className={styles.socialDownloadLinks}>
            <div className={styles.socialIcons}>
              <a
                href="https://instagram.com/costarastrology/"
                onClick={() => onFooterClick('Instagram')}
              >
                <img src={require('../../../../images/icons/insta-logo-white.svg')} />
              </a>
              <a
                href="https://www.tiktok.com/@costarastrology"
                onClick={() => onFooterClick('TikTok')}
              >
                <img src={require('../../../../images/icons/tiktok-logo-white.svg')} />
              </a>
            </div>
            <a href={itunesStoreURL(ITUNES_CAMPAIGN_TOKENS.SITE_MARKETING)}>
              <div onClick={() => onFooterClick('download')} className={styles.downloadLinks}>
                <img src={require('../../../../images/icons/apple-logo-white.svg')} />
                <h4 className={styles.links}> Download iOS</h4>
              </div>
            </a>
            <a href={playStoreUrl(PLAY_STORE_CAMPAIGN_TOKENS.SITE_MARKETING)}>
              <div
                onClick={() => onFooterClick('downloadandroid')}
                className={styles.downloadLinks}
              >
                <img src={require('../../../../images/icons/google-playstore-white.svg')} />
                <h4> Download Android</h4>
              </div>
            </a>
          </div>
        </div>

        <br />
      </div>
    </footer>
  );
};

export default Footer;
