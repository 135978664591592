import React from 'react';

import {
  UserTestingSignupFormData,
  SubmitSignupForm,
} from './UserTestingSignUpTypes';

// TO DO -- move to utils? use form validation lib?
const validateInput = (event: any) => {
  event.target.classList.remove('error');
  if (!event.target.validity.valid || event.target.value === '') {
    event.target.classList.add('error');
    return false;
  }

  return true;
};

class UserTestingSignupForm extends React.Component<
{ onSubmit: SubmitSignupForm },
UserTestingSignupFormData
> {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      name: '',
      city: '',
      whoAreYou: '',
      astroLevel: '',
      username: '',
    };
  }

  onSubmit = (event: any) => {
    event.preventDefault();

    let errors = 0;

    Array.from(event.target.elements).forEach((el) => {
      const isValid = validateInput({ target: el });
      if (!isValid) {
        errors += 1;
      }
    });

    if (errors > 0) {
      return;
    }

    this.props.onSubmit(event, this.state);
  };

  handleEmailInputChange = (event: any) => {
    this.setState({
      email: event.target.value,
    });
  };

  handleNameInputChange = (event: any) => {
    this.setState({
      name: event.target.value,
    });
  };

  handleCityInputChange = (event: any) => {
    this.setState({
      city: event.target.value,
    });
  };

  handleWhoAareYouInputChange = (event: any) => {
    this.setState({
      whoAreYou: event.target.value,
    });
  };

  handleInputChange = (event: any) => {
    const stateChange = {
      [event.target.name]: event.target.value,
    } as unknown as UserTestingSignupFormData;
    this.setState(stateChange);
  };

  render() {
    const curForm = this.state;
    return (
      <form className="signup-form" onSubmit={this.onSubmit}>
        <input
          type="text"
          name="name"
          onBlur={validateInput}
          onChange={this.handleNameInputChange}
          value={curForm.name}
          placeholder="Name"
        />
        <input
          type="text"
          name="email"
          onBlur={validateInput}
          onChange={this.handleEmailInputChange}
          value={curForm.email}
          placeholder="Email"
        />
        <input
          type="text"
          name="city"
          onBlur={validateInput}
          onChange={this.handleCityInputChange}
          value={curForm.city}
          placeholder="City"
        />
        <input
          type="text"
          name="username"
          onBlur={validateInput}
          onChange={this.handleInputChange}
          value={curForm.username}
          placeholder="Co-Star Username"
        />
        <select
          value={curForm.astroLevel}
          onChange={this.handleInputChange}
          name="astroLevel"
        >
          <option disabled value="">
            How familiar are you with astrology?
          </option>
          <option value="Level0">I&apos;m completely new to astrology.</option>
          <option value="Level1">
            I know my sign and read my horoscope regularly.
          </option>
          <option value="Level2">
            I know a lot about my chart and help friends understand theirs.
          </option>
          <option value="Level3">I&apos;m an expert.</option>
        </select>
        <textarea
          name="whoAreYou"
          onBlur={validateInput}
          onChange={this.handleWhoAareYouInputChange}
          value={curForm.whoAreYou}
          placeholder="Who are you?"
        />
        <input
          type="submit"
          value="SEND"
          name="subscribe"
          className="black btn submit"
        />
      </form>
    );
  }
}

export default UserTestingSignupForm;
