import * as React from 'react';

import PageLayout from 'landing-page/views/PageLayout';
import { ProspectiveUserForm } from '../../../../components/form';
import { Features } from '../../../features';
import { PressFeatures } from '../../../press-features';
import FooterForm from 'landing-page/components/FooterForm';
import {
  itunesStoreURL,
  playStoreUrl,
  ITUNES_CAMPAIGN_TOKENS,
  PLAY_STORE_CAMPAIGN_TOKENS,
} from '../../../constants';
import { sendClickEvent } from 'analytics';

class HomePage extends React.Component {
  scrollAway = (_) => {
    window.scroll(0, window.innerHeight);
  };

  homepageClick(action: string) {
    return () => {
      sendClickEvent('Homepage', action);
    };
  }

  render() {
    return (
      <div>
        <div className="launch-hero">
          <h1>Co–Star.</h1>
          <p className="subtitle body-font-basics">
            The astrology app that deciphers the mystery of human relations through NASA data and
            biting truth.
          </p>

          <div className="header-btns">
            <a
              href={itunesStoreURL(ITUNES_CAMPAIGN_TOKENS.SITE_MARKETING)}
              className="btn sign-up white"
              onClick={this.homepageClick('downloadios')}
            >
              DOWNLOAD iOS
            </a>
            <a
              href={playStoreUrl(PLAY_STORE_CAMPAIGN_TOKENS.SITE_MARKETING)}
              className="btn sign-up black"
              onClick={this.homepageClick('downloadandroid')}
            >
              DOWNLOAD ANDROID
            </a>
            <div>
              <a tabIndex={1} onClick={this.scrollAway} className="btn white learn-more">
                <span className="or">or&nbsp;</span>
                <span>LEARN MORE</span>
              </a>
            </div>
          </div>

          <img
            className="launch-hero-image-desktop"
            src={require('../../../../../images/homepage-hero.webp')}
          />
          <img
            className="launch-hero-image-mobile"
            src={require('../../../../../images/homepage-hero-mobile.gif')}
          />
        </div>
        <div className="container dark-container">
          <div className="container-inner">
            <div className="section-title">WHAT IS THIS</div>
            <div className="what-is-this">
              <div className="image-desc">
                <img src={require('../../../../../images/moon.png')} />
              </div>
              <p>
                Access to astrology this accurate has historically been restricted to those with
                access to personal astrologers—now these predictions can be anyone's.
              </p>
            </div>
            <div className="what-is-this">
              <div className="image-desc">
                <img src={require('../../../../../images/hand.png')} />
              </div>
              <p>
                Our powerful natural-language engine uses NASA data, coupled with the methods of
                professional astrologers, to algorithmically generate insights about who you are and
                how you relate to others.
              </p>
            </div>
            <div className="what-is-this">
              <div className="image-desc">
                <img src={require('../../../../../images/skull.png')} />
              </div>
              <p>
                Astrology puts our temporary bodies in context with our universe's vastness,
                allowing irrationality to invade our techno-rationalist ways of living.
              </p>
            </div>
          </div>
        </div>
        <Features />
        <PressFeatures />
        <FooterForm formLocation={'Home'} />
      </div>
    );
  }
}

export default PageLayout(HomePage);
