import * as React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default class NotFoundPage extends React.Component<{}, {}> {
  render() {
    return (
      <div className="floating-container">
        <Helmet>
          <meta name="prerender-status-code" content="404" />
        </Helmet>
        <div className="floating-container-inner">
          <div>
            <h1 className="subtitle">😱😱😱</h1>
            <p>
              We couldn't find the page you're looking for. <br />{' '}
              <Link to="/contact" className="underline">
                Let us know
              </Link>{' '}
              if you think you found a bug.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
