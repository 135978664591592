export type FeedbackType =
  | 'password-update'
  | 'change-phone-number'
  | 'bug'
  | 'feedback'
  | 'other'
  | 'default';

export interface ContactFormData {
  email?: string;
  username?: string;
  oldPhoneNumber?: string;
  newPhoneNumber?: string;
  currentPhoneNumber?: string;
  device?: string;
  os?: string;
  message?: string;
}

// constants i.e. options, strings, etc
export const contactFormTypeOptions = [
  'Password Update',
  'Change Phone Number',
  'Bug',
  'Feedback',
  'Other',
];

export const contactFormChildrenOptions = {
  'password-update': ['username', 'current-phone-number', 'email'],
  'change-phone-number': ['username', 'old-phone-number', 'new-phone-number', 'email'],
  bug: ['username', 'device', 'os', 'email', 'message'],
  feedback: ['email', 'message'],
  other: ['email', 'message'],
};

export const placeholderCopy = {
  username: 'Username',
  'current-phone-number': 'Current phone number with country code',
  'old-phone-number': 'Old phone number with country code',
  'new-phone-number': 'New phone number with country code',
  device: "The device you're using (e.g. Google Pixel 4, iPhone 11 Pro Max)",
  os: '& its OS version (e.g. Android 10, iOS 14)',
  'full-name': 'Full name (as it appears on Facebook)',
  bug: "Please describe the bug you're experiencing in as much detail as possible, someone will follow up on your issue and might request screenshots",
  email: 'Email',
};
// copy for textarea placeholder
export const bugCopy =
  "Please describe the bug you're experiencing in as much detail as possible, someone will follow up on your issue and might request screenshots.";
export const generalFeedbackCopy =
  "We'd love any feedback you have about what you love, what you hate, or your thoughts on astrology and the twenty-first century in general.";
