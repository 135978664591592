import React, { forwardRef } from 'react';
import cn from 'classnames';

import themeStyles from '../AstroIntuitionContainer/AstroIntuitionContainer.module.scss';
import styles from '../AstroIntuition.module.scss';

type ContainerTheme =
  | 'dark'
  | 'light'
  | 'white'
  | 'transparent'
  | 'footer'
  | 'dark-orb'
  | 'dark-parallax-sticky';

type AstroIntuitionContainerProps = {
  theme: ContainerTheme;
  children: React.ReactNode;
};

const innerContainerStyles = cn('container-inner', styles.containerInner);

const NatalChartScrollContainer: React.ForwardRefRenderFunction<
  HTMLDivElement,
  AstroIntuitionContainerProps
> = (props, ref) => {
  const { theme, children } = props;
  return (
    <div
      className={cn(themeStyles.base, 'container', {
        [themeStyles.dark]: theme.includes('dark'),
        [themeStyles.sticky]: theme.includes('sticky'),
      })}
      ref={ref}
    >
      <div
        className={cn({ [themeStyles.parallax]: theme.includes('parallax') }, innerContainerStyles)}
      >
        {children}
      </div>
    </div>
  );
};

export default forwardRef<HTMLDivElement, AstroIntuitionContainerProps>(NatalChartScrollContainer);
