import React from 'react';
import Helmet from 'react-helmet';
import qs from 'qs';
import { useLocation } from 'react-router';

import ContactForm from 'landing-page/components/ContactForm';

const ContactFormWebView = () => {
  const location = useLocation();
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const isDarkMode = queryParams.style === 'dark';
  const utmMedium = queryParams.utm_medium;

  const darkModeJSX: React.ReactNode = isDarkMode ? (
    <Helmet>
      <style>{'#app { background-color: #141414; }'}</style>
    </Helmet>
  ) : null;
  const hideHeader = (
    <Helmet>
      <style>{'header.header, canvas { display: none; }'}</style>
    </Helmet>
  );
  return (
    <>
      {darkModeJSX}
      {hideHeader}
      <ContactForm darkMode={isDarkMode} utmMedium={utmMedium} />
    </>
  );
};

export default ContactFormWebView;
