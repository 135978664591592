import PageLayout from 'landing-page/views/PageLayout';
import React from 'react';

const PrivacyPage = () => (
  <div>
    <div className="container static-container">
      <h1> Privacy Policy </h1>
      <h4>Last Updated: March 31st, 2025</h4>
      <p>Co-Star Astrology Society is committed to protecting the personal information you share with us. This Privacy Policy explains how we collect, store, use, and disclose information. This Privacy Policy applies to our mobile application (“App”), our website{' '}<a href="https://www.costarastrology.com">costarastrology.com</a> (“Website”), and our corporate activities, collectively referred to as our services (“Services”). By affirmatively assenting to this Privacy Policy or otherwise using our Services or providing information to us, you consent to us collecting, storing, using, and disclosing information as described in this Privacy Policy.</p>
      <h4>Contact us</h4>
      <p>If you have any questions regarding this Privacy Policy, please contact us at{' '}<a href="mailto:horoscopes@costarastrology.com">horoscopes@costarastrology.com</a>.</p>
      <h3>Your acceptance of this privacy policy</h3>
      <p>The provisions contained in this Privacy Policy supersede all previous notices andstatements regarding our privacy practices with respect to the Services. If you do not agreeto all provisions of this Privacy Policy, you may not access or use the Services.</p>
      <p>Further, you acknowledge that this Privacy Policy is part of our Terms of Service at costarastrology.com/terms and is incorporated therein by reference. By affirmatively assenting to our Terms of Service, or otherwise accessing or using our Services, you agree to be bound by the Terms of Service. If you do not agree to our Terms of Service, do not access or use our Service.</p>
      <h3>Information we get from you</h3>
      <p>When you use our Services, we collect information that you provide to us. Additionally,certain information is automatically collected from you. Some of this information mayidentify you (“Personal Information”), and some of this information may not identify you(“Anonymous Information”). Sometimes, Anonymous Information may directly or indirectly beassociated or combined with Personal information. In these cases, such Anonymous Informationwill be considered Personal Information for purposes of this Privacy Policy. In addition, tothe extent that Internet Protocol (“IP”) addresses or similar identifiers or information areconsidered personally identifiable information by applicable law, we will treat those itemsas Personal Information under this Privacy Policy.</p>
      <h4>WE DO NOT SELL PERSONAL INFORMATION</h4>
      <p>We do not sell your Personal Information, nor do we share your Personal Information to provide personalized or targeted advertising.</p>
      <h4>Information You Provide to Us</h4>
      <p>You may give us information by signing up for an account, entering information through forms or surveys, inputting information while using our Services, contacting us for information or customer service, and more.</p>
      <p>The categories of information you may provide to us include:</p>
      <ul>
        <li>Identifiers, such as name, online identifier, IP address, email address, or other similar identifiers.</li>
        <li>Categories of Personal Information described in Section 1798.80(e) of the California Civil Code, such as name, address, telephone number, or other similar information.</li>
        <li>Commercial information, such as products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</li>
        <li>Internet or other electronic network activity information, such as information regarding your interactions with our Website or App.</li>
        <li>Inferences drawn from the above categories.</li>
      </ul>
      <p>The categories of sensitive information include:</p>
      <ul>
        <li>Account log-in, in combination with any required security or access code, password, or credentials allowing access to your account.</li>
        <li>If you apply for a position with us, you may provide the following additional categories of Personal Information:</li>
        <li>Categories of Personal Information described in Section 1798.80(e) of the California Civil Code, such as education, employment, employment history, or other similar information.</li>
        <li>Professional or employment-related information.</li>
        <li>Education information.</li>
      </ul>
      <h4>Information Collected Automatically</h4>
      <p>When you access our Services, we may automatically collect information that is linked to your computer, phone, or other device, including information related to your behavior and activity while interacting with us, such as your IP address, operating system and version, local time zone, date, time, and activity of your request, device type (e.g. desktop, laptop, tablet, phone, etc.), device manufacturer, screen size, and language preference.</p>
      <h4>Information We Collect from Third Parties</h4>
      <p>If you access our Services through third parties (e.g., Facebook or Google), or if you share content from our Services to a third-party social media service, the third-party service will send us certain information about you if the third-party service and your account settings allow such sharing. The information we receive will depend on the policies and your account settings with the third-party service.</p>
      <h4>Do Not Track</h4>
      <p>Do Not Track (“DNT”) is an optional browser setting that allows you to express your preferences regarding tracking by advertisers and other third parties. The Services may not currently respond to all Do Not Track (“DNT”) or similar signals, as we are awaiting consensus from the Internet policy and legal community on the meaning of DNT and the best way to respond to these signals.</p>
      <h3>How we use information</h3>
      <p>In general, we use the information we collect to provide you with the best possibleServices, and continually improve them over time.</p>
      <p>Specifically, we may use Anonymous Information as well as Personal Information as describedelsewhere in this Privacy Policy to improve our Services, to ensure our Services workproperly, and for other research and commercial purposes. These purposes include, amongother things, to:</p>
      <ul><li>To provide the Services and related notices;</li>
        <li>To create, maintain, and service user accounts and/or profiles (e.g., through registration and import user contacts);</li>
        <li>To respond to your inquiries and/or requests about our Services and products;</li>
        <li>To administer, maintain, analyze, improve, and customize the Services and create a better  experience for users in our Services;</li>
        <li>To provide or send information about our Services;</li>
        <li>To send transactional text messages directly relating to your use of your Services (e.g.,  confirming your account and resetting your password);</li>
        <li>To receive feedback and provide customer and technical support for the Services;</li>
        <li>To conduct market research and project planning, which may include conducting surveys, research, and audits;</li>
        <li>To provide analytics services, such as analyzing customer usage and improving Services offered;</li>
        <li>To fulfill any orders placed through the Services (including processing your payment information through a third-party payment processor, arranging for shipping, and providing you with invoices and/or order confirmations);</li>
        <li>To provide you with employment opportunities, which includes processing your employment application, assessing your job qualifications, conducting reference checks, and conducting background checks if we offer you a position, as permitted by applicable law;</li>
        <li>To provide information to our representatives and/or advisors, such as our attorneys,  accountants, and others to help us comply with legal, accounting, or security  requirements;</li>
        <li>To verify and respond to your requests regarding your Personal Information or questions you may have about this Privacy Policy; and,</li>
        <li>To detect, prevent, and investigate actual or suspected fraud, hacking, infringement, or  other misconduct involving the Services; and for other purposes disclosed at the time of  collection.</li>
      </ul>
      <p>Additionally, we may use your Personal Information if we think we need to or are permitted to for legal purposes, to protect our business, our rights, or our property, or to address activity that may be illegal, unethical, or legally actionable.</p>
      <p>We may also use your Personal Information to contact you with newsletters, marketing, or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe instructions provided in any email we send, or you can contact us using the contact details provided in the “Contact us” section above. You will still continue to receive service-related messages concerning Services you have purchased (unless we indicate otherwise).</p>
      <h3>How we share information</h3>
      <p>Co-Star does not sell your Personal Information to third parties, nor do we disclose Personal Information to third parties for targeted or cross-context behavioral marketing purposes.</p>
      <p>We may share Anonymous Information with third parties as described elsewhere in this PrivacyPolicy and to improve our Services and for other commercial purposes.</p>
      <p>We take your privacy seriously, and may disclose Personal Information only as follows:</p>
      <ul><li><span style={{ fontWeight: 'bold' }}>To business partners, service providers, and affiliates.</span> We share your Personal Information with our business partners, service providers, affiliates, and subcontractors as required to offer you our Services. We use these entities to help us to:</li><ul>  <li>  Run, operate, and maintain our Services through third-party platforms and software tools;  </li><li>Perform content moderation and crash analytics;  </li><li>Run email messaging campaigns;  </li><li>Provide measurements services;  </li><li>Administer live events, sweepstakes, and promotions, including registering attendees, managing check-in and attendance, verifying eligibility and prize fulfilment;</li><li>Provide payment attribution; and,  </li><li>Provide technical and customer support.  </li></ul><li><span style={{ fontWeight: 'bold' }}>To customer support employees.</span> Authorized  customer support employees may access to your Personal Information as needed to resolve  issues you encounter on our Services and help you use our Services.</li>
        <li>We may be required to disclose your data in response to lawful requests by public authorities, including to meet law enforcement requirements. We may be under a duty to disclose or share your Personal Information to comply with any legal obligation, to enforce or apply our terms and conditions and other agreements, to protect our rights, property, or safety, or to protect the rights, property, or safety of others. This includes exchanging information with other companies and organizations for the purposes of fraud protection.</li>
        <li>We may share information about you as part of a merger or acquisition. If Co–Star gets involved in a merger, asset sale, financing, liquidation or bankruptcy, or acquisition of all or some portion of our business to another company, we may share your information with that company before and after the transaction closes.</li>
        <li>We may share your information with third-party analytics partners.</li>
      </ul>
      <p>Some external service providers may also collect information directly from you (for example, a payment processor may request your billing and financial information) in accordance with their own privacy policy. These external service providers do not share your financial information, like credit card number, with us, but may share limited information with us related to your purchase, like your zip code.</p>
      <h4>WE DO NOT SELL PERSONAL INFORMATION</h4>
      <h3>Protecting Children's Privacy</h3>
      <h4>Children Online Protection</h4>
      <p>Our Services are not directed to children under the age of sixteen (16), and we do not knowingly collect Personal Information from children under the age of sixteen (16). If you are not sixteen (16) years or older, do not use our Website, Apps, or Services. If you believe that we have received the information of a child, please contact us at{' '}<a href="mailto:horoscopes@costarastrology.com">horoscopes@costarastrology.com</a>.</p>
      <h4>Erasure Law</h4>
      <p>In accordance with California law, minors under the age of eighteen (18) residing in California may remove, or request and obtain the removal of, content and information that they share on the Websites or App. To remove or to request and obtain the removal of such content and information, the user must email{' '}<a href="mailto:horoscopes@costarastrology.com">horoscopes@costarastrology.com</a>. Users should be aware that removing content and information posted by a minor does not ensure complete or comprehensive erasure of content or information posted on our Services.</p>
      <p>Under certain circumstances, Co-Star may not have to comply with the above removal requirements. Co-Star also reserves the right, to the extent permitted by law, to anonymize the posted content or information or to make such content or information invisible to other users and the public, instead of removing or deleting the content or information.</p>
      <h3>Links to third-party websites</h3>
      <p>When you use our Services, you may be directed to other websites and applications that are beyond our control. We may also allow third-party websites or applications to link to the Services. We are not responsible for the privacy practices of any third parties or the content of linked websites and applications, but we do encourage you to read the applicable privacy policies and terms and conditions of such parties, websites, and applications. This Privacy Policy only applies to the Services. {' '}</p>
      <h3>Social Media Features</h3>
      <h4>Login</h4>
      <p>You can log in to our Services using Facebook Connect. Facebook Connect will authenticate your identity and provide you the option to share certain Personal Information with us, such as your name and email address to pre-populate our sign-up form.</p>
      <h4>Information You Share Socially</h4>
      <p>Keep in mind that the users who view any content you share (e.g. your profile or your DMs) can always save that content or copy it outside the App. So, the same common sense that applies to the internet at large applies to Co–Star as well: Don’t share or make public any content that you wouldn’t want someone else to save or share. </p>
      <h3>How we secure, store, and retain information</h3>
      <h4>Security</h4>
      <p>We have implemented a variety of technical and organizational precautions to protect the confidentiality, security and integrity of your Personal Information from accidental or unlawful destruction, loss, alteration, disclosure, or unauthorized access. However, no website, application, or transmission can guarantee security. Thus, while, we have established and maintain what we believe to be appropriate technical and organizational measures to protect the confidentiality, security, and integrity of Personal Information obtained through our Services, we cannot guarantee or warrant the security of any information transmitted to us.</p>
      <h4>Storage</h4>
      <p>The Personal Information that you provide to us is generally stored on servers maintained by AWS, or Amazon Web Services. These servers are located in the United States. If you are located in another jurisdiction, you should be aware that once your Personal Information is submitted through our Services, it will be transferred to our servers in the United States and therefore governed by local data protection laws, which can vary state by state.</p>
      <h4>Retention</h4>
      <p>We will retain your Personal Information for the period necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law. When the information is no longer necessary for these purposes, we delete it or keep it in a form that does not identify you. When determining the retention period, we take into account various criteria, such as the type of Services requested by or provided to you, the nature and length of our relationship with you, possible re-enrollment with our Services, the impact on the Services we provide to you if we delete some information from or about you, and mandatory retention periods provided by law or the statute of limitations. </p>
      <h3>Privacy rights & additional disclosures for EEA, UK, and Swiss individuals</h3>
      <p>If you are from the European Economic Area (EEA), United Kingdom (UK), or Switzerland, our legal bases for collecting and using your Personal Information is as follows:</p>
      <ul>
      <li>The performance of your contract or to enter into the contract and to take action on your requests. For example, the processing of your account registration.</li>
      <li>Our legitimate business interests. For example, fraud prevention, maintaining the security of our network and Services, direct marketing to you, and improvement of our Services.</li>
      <li>Compliance with mandatory legal obligations. For example, accounting and tax requirements, which are subject to mandatory retention periods. We may also collect your Personal Information to record your requests to exercise your rights and to verify your identity for such requests.</li>
      <li>Consent you provide where we do not rely on another legal basis. Consent may be withdrawn at any time.</li>
      <li>In some limited cases, we may also have a legal obligation to collect Personal Information from you in response to lawful requests by public authorities, including to meet law enforcement requirements, as described above in the “How we share information” section.</li>
      </ul>
      <h4>Rights</h4>
      <p>If you are from the EEA, UK, or Switzerland, you have the right, under certain circumstances, to:</p>
      <ul><li>Access your Personal Information;</li>
        <li>Correct inaccurate information in your Personal Information;</li>
        <li>Request erasure of your Personal Information without undue delay;</li>
        <li>Request restriction of, in certain circumstances, the processing of your Personal Information;</li>
        <li>Request receiving a portable copy of your Personal Information held by us, and to have that portable copy transmitted to another controller; and,</li>
        <li>To object to the processing of your Personal Information.</li>
      </ul>
      <p>To exercise any of these rights, please contact us using the contact details provided at the top of this Privacy Policy, and please specify which privacy right(s) you wish to exercise. We may need to verify your identity to honor your request.</p>
      <p>If you have any issues with our compliance, you have the right to lodge a complaint with the appropriate supervisory authorities. We would, however, appreciate the opportunity to first address your concerns and would welcome you direct your inquiry to us via the contact information at the top of this Privacy Policy.</p>
      <h4>International Transfers of Data</h4>
      <p>Any data that you provide to us may be accessed, shared, or processed by our offices, located in the United States, and service providers located in the United States, if such data transfer is necessary for the specific purpose for which you submitted your data (such as the provision of goods or services under a written contract). This may entail a transfer of your Personal Information across international borders. The data protection standards may differ and be lower than the standards enforced in your jurisdiction, so we strive to maintain appropriate safeguards as required by applicable law for any Personal Information transferred internationally.</p>
      <h3>Privacy rights & additional disclosures for certain U.S. residents</h3>
      <p>Several states within the United States grant residents with rights over their Personal Information. We provide the following information to further help you understand your potential privacy rights. If you would like to exercise any rights, or inquire as to whether such rights are available to you, please contact us at horoscopes@costarastrology.com.</p>
      <h4>Request for Information, Correction, or Deletion</h4>
      <p>Residents of certain U.S. states have the right to know whether we are processing their Personal Information, and in some instances, you have the right to request that we disclose to you the categories listed below for the preceding 12 months.</p>
      <ul><li>The categories of Personal Information we collect about you.</li>
        <li>The categories of sources from which your Personal Information is collected.</li>
        <li>The business or commercial purpose(s) for collecting, selling, sharing, or disclosing your Personal Information, and the categories of Personal Information disclosed for such purpose(s).</li>
        <li>The categories of third parties with whom we share your Personal Information.</li>
        <li>The categories of Personal Information we have sold, if any, about you and the categories of third parties to whom your Personal Information was sold, by category or categories of Personal Information for each third party to whom the Personal Information was sold.</li>
        <li>The specific pieces of Personal Information we have collected about.</li>
      </ul>
      <p>In addition, you may have the right to request we correct or rectify inaccurate Personal Information, or request that we delete your Personal Information.</p>
      <h4>Request for Portable Data</h4>
      <p>Residents of certain U.S. states have the right to receive, in certain circumstances, a portable format of their Personal Information that allows the data to be reasonably transmitted to another entity.</p>
      <h4>Do Not Sell or Share My Personal Information</h4>
      <p>Residents of certain U.S. states have the right to opt out of the sale or sharing of your Personal Information. However, we do not sell your Personal Information, nor do we share your Personal Information to provide personalized or targeted advertising.</p>
      <h4>Limit the Use of Sensitive Personal Information</h4>
      <p>Residents of California have the right to direct us to use or disclose Personal Information only for providing goods or Services, or as otherwise minimally permitted under applicable law. However, we do not use or disclose sensitive Personal Information for any purpose other than providing our goods or Services, or as otherwise minimally permitted under applicable law.</p>
      <h4>Third Party’s Direct Marketing</h4>
      <p>If you are one of our Customers, California Civil Code Section 1798.83 permits you to request information regarding the disclosure of your Personal Information to third parties for the third parties’ direct marketing purposes. However, we do not disclose your Personal Information to third parties for third parties’ direct marketing purposes.</p>
      <h4>Automated Processing and Profiling</h4>
      <p>Residents of certain U.S. states have the right to opt out of the processing of Personal Information for purposes of profiling in furtherance of decisions that produce legal or similarly significant effects concerning you. However, we do not process Personal Information for these purposes.</p>
      <h4>Right to Equal Service and Price</h4>
      <p>Residents of certain U.S. states have the right to receive equal service and price, even if they exercise a privacy right.</p>
      <h4>Verification Process for Exercising Rights</h4>
      <p>To protect your privacy, we verify privacy rights requests to ensure that only you (or your authorized agent) can exercise rights pertaining to your Personal Information. As part of our verification process, we may request you to submit additional information.</p>
      <p>If you are an authorized agent wishing to exercise rights on behalf of a state resident, please contact us using the information at the top of this Privacy Policy and provide us with a copy of the consumer’s written authorization designating you as their agent. We may need to verify your identity and place of residence before completing your rights request.</p>
      <h4>Appeal Process</h4>
      <p>In certain cases, we may decline to take action on your request as permitted by law. Should we decline your request, we may provide you with a written explanation of the reasons for our decision and an opportunity to appeal the decision.</p>
      <p>If we deny your appeal, we may provide a written explanation of our decision with instructions on how to contact your local Attorney General or Department of Justice for further review, where permitted under certain U.S. state privacy laws.</p>
      <h3>Changes to this privacy policy</h3>
      <p>We may update or modify this Privacy Policy from time to time and will alert you that changes have been made by indicating on the Privacy Policy the date it was last updated at the top of this Privacy Policy, prominently posting an announcement of the changes on our Services, or by sending you a written communication using the contact information you provided us prior to the new policy taking effect. Users are bound by any changes to the Privacy Policy if they use our Websites, Apps, and Services after notice of such changes has been communicated. We encourage you to review this Privacy Policy regularly to ensure that you are familiar with Co-Star's current practices.</p>
    </div>
  </div>
);

export default PageLayout(PrivacyPage);
