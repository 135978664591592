import React, { useMemo, useState, useEffect } from 'react';
import Client from 'shopify-buy';

/**
 * @name useShopify
 * @param {String} domain: shopify site domain
 * @param {String} accessToken: shopify storefront API access token
 * @description This is a React Hook that will initialize the client connection with Shopify
 * It utilizes useMemo so that it only runs once and will only run again if the domain or access token changes
 */

const useShopify = (domain: string, accessToken: string) => {
  const [client, setClient] = useState(null);

  useMemo(() => {
    setClient(
      Client.buildClient({
        domain,
        storefrontAccessToken: accessToken,
      }),
    );
  }, [domain, accessToken]);
  return client;
};

export default useShopify;

/**
 * @name useShopifyProduct
 * @param {Object} client: shopify client object
 * @param {String} id: product ID
 * @description This is a React Hook that will fetch and return a specific product from shopify
 */

export const useShopifyProduct = (client, id: string) => {
  const [product, setProduct] = useState(null);

  useEffect(() => {
    client.product.fetch(id).then((product) => {
      setProduct(product);
    });
  }, [client, id]);

  return product;
};

/**
 * @name useCheckout
 * @param {Object} client Shopify Client obj
 * @description This is a React Hook that will initialize a shopify cart/checkout
 */

export const useShopifyCheckout = (client) => {
  const [checkout, setCheckout] = useState(null);

  useEffect(() => {
    client.checkout.create().then((checkout) => {
      setCheckout(checkout);
    });
  }, [client]);

  return checkout;
};

/**
 * @name addCheckoutAttrs
 * @param {String} client Shopify Client obj
 * @param {String} checkoutID id of checkout
 * @param {Object} input an input object containing
 *   @param {Object[]} input.customAttributes A list of custom attributes for the checkout
 *   See the {@link https://help.shopify.com/api/storefront-api/reference/input_object/attributeinput|Storefront API reference} for valid input fields.
 * @description wrapper around the updateAttributes method on the shopify client
 * {@link https://github.com/Shopify/js-buy-sdk/blob/fee5fafb357c27965c1333270bcde6426637117a/src/checkout-resource.js#L89}
 */

export const addCheckoutAttrs = (client, checkoutID: string, input) => client.checkout.updateAttributes(checkoutID, input);
