import React, { Dispatch, useEffect, useState } from 'react';
import cn from 'classnames';
import Api, { ProspectiveUserReadingCreate, PlacesSuggestions, PlacesSuggestion, Coordinates, NewProspectiveUserReading  } from 'api';
import { useForm } from 'react-hook-form';
import { debounce } from 'underscore';
import Channel from 'channel';
import { SelfManagingProgressBar } from 'components/progress';
import { sendFormEvent, sendFormEventError, sendFormEventSuccess } from 'analytics';
import Input from '../Input';

import styles from './AdvancedChartForm.module.scss';

type ProspectiveUserFormProps = {
  setPaidReading: Dispatch<NewProspectiveUserReading>;
  birthData:
    | Record<string, never>
    | {
        email: string;
        date: string;
        time: string;
        place: string;
        prospectiveUserId: number;
        birthCoords: {
          lat: number;
          lon: number;
        };
      };
  shopURL: string;
};

const ProspectiveUserForm = ({ birthData, setPaidReading, shopURL }: ProspectiveUserFormProps) => {
  const { email, date, time, place, birthCoords, prospectiveUserId } = birthData;
  let defaultValues = {};
  if (Object.keys(birthData).length) {
    defaultValues = { email, date, time, place };
  }
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitted, isSubmitSuccessful, dirtyFields },
  } = useForm({ mode: 'onBlur', defaultValues });
  const [placeSuggestions, setPlaceSuggestions] = useState<PlacesSuggestion[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [coords, setCoords] = useState<Coordinates>(birthCoords || { lat: 0, lon: 0 });
  const [error, setError] = useState('');
  const [canRedirect, setCanRedirect] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleDebounceFn = (event) => {
    event.persist();
    if (event.target.value === '') setShowSuggestions(false);
    else {
      Api.getPlacesSuggestions(event.target.value)
        .then((suggestions: PlacesSuggestions) => {
          setPlaceSuggestions(suggestions.items);
          setShowSuggestions(true);
          setError('');
        })
        .catch(() => {
          setShowSuggestions(false);
          setError('Something went wrong when loading city suggestions, please try again.');
        });
    }
  };
  const debouncedPlaceFn = debounce(handleDebounceFn, 250);

  const handlePlaceInput = (event) => {
    debouncedPlaceFn(event);
  };

  const handlePlaceSelection = (place) => {
    setValue('place', place.name);
    setCoords({ lat: place.lat, lon: place.lng });
    setShowSuggestions(false);
    debouncedPlaceFn.cancel();
  };

  const renderSuggestions = () => {
    const listItems = placeSuggestions.map((val) => (
      <li key={val.name} onClick={() => handlePlaceSelection(val)}>
        {val.name}
      </li>
    ));
    return listItems;
  };

  useEffect(() => {
    if (isSubmitSuccessful && shopURL && !error && canRedirect) {
      setTimeout(() => {
        window.location.href = shopURL;
      }, 600);
    }
  }, [shopURL, isSubmitSuccessful, error, canRedirect]);

  const onSubmit = async (data) => {
    setButtonDisabled(true);
    setError('');
    sendFormEvent('AdvChartPDP', 'PurchaseSubmit');

      let birthTime;

      if (!data.time) {
        birthTime = '12:00';
      } else {
        birthTime = data.time;
      }
      const dateTime = `${data.date}T${birthTime}`;

      const prospectiveUserReadingCreate: ProspectiveUserReadingCreate = {
        prospectiveUserId: prospectiveUserId || null,
        birthPlace: data.place,
        birthLocalTime: dateTime,
        birthCoords: coords,
      };

      await Api.createProspectiveUserReading(prospectiveUserReadingCreate)
        .then((res) => {
          setPaidReading(res)
          setCanRedirect(true);
          sendFormEventSuccess('AdvChartPDP');
        })
        .catch((e) => {
          setButtonDisabled(false);
            sendFormEventError('AdvChartPDP', 'server error');
            setError('Something went wrong, please try again.');
        });
  };
  if (isSubmitted && !shopURL && !error)
    return <SelfManagingProgressBar message="Analyzing birth chart..." />;
  if (isSubmitSuccessful && shopURL && !error)
    return <SelfManagingProgressBar message="Analyzing birth chart..." />;

  return (
    <div className="ac-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formContent}>
          {error && (
            <div className={styles.formError}>
              <span>{error}</span>
            </div>
          )}
          <div className={cn(styles.inputGroup, { [styles.error]: errors.place })}>
            <span> I was born in&nbsp;</span>
            <div className={styles.placesGroup}>
              <Input
                type="text"
                placeholder="a city"
                error={errors.place}
                required
                {...register('place', {
                  required: true,
                  onChange: handlePlaceInput,
                })}
              />
              <ul className={cn(styles.dropDown, { [styles.hidden]: !showSuggestions })}>
                {renderSuggestions()}
              </ul>
            </div>
          </div>
          <div
            className={cn(styles.inputGroup, {
              [styles.error]: errors.date,
              [styles.dateTimeHasValue]: dirtyFields.date || defaultValues.date,
            })}
          >
            <span>&nbsp;on&nbsp;</span>
            <Input
              type="date"
              placeholder="yyyy-mm-dd"
              error={errors.date}
              required
              {...register('date', {
                required: true,
                pattern: /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/,
              })}
            />
          </div>
          <div
            className={cn(styles.inputGroup, {
              [styles.error]: errors.time,
              [styles.dateTimeHasValue]: dirtyFields.time || defaultValues.time,
            })}
          >
            <span>at&nbsp;</span>
            <Input
              type="time"
              placeholder="hh:mm AM"
              required
              error={errors.time}
              {...register('time', {
                pattern: /(\d?\d):(\d\d)( (AM|PM|am|pm))?/,
              })}
            />
          </div>
          <div className={styles.submitGroup}>
            <input type="submit" value="CONTINUE TO CHECKOUT" disabled={buttonDisabled} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProspectiveUserForm;
