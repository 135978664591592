import React from 'react';
import { Planet, Sign } from '../../../astrology';
import AdvancedChartAttribution from './AdvancedChartAttributions';
import { cutouts } from './AdvancedChartTypes';

import styles from './AdvancedChart.module.scss';

type AdvancedChartReadingItemProps = {
  content: {
    paidReadingAttribution: {
      [K in Planet]: Sign;
    };
    paidReadingCategory: string;
    paidReadingText: string;
  };
  imageIdx?: number;
};

const AdvancedChartReadingItem = ({ content, imageIdx }: AdvancedChartReadingItemProps) => {
  const attributions = Object.keys(content.paidReadingAttribution).map((el) => (
    <AdvancedChartAttribution planet={el} sign={content.paidReadingAttribution[el]} key={el} />
  ));
  return (
    <div className={styles.readingContainer}>
      <h3>{content.paidReadingCategory}</h3>
      <p className={styles.readingText}>{content.paidReadingText}</p>
      <div className={styles.attributions}>Based on your {attributions}</div>
      {imageIdx !== undefined && <img src={cutouts[imageIdx]} alt="" />}
    </div>
  );
};

export default AdvancedChartReadingItem;
