import React, { useRef } from 'react';
import useD3 from 'hooks/useD3';
import * as _ from 'underscore';
import * as d3 from 'd3';

import { fromNullable } from 'fp-ts/lib/Option';
import { curry } from 'fp-ts/lib/function';

import { natalChartOrderedSigns, planetData } from '../../../../astrology';

import { PlanetImagesObj } from './RisingSignTypes';

type ZodiacRingPropTypes = {
  startingSign: string;
  planets?: [];
  planetObjs?: Record<string, unknown>;
  centerImage?: boolean;
  planetImages?: PlanetImagesObj[] | [];
  planetRing?: boolean;
  fillInBackground?: boolean;
  aspects?: [];
  baby2?: boolean;
  baby1?: boolean;
  pathLines?: boolean;
  horizonTop?: boolean;
  horizonBottom?: boolean;
  babyInfo?: string;
  className: string;
};

const font = require('!!url-loader!../../../../../images/fonts/Akkurat-Mono.ttf');

function signToAngle(sign) {
  const i = natalChartOrderedSigns.indexOf(sign) * toRad(30);
  return i;
}

function toRad(angle) {
  return (angle * Math.PI) / 180;
}

function toDeg(rad) {
  return (rad * 180) / Math.PI;
}

function rotateBy90(angle) {
  return angle - Math.PI / 2;
}

const RisingSignZodiacRing = ({
  startingSign,
  planets,
  planetObjs,
  className,
  aspects = [],
  baby2 = false,
  baby1 = false,
  fillInBackground = false,
  centerImage = false,
  planetImages = [],
  planetRing = true,
  pathLines = false,
  horizonTop = false,
  horizonBottom = false,
}: ZodiacRingPropTypes) => {
  const orderedSigns: string[] = [];
  const indexOfStartingSign = natalChartOrderedSigns.indexOf(startingSign);
  orderedSigns.push(...natalChartOrderedSigns.slice(indexOfStartingSign));
  orderedSigns.push(...natalChartOrderedSigns.slice(0, indexOfStartingSign));

  const angleGen = d3.pie();
  let data = angleGen([1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]);

  data = orderedSigns.map((sign, i) => ({
    ...data[i],
    name: sign,
  }));

  const ref = useD3((svg) => {
    const height = 500;
    const width = 500;
    const radius = Math.min(width, height) / 2;

    svg.attr('viewBox', `${(-1 * width) / 2} ${(-1 * height) / 2} ${width} ${height}`);
    svg
      .append('defs')
      .append('style')
      .attr('type', 'text/css')
      .text(`@font-face {font-family: 'Akkurat-Mono'; src: url(${font});}`);

    svg = svg.append('g');

    svg
      .selectAll('rect')
      .data([1])
      .enter()
      .append('rect')
      .attr('x', 0 - width / 2)
      .attr('y', 0 - height / 2)
      .attr('width', width)
      .attr('height', height)
      .attr('fill', 'transparent');

    const outerArc = d3
      .arc()
      .innerRadius(radius - 55)
      .outerRadius(radius - 20)
      .startAngle((d, _) => d.startAngle)
      .endAngle((d) => d.endAngle);

    function isUpsideDown(endAngle): boolean {
      // js mod is kinda busted for negative numbers?
      const modAngle = endAngle % (Math.PI * 2);
      const newAngle = (toDeg(modAngle) + 360) % 360;
      return newAngle > 90 && newAngle < 270;
    }

    svg
      .selectAll('.sign-path')
      .data(data)
      .enter()
      .append('path')
      .attr('class', 'sign-path')
      .attr('d', outerArc)
      .style('fill', '#000')
      .style('stroke', '#d6d6d6')
      .each(function (d, i) {
        // A regular expression that captures all in between the start of a string (denoted by ^)
        // and the first capital letter L
        const firstArcSection = /(^.+?)L/;

        // The [1] gives back the expression between the () (thus not the L as well)
        // which is exactly the arc statement

        /* TODO: I have no idea how to fix this particular case of
                                          "'this' implicitly has type 'any' because it does not have a type annotation."
                                          So for now suppressing with TS-Ignore
                                        */
        // @ts-ignore
        let newArc = firstArcSection.exec(d3.select(this).attr('d'))[1];
        // Replace all the commas so that IE can handle it -_-
        // The g after the / is a modifier that "find all matches rather than stopping after the first match"
        newArc = newArc.replace(/,/g, ' ');
        if (isUpsideDown(d.endAngle)) {
          const startLoc = /M(.*?)A/; // Everything between the capital M and first capital A
          const middleLoc = /A(.*?)0 0 1/; // Everything between the capital A and 0 0 1
          const endLoc = /0 0 1 (.*?)$/; // Everything between the 0 0 1 and the end of the string (denoted by $)
          // Flip the direction of the arc by switching the start and end point (and sweep flag)
          const newStart = fromNullable(endLoc.exec(newArc)).map((v) => v[1]);
          const newEnd = fromNullable(startLoc.exec(newArc)).map((v) => v[1]);
          const middleSec = fromNullable(middleLoc.exec(newArc)).map((v) => v[1]);

          const mkNewArc = (start: string, end: string, middleSec: string) =>
            `M${start}A${middleSec}0 0 0 ${end}`;

          newArc = newStart.map(curry(mkNewArc)).ap_(newEnd).ap_(middleSec).getOrElse(newArc);

          // Build up the new arc notation, set the sweep-flag to 0
        }
        // Create a new invisible arc that the text can flow along
        svg
          .append('path')
          .attr('class', 'hidden-donut-arc')
          .attr('id', `signArc_${i}`)
          .attr('d', newArc)
          .style('fill', 'none')
          .attr('transform', 'rotate(4)');
      })
      .attr('transform', 'rotate(4)');

    if (planetRing) {
      const middleArc = d3
        .arc()
        .innerRadius(radius - 60)
        .outerRadius(radius - 55)
        .startAngle(0)
        .endAngle(2 * Math.PI);
      svg.append('path').attr('d', middleArc).style('fill', '#f7f7f7').style('stroke', '#d6d6d6');

      const pathData = [
        { startAngle: -0.5 * Math.PI, endAngle: 0.5 * Math.PI },
        { startAngle: 90 * (Math.PI / 180), endAngle: 270 * (Math.PI / 180) },
      ];

      const arc = d3
        .arc()
        .innerRadius(radius - 90)
        .outerRadius(radius - 60)
        .startAngle((d, _) => d.startAngle)
        .endAngle((d) => d.endAngle);

      const path = svg.selectAll('.house-path').data(pathData).enter();

      path
        .append('path')
        .attr('class', 'house-path')
        .attr('d', arc)
        .style('fill', '#fff')
        .style('stroke', '#d6d6d6');

      svg
        .selectAll('.planet-labels')
        .data(planets)
        .enter()
        .append('image')
        .attr('class', 'planet-labels')
        .attr('x', (d) => planetObjs[d.name].x)
        .attr('y', (d) => planetObjs[d.name].y)
        .attr('width', (d) => planetObjs[d.name].imageWidth)
        .attr('height', (d) => planetObjs[d.name].imageHeight)
        .attr('xlink:href', (d) => {
          const str = planetData[d.name].imgDataUrl;
          return str;
        });
    }

    if (fillInBackground) {
      const outerRadius = planetRing ? radius - 90 : radius - 55;
      const centerArea = d3
        .arc()
        .innerRadius(0)
        .outerRadius(outerRadius)
        .startAngle(0)
        .endAngle(2 * Math.PI);
      svg.append('path').attr('d', centerArea).style('fill', '#f7f7f7');

      if (horizonBottom) {
        const horizonDonut = d3
          .arc()
          .innerRadius(0)
          .outerRadius(radius - 55)
          .startAngle(Math.PI / 2)
          .endAngle(Math.PI * 1.5);
        svg.append('path').attr('d', horizonDonut).style('fill', '#D9D9D9');
      }

      if (horizonTop) {
        const horizonDonut = d3
          .arc()
          .innerRadius(0)
          .outerRadius(radius - 55)
          .startAngle(-(Math.PI / 2))
          .endAngle(Math.PI / 2);
        svg.append('path').attr('d', horizonDonut).style('fill', '#D9D9D9');
      }

      if (horizonBottom || horizonTop) {
        svg
          .append('line')
          .attr('id', 'horizon')
          .attr('x1', -195)
          .attr('x2', 195)
          .attr('y1', 0)
          .attr('y2', 0)
          .attr('stroke', '#000');
        svg
          .append('text')
          .style('font-family', 'Akkurat-Mono')
          .style('font-size', '14px')
          .attr('text-anchor', 'middle')
          .attr('x', -150)
          .attr('y', -5)
          .text('HORIZON');
      }
    }

    if (planetImages.length) {
      svg
        .selectAll('.planet-images')
        .data(planetImages)
        .enter()
        .append('image')
        .attr('height', (d) => d.height)
        .attr('width', (d) => d.width)
        .attr('xlink:href', (d) =>
          d.planet === 'Sun'
            ? require('../../../../../images/sun-2.png')
            : require('../../../../../images/moon-2.png'),
        )
        .attr('x', (d) => d.x)
        .attr('y', (d) => d.y);

      svg
        .append('circle')
        .attr('class', 'orbit')
        .attr('r', 85)
        .attr('stroke', '#ccc')
        .attr('fill', 'transparent')
        .attr('cx', planetImages[0].x + 20)
        .attr('cy', planetImages[0].y + 20);
    }

    if (centerImage) {
      svg
        .append('image')
        .attr('height', '35px')
        .attr('width', '35px')
        .attr('xlink:href', require('../../../../../images/Earth.png'))
        .attr('x', -17.5)
        .attr('y', -17.5);
      svg
        .append('circle')
        .attr('class', 'orbit')
        .attr('r', 45)
        .attr('stroke', '#ccc')
        .attr('fill', 'transparent');

      if (baby1) {
        svg
          .append('image')
          .attr('width', '50px')
          .attr('height', '60.4px')
          .attr('xlink:href', require('../../../../../images/baby.png'))
          .attr('x', -25)
          .attr('y', -95);
        let babyText;
        if (startingSign === 'Leo') {
          babyText = planetRing ? 'BABY NIC AT 2PM' : 'BABY NIC IN THE U.S.!';
        } else {
          babyText = 'BABY HANNA AT 4PM';
        }
        svg
          .append('text')
          .style('font-family', 'Akkurat-Mono')
          .style('font-size', '14px')
          .attr('text-anchor', 'middle')
          .attr('x', 0)
          .attr('y', -115)
          .text(babyText);

        if (!baby2 && !planetRing) {
          svg
            .append('text')
            .style('font-family', 'Akkurat-Mono')
            .style('font-size', '14px')
            .attr('text-anchor', 'middle')
            .attr('x', 0)
            .attr('y', 100)
            .text('OTHER SIDE OF THE EARTH');
          svg
            .append('text')
            .style('font-family', 'Akkurat-Mono')
            .style('font-size', '14px')
            .attr('text-anchor', 'middle')
            .attr('x', 0)
            .attr('y', 120)
            .text('(INVISIBLE TO BABY NIC)');
        }
      }

      if (baby2) {
        svg
          .append('image')
          .attr('width', '50px')
          .attr('height', '60.4px')
          .attr('xlink:href', require('../../../../../images/baby.png'))
          .attr('x', -25)
          .attr('y', -95)
          .attr('transform', 'rotate(180)');
        svg
          .append('text')
          .style('font-family', 'Akkurat-Mono')
          .style('font-size', '14px')
          .attr('text-anchor', 'middle')
          .attr('x', 0)
          .attr('y', 130)
          .text('BABY 安娜 IN CHINA!');

        if (!baby1) {
          svg
            .append('text')
            .style('font-family', 'Akkurat-Mono')
            .style('font-size', '14px')
            .attr('text-anchor', 'middle')
            .attr('x', 0)
            .attr('y', -110)
            .text('OTHER SIDE OF THE EARTH');
          svg
            .append('text')
            .style('font-family', 'Akkurat-Mono')
            .style('font-size', '14px')
            .attr('text-anchor', 'middle')
            .attr('x', 0)
            .attr('y', -90)
            .text('(INVISIBLE TO BABY 安娜)');
        }
        if (pathLines) {
          svg
            .append('line')
            .attr('id', 'moon-line')
            .attr('x1', -50)
            .attr('x2', -195)
            .attr('y1', 10)
            .attr('y2', 30)
            .attr('stroke', '#000');

          svg
            .append('text')
            .attr('id', 'moon-text')
            .style('font-family', 'Akkurat-Mono')
            .style('font-size', '14px')
            .style('letter-spacing', '.1em')

            .attr('text-anchor', 'middle')
            .attr('x', -122.5)
            .attr('y', 15)
            .attr('transform', 'rotate(-6, -122.5, 15)')
            .text('MOON');

          svg
            .append('line')
            .attr('id', 'moon-earth-line')
            .attr('x1', -35)
            .attr('x2', -15)
            .attr('y1', 10)
            .attr('y2', 5)
            .attr('stroke', '#000')
            .attr('stroke-dasharray', '3, 3');

          svg
            .append('line')
            .attr('id', 'sun-earth-line')
            .attr('x1', 17)
            .attr('x2', 60)
            .attr('y1', -2)
            .attr('y2', -15)
            .attr('stroke', '#000')
            .attr('stroke-dasharray', '3, 3');

          svg
            .append('line')
            .attr('id', 'sun-line')
            .attr('x1', 100)
            .attr('x2', 195)
            .attr('y1', -27)
            .attr('y2', -65)
            .attr('stroke', '#000');

          svg
            .append('text')
            .attr('id', 'sun-text')
            .style('font-family', 'Akkurat-Mono')
            .style('font-size', '14px')
            .style('letter-spacing', '.1em')

            .attr('text-anchor', 'middle')
            .attr('x', 147.5)
            .attr('y', -51)
            .attr('transform', 'rotate(-20, 147.5, -51)')
            .text('SUN');
        }
      }
    }

    svg
      .selectAll('.sign-text')
      .data(data)
      .enter()
      .append('text')
      .attr('class', 'sign-text')
      .attr('dy', (d, _) => {
        const pos = isUpsideDown(d.endAngle) ? -12 : 23;
        return pos;
      })
      .style('fill', '#f7f7f7')
      .style('font-family', 'Akkurat-Mono')
      .style('font-size', '14px')
      .style('letter-spacing', '.1em')
      .append('textPath')
      .attr('startOffset', '50%')
      .style('text-anchor', 'middle')
      .attr('xlink:href', (_, i) => `#signArc_${i}`)
      .text((d) => d.name.toUpperCase());

    const findAspectCoord = (num) => num + 10;

    svg
      .selectAll('.aspects')
      .data(aspects)
      .enter()
      .append('line')
      .attr('x1', (d) => findAspectCoord(planetObjs[d[0]].x))
      .attr('x2', (d) => findAspectCoord(planetObjs[d[1]].x))
      .attr('y1', (d) => findAspectCoord(planetObjs[d[0]].y))
      .attr('y2', (d) => findAspectCoord(planetObjs[d[1]].y))
      .attr('stroke', '#000')
      .attr('stroke-dasharray', '3, 3');
  }, []);

  return (
    <svg ref={ref} className={className}>
      <g className="area" />
    </svg>
  );
};

export default RisingSignZodiacRing;
