import { Point } from 'geometry';

export class Cursor {
  public point: Point;

  public smooth: Point;

  public percent: Point;

  public smoothPercent: Point;

  constructor(smooth) {
    this.point = new Point(0, 0);
    this.smooth = new Point(0, 0);
    this.percent = new Point(0, 0);
    this.smoothPercent = new Point(0, 0);

    document.onmousemove = (e) => {
      this.point.x = e.pageX;
      this.point.y = e.pageY;

      this.percent.x = e.pageX / innerWidth;
      this.percent.y = e.pageY / innerHeight;
    };

    smooth(() => {
      var again = () => {
        this.smooth.x += (this.point.x - this.smooth.x) / 2;
        this.smooth.y += (this.point.y - this.smooth.y) / 2;

        this.smoothPercent.x = this.smooth.x / innerWidth;
        this.smoothPercent.y = this.smooth.y / innerHeight;
        smooth(again);
      };
      again();
    });
  }
}

export default new Cursor(requestAnimationFrame);
