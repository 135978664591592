import React from 'react';

import styles from './OracleResourcesPage.module.scss';

const OracleResourcesPageBottomBar = () => {
  const handleClick = () => {
    window.location.href = 'https://www.google.com';
  };
  return (
    <div className={styles.bottomBar}>
      <h4>Someone watching?</h4>
      <button className="btn white" type="button" onClick={handleClick}>
        EXIT SITE
      </button>
    </div>
  );
};

export default OracleResourcesPageBottomBar;
