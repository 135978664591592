/* eslint-disable import/no-named-as-default */
import React from 'react';
import { Helmet } from 'react-helmet';
import { failure, initial, success } from '@devexperts/remote-data-ts';
import Api from 'api';
import { none, some } from 'fp-ts/lib/Option';
import PageLayout from 'landing-page/views/PageLayout';
import { WebData } from '../../../server/request';

import { UserTestingSignupFormData } from './UserTestingSignUpTypes';
import UserSignupFormContainer from './UserTestingSignUpFormContainer';

interface EmailState {
  submitState: WebData<any>;
}

const defaultError = 'Our records show that email may be taken';

const SuccessState: React.FC = () => (
  <div className="floating-container">
    <div className="floating-container-inner form-info">
      <div className="success-text-section">
        <h1>Thank you for signing up! </h1>
        <h6>Your participation helps us make the app better.</h6>
        <h6>We schedule user tests in the order we receive sign-ups.</h6>
        <h6>We hope to be in touch with you soon</h6>
      </div>
    </div>
  </div>
);

class UserTestingSignup extends React.Component<Record<string, never>, EmailState> {
  constructor(props) {
    super(props);

    this.state = {
      submitState: initial,
    };
  }

  submitForm = (event: any, formInput: UserTestingSignupFormData) => {
    event.preventDefault();


    Api.signupForUserTesting(formInput)
      .then((newUser) => {
        this.setState({
          submitState: success(newUser),
        });
      })
      .catch((e: any) => {
        console.error('ERROR! ', e);
        this.setState({
          submitState: failure(e),
        });
      });
  };

  render() {
    return (
      <div className="user-test-signup-page-container">
        <Helmet>
          <title>
            {/* eslint-disable-next-line no-irregular-whitespace */}
            Co – Star User Sign Up: Hyper-Personalized, Real-Time Horoscopes
          </title>
          <meta name="description" content="Sign up to be a user tester" />
        </Helmet>
        {this.state.submitState.foldL(
          /** ****  Not Asked ******* */
          () => (
            <UserSignupFormContainer
              onSubmitForm={this.submitForm}
              mbError={none}
            />
          ),

          /** ****  Loading ******* */
          () => (
            <div />
          ),

          /** ****  Error ******* */
          () => (
            <UserSignupFormContainer
              onSubmitForm={this.submitForm}
              mbError={some(defaultError)}
            />
          ),

          /** ****  Success ******* */
          () => (
            <SuccessState />
          ),
        )}
        {/* } */}
      </div>
    );
  }
}

export default PageLayout(UserTestingSignup);
