import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Breadcrumbs.module.scss';

type BreadcrumbsProps = {
  crumbs: { name: string; path: string }[];
};

const Breadcrumbs = ({ crumbs }: BreadcrumbsProps) => {
  if (crumbs.length <= 1) return null;
  const interleave = (arr, thing) => [].concat(...arr.map((n) => [n, thing])).slice(0, -1);
  const crumbLinks = crumbs.map(({ name, path }, idx) =>
    idx + 1 === crumbs.length ? (
      <span key={idx}>{name}</span>
    ) : (
      <Link to={path} key={idx}>
        {name}
      </Link>
    ),
  );
  const crumbComponents = interleave(crumbLinks, <span key="slash">/</span>);
  return <div className={styles.container}>{crumbComponents}</div>;
};

export default Breadcrumbs;
