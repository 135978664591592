import React from 'react';
import { useParams } from 'react-router-dom';

import Api from 'api';
import PageLayout from 'landing-page/views/PageLayout';
import { ProspectiveUserForm } from '../../components/form';

function UpdateSettingsPage() {
  const { token } = useParams() as { token: string };

  return (
    <div className="floating-container">
      <div className="floating-container-inner">
        <div>
          <h1>Settings</h1>
          <ProspectiveUserForm
            learnMore={false}
            submit="UPDATE"
            formLocation="UpdateSettings"
            className="update-form"
            submitClass="btn submit sign-up black update-form-submit"
            endpoint={(data) => Api.updateProspectiveUser(token, data)}
          />
        </div>
      </div>
    </div>
  );
}

export default PageLayout(UpdateSettingsPage);
