import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../../style/style.scss';
import { Grid } from 'grid';
import { signs } from 'astrology';
import UserProvider from 'context/userContext';
import RouterWrapper from './components/RouterWrapper';
import HeaderNav from './components/HeaderNav';
import HomePage from './pages/HomePage/HomePageV1';
import DownloadPage from './pages/download';
import InvitePage from './pages/invite-landing';
import FeedbackPage from './pages/feedback';
import ContactPage from './pages/Contact';
import BetaPage from './pages/beta';
import NatalChartLandingPage from './pages/NatalChartPages/NatalChartLandingPage';
import PrivacyPage from './pages/privacy';
import TermsPage from './pages/terms';
import JobsPage from './pages/jobs';
import TeamPage from './pages/TeamPage';
import AboutPage from './pages/about';
import PasswordResetPage from './pages/PasswordResetPage/PasswordResetPage';
import UpdateSettingsPage from './pages/update-settings';
import FAQPage from './pages/FAQPage';
import WhyHaskellPage from './pages/why-haskell';
import NatalChartPage from './pages/NatalChartPages/NatalChartPageContainer';
import ProspectiveUserUnsubscribePage from './pages/prospective-user-unsubscribe-page';
import MigrateFBPage from './pages/migrate-fb';
import NatalChartWebviewPage from './pages/natal-chart-webview';
import NotFoundPage from './pages/NotFoundPage';
import UserTestingSignup from './pages/UserTestingSignUp';
import ComingSoonPage from './pages/coming-soon';
import { MenuModal } from './components/menu-modal';
import WhatIsAstrology from './pages/AstroIntuition/WhatIsAstrology';
import NatalChart from './pages/AstroIntuition/NatalChart';
import RisingSign from './pages/AstroIntuition/RisingSign';
import Aspects from './pages/AstroIntuition/Aspects';
import Transits from './pages/AstroIntuition/Transits';
import Houses from './pages/AstroIntuition/Houses';
import HouseSystems from './pages/AstroIntuition/HouseSystems';
import AstroIntuition from './pages/AstroIntuition';
import SignsPage from './pages/AstroIntuition/ZodiacSigns';
import ZodiacSignPage from './pages/AstroIntuition/ZodiacSigns/ZodiacSignPage';
import ContactFormWebView from './pages/ContactWebView/ContactWebView';
import AdvancedChart from './pages/AdvancedChart';
import AdvancedReadings from './pages/Shop/AdvancedReadings';
import YouInLove from './pages/Shop/YouInLove';
import AskTheStars from './pages/Shop/AskTheStars';
import OracleResourcesPage from './pages/OracleResourcesPage';

export default class App extends React.Component<{}, AppState> {
  constructor(props) {
    super(props);

    this.state = {
      showMenuModal: true,
    };
    this.handleModal = this.handleModal.bind(this);
  }

  componentDidMount() {
    new Grid();
  }

  handleModal() {
    const { showMenuModal } = this.state;
    this.setState({ showMenuModal: !showMenuModal });
  }

  render() {
    return (
      <UserProvider>
        <BrowserRouter>
          <RouterWrapper>
            <div>
              <Helmet>
                <meta
                  name="description"
                  content="Algorithmically-generated horoscopes, customized to your entire chart."
                />
              </Helmet>
              <Routes>
                <Route path="/" element={<HomePage/>} />
                <Route path="/index.html" element={<HomePage />}/>
                <Route path="/invite" element={<InvitePage />} />
                <Route path="/download" element={<DownloadPage />} />
                <Route path="/feedback" element={<FeedbackPage />} />
                <Route path="/app-feedback" element={<FeedbackPage />} />
                <Route path="/password-reset/:token" element={<PasswordResetPage />} />
                <Route
                  path="/prospective_user/:puid/unsubscribe/:hash"
                  element={<ProspectiveUserUnsubscribePage />}
                />
                <Route
                  path="/migrate/:targetUserId/:deadUserId/:token"
                  element={<MigrateFBPage />}
                />
                <Route path="/update/:token" element={<UpdateSettingsPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/beta" element={<BetaPage />} />
                <Route path="/usertest" element={<UserTestingSignup />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/chart/:prospectiveUserId" element={<NatalChartPage />} />
                <Route path="/natal-chart/:prospectiveUserId" element={<NatalChartPage />} />
                <Route
                  path="/natal-chart-webview/:birthedEntityId"
                  element={<NatalChartWebviewPage />}
                />
                <Route path="/natal-chart" element={<NatalChartLandingPage />} />
                <Route path="/coming-soon" element={<ComingSoonPage />} />

                <Route path="/privacy" element={<PrivacyPage />} />
                <Route path="/terms" element={<TermsPage />} />
                <Route path="/jobs" element={<JobsPage />} />

                <Route path="/team" element={<TeamPage />} />
                <Route path="/faq" element={<FAQPage />} />
                <Route path="/why-haskell" element={<WhyHaskellPage />} />

                <Route path="/:username" element={<NatalChartPage />} />
                <Route path="/how-does-astrology-work" element={<AstroIntuition />} />
                <Route
                  path="/how-does-astrology-work/what-is-astrology"
                  element={<WhatIsAstrology />}
                />
                <Route
                  path="/how-does-astrology-work/what-is-a-natal-chart"
                  element={<NatalChart />}
                />
                <Route path="/how-does-astrology-work/rising-sign" element={<RisingSign />} />
                <Route path="/how-does-astrology-work/aspects" element={<Aspects />} />
                <Route path="/how-does-astrology-work/transits-orbs" element={<Transits />} />
                <Route path="/how-does-astrology-work/houses" element={<Houses />} />
                <Route path="/how-does-astrology-work/house-systems" element={<HouseSystems />} />

                <Route path="/houses" element={<Navigate to="/how-does-astrology-work/houses" />} />
                <Route
                  path="/house-systems"
                  element={<Navigate to="/how-does-astrology-work/house-systems" />}
                />

                <Route path="/zodiac-signs" element={<SignsPage />} />
                {signs.map((sign) => (
                  <Route
                    path={`zodiac-signs/${sign.toLowerCase()}-sign`}
                    element={<ZodiacSignPage sign={sign} key={sign} />}
                  />
                ))}

                <Route path="/contact-webview" element={<ContactFormWebView />} />
                <Route path="/advanced-readings" element={<AdvancedReadings />} />
                <Route path="/advanced-readings/you-in-love" element={<YouInLove />} />
                <Route path='/advanced-readings/ask-the-stars' element={<AskTheStars />}/>
                <Route path="/advanced-readings/:id" element={<AdvancedChart />} />

                <Route path="/mental-health-resources" element={<OracleResourcesPage />} />

                <Route path="*" element={NotFoundPage} />
              </Routes>

              <HeaderNav showMenuModal={this.state.showMenuModal} handleModal={this.handleModal} />

              <div className="modalMenuContainer">
                <MenuModal isOpen={!this.state.showMenuModal} closeModal={this.handleModal} />
              </div>
            </div>
          </RouterWrapper>
        </BrowserRouter>
      </UserProvider>
    );
  }
}

interface AppState {
  showMenuModal: boolean;
}

ReactDOM.render(<App />, document.getElementById('app'));
