import React from 'react';

import styles from './PullQuote.module.scss';

const BanuBox = () => {
  return (
    <svg
      className={styles.border}
      viewBox="0 0 894 451"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.9604 4.65304C31.8172 4.65304 21.3262 3.1062 10.5767 3.1062C10.2243 5.74193 10.5767 35.607 10.5767 45.0929V85.1055C10.5767 97.5151 8.36978 106.294 8.36978 116.314C8.36978 125.313 7.08758 134.17 6.89848 143.021C6.67268 153.59 3.95588 163.585 3.95588 174.08V213.24C3.95588 227.834 3.76834 243.272 1.7996 257.727C-0.570408 275.129 2.9981 292.579 3.87414 309.866C4.62406 324.664 6.17434 339.708 9.02369 354.278C12.0266 369.634 9.84108 386.674 9.84108 402.216V440.026C9.84108 447.444 10.783 445.855 18.015 445.855C30.6933 445.855 43.2214 449.494 55.7783 449.906C71.6942 450.427 87.3998 448.612 103.187 448.612C128.08 448.612 153.007 448.975 177.896 448.612C189.072 448.45 200.36 450.187 211.409 449.906C220.865 449.665 230.014 447.364 239.364 447.205C309.01 446.021 378.48 445.503 448.207 445.503C500.623 445.503 554.133 448.516 606.371 444.828C632.445 442.987 658.354 445.066 684.35 443.392C697.204 442.564 709.903 444.632 722.604 442.735C731.7 441.375 740.731 441.752 749.823 440.034C758.488 438.397 767.891 439.033 776.715 438.233C785.792 437.411 794.788 436.39 803.852 435.533C817.76 434.217 831.554 436.526 845.539 436.526C852.046 436.526 892.397 439.185 892.92 431.019C893.508 421.847 890.681 396.789 889.678 387.587C888.104 373.137 889.11 358.813 888.227 344.225C886.743 319.713 885.264 295.225 885.264 270.781C885.264 233.591 882.798 196.518 882.322 159.376C882.064 139.279 882.486 119.245 880.523 99.2093C878.916 82.7905 880.523 73.0919 880.523 51.5834V51.3926C880.524 35.5344 880.524 21.4094 873.167 3.9336C871.836 0.772781 855.896 1.47065 851.678 1.47065H806.721C747.12 1.47065 677.744 3.9336 618.142 3.9336H565.175H504.526C490.492 3.9336 469.646 3.17363 455.569 3.9336C446.762 4.40909 365.94 1.34695 357.111 1.34695C287.697 1.34695 294.181 6.93998 224.978 1.64703C208.593 0.393859 191.715 1.34695 175.281 1.34695C152.09 1.34695 132.995 2.05065 106.293 2.05065C94.3736 3.1062 88.0091 2.86628 78.5985 3.1062C71.3743 3.29038 54.6639 4.65304 45.9604 4.65304Z"
        fill="white"
        stroke="#A6A6A6"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

const PullQuote = () => {
  return (
    <div className={styles.container}>
      <BanuBox />
      <div className={styles.contents}>
        <img className={styles.headshot} src={require('../../../../images/banu-headshot.png')} />
        <div>
          <div className={styles.quote}>
            “Being seen is that feeling you get when you read something real. You feel seen when you
            read Tolstoy or Sappho or the Greek myths, that’s why they’ve stuck around for so long.
            It makes it possible to articulate what’s actually happening to you. Astrology is the
            same - it validates reality, makes it easier to say things that are hard to say. It’s
            what allows us to build closeness in a sea of isolation. Co–Star isn’t just another
            horoscope app. It’s about that irrational feeling of being completely seen.”
          </div>
          <div className={styles.footer}>BANU GULER, CEO + FOUNDER </div>
        </div>
      </div>
    </div>
  );
};

export default PullQuote;
