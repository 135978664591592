import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { sendClickEvent } from 'analytics';
import styles from './YouInLoveProductCard.module.scss';

const YouInLoveProductCard = () => {
  const buttonClick = () => {
    sendClickEvent('Shop', 'AdvChart');
  };
  return (
    <div className={styles.backgroundContainer}>
      <div className={styles.contentContainer}>
        <h3>Read more about how you or a friend are in relationships</h3>
        <img src={require('../../../../../../images/you-in-love-card-blurb.png')} alt="" />
        <h4>$9 PER READING</h4>
        <Link to="/advanced-readings/you-in-love">
          <button className={cn(styles.button, 'btn', 'black')} type="button" onClick={buttonClick}>
            VIEW DETAILS
          </button>
        </Link>
      </div>
    </div>
  );
};

export default YouInLoveProductCard;
