import { Planet } from 'astrology';

export const signs = [
  'Aries',
  'Taurus',
  'Gemini',
  'Cancer',
  'Leo',
  'Virgo',
  'Libra',
  'Scorpio',
  'Sagittarius',
  'Capricorn',
  'Aquarius',
  'Pisces',
];

export type Sign =
  | 'aries'
  | 'taurus'
  | 'gemini'
  | 'cancer'
  | 'leo'
  | 'virgo'
  | 'libra'
  | 'scorpio'
  | 'sagittarius'
  | 'capricorn'
  | 'aquarius'
  | 'pisces';

export const plural = {
  aries: 'Aries',
  taurus: 'Tauruses',
  gemini: 'Geminis',
  cancer: 'Cancers',
  leo: 'Leos',
  virgo: 'Virgos',
  libra: 'Libras',
  scorpio: 'Scorpios',
  sagittarius: 'Sagittarians',
  capricorn: 'Capricorns',
  aquarius: 'Aquarians',
  pisces: 'Pisces',
};

export const displayYear = '2025'

export const dates = {
  2025: {
    aquarius: 'January 19 to February 17',
    pisces: 'February 18 to March 19',
    aries: 'March 20 to April 18',
    taurus: 'April 19 to May 19',
    gemini: 'May 20 to June 19',
    cancer: 'June 20 to July 21',
    leo: 'July 22 to August 21',
    virgo: 'August 22 to September 21',
    libra: 'September 22 to October 21',
    scorpio: 'October 22 to November 20',
    sagittarius: 'November 21 to December 20',
    capricorn: 'December 21 to January 18',
  }
};

export const symbols: { [Key in Sign]: string } = {
  aries: 'The ram',
  taurus: 'The bull',
  gemini: 'The twins',
  cancer: 'The crab',
  leo: 'The lion',
  virgo: 'The virgin',
  libra: 'The Scales',
  scorpio: 'The scorpion',
  sagittarius: 'The Archer',
  capricorn: 'The sea goat',
  aquarius: 'The water bearer',
  pisces: 'The fishes',
};

type Element = 'Fire' | 'Earth' | 'Air' | 'Water';

export const elements: { [Key in Sign]: Element } = {
  aries: 'Fire',
  taurus: 'Earth',
  gemini: 'Air',
  cancer: 'Water',
  leo: 'Fire',
  virgo: 'Earth',
  libra: 'Air',
  scorpio: 'Water',
  sagittarius: 'Fire',
  capricorn: 'Earth',
  aquarius: 'Air',
  pisces: 'Water',
};

type Modality = 'Cardinal' | 'Fixed' | 'Mutable';

export const modalities: { [Key in Sign]: Modality } = {
  aries: 'Cardinal',
  taurus: 'Fixed',
  gemini: 'Mutable',
  cancer: 'Cardinal',
  leo: 'Fixed',
  virgo: 'Mutable',
  libra: 'Cardinal',
  scorpio: 'Fixed',
  sagittarius: 'Mutable',
  capricorn: 'Cardinal',
  aquarius: 'Fixed',
  pisces: 'Mutable',
};

export const rulingPlanets: { [Key in Sign]: Planet | 'The Moon' | 'The Sun' } = {
  aries: 'Mars',
  taurus: 'Venus',
  gemini: 'Mercury',
  cancer: 'The Moon',
  leo: 'The Sun',
  virgo: 'Mercury',
  libra: 'Venus',
  scorpio: 'Pluto',
  sagittarius: 'Jupiter',
  capricorn: 'Saturn',
  aquarius: 'Uranus',
  pisces: 'Neptune',
};

export const traits: { [Key in Sign]: string[] } = {
  aries: [
    'No filter',
    'Gets angry, then forgets why they were angry',
    'Thinks everything is a game they can win',
    'Will do anything on a dare',
    'Easily bored',
  ],
  taurus: [
    'Just wants to cuddle',
    'Homebody',
    'All or nothing, no in-between',
    'Wears the same outfit everyday',
    'Hates big changes',
  ],
  gemini: [
    'Charismatic',
    'Uses humor as a crutch',
    'Could talk to a brick wall',
    'Arguments as flirting',
    'Knows a little about everything',
  ],
  cancer: [
    'Sensitive',
    'Seeks comfort',
    'Forgives but never forgets',
    'Only has one boundary, but it is very firm',
    "Takes on other people's problems",
  ],
  leo: [
    'Exudes warmth and creativity',
    'A little bit vain',
    'Really big personality',
    'Wants to stand out',
    'Interested in luxury',
  ],
  virgo: [
    'Needs to feel useful',
    'Has a quick fix for everything',
    'Judgmental, but with good intentions',
    'Exceptional spatial awareness',
    'A million ideas per second',
  ],
  libra: [
    'Hates being alone',
    'Really good aesthetics',
    'Conflict avoidant',
    'Sees every side',
    'Prone to fantasy',
    "Can't make decisions",
  ],
  scorpio: [
    'Primary emotion is betrayal',
    'Looks cool in a leather jacket',
    'OK with uncomfortable silence',
    "Can't be sure if they're serious or joking",
    'Eyes that look into your soul',
  ],
  sagittarius: [
    'No indoor voice',
    'Forms opinions off of pure emotion',
    'Obsessed with self-improvement',
    'Wields their truth like a blunt weapon',
    'Friendliest person at the party',
  ],
  capricorn: [
    'Full grown adult since age six',
    'The responsible friend',
    'Motivated by duty',
    'Takes a while to warm up to people',
    'Represses any emotion that gets in the way of success',
  ],
  aquarius: [
    'Purposefully esoteric',
    'No feelings, just concepts',
    'Actually believes in conspiracy theories',
    'More in love with humanity as a whole than individuals',
    'Always feels like an outcast',
    'Fetishizes personal freedom',
  ],
  pisces: [
    'Somehow both 5 and 50 years old at once',
    'Thinks everything is a sign',
    "Can't remember if they dreamt it or it actually happened",
    'Excessively romantic',
    'Prone to fantasy',
    'No boundaries',
  ],
};

export const celebs: { [Key in Sign]: string[] } = {
  aries: [
    'Mariah Carey',
    'Jackie Chan',
    'Leonardo DaVinci',
    'Charles Baudelaire',
    'Harry Houdini',
    'Cesar Chavez',
    'Andrei Tarvosky',
    'Ilana Glazer',
  ],
  taurus: [
    'Karl Marx',
    'Malcolm X',
    'Audrey Hepburn',
    'William Shakespeare',
    'James Brown',
    'Grace Jones',
    'Sigmund Freud',
    'John Waters',
  ],
  gemini: [
    'Laverne Cox',
    'Kendrick Lamar',
    'Jean-Paul Sartre',
    'Allen Ginsberg',
    'Thomas Mann',
    'Prince',
    'Laurie Anderson',
    'Stevie Nicks',
  ],
  cancer: [
    'Ariana Grande',
    'Frida Kahlo',
    'Marcel Proust',
    'Assata Shakur',
    'Stokely Carmichael',
    'Franz Kafka',
    'Patrice Lumumba',
    'Emma Goldman',
  ],
  leo: [
    'Usain Bolt',
    'James Baldwin',
    'Kylie Jenner',
    'Coco Chanel',
    'Kate Bush',
    'Whitney Houston',
    'Duchamp',
    'Charli XCX',
  ],
  virgo: [
    'Fred Hampton',
    'Bernie Sanders',
    'Agatha Christie',
    'Mary Shelley',
    'D.H. Lawrence',
    'George Bataille',
    'Antonin Artaud',
    'Marsha P. Johnson',
  ],
  libra: [
    'Michel Foucault',
    'Friedrich Nietzsche',
    'Kim Kardashian',
    'Donald Glover',
    'Gwyneth Paltrow',
    'Neil DeGrasse Tyson',
    'Nico',
    'Arthur Rimbaud',
  ],
  scorpio: [
    'Leonardo DiCaprio',
    'Charles Manson',
    'Marie Antoinette',
    'Frank Ocean',
    'Albert Camus',
    'Fyodor Dostoevsky',
    'Sylvia Plath',
    'Björk',
  ],
  sagittarius: [
    'Nicki Minaj',
    'Jane Austen',
    'Jean Michel Basquiat',
    'Nostradamus',
    'Emily Dickinson',
    'Britney Spears',
    'Diego Rivera',
    'Friedrich Engels',
  ],
  capricorn: [
    'Martin Luther King Jr.',
    'Elvis Presley',
    'Hayao Miyazaki',
    'Timothée Chalamet',
    'Michelle Obama',
    'Dolly Parton',
    'Mao Zedong',
    'Edgar Allen Poe',
  ],
  aquarius: [
    'Angela Davis',
    'Virginia Woolf',
    'Frederick Douglass',
    'Michael Jordan',
    'Yoko Ono',
    'Grigori Rasputin',
    'Huey P. Newton',
    'James Joyce',
    'Audre Lorde',
  ],
  pisces: [
    'Nina Simone',
    'Pier Paolo Pasolini',
    'Genesis P-Orridge',
    'Gabriel García Márquez',
    'Anaïs Nin',
    'Rihanna',
    'Victor Hugo',
    'Rosa Luxemburg',
  ],
};

export const careers: { [Key in Sign]: string[] } = {
  aries: ['Stunt double', 'Part-time punk', 'Pro-wrestler', 'Petty thief', 'Cereal box mascot'],
  taurus: [
    'Hermit in the woods',
    'Wall to bump up against',
    'Food blogger',
    'Sleep study participant',
    'Sweatpants model',
  ],
  gemini: [
    'Likable politician',
    'Quirky side character',
    "Devil's advocate",
    'Bubble gum wrapper joke writer',
    'Twitter personality',
  ],
  cancer: [
    'Emotional packmule',
    'Caretaker for lost skaters',
    'That one influential elementary school teacher',
    '"Free hugs" booth operator',
    'Metaphorical heart surgeon',
  ],
  leo: [
    'Chanteuse',
    'B-list actor',
    'Motivational speaker',
    'Autobiographical author',
    'Style icon',
  ],
  virgo: [
    'Hot librarian',
    "Tupperware for other people's messes",
    'Walking encyclopedia',
    'Human GPS',
    'One-person welcome committee',
  ],
  libra: ['Hivemind', 'Socialite', 'Instagram model', 'Muse', 'Mediator'],
  scorpio: ['Con artist', 'Puppet master', 'Brooding protagonist', 'Poltergeist', 'Cult leader'],
  sagittarius: [
    'Foreign correspondent',
    'Health fad guru',
    'Karaoke Emcee',
    'Club Kid',
    'Unauthorized anthropologist',
  ],
  capricorn: ['Accountant', 'Lawyer', 'Landlord', 'Sisyphus', 'Elementary school hall monitor'],
  aquarius: [
    'Professional dilettante',
    'Bohemian',
    'Casual anthropologist',
    'Rioter and looter',
    'Artificial Intelligence',
    'Armchair philosopher',
  ],
  pisces: [
    'Volunteer therapist',
    'Curbside fortune teller',
    'Amateur poet',
    'Sad clown',
    'Orb of light',
    'Vapor',
  ],
};

export const metadata: { [Key in Sign]: string } = {
  aries:
    'Aries are unafraid of conflict, highly competitive, honest and direct. Of all the zodiac signs, they are the least conflicted about what they want.',
  taurus:
    'Tauruses are oriented around the physical world. The most grounded zodiac sign, they love their routine and are committed to comfort.',
  gemini:
    'People with a Gemini Sun sign are intelligent and perceptive. They can size up a person’s character in a matter of seconds and always know who’s bluffing.',
  cancer:
    'People born in the Cancer Sun sign of the zodiac don’t like surprises. They are highly sensitive, deeply caring, and feel everything deeply.',
  leo: 'Leos are bold, warm, and loving. They don’t necessarily want to be worshipped, they just want to be recognized for their authentic selves.',
  virgo:
    'Of all the Sun signs, Virgo is the most detail-oriented. Virgos are perceptive, analytical and driven by the need to feel useful.',
  libra:
    'Libras crave validation and are hyper-attuned to other people. Their internal world is turbulent and they work very hard to maintain balance.',
  scorpio:
    'Scorpio is the zodiac sign associated with intense, emotional depth. Scorpios chase experiences that involve the risk of being completely destroyed.',
  sagittarius:
    'People born with their Sun sign in Sagittarius feel like the world is their playground. Quick to blurt out their opinions, they somehow still manage to be friends with everyone.',
  capricorn:
    'Capricorns often come across as stoic, but really they’re just stewing on their feelings and unable to express them.',
  aquarius:
    'Aquarians are archetypical outcasts. Yet they thrive in large groups, where they can charm you with their peculiar sense of humor.',
  pisces:
    'Pisces is the most diffused Sun sign of the zodiac. They tend to dilute themselves with larger personalities to avoid having to form their own identity.',
};

type CopyItem = {
  subheader: string;
  body: string[];
};

export const about: { [Key in Sign]: CopyItem[] } = {
  aries: [
    {
      subheader: 'What is the personality of an Aries?',
      body: [
        'At their core, Aries do things their own way. They are unafraid of conflict, highly competitive, and honest. They throw themselves at the world eagerly and without fear.',
        'Aries are driven by a desire to prove themselves and their strength. They have high energy, and are competitive and ambitious. They naturally take charge because they are good at initiating new projects. They can also be impatient, but are naturally active and don’t like to waste time.',
        'While Aries like competition, they don’t like to play games. They are highly self-aware, have strong opinions and are always ready to defend them.',
      ],
    },
    {
      subheader: "What are an Aries' weaknesses?",
      body: [
        'All Aries want to feel things intensely. This is one of their most commendable qualities, but also what causes them a great deal of pain and grief. Aries tend to be impulsive, impatient, and hotheaded. They are often reacting out of emotion rather than from a place of calm clear-headedness. They do not apologize for their anger. They say “I don’t care” and mean it. They don’t always have the best self-control.',
      ],
    },
  ],
  taurus: [
    {
      subheader: 'What is the personality of a Taurus?',
      body: [
        'Tauruses are the human equivalent of moss. A handmade wooden chair. They are normally satisfied with the way things are. They embody stability. Sitting in a patch of grass admiring the breeze. When everything else seems to be falling apart, Tauruses are a rock of dependability in an oasis of calm.  Practical knowledge and experience is their modus operandi.',
        'Tauruses are oriented around the physical world. They tend to be grounded and logical. They love routine and they’re committed to their own comfort. They like to be in control. They’re patient and steady, and their materialism is an extension of their pursuit of stability.',
        'Once they get into a groove, it’s difficult for them to get out of it. Object in motion stays in motion. Object at rest stays at rest. Appreciative of beauty. Attuned to physical pleasure. They like things to be predictable. They are perfectly happy eating the same meal over and over, or wearing the same outfit for a week straight. They would prefer things be consistent than chaotically good. They like to discover what they have the most fun doing, and then do it to the extreme.',
        'Tauruses have a regal quality about them. They are graceful and diligent laborers. They can be stubborn, bull-headed, and set in their ways, but they are also great listeners and very dependable.',
        'Tauruses can absolutely go on forever. They are tireless machines. And no matter what someone throws at them, they will inevitably overcome their obstacles because they’ve already prepared for every contingency. They are the hand-built truck that will never break down. ',
      ],
    },
    {
      subheader: 'What bad traits do Taureses have?',
      body: [
        "Some of Tauruses' bad traits have to do with their materialistic tendencies. Sometimes they are overly determined to achieve material success. They are not immune to greed. They can be apathetic to the needs of others if they are motivated to achieve their own goals.",
        'Tauruses don’t respond to criticism. Not even that they negatively react to it, but it’s almost like they are made of cement, and any feedback just bounces right off. They don’t budge.',
        'Tauruses are stubborn. They’re not going to do something they don’t want to do. They’re slow to change their minds because change threatens their sense of stability. They can become so set in their routine that they get too comfortable and lose sight of what really matters. Tauruses can become emotionally and physically lazy.',
        'They can become stubborn and obstinate, and have a hard time listening to other people. They can be like a bull–stuck in the mud, and unable to see the bigger picture. Tauruses are slow to act, and may be too caught up in the moment to see the potential for something bigger. They can also be reckless, especially when they are young. They can struggle with over-indulgence in vices because they don’t know moderation. Once they decide they like something, they go all in.',
      ],
    },
  ],
  gemini: [
    {
      subheader: 'What kind of person is a Gemini?',
      body: [
        'Geminis are very intelligent and pick up knowledge quickly. They are perceptive, analytical, and often very funny. They have an unreserved, childlike curiosity, always asking new questions.',
        'Geminis have an uncanny ability to size up a person’s character in a matter of seconds, even if they only just met them. If someone’s bluffing, they’ll be the first to notice. They are great communicators because they tend to be very responsive and sensitive listeners.',
        'Geminis are versatile, comfortable being both introverts and extroverts. They are quick to adapt to the energy of a room–they can be the life of the party or a complete wallflower. Geminis know how to bring dissimilar people together and make them get along.',
      ],
    },
    {
      subheader: "What are a Gemini's weaknesses?",
      body: [
        'Geminis are afraid of being alone. This is why they tend to have large social circles and always have a companion. They’re afraid of being trapped in their own minds. It’s not that Geminis aren’t creative or intelligent, it’s that they’re afraid of the power of their own imagination. They’re afraid of what they’ll find once they finally find themselves.',
        'They’re not afraid of their emotions, but Geminis are constantly worried about how they convey them, about their words being misconstrued, about unintentionally hurting someone’s feelings. This is a common pattern for Geminis, and a result of how they externalize their insecurities. They’re afraid of getting caught up in an emotion that they can’t control. They simply react to their emotions, rather than feel them.',
        'A Gemini mind is an endless race track. They’re constantly scanning under the surface of their current reality for something that they haven’t seen before.',
        "Geminis aren't in a rush to get anywhere, they’re just looking for something new. The most important thing Geminis need to realize is that there is no final destination to be found at the end of the road. They can’t run forever. At some point they’re going to have to stop and look around. To take responsibility for the environment they find themselves in. They’re going to have to look at the world that they’ve built for themselves and decide if it’s really what they want.",
      ],
    },
  ],
  cancer: [
    {
      subheader: 'What is the personality of a Cancer?',
      body: [
        "A Cancer’s personality is like wading chest deep in a lake of warm water. It feels sparkling and cool while you're in the shallow end, but you know that if you were to dive in, it would feel warm.",
        'The self-awareness of a Cancer is like the tides–constantly moving in and out of focus. Their personalities are layered. Cancers have many moods, some of which are contradictory, but they also have a deep, core self that persists.',
        'Cancers are weighed down by their own sorrows and the sorrows of those around them. They are frequently haunted by grief. It’s hard for them to share their pain with others, and they are often afraid to be vulnerable because they carry a fear that people will use their weaknesses against them. Cancers have learned to hide their pain to avoid burdening anyone else. They pretend they’re okay when they’re not.',
        'Their emotions are like an exposed nerve. Cancers can feel everything. They’re like a tuning fork that vibrates at the slightest provocation. They tend to carry deep grudges because they can’t forget the emotional sting of even a slight.',
      ],
    },
    {
      subheader: 'What are Cancers attracted to?',
      body: [
        "Cancers are homebodies. Drawn to stability, routine, and the comfort of the familiar, they don't like change.  Cancers like to know what is expected. They tend to be less experimental than others. Traditions and the past captivate their imaginations. They love art that reminds them of a different time, old stories and art forms. Predictable environments make them feel comfortable. They like to know what’s going to happen. They like to feel that they’re part of a bigger plan. They don’t like surprises.",
      ],
    },
  ],
  leo: [
    {
      subheader: 'What is the personality of a Leo?',
      body: [
        'Leos are bold, warm, and loving. They are also the ultimate performers. They can dazzle with the theatrical flair of a Broadway star and the charisma of a politician. They are captivating personalities. No matter how quickly they’ve just been introduced to a topic, they can speak eloquently about almost anything because they have such a way with words.',
        'Leos inject ambition into everything they do. They are ruled by the heart. Their actions are natural and effortless because they are brash and confident and trust their instincts.',
        'Leos have an unrelenting appetite for growth that is easily satiated by the praise of others. When they are hurting, it shows. They will use their anger as a protective wall to shield their vulnerabilities. They want to be perceived as unbreakable because they are scared of admitting vulnerability, needing things from other people, or being incomplete.',
      ],
    },
    {
      subheader: "What are a Leo's weaknesses?",
      body: [
        "A Leo's bad sides appear when their admirable character traits cross over into excess. They can be perceived as overbearing, but this is due to their hyper-presence and warmth.",
        'Leos have a reputation for bragging a lot. They see bragging as sharing. Sharing their accomplishments with others makes Leos feel connected, and they expect the same in return from their friends. They want to be celebrated, but they are equally happy to celebrate you.',
        'Leos like to feel important. They don’t necessarily want to be worshipped. They just want to be recognized for their authentic selves. The difference between admiration and worship is subtle, but it’s the difference between a Leo being preoccupied with themselves, or being focused on the good of the people they care about.',
        'Leos want to be the center of the universe. They want to be rewarded for being the best and the brightest. They want to be given special treatment. But they also want to feel deserving of it.',
      ],
    },
    {
      subheader: 'What do Leos hate the most?',
      body: [
        'Leos hate being bossed around. They are very much in charge of their own destiny. Leos are perpetually wounded by what they perceive to be acts of betrayal and abandonment. Their pride is a sword that they wield to protect their autonomy.',
        'Because their sense of honor is so intense, Leos hold both themselves and others to a very high standard. They can feel deeply wounded when someone breaks that code. There is an ingrained need for everyone to acknowledge and validate their existence. This can lead to what can be labeled as narcissistic or absolutist behavior that borders on the absurd.',
        "Leos hate being told what to do, but if you can get them to listen, they can usually be persuaded—especially if you make them feel like it was what they wanted all along. But they’ll always secretly resent that they didn’t reach this conclusion alone. Leos don’t like to be told to do things, and they especially don't like being forced.",
      ],
    },
  ],
  virgo: [
    {
      subheader: 'What is the personality of a Virgo?',
      body: [
        'Virgos are known for being perfectionists. They are known for their attention to detail, which helps them find patterns where there are none. At times, Virgos can be finicky and critical to a fault.',
        "It’s true that Virgos are very particular, but that doesn’t necessarily mean that they keep neat spaces. Their particularities and habits don’t necessarily line up with traditional views of cleanliness. They could live in what looks like a Tasmanian devil-style dust storm ruin, but still impose a “no shoes in the house” or “no outside clothes on the bed” rule. Maybe their house looks cluttered, but they still know where everything is. Everything has its place. Virgos prefer to exist in organized spaces, but put helping others over their own comfort. A Virgo can become so busy fixing the lives of those around them that they don't put much work into providing for their own needs. Virgos are rarely motivated by their own self-interest.",
        'Virgos are also known for being intelligent, but because of their introverted nature they can sometimes have trouble expressing themselves. Talking to them may feel like floating on the surface of existence, and like you never know what they’re thinking and feeling deep inside. Their emphasis on thoughts and ideas can seem like a cover-up for their lack of emotional depth. In reality, they are a fortress unto themselves—the definition of self-containment. Virgos will be endlessly accepting of whatever you have to share, but may not see the value in spontaneously performing a similar excavation of themselves for anyone else.',
      ],
    },
    {
      subheader: 'What makes Virgos happy?',
      body: [
        'To be happy, Virgos need to feel like they’re being useful. They feel productive by contributing to something larger than themselves. This is how they derive self-worth–by feeling needed.',
        'Virgos actively seek out messes of all kinds–physical, interpersonal, emotional.They like working on busy little behind-the-scenes tasks that make life run more smoothly for everyone. Virgos need to keep going at full speed, because if they slow down, the unfolding chaos of the world catches up with them and fills their heads with constant anxiety. It’s this internal disorder that Virgos are always running from, throwing themselves at the Sisyphean task of being the world’s dustpan.',
        'Virgos need a sense of purpose and achievement. They love setting goals and checking things off their to-do lists. What makes them happy is the feeling that they’re making an impact, or at least trying to.',
      ],
    },
    {
      subheader: 'What type of person is a Virgo?',
      body: [
        'Virgos are generally very modest. They want to feel acknowledged for their contributions, but they don’t need grandiose gestures of appreciation to feel valuable. They are little geniuses. They notice the details. Virgo knows when their toothbrush has been moved even a centimeter.',
        'Virgos are insecure about the fact that they’re not perfect. They’re aware that their actions have consequences, and that they can, at times, be responsible for their own suffering. This is why you’ll often hear them saying things like “It’s my fault,” and “I did this to myself.',
        'Virgos are the literal embodiment of the medieval philosopher who lives in a sterile cell, never opening the door for fear that people will catch a glimpse of the mess inside. Their motto is “In order to save myself from myself, I must first destroy myself.” What they want is a pure, clean existence.',
        'They need to be the best to feel worthy of their existence. They can be so obsessed with their own image of perfection, that they lose touch with their true impulses. They repress their feelings by locking them away in order to achieve a state of impassivity.',
        'They can come off as far more rational than they really are. Their feelings are generally a great mystery to them. They feel so many things so deeply, but they usually don’t know how to talk about it. The only way they can express the ineffable is through dry sarcasm.',
      ],
    },
  ],
  libra: [
    {
      subheader: 'What is the personality of a Libra?',
      body: [
        'Libras are difficult to really understand because they seem so contradictory. They’re simultaneously extroverted and introverted, strategic and spontaneous, focused and intuitive. This variability makes it difficult to pin down their true character. They are an entire constellation of personalities.',
        "Libras are different depending on who they're around. This is because they value empathy and want to be receptive. They can be other people’s mirrors. While they have strong opinions about other people, it can take a long time for them to understand themselves.",
        'Libras want to be admired not for their talents but for their charm. They want to be seen as the responsible figure who can balance out the turbulent elements around them. Libras feel an intense amount of guilt and shame when they are unhappy because they know that their unhappiness affects everyone around them.',
      ],
    },
    {
      subheader: "What are a Libra's weaknesses?",
      body: [
        "Libras are compassionate and empathetic people. They are willing to go out of their way to help others. They dislike conflict and avoid confrontations. These traits lead them to be manipulated by others. Libras also have a tendency to be indecisive. They are so concerned about other people's feelings that they let other people make decisions for them.",
        'Libras put a lot of weight on the opinions of others. Although they are secretly terrified of negative judgment, they have a hard time avoiding the spotlight. They need to be noticed in order to feel validated.',
        'What Libras need to discover is that positivity is its own kind of delusion. Libras are bound by the limitations of their bodies and their minds. They are contained within the boundaries of what their five senses tell them. They are able to understand the flavor of the world, but not the color. They’re not able to interpret the underlying micro-signals that others can. The dark abyss of the unknown is a terrifying place for them, which is why they have to pretend that they know everything. They project an air of omniscience. But in truth, they have no idea what they are talking about.',
      ],
    },
    {
      subheader: 'What are Libras afraid of?',
      body: [
        'Libras are afraid of being alone. A fear of being disconnected from the collective is part of their primal psychological makeup. Libras are afraid of the unknown. They’re afraid of their own secrets. They are capable of organizing their lives with such complex structure that they rarely face truly unknown situations.',
        'Libras fear that they will live their lives in a constant state of fear. They fear that fear will constantly dictate their behavior. That fear will impede their ability to be happy. That fear will prevent them from achieving their goals. Libras are afraid that this fear will accumulate into an insurmountable obstacle.',
      ],
    },
    {
      subheader: 'Why are Libras so angry?',
      body: [
        'The source of Libras’ anger is the tension between what they think life should be like and what it actually is like. They fantasize about success, influence, and power, but feel as though they have to work harder than other people to achieve it. Libras harbor secret envy. They keep an internal rolodex of their artistic and intellectual heroes, and idealize their freedom and nonchalance. Any criticism cuts deep because they are so sensitive, and can get angry when they feel that their integrity is threatened.',
        'Libras’ self-worth is pinned to validation from other people. This breeds unconscious resentment. Libras tend to compare their happiness to others, then struggle to admit how they feel more restricted and unhappy than other people.',
        'If Libras feel bad about themselves for extended periods, it all becomes too much to hold in and they start to lash out at others. This is a way of deflecting their pain onto other people in order to avoid facing the truth about themselves.',
        'Libras are very emotional, and easily hurt. Their internal world is turbulent and they work very hard to keep the balance. For all this hard work they put in they often feel that the world owes them something. Libras must learn that the world can’t give them what they need unless they know how to ask for it.',
      ],
    },
    {
      subheader: 'Do Libras get bored easily?',
      body: [
        'Libra’s problem isn’t boredom. Their problem is monotony. They despise any repetitive action. They hate being stuck in the same old patterns that other people seem to get stuck in. They crave variety and adventure. Libras can be immensely resourceful at finding ways to be challenged, amused, and inspired.',
      ],
    },
  ],
  scorpio: [
    {
      subheader: 'What is the personality of a Scorpio?',
      body: [
        "A Scorpio's personality is a chasm of infinite complexity (or at least how they project themselves). They are difficult people to get to know because they are psychological trap doors.",
        "Scorpios socialize from behind a double-sided mirror, always scanning, reading you while you can only see your own reflection. They prefer to be the people asking the questions, removing  your skin with their perceptive scalpel and taking inventory of your pulsing viscera. They subtly probe and push your pressure points so they can get the answer they're seeking. Scorpios are keenly aware of power, its flows, and their position within its matrix.",
        "Despite their ability to be popular, there’s something very lonely about Scorpios. They have a ruthless view of the world. Eat or be eaten. Their knowledge of other people’s internal structures gives them an edge over the competition. They know how to play people against each other. Every human interaction is a meeting of opposing powerful forces. A clashing of wants and needs where somebody wins and somebody loses. They are forthcoming and evasive at the same time, depending on what is situationally beneficial. They are blunt, but not without tact. They are intentional about the information they reveal. They’re not liars, they don't spread lies, but they are masters of opening windows on certain truths while concealing others behind heavy curtains.",
      ],
    },
    {
      subheader: 'What are Scorpios afraid of?',
      body: [
        'Scorpios aren’t afraid of most things other people fear. Not darkness. Not pain. Not death. They confront these truths head on.',
        'Instead of instilling fear, this acknowledgement of darkness is the whetstone that hones their appreciation for life. Scorpios teeter on the fine edge of the unknowable. Life is more bearable that way.',
        'Scorpios do fear vulnerability because they are scared of being known. Darkness can be a hiding place. Pain can be an excuse not to trust. When they open up, it gives other people power over them, and Scorpios need to be in control. Control–over themselves and others–is an illusion to make them feel secure in an otherwise uncertain world.',
      ],
    },
    {
      subheader: "What is a Scorpio's favorite color?",
      body: ['Scorpio’s favorite color is irrelevant. Everything is the same color in the dark.'],
    },
  ],
  sagittarius: [
    {
      subheader: 'What is the personality of a Sagittarius?',
      body: [
        'Sagittarians are the ultimate empiricists. They will always choose principles over feelings and will often question who they are. They move from job to job, philosophy to philosophy, belief to belief. They are explorers of the human condition and are unafraid of change. Sagittarians feel like the world is their playground. They love to explore the unknown. At their core, they want to understand how the world works.',
        'On an unbounded quest to discover the whole of the universe inside of themselves, Sagittarians are unshackled from any particular worldly attachment. They are reckless in their pursuit of what they want, and often end up doing and experiencing things that defy conceptions of the possible.',
        'Sagittarians are explorers. They are both the fearless adventurers and the jaded critics. They understand that knowledge comes in two forms: the shallow, disposable kind that comes from external sources, and the kind that comes from within. Sagittarians know that external knowledge can be easily gained while internal knowledge is exponentially deeper and more powerful.',
      ],
    },
    {
      subheader: "What are a Sagittarian's weaknesses?",
      body: [
        'A Sagittarius is both a warrior and a poet. They hold a firearm in one hand and a book in the other. They encapsulate a relentless drive for freedom. They are one of the most magnanimous and worldly signs in the zodiac. Their boundless optimism is infectious. It can be hard for them to find focus, but once they have their mind set on something they’ll stop at nothing to achieve it. Their ability to overcome insurmountable challenges is testament to their adaptable strength.',
      ],
    },
    {
      subheader: 'Why is Sagittarius so special?',
      body: [
        "Sagittarians can be overly aspirational. They want to believe so intensely that they can transcend the limitations of reality that it’s hard for them to accept life's simple joys. Sagittarians can be so focused on achieving their goals that they don’t stop to appreciate the small victories along the way. At times, their high standards make them seem dismissive and arrogant.",
        'Sagittarians want to know everything. They can become lost in their endless quest for knowledge. Questioning things is their way of forming a coherent worldview. It’s also a way of finding new ways to experience the world. Their endless curiosity can lead them to rebuild the very foundations of their beliefs. Because of their hunger for knowledge, Sagittarians are restless and can have a hard time following through.',
        'Sagittarians don’t believe in playing it safe. They are always looking for the next great breakthrough, and almost never look back. This tendency to abandon things before they even get started often leaves them feeling like they never quite get anything done.',
      ],
    },
  ],
  capricorn: [
    {
      subheader: 'Do Capricorns like attention?',
      body: [
        'Capricorns aren’t consumed by the need for attention, but are not immune to it. They simply don’t indulge in attention-seeking behavior. Capricorns don’t want attention for their looks or style or ingenuity. They don’t want to be admired, but they do want to be respected. They don’t care to be the center of attention. Instead it’s like they need to prove their own abilities to themselves. They plow through adversity with blinders on until they reach success.',
        'Capricorns are constantly competing with themselves. They do want praise, though. Praise is a simple acknowledgment of a job well done. Praise is a pat on the back. They use this as validation to compensate for general feelings of incompetence and inferiority.',
      ],
    },
    {
      subheader: 'What kind of person is a Capricorn?',
      body: [
        'Capricorns are masters of discipline. The wringing of the hands, the constant reminders, the exacting structure, the ever-increasing goals, the tidal wave of self-criticism that lasts forever. They are the ultimate perfectionists. They can be so absorbed in their own internal monologue that it becomes impossible to get them to look away from themselves. Capricorns are often called “workaholics.”',
        'They are incredibly pragmatic. Capricorns are rule followers with a highly developed moral compass. From a young age, it is impressed upon them that their whole sense of worth and meaning is based on their ability to hunker down and force their way to the finish line. Their drive to succeed is a reflection of their fear of failure. The most stressful time in a Capricorn’s life is when they question their own authority. When a Capricorn is in a position of power, they are most stable. When they’re put in a position where they have to cater to someone else’s agenda, they can become a little unhinged.',
        'Capricorns collect responsibility. And they always seem to take it all on. Capricorns need to be the one to fix everything. Responsibility is their natural state. They have a “can do” attitude. Capricorns are both the martyrs and the champions. They are the guardians and the judges. The team captains. The chief of chiefs. The general. The leader. But even as they lead the charge, Capricorns can feel like the loneliest people in the world. They wish to be completely self-sufficient, because they are so scared of depending on others.',
      ],
    },
  ],
  aquarius: [
    {
      subheader: 'What kind of person is an Aquarius?',
      body: [
        'Aquarians are archetypal outcasts. This doesn’t mean they’re loners. In fact, they thrive in large groups—charming you with their peculiar senses of humor, intriguing you with fun facts about the history of disposable straws, or convincing you to join their reading groups. The alienation they feel is often self-imposed—a result of their knee-jerk contrarianism, rather than a lack of social intelligence. They try to be weird. Aquarians hang grapefruit rinds from the wall and call it art, they pretend to actually like noise music, they saturate their internal monologues with SAT words.',
        "Intelligence, to them, means the ability to formulate the most unpopular opinion possible. If they were white sheep, they’d dye their wool black just to prove a point. They are destined to live their lives in direct opposition to the current world. In fact, they are world-builders. An entire universe exists in their heads, and this universe adheres to its own set of logic that doesn’t map onto reality. They aren't anarchists. They are utopians. They are not cold. They are rational. To them, emotions are just holes in their idealistic vessels. Why succumb to sensitivity when there is so much knowledge to acquire, so many problems to be solved, worlds to invent?",
        'They sometimes ask, “Why can’t I be normal?” but in reality, they find normalcy boring. They want to be unknowable. Aquarians want to evade definition. Definitions are binding little rules that other people use to restrict their movement, and they must resist anything that infringes on their freedom to drift. Between definitions, between the individual and the common, between themselves and humanity. The ultimate Aquarian struggle is resolving the tension between their need for community, and their need for complete detachment.',
        'Aquarians are always running little tests. They live their lives as one big science experiment, using the element of surprise to collect reactions and construct theories about the intricacies of human nature. They are walking abstract art pieces, shattering traditional perspectives with their complete disregard for convention. They are martyrs to the cause of humanity. Freedom is their only demand.',
      ],
    },
  ],
  pisces: [
    {
      subheader: 'Is Pisces a good sign?',
      body: [
        "If you're asking this question, you’re probably a Pisces who is insecure. If you don’t feel valued, it’s not because being a Pisces is bad, but because society as a whole generally undervalues soft skills like intuition and sensitivity. Your challenge is to start viewing these things as talents instead of impediments.",
      ],
    },
    {
      subheader: 'What is the personality of a Pisces?',
      body: [
        'Describing a Pisces’ personality can be difficult because Pisces tend to evade distinction. Their behavior changes significantly based on who they’re around. Pisces are just permeable membranes that pensively let things flow through them. They are cerebral sea sponges. They are boundless, diluting themselves with larger personalities to avoid having to form coherent identities.',
        'Most of the qualities usually associated with Pisces (dreaminess, emotionality, imagination) are internal processes that are difficult to observe from the outside. Pisces are primarily inward-facing. They are not self-absorbed, but they are absorbed in themselves.',
        'Ultimately, a Pisces wants to dissolve. To wrest themselves of a physical form and diffuse into everything they touch as love. To exist in the gossamer liminal layer that hangs just barely above the material. To live their life like a romantic poem. To understand that reality and fantasy are neighbors on the same infinite plane.',
      ],
    },
    {
      subheader: 'What are Pisces weaknesses?',
      body: [
        "The true Pisces weakness is that they’re often the cause of their own turmoil. They’re a little bit addicted to melancholy. Pisces want to feel things intensely. Pisces find sorrow's poignance pleasurable. They would rather marinate in a voluptuous pit of despair than have no feelings at all. The search for heart-rending emotional experiences sometimes causes Pisces to seek out situations that are subconsciously self-defeating.",
      ],
    },
  ],
};

export const romance: { [Key in Sign]: CopyItem[] } = {
  aries: [
    {
      subheader: 'How to seduce an Aries',
      body: [
        "Dress in all red so they're sure to notice you.",
        'Loudly announce that you wish you had a lover with whom to share your passion.',
        'Ask if they know where you could get some.',
      ],
    },
    {
      subheader: 'Does Aries fall in love quickly?',
      body: [
        'Aries do fall in love relatively quickly. They have a lot of energy, and they don’t want to waste too much time on trivial matters. While they enjoy taking the time to get to know a person, they may not do so for an extended period of time. Aries usually are immediately aware of whether or not they are in love with someone. The decision to be in a relationship doesn’t take long for them. An Aries will have strong feelings about whether or not the relationship is working, and will be able to break things off without much difficulty.',
      ],
    },
    {
      subheader: 'Who are Aries attracted to?',
      body: [
        'Aries are attracted to people who can keep up with them. For Aries, emotions are fleeting and they like being around people who embrace that. They are drawn to physicality, directness, and warmth. Being upfront about your intentions and desires will put an Aries at ease—it conveys that it’s safe for them to be themselves around you.',
      ],
    },
    {
      subheader: 'How do you love an Aries?',
      body: [
        "Aries are incapable of hiding their emotions, and they demonstrate them physically. On their good days, Aries are incredibly loving. When they’re in a bad mood, they’re disastrous forces of nature. This is because they shift between extremes. They’ll push you away when they’re upset or feeling vulnerable, and then they’ll come running back when they start missing you. To love an Aries, you need an incredible amount of strength because you'll have to become a resolute pillar amid a roiling sea.",
        'Aries can sometimes get carried away and end up hurting people they love. They need to learn how to compromise and that strength doesn’t come from force. You may need to help them see that their first impulses are not always the right ones.',
      ],
    },
    {
      subheader: "Who is an Aries' soulmate?",
      body: [
        "Aries' soulmate is a fierce, flirtatious, and emotionally forthright person. They enjoy being captivating, and they’re also allured by the idea of being captured. Aries seeks out lovers who share their appetite for risk.",
        'They have an effervescent personality, and their moods tend to be mercurial. They can be very aggressive in love. They want someone who can stand their ground.',
        'Aries are emotionally direct. They rarely sugarcoat their feelings. They want someone who doesn’t startle or swoon at their bluntness.',
        'Aries tend to be non-conformist, impulsive, and actively seek out the things that scare them. They’re not really into tradition. Due to their eccentric nature, they don’t really believe in monogamy. They’d rather play the field. But, they are also prone to falling so hard that they forget they’re playing at all. Aries want to be impressed. They want a lover who can write their own romantic epic.',
        'If you’re able to handle their trademark volatility, you’ll find that they’re fiercely loyal and emotionally generous. An Aries is the kind of person who will ride into your life and gently hand you a rose in the middle of an emotional storm. They’re the kind of person who likes to leave things better than they found them.',
      ],
    },
  ],
  taurus: [
    {
      subheader: 'How to seduce a Taurus',
      body: [
        'Invite them to a picnic in a secluded grove. Make sure the spread is substantial and the wine is high quality.',
        'Say, “I’m just looking for someone to settle down with.”',
        'Move in with each other after two weeks of dating.',
      ],
    },
    {
      subheader: 'Do Tauruses fall in love fast?',
      body: [
        'Tauruses aren’t careless with their affection. They fall in love one step at a time. They have very strong feelings. When they do fall in love, things get serious relatively quickly and last for a long time. They usually know their own minds and are more likely to fall in love because it feels right, not because of an arbitrary “they’re cute” reason. Their love life is rooted in a foundation of appreciation and reverence. Tauruses are very aware of the people they love, and they very much enjoy reflecting on them. They don’t say “I love you” often, because they would rather show you. It is not easy for Tauruses to leave someone they love. They won’t leave easily and instead try to work things out the best they can.',
      ],
    },
    {
      subheader: 'Do Tauruses hide their feelings?',
      body: [
        'Taureans are not particularly expressive, but they don’t hide their feelings. They show their love physically, they’re less able to express things with their words. Taurus sometimes have difficulty expressing their emotions, so they project a harsh exterior to cover up their sensitivity. They may seem to be insensitive to the point of cruelty. If they don’t value themselves, they don’t value others.',
      ],
    },
    {
      subheader: 'Who is a Taurus attracted to?',
      body: [
        'Tauruses are attracted to practical people who make them feel safe and comfortable. People that can cater to the needs that a Taurus may have. A person’s values must align with the values of Taurus. They seek out people that value and appreciate simple pleasures.',
        'Tauruses are attracted to people who are dependable and strong. They will likely be turned off by a person who is constantly flitting around the room or who seems out of control. They are also attracted to people who are self-sufficient and capable of being relaxed. People with a high degree of physical attractiveness are desirable to them. They can easily be turned off by people who aren’t as well dressed as they are or who may seem like they don’t care what they look like. They are often attracted to people who cook, and appreciate a home-cooked dinner.',
        'Tauruses are very loyal, but they also expect loyalty back. They are only willing to invest in relationships that have a firm foundation. They don’t do casual or frivolous. Tauruses don’t tend to be people pleasers because they have a lot of pride. They tend to believe that they are enough without validation from others.',
      ],
    },
  ],
  gemini: [
    {
      subheader: 'How to seduce a Gemini',
      body: [
        'Leave a note in their jacket pocket that says “I’m into you.” Don’t sign it; they’ll figure it out.',
        'Write your own horoscope with special references to them.',
        'Make a playlist for each of their moods titled “Happy,” “Jubilant,” “Interested,” and “Bored.”',
      ],
    },
    {
      subheader: 'Do Geminis fall in love fast?',
      body: [
        'For the most part, Geminis fall in love fast. They are captivated by the glittering reflections of themselves that they see in other people. They are naturally curious about people who don’t quite fit into a tidy world of black and white. Geminis are driven by a fundamental curiosity about who other people are, and who they might become around them.',
        'Geminis may be easily seduced, but they are also flighty and unpredictable. They can be equally quick to fall out of love. Sometimes they’re so quick to abandon ship that they end up having multiple relationships in a short period of time, usually with very different people. Because Geminis are easily bored, they often start relationships with the intention of having fun, rather than actually wanting to get involved with someone.',
      ],
    },
    {
      subheader: 'How do Geminis break up?',
      body: [
        'Gemini breakups are messy. It’s really hard for them to even admit that they’re in a relationship in the first place. They have a lot of trouble making emotional commitments because their sense of self is easily dissolved. Because of their dual nature, Gemini are incredibly indecisive about even simple problems. They vacillate. They hedge. And they generally just avoid making decisions. This is why Geminis are notorious for not being able to end their relationships. It’s just too hard.',
        'When Geminis do decide to call things off, they won’t do it directly. Instead, they’ll get their partner to confront them first. They’ll give their partner a reason to break up with them. Then they’ll act like a victim who had no control over the situation. It will be as if they were passive observers, rather than active participants.',
      ],
    },
    {
      subheader: "Who is a Gemini's soulmate?",
      body: [
        "A Gemini's soulmate is someone who can help them sort through the chaos of their feelings. They need someone who can help them see the more stable side to their personality. Someone who can help them make sense of their life.",
        'Geminis want to be accepted. They want to be understood. They’re looking for someone who can appreciate their duality and can understand the duality of their shared experience. They’re looking for a partner who can appreciate the complexity of their thoughts and actions.',
        'A Gemini’s soulmate will stimulate their mind and challenge them intellectually. Their partner must be able to keep up with them and be able to communicate on an abstract or physical level without getting too emotional. A Gemini’s soulmate is someone who loves to break away from the mundane and follow their instincts. At the end of the day, a Gemini needs someone who can keep up with their ever-shifting moods.',
      ],
    },
    {
      subheader: 'What does it mean when Geminis say they love you?',
      body: [
        'When a Gemini says "I love you" they are saying that the moment is perfect. The words are an attempt to capture the moment, to preserve it in amber, to hang it from the rafters of their mind so they can return to it over and over again. They\'re not saying that they will love you forever, but in this moment they do.',
        'A Gemini’s love has to feel new. They’ll always have the spark of fascination in their eyes when they look at their partner. They are always discovering new things about themselves and their partner. There is no stagnant love with Geminis.',
        'If you fall in love with a Gemini, you are not falling in love with a single person. You are falling in love with the idea of love and the thrill of romance. A love that exists in the ephemeral moment before the Gemini changes direction. And ultimately, you are falling in love with yourself.',
      ],
    },
  ],
  cancer: [
    {
      subheader: 'How to seduce a Cancer',
      body: [
        'Grab their hand while you’re walking together down the street. Make sure to clutch it gently but firmly like you’re holding on to a baby bird.',
        'Take them to the home you grew up in. Play sad music. While you lay on the bed together, look at photos of your family members and talk about your childhoods.',
        'Bake them a cake that says “I need you” in mauve colored frosting.',
      ],
    },
    {
      subheader: 'Do Cancers fall in love easily?',
      body: [
        'Cancers do fall in love easily. They may appear shy and reserved, but underneath this shield is a romantic who suffers from extreme shyness a bad case of the nerves. They don’t fall in love with a person, but with what they’re hoping to find. Cancers are looking for a complete and unconditional acceptance because they feel so unfinished themselves. They are in love with the idea of being in love. This is why they often find themselves incapable of falling in love with the people who actually love them back.',
        'They want to be loved, but they don’t trust that it will last. They want to be touched, but don’t trust the intentions of the person touching them. They want to be protected, but they don’t trust that anyone will protect them. Once they fall in love with an actual person, they will carry those romantic feelings for their entire life. A Cancer is someone whose entire body becomes a vessel for the emotion.',
      ],
    },
    {
      subheader: 'Do Cancers flirt?',
      body: [
        'Yes. But more importantly, when a Cancer flirts, they always seems to have a hidden agenda. Cancers are terrible at keeping their romantic secrets. They reveal themselves readily and allow the other person to see behind their masks. They often do this without realizing it. It’s not that they hold nothing back, but rather that they unconsciously impose their moods and feelings on the other person. It’s not uncommon for Cancers to suddenly realize they have told someone about their family history or their feelings on a recent tragedy before even realizing what they’re doing.',
      ],
    },
    {
      subheader: 'Do Cancers go back to their exes?',
      body: [
        'Cancers are known to go back to their exes. Their desire for affection can often lead them to look back at their romantic pasts and linger fondly on the memories of the person they used to be with. They’ll find themselves reminiscing about what a relationship meant to them. They may have been betrayed or lied to, but the initial rush of love they felt for the person will always be there.',
      ],
    },
    {
      subheader: 'Do Cancers like to cuddle?',
      body: [
        "Yes–Cancers also want to be spooned, but they'll be too shy to initiate it. Because they may also feel that it’s a sign of weakness, they’ll try to push you to do it first.",
      ],
    },
    {
      subheader: 'What are Cancers like in relationships?',
      body: [
        'Cancers can be like a bird that has been caged and hand fed for so long that they begin to believe that they are not capable of surviving in the wild. They can lose the will to fly.',
        'Cancers need a love interest to define them. Their memory is a patchwork of past loves, an impressionist painting of their relationships. Cancers use love as a building block to construct a more concrete sense of self, as their identity is sculpted by people who have made them feel a certain way.',
        'Their role in a relationship is not to dictate its development, but to react to someone else’s lead. They are shaped by their romantic relationships as much as they shape them. Cancers are living, breathing collages of the people who have made them feel special. With each new partner, they become a little more whole.',
        'When a Cancer is in love, they ignore anything negative about the relationship. Because they trust too easily and want to believe the best about everyone, they have a hard time understanding why something feels wrong. They love “nice people.” They can’t understand why everyone isn’t nice. They want to be supportive of everyone.',
        'Cancers need to learn to trust their own instincts and intuition. They need to learn how to survive on their own because being too dependent on someone else will only postpone their healing process.',
      ],
    },
  ],
  leo: [
    {
      subheader: 'How to seduce a Leo',
      body: [
        'Scroll back on their social media and “accidentally” like one of their first posts. This will immediately get them thinking about you.',
        'Commission a walk-of-fame plaque and install it outside their front door.',
        'Put in reflective contacts so they can see their own reflection while they stare into your eyes.',
      ],
    },
    {
      subheader: 'What are Leos attracted to?',
      body: [
        "Leos are naturally energetic and passionate. They often fall for partners that are equally enthusiastic. The qualities they're most attracted to in others are the same qualities they see in themselves. They like partners who are confident, proactive, and will go after what they want without apology.",
        'Leos tend to be attracted to signs of obvious strength. They want someone who isn’t just confident, but sophisticated. Their ideal partners are people who can not only formulate an intelligent argument, but can also present it in a smoothly and articulately.',
        'While Leos love to be the center of attention, they are also secretly self-loathing. All their charm is a defense mechanism that is designed to compensate for their deep-seated insecurity. Because of this, Leos tend to have an affinity for people who are more spontaneous and carefree than they are. They like a little bit of chaos in their lives to counteract the order that they impose on themselves.',
      ],
    },
    {
      subheader: "Who is a Leo's soulmate?",
      body: [
        "Leos desire someone who can feed them both physically and emotionally. They want to be worshipped, to feel like the focus of your attention. Leos need to know you’d do absolutely anything for them—and that you’d do it not out of obligation, but by choice. They love to feel like they’re in a position of power, but they don't want to have to exercise it by force. To them, their natural charisma should be enough. They are fiercely independent, and they love to get their way, so they tend to need partners who are a little submissive—or at least who are willing to take on that role in the relationship.",
        'At the same time, Leos need someone who is quietly and secretly powerful in their own right, someone who can help them realize their ambitions. Someone who can help them make a name for themselves.',
        'They need to be with someone who can get under their skin and occupy their imagination. Someone who can keep up with their endless emotional hunger. Someone who can help them feel invincible.',
      ],
    },
    {
      subheader: 'Are Leos good kissers?',
      body: [
        'Leos love a challenge. They have an almost inherent need to compete and dominate. Leos kiss like they’re competing against someone else for your affection. It’s like they imagine that, somewhere in the world, there’s someone you love more than you love them, and they’re going to prove that their kiss is better by kissing you more passionately than this imaginary person possibly could.',
        'Leos kiss like the only things that matter in the world are you and them and the way that their lips feel against yours. To be kissed like a Leo kiss is to be kissed like the whole world is watching. To be kissed by a Leo is to be kissed like there’s no other kiss but your kiss.',
      ],
    },
  ],
  virgo: [
    {
      subheader: 'How to seduce a Virgo',
      body: [
        'Leave your phone some place obvious, and exclaim loudly that you’ve misplaced it.',
        'While they help you locate your phone, casually mention your passion for getting into a bed with warm sheets fresh from the laundry.',
        'When they invite you over, compliment them on their taste in mid-century modern furniture, and the particular arrangement they’ve chosen.',
      ],
    },
    {
      subheader: 'Are Virgos pretty?',
      body: [
        "There’s something attractive about someone who is so orderly and fixed. Virgos have very defined styles, not so much to reach some kind of beauty standard, but to appease their own standards. They are concerned with health and create habits and expectations around it. Virgos are very critical of themselves, which can make them  meticulous when it comes to their appearance. They like to look good. A Virgo's social media is curated, their closets are organized, their bodies are toned. Virgos are image-conscious because they know they have the power to sculpt their physical being into the self they want others to see.",
      ],
    },
    {
      subheader: 'How do Virgos fall in love?',
      body: [
        'It takes Virgos a while to fall in love. They generally don’t like to give themselves over to passion. They like to have control over their reality, and the emotional intensity mirrors the feeling of chaos.',
        'Virgos tend to be more pessimistic about love than others. They’re naturally cynical about romanticism because they know that love is a fickle, fragile, ephemeral thing. They suspect that love might not be real at all. But when a Virgo finally feels love, it’s not just love—it’s like a homecoming. It’s like they’ve finally found a place where they belong. It’s like they found a way to love themselves.',
        'The real problem is that Virgo is looking for an experience outside of reality. Something that is not attainable in the material world. They believe that the love they’re after exists on some higher plane. And they’re not wrong. It’s just that how they go about it is a little flawed.',
        "Virgos tend to look for love in the wrong place. They don't realize the love they're looking for is inside themselves. Virgos need to make themselves the center of their universe, then they will be able to understand that they are worthy of love.",
      ],
    },
    {
      subheader: 'Who are Virgos attracted to?',
      body: [
        'Despite their self-professed perfectionism, Virgos can be attracted to a wide variety of people. Virgos are not superficial. They can find the beauty in things that most people would dismiss as unappealing.',
        "Initially, a Virgo might be drawn to someone because they are trying to fulfill some need in themselves. Virgos have a tendency to be attracted to people who are slightly unstable. It’s mostly because they want to put someone else back together. Of course, they always feel they have the capacity to tap into another person's deepest emotional truths. This belief often gets them in situations where they get taken advantage of, or feel like they have to be someone’s only savior. At worst, their relationships can start to feel transactional.",
      ],
    },
    {
      subheader: "Why are Virgos' expectations so high?",
      body: [
        "Virgos are afflicted with an absurd amount of expectation. Their love lives are often tumultuous because they tend to be overly critical of other people. It's not that they're trying to be mean, they just feel obligated to point out the flaws in others.",
        'When they find someone that they like, Virgos start imagining how perfect their love could be and as time goes on, they begin to conflate the potential of the relationship with the actual relationship. Virgos tend to be much more interested in things and ideas than they are people and feelings. As a result, they can get easily bored and easily distracted. ',
      ],
    },
  ],
  libra: [
    {
      subheader: 'How to seduce a Libra',
      body: [
        'Whenever they say something in conversation, respond with, “Oh my God, me too!”',
        'Spend three days together without any alone time.',
        'Ask them to be your muse.',
      ],
    },
    {
      subheader: 'Why are Libras so beautiful?',
      body: [
        'Libras care about beauty. They know how they feel is directly related to their appearance. Because of this, Libras dress in ways that highlights not only their best physical features, but also their moods.',
        'Libras are so attracted to the idea of beauty that they even find beauty in other people’s flaws. They believe everyone is capable of being beautiful and that anything that convinces them otherwise is just an illusion.',
      ],
    },
    {
      subheader: "Who is a Libra's soulmate?",
      body: [
        "Libras are most identifiable by the contrast between their exteriors and interiors. Their exteriors are sophisticated and composed, while their interiors are insecure and a constant state of flux. A Libra's soulmate is someone who can bridge this dichotomy.",
        "A Libra's soulmate is someone who is agreeable without being a doormat. Libras need someone who can be there for them in their most disorienting moments. Their soulmate should be a lighthouse to guide them out of their own fog.",
        'A Libra soulmate is someone who understands that Libra has a darkness in them, but that darkness is a natural part of them. It’s not something to be ashamed of. A Libra soulmate is someone who encourages them to keep being themselves. Someone who helps them see that they’re in control of themselves',
      ],
    },
  ],
  scorpio: [
    {
      subheader: 'How to seduce a Scorpio',
      body: [
        'Wear an outfit that makes you look innocent. Pair it with slightly smudged eyeliner so they can tell you’re a little unhinged under the surface.',
        'Dim the lights. Put on a mournful Joni Mitchell song and ask them very seriously what their deepest fear is. Suggest that this has something to do with their childhood.',
        'If they are quick to tell you that they love you, it’s a test. Don’t say it back until you’re sure you both mean it.',
      ],
    },
    {
      subheader: 'Do Scorpios fall in love quickly?',
      body: [
        "Scorpio's ability to be direct and establish immediate intimacy causes people to fall for them quickly, but it takes them a while to open themselves up to someone enough to really fall in love. For Scorpios, love is the capacity for pain. Love also means partially relinquishing some self-control and this is especially hard for a Scorpio.",
        'When they do let someone in, they fall in love completely. They can be fiercely possessive. Scorpios want to learn everything about you. They want to be around you as much as possible and can get attached scarily fast.',
      ],
    },
    {
      subheader: 'How do Scorpios flirt?',
      body: [
        'Scorpios’ flirtatiousness is extreme enough to be considered fatal. It’s like watching two deadly snakes entwine. A volcano erupting.',
        'Their uninhibited flirtatiousness is not about making friends. They flirt with others to take control of the situation and assert dominance. Scorpios are the type to whisper in your ear “I see you looking” if they catch you staring and then smile wickedly.',
        "Scorpios don’t make small talk. They stare into your eyes and say, “I know you’re feeling something.” Scorpios don’t need to say “I like you” to get you to like them. They don't have to play nice–they say exactly what they mean exactly how they mean it.",
      ],
    },
    {
      subheader: 'What are Scorpios attracted to?',
      body: [
        'Scorpios are attracted to what’s under the surface. They want to be with someone who pushes their boundaries, who brings out their most intense desires and most profound fears. Intrigue. Passion. Lust. The need for completion. A darkly seductive energy. A screen for projection. An enigmatic intensity. The forbidden and the unknown.',
        'They have the ability to see past the mask of pretense to the soul of desire, and they look for people who can see and appreciate this part of them. Scorpios are into things that are real. They are into people who are not afraid to say what they mean.',
        'Scorpios are not interested in anything that doesn’t involve the risk of being completely destroyed. They want to have as many dramatic experiences as possible because the absence of friction is a kind of subtle death.',
        'But even though Scorpios are attracted to passion, they are also repelled by it. They want to desire things, but they don’t want to be ruled by their desires. This is why they will sometimes date people who are the exact opposite of what they claim to want.',
      ],
    },
    {
      subheader: 'How do you know a Scorpio likes you?',
      body: [
        'Scorpios are silent assassins. They don’t immediately make their feelings known. First, they give you a venomous, penetrating look. Scorpios want to feel you out. They will try to test your character. Once they’ve determined that you’re trustworthy, they’ll give you a second look, this time with slightly dilated pupils.',
        'A Scorpio will pursue you with the same intensity they use to pursue their enemies. They will uncover your devious secrets, and they will expose you. They will impress you with their insight and wit. Scorpios seduce with magnetism. They will tell you the most elaborate fantasies about your future together.',
      ],
    },
  ],
  sagittarius: [
    {
      subheader: 'How to seduce a Sagittarius',
      body: [
        'Point at the book they’re reading and claim that you hate it. Cite a bold but generic reason.',
        'Share with them your personal philosophy: that the world would be a much better place if everyone learned to laugh a little more and cry a little less.',
        'After your first night together, ask them to elope.',
      ],
    },
    {
      subheader: 'Do Sagittarians fall in love quickly?',
      body: [
        'Sagittarians are incredibly open, which leads them to fall in love quickly. They don’t need much time to decide if they want you. Sagittarians don’t see the point of caution because they feel they’ve already run the calculations and determined that the potential for reward outweighs the risk.',
        'Love, like all emotions, are fleeting to Sagittarians. They don’t need to hold on to it or possess love to validate its existence. They are attracted to novelty and love the idea that they can move on quickly. If they don’t fall in love at first sight, it usually doesn’t take too long.',
        'Sagittarians will often fall in love with the idea of a person. Because they’re also very independent and prone to wandering, they get bored easily. Their sights are always set on the next high, thrill, or adventure.',
      ],
    },
    {
      subheader: 'What do Sagittarians find attractive?',
      body: [
        'Sagittarians are looking for someone to have a good time with. They want to be able to experience the world without having to worry about the consequences of their actions or getting tied down. They need someone who doesn’t take life too seriously.',
        "To Sagittarians, brains are the hottest part of the body. Because they're ruled by the planet of wisdom, they appreciate people who have an intellectual edge, a way with words, and a sharp sense of humor. Sagittarians are attracted to the kind of person who inspires them to think bigger thoughts, to open themselves up to the infinite. They feel the most alive when they are around people who bring out the philosopher in them.",
      ],
    },
    {
      subheader: 'Why are Sagittarians single?',
      body: [
        "Sagittarians aren't single because they are searching for a soul mate. Instead, they’re trying to figure out what love is. They are students of love and have a deep admiration for it. Sagittarians think about love more than any other sign.",
        'They are too busy composing the symphony that is their life. Every experience, adventure, and person that Sagittarians meet along the way is a movement in the symphony. Every potential partner they meet is a potential instrument. They are not in need of one special person to complete them.',
        'Sagittarians are scared of surrendering their freedom and being at the mercy of another person. They are afraid of being vulnerable and trusting. This is why Sagittarius are always on the move. They need new horizons. Sagittarians feel that they need to be swimming the river of life rather than resting on the shore. They want constant change, constant action, constant movement.',
      ],
    },
  ],
  capricorn: [
    {
      subheader: 'How to seduce a Capricorn',
      body: [
        'It takes a Capricorn a while to develop a crush. You’ll probably have to become their best friend and drop subtle hints slowly over the years until one day they realize that they were in love with you all along.',
        'Then they will marry you.',
      ],
    },
    {
      subheader: 'Are Capricorns good in bed?',
      body: [
        'Capricorns try hard to be good at whatever they do. This can be dispassionate, maybe mechanical. They may view sex as a math equation. Slightly disconnected from carnal undercurrents, but generally responsive to criticism.',
        'Sex and romance are seen as a competition. Sex is not about feelings, but mastering a skill. In the game of love, the winner is determined by the ability of one person to fulfill the other person’s fantasy. Capricorns can be sophisticated lovers because they have a good sense of humor. They know how to be gentle and nurturing when the situation calls for it. Because of their singlemindedness, they are slow to lose interest and can be a little too intense at times.',
      ],
    },
    {
      subheader: 'Do Capricorns fall in love easily?',
      body: [
        'Capricorns don’t fall in love easily. It often takes some effort. Love is about making a strategic emotional investment. They don’t like feeling emotionally dependent on anyone else so they often keep their distance. They can be awkward when it comes to romance. Capricorns can be awkward around expressing their feelings. It takes them a while to understand what their feelings are. Their romantic exploits are almost platonic partnerships.',
        'They might not express their affections overtly, but Capricorns are very sentimental. They value family, tradition, and stability. They want to build something real.',
        'Capricorns want to be responsible for something, and for them, responsibility to someone else is the most intimate act of all. Love is the end result of this deep desire. It is a way to make a commitment.',
        "Their emotional maturity can be seen in their ability to harness their baser feelings into something constructive. Capricorns' disciplined natures are a necessity in the face of raw, animalistic sexual desire. To know a Capricorn is to know that they’re grounded, but that does not mean they aren’t sensitive to the warmth of another person’s touch. It just means that they can control it.",
      ],
    },
    {
      subheader: 'What do Capricorns find attractive?',
      body: [
        'Capricorns do not want your mess. They admire other people who are self-sufficient, who inspire them in some way. Capricorns are motivated by respect. They are drawn to people who give them the sense that they know something, or who have an exceptional understanding of some arcane corner of the world. They will be impressed if you can speak to technical details or philosophical matters in depth.',
        'Capricorns want to be the one to take the lead, because they have a desire to control and command a situation. They want to direct the scene. They aren’t attracted to people who’re a little bit on the loose side. They’re much more interested in people who are guarded, not overly emotional or dramatic. People who are more even-keeled. Capricorns are attracted to people who share the same values as them, or at least respect those values.',
      ],
    },
  ],
  aquarius: [
    {
      subheader: 'How to seduce an Aquarius',
      body: [
        'Walk around with a blasé air, as if the world is just happening around you without your permission. Maybe get a weird haircut.',
        'Send them a link to a Gregorian chant. Tell them that this song reminds you of them.',
        'Stay up until 6:00 AM talking about “the system” and your shared hatred of it, without ever getting specific about what you actually mean.',
      ],
    },
    {
      subheader: 'Are Aquarians hard to date?',
      body: [
        'Aquarians are lofty philosophers evaluating the dating pool from their perch on a mountain top. Everything they know about love they’ve learned from the many books they’ve read. Love to them is just a practical theory. A philosophical musing. A meshing of minds. No head rush. No warm blood needed. It’s a science. It’s chemistry. It’s a psychological phenomenon.',
      ],
    },
    {
      subheader: 'How do Aquarians fall in love?',
      body: [
        'Aquarians are attracted to intelligence. Inspiration is a romantic spark for them. Sharing the same sense of humor is the same thing as a connection. They aren’t necessarily attracted to people who share their interests, but they are drawn to people with niche pursuits that they don’t fully understand.',
        'Aquarians are obsessed with figuring people out. They want to know about you. Not your feelings or your childhood. Not your psychological and emotional makeup, but the facts you know, the interesting experiences you’ve had, the opinions you’ve developed. They use this information to depict you as an idealized caricature that they can hang in the heart-shaped museum of all their past and present relationships.',
        'Aquarians want to be accepted for their eccentricities, but find it difficult to accept when their partners don’t match their idealized portraits. What Aquarians fear most is being confronted by a real person with normal faults and failures. They know that when people get close, they form expectations. Expectations that threaten their independence make them feel resentful. They grow cold and distant, then wonder why their partners start acting clingy. Aquarians don’t see their unwillingness to compromise as selfishness. When they turn inward, they don’t see a self at all, they only see the truth.',
      ],
    },
    {
      subheader: 'How do Aquarians flirt?',
      body: [
        'Aquarians are clever. They don’t present themselves as very flirtatious—in that sense, they can be a little elusive. They don’t like small talk. It may seem like they are on the verge of kissing you, and then they’ll just start talking about some new economic policy they read about. In their nervousness, they sometimes get so excited talking about themselves that they forget to ask you questions or otherwise show you that they’re interested. Aquarians often use humor as a crutch. They’ll start playing some nonsensical game to make you laugh. If you’re interested in an Aquarius, you may need to do most of the work in driving things forward. Don’t take them too seriously, but make them feel understood and comfortable enough to slow down and be present.',
      ],
    },
  ],
  pisces: [
    {
      subheader: 'How to seduce a Pisces',
      body: [
        'Bring a copy of a classic romantic novel to a park that they frequent. Wear a little musk or patchouli. Put a wildflower behind your ear. When you notice them looking, meet their gaze. Stare deeply, but not creepily.',
        'During your first conversation, tell them about your most recent heartbreak. Make sure to slip in that you’ve “never been able to talk to anyone like this before.”',
        'Confess your feelings in a handwritten letter. But first, type what you want to say into Google translate. Translate it into French and then back into English so it sounds more poetic.',
      ],
    },
    {
      subheader: 'Who is attracted to Pisces?',
      body: [
        'Pisces have an elusive charm that can be alluring to some and frustrating to others. Their boundlessness causes them to attract other people with bad boundaries who take advantage of their compassion. Pisces need to learn that they are more than just a mop for other people’s emotional messes.',
      ],
    },
    {
      subheader: "Who is a Pisces's soulmate?",
      body: [
        "There is a tension between what Pisces need in a partner and what they want. They want a love that overpowers them. Pisces need to know that it’s okay to be sculpted by love, but that there has to be something definite. Some hard wire underneath all the malleability. Love is not a complete absence of a self, or a full dissolution into someone else. The right partner already knows this and encourages Pisces to build rudimentary walls. A Pisces soulmate is someone who's rooted in reality while still being susceptible to romance.",
      ],
    },
  ],
};

export const friendship: { [Key in Sign]: CopyItem[] } = {
  aries: [
    {
      subheader: 'Are Aries loyal?',
      body: [
        'Aries are loyal. But when Aries feel that they are being disrespected or not treated in a way that they consider fair, they can lose respect for the person who they feel has wronged them.',
        'Aries will love you unconditionally, but not necessarily stay with you. They can be incredibly loyal, but in the same breath, they can be incredibly fickle. Aries are driven by passion, and that passion doesn’t always last.',
      ],
    },
    {
      subheader: 'What upsets an Aries?',
      body: [
        'Aries believe that they’re the big powerhouse behind the wheel of their life. When they’re forced to take a backseat, they get really upset. It’s not that they need to be in charge, they just don’t trust anybody else’s ability to steer their life.',
        'Aries are very insecure about their ability to control their destiny. Even though they lead with confidence, they’re actually constantly searching for reassurance. There are a lot of insecurities that lurk beneath the surface of their  bravado.',
        "If they think you’re a better driver, they don’t have a problem with you taking the wheel, as long as you do it confidently. If they think you don’t know what you’re doing, they’ll step in to take control. Aries just need to be occasionally reminded they're a leader, not a tyrant.",
        'If you want to make an Aries mad, try to steal their power.',
      ],
    },
  ],
  taurus: [
    {
      subheader: 'How bad is the temper of a Taurus?',
      body: [
        "Taurus don’t have temper tantrums, but they are constantly on a low simmer. When they do boil over, they make a lot of noise and can leave destruction in their wake. Tauruses have a tendency to let all of their anger, resentment, and bitterness fester and periodically explode into devastating outbursts. A Taurus’ temper is as good as their mood. When they’re in a good mood they will deal with most situations with a measure of patience and calm that can be surprising to people who don’t know them well. But when they’re in a bad mood, they will be a little bit snarly. They have a low tolerance for passive aggression. If someone is wasting their time, they aren't afraid to tell them.",
      ],
    },
    {
      subheader: "Who is a Taurus' best friend?",
      body: [
        'Tauruses are friends with everyone. They’re actually not very selective about who they make friends with. If someone is nice to them, that person becomes their friend. They have a tendency to come together with people who share their interests and temperaments. Tauruses don’t necessarily form cliques, but they have an innate understanding of who they’ll be compatible with. If they’re not sure about a new person, they’ll take the time and space to figure out if that person is worthy of their time. Tauruses are very slow to judge.',
      ],
    },
  ],
  gemini: [
    {
      subheader: 'What are Geminis like as friends?',
      body: [
        'Geminis make friends wherever they go. They have the ability to talk to almost anyone about every little thing that’s on their minds. They may show up to your party an hour late, but will end up staying for hours.',
        'Geminis can be very entertaining to be around. Their way with words can hypnotize you into momentary bliss. Seek them out when you’re looking to have the kinds of experiences to write novels about, or if you’re just looking to live vicariously through stories of their own novel-worthy experiences.',
        'They’re not always the most reliable. Deep emotional engagement can make them restless and impatient. Go to them for the unsparing, unvarnished truth. They’re great with comebacks, which is extremely important in the ruthless reality of friendship.',
      ],
    },
    {
      subheader: 'How do Geminis fight?',
      body: [
        'Geminis fight like they talk—with a stream-of-consciousness barrage of arguments and witticisms. They are masters of the ad hominem and will use your own words against you. They’ll make a point, contradict themselves, make another, contradict themselves again, and then somehow end up talking about your parents.',
        'Geminis are defenders of discord. Anything that stifles the free flow of information is seen as an enemy and worthy of destruction. Geminis are the first to start a fight and the last to back down. They will relentlessly attack until the other party is incapable of fighting back, even if it means self-deprecation. Geminis are merciless.',
        'Deep down Geminis don’t even really want to fight. They would rather find some obscure way to cooperate with you, even if it’s just pretending to be mad at you, so that they feel justified in dropping the subject. They tend to be pretty passive aggressive. Geminis will let you know how they feel about you in a thousand different subtle ways, but they will never just walk up to you and tell you how you really made them feel.',
      ],
    },
    {
      subheader: 'How dangerous are Geminis?',
      body: [
        'Geminis are dangerous because they seem to exist in a perpetual state of internal conflict. They’re like a pool of water. Still on the surface, but below, the water is churning with turbulent waves. Geminis will always be fighting a battle between their rational and irrational sides. One moment they’re speaking like an objective neurosurgeon, the next they’re like a zealous and passionate street corner preacher.',
        'Geminis are elusive. They have a knack for slipping out of any situation that makes them feel uncomfortable. They avoid facing anything that poses a challenge, because their charm is their only weapon. They’re quick thinkers and can talk themselves into or out of almost anything. Geminis can be quasi-hustlers, but mostly they’re just an observer. They’re never the main event of a situation, unless they’ve calculated the odds and decided it’s worth their while. Geminis are the type to ride a wave of someone else’s energy. They can make something out of nothing, but they can also just as easily make nothing out of something.',
      ],
    },
  ],
  cancer: [
    {
      subheader: 'Are Cancers stubborn?',
      body: [
        'Cancers are often stubborn. They are often criticized for being harsh on those who they feel have wronged them. Cancers can be slow to forgive. They hold on to past hurts, but that doesn’t mean they wield them. They’re often too self-effacing to bring them up except when they finally erupt. It would be easier if they could just forgive and forget. Cancers want their wounds to be healed, so they bury them. They’re wounded by their own attachment to memory. It is important for Cancers to remember that transformation is not only possible, but is also the natural order of life.',
      ],
    },
    {
      subheader: 'Are Cancers controlling?',
      body: [
        "Cancers' need for control doesn't come from a desire for approval from the outside world, but instead a desire to adjust their external environment to reflect their inner world.",
        'Cancers live in a twilight realm where everything is blurred. The border between themselves and others is not clearly defined, and this is mirrored by their external environment. Their home is a sanctuary that is harmonious with their mood and their possessions are a manifestation of their internal world.',
        'Cancers do not demand control out of a need for power, but rather because they feel most comfortable when they’re in charge. They have an insatiable desire to care for others, and believe they can achieve comfort by orchestrating the lives of those closest to them.',
      ],
    },
    {
      subheader: "Who is a Cancer's soulmate?",
      body: [
        'Cancers are highly sensitive and very insecure, so they need constant reassurance. This doesn’t have to come from only their lovers–it can also be a close friend or a parent. Cancers need this emotional intimacy or they suffer.',
        "Cancers thrive on the security of close relationships. Strong relationships are the foundation of their sense of well-being. They need to feel loved. A Cancer's soulmate is often someone who has a stronger personality, and who can help Cancer feel more grounded.",
      ],
    },
  ],
  leo: [
    {
      subheader: 'What are Leos like as friends?',
      body: [
        'As friends, Leos are attentive, loyal, and generous. They always feel the need to give more than they receive. As one of the most playful and luxury-loving signs, they treat you the way they treat themselves, and will spontaneously do nice things for you just because they feel like it. They’re not really into keeping track of turns or even splits.',
        'If you give a Leo a reason to feel special, they will shower you with the same level of attention, affection, and praise. Friendship means everything to them. And if you have a problem, they will drop everything and come help you.',
        'Leos are very proud and value their honor. If you hurt a Leo’s feelings, they will make you very aware of it with the silent treatment. They can be vindictive and hold onto resentment for a long time. Even if you made them mad five years ago, they’ll still remember it. And they’ll remember it the way they want to remember it. They’ll hold onto their version of the story and you just have to accept that this is their way of forgiving you.',
      ],
    },
    {
      subheader: 'Why are Leos so lonely?',
      body: [
        'To Leos, loneliness is a captive state. It binds them to the cage of their own desire. They’ve got a hole inside of them that they try to fill with love, attention, and approval. They want to be loved for who they are. They want genuine connections, but take a long time to learn how to initiate them. They’re always searching for meaning.',
        "Leos make a lot of friends, but they’re not so good at keeping them or deepening these relationships. The problem is that they're able to form superficial connections with their ample charm, but. It can be hard for them to show the vulnerability that deeper relationships require. While they’re great at communicating with the world, they’re not really that great at communicating their thoughts and feelings.",
        'To connect with people, they’d have to open up about their own emotions, and that’s something they’re not comfortable doing. What they don’t realize is that emotional expression can be the very catalyst for developing deep connections. Leos can feel the need for companionship, but they don’t know how to create a space for it to grow. They don’t understand that friendship requires constant work—and that you get what you put in. They’re the type of person who says “I’ll call you!” and then never calls because they’re always very busy.',
        'Of course, Leos aren’t doomed to a life of loneliness. They just have to learn how to be vulnerable. They have to find a way to be friends with people without seeing others as only a source of attention.',
      ],
    },
  ],
  virgo: [
    {
      subheader: "Who is a Virgo's enemy?",
      body: [
        'The first Virgo enemy is themselves. The second is waste—wasted time, wasted materials, wasted space, wasted energy. Virgos are efficiency addicts.',
      ],
    },
    {
      subheader: 'What are Virgos like as friends?',
      body: [
        "Virgos are usually very careful about who they make friends with. They're particular about the company they keep, so look for people who are compassionate and kind, but who are also serious and analytical. Virgos often enjoy being around people who are equally interested in self-improvement and intellectual inquiry.",
        'They are particularly good at giving advice. They are innate problem solvers. Virgos are very dependable as friends because they are meticulously conscientious. They think things through before acting. They take others’ emotions into consideration. A Virgo friend will call you to ask what they can do to help when they know you’re going through a tough time.',
        'Virgos can sometimes be very judgmental. This is because their internal monologue is always going. They’re on the lookout for signs of hypocrisy or injustice. On the one hand, this makes them very perceptive and good at identifying flaws in logic or character. On the other, it can make them pessimistic and cynical.',
      ],
    },
  ],
  libra: [
    {
      subheader: 'What sign does Libra hate?',
      body: ["Libra doesn't hate anyone (to their face)."],
    },
    {
      subheader: 'What is Libra like as a friend?',
      body: [
        'Libras have a reputation for being flighty, but really they tend to lack commitment. But Libra isn’t as flaky as they may seem. They just have a tough time staying focused on one thing. Because of this, Libras are very easy to get along with, but somewhat impossible to maintain a consistent relationship with. They’ll always be there to listen to your problems, but they’ll always have somewhere to be. They’ll always want to help you out, but they’ll always have someone else they need to help out, too.',
        'Libras want to please everyone and will do anything to keep the peace. Compromise is a natural reflex for them. They are also very compassionate people, and sometimes have a hard time saying no to others. This can make it hard for them to see when others are manipulative or self-serving.',
      ],
    },
  ],
  scorpio: [
    {
      subheader: 'What makes Scorpios happy?',
      body: [
        'A Scorpio’s happiness is rooted in their ability to feel powerful. They are deeply in tune with their insatiable drive for dominance. Scorpios want to be a vital organ in the big throbbing heart of the world. Once they feel essential, they can sink back into their psychic depths and become excited by the prospect of creating something sacred.',
      ],
    },
    {
      subheader: 'Are Scorpios good friends?',
      body: [
        'Scorpios are very good friends. They are extremely loyal and deeply compassionate. Scorpios place the highest value on what’s real. They tend to be very direct because they don’t have time for fake. One of their strengths is separating fact from fiction. Scorpios are incredibly self-aware, and this enables them to be accountable towards people they care about.',
        'Their ability to understand other people’s emotions allows them to sense and respond to the shifts in your world, and come to your aid before you even know you need it.',
      ],
    },
  ],
  sagittarius: [
    {
      subheader: 'Are Sagittarians loyal?',
      body: [
        'Loyalty is a concept that is built on the principle of consistency. Sagittarians are loyal, but not always to the people they claim to love. They are loyal to the idea of love. Loyal to the idea of freedom. Loyal to the idea of building a better world. They may love you, but they love the idea of you more. And if you begin to hold them back and retrain them to think like everyone else, they’ll start to resent you for it.',
      ],
    },
    {
      subheader: 'Who is the enemy of Sagittarians?',
      body: [
        'The Sagittarian enemy is structure, or anything that attempts to constrict their growth. Sagittarius never wants to slow down and really hates sitting still. Their enemy is anyone who tries to hold them back. The enemy is anyone who wants to tie them down or put them in a box.',
        'Sagittarian enemies are also the people who try to give them advice. Sagittarians are sure they don’t need to be told what to do because they know everything they need to know. The truth is that Sagittarians could learn a lot from other people, but their pride won’t let them.',
      ],
    },
  ],
  capricorn: [
    {
      subheader: 'What are Capricorns like as friends?',
      body: [
        'Capricorns are ambitious and have a “push yourself to succeed” work ethic that can often rub other people the wrong way. But they do have a tender side. They can be deeply emotional under the glossy veneer of “adulting.” Their softness gets buried in the rocky terrain of their exterior. Capricorns are taciturn by design, not by nature.',
        'They are very practical. It may seem like they don’t really care about their friends’ feelings, but they will do anything they can to help them succeed. Capricorns don’t want to be wasteful with their resources, and think about how each of their actions will affect the future.',
        'Capricorns are also very emotionally dependable. They will be there for you when you need them, and you can be certain that they won’t freak out on you. They know that being dependable is a sign of respect.',
      ],
    },
    {
      subheader: 'Are Capricorns loyal?',
      body: [
        'Capricorns are incredibly loyal friends and expect as much from other people. Capricorns have a deep need for security. They like structure and repetition. Capricorns want to know what to expect in stable, scheduled increments. They need to know that their environment is reliable.',
        'Capricorns need purpose in everything they do, so they will only dedicate themselves to people, projects, and ideas that revolve around their values.',
      ],
    },
  ],
  aquarius: [
    {
      subheader: 'Are Aquarians loyal?',
      body: [
        'Aquarians make very good acquaintances. They are friendly people, but their enigmatic nature makes them difficult to get close to. You can know an Aquarius for years and still feel like you don’t know anything about their personal lives. When you ask them about themselves, they talk about the impending environmental catastrophe, or repeat facts from the last documentary they watched.',
        'Aquarians want to be helpful, but this attentiveness is frequently directed at the people they know the least. They may borrow your sweater without asking, then pass it on to a stranger on the train who looked cold. It’s as if their duty to humanity trumps any sense of responsibility they might feel towards the people closest to them. They think it’s selfishly biased to prioritize close friendships over everyone else in the world, so they stroke their humanistic ego by projecting themselves as the selfless giver.',
        'Aquarians are personality collectors. They tend to surround themselves with a curated cast of strange characters, but never get to know them beneath their surface-level attributes. Deep connections based on shared sensibilities are not necessarily the most interesting ones. Aquarians want to learn from people who are as different from them as possible. They’re terrified of falling into the complacent herd mentality of a single friend group.',
        'Aquarians aren’t necessarily the friends you seek out when you’re in a crisis. They want to support you, but their problem-solving mind short-circuits trying to come up with the objectively right thing to say. Turn to them when you’re hungry for a strange adventure that will turn into an entertaining story to tell at parties—or if you want someone to explain what ontology means.',
      ],
    },
    {
      subheader: 'What makes Aquarians angry?',
      body: [
        'There are two main sources of Aquarian anger. The first one is the world not operating according to their idealistic standards: social injustice or an inefficiently designed grocery store. When their boss tells them to do something that doesn’t make sense. To this they respond with irritation, and righteous disbelief, completely unafraid of the consequences.',
        'The second is when someone blames Aquarians for being wrong in some way: acting immorally, hurting someone’s feelings, being a hypocrite. Accusations of this sort rattles Aquarians to their core, though you might not see evidence of that until till months later.',
        'When Aquarians get angry, it comes as a total surprise. The root cause is usually that they feel misunderstood, unjustly blamed, or that their actions have been misconstrued in some way—anything that tarnishes their idealistic self-image. It is hard for Aquarians to express their anger, because even though they take everything personally they are constantly afraid of being considered illogical. They are experts at maintaining the upper hand, and are more likely to deal with upset feelings through sarcasm, condescension, or defensive deflections. So at first, they’ll push their own anger out of view. It’ll eat away at them. Next time they’re triggered it all comes bubbling up to the surface in a mass-eruption of convoluted explanations.',
        'An Aquarian who has learned to get in touch with their emotions can be quite forthright when they are angry. It’s almost like, the angrier they get the more logically they approach it. This doesn’t mean they won’t get emotional. But when they resent you or feel hurt, instead of shutting down they’ll tell you to your face.',
      ],
    },
  ],
  pisces: [
    {
      subheader: 'Do Pisces make friends easily?',
      body: [
        'Pisces are generally good at making friends. They find it easy to relate to most people.',
      ],
    },
    {
      subheader: 'Are Pisces loyal?',
      body: [
        'Pisces are loyal in their own way. They’ll send a text saying, “I’m here if you need to talk” right as their phone dies. They try to always be there to listen. It may be difficult for them to not internalize other people’s problems and make them worse in the process. Pisces can be gossips, not maliciously, but because they’re genuinely interested in other people’s personal lives. They vacillate between codependent friendships and needing lots of alone time.',
      ],
    },
    {
      subheader: 'What makes a Pisces angry?',
      body: [
        'It’s difficult for a Pisces to get angry until there has been a gradual slow build up. They are quick to dismiss small feelings of discomfort, blaming themselves and their own sensitivity. When the situation finally erupts, it’s messy and awkward and it’s hard to understand where all this resentment is coming from. They expect others to be emotional mind readers; all of a sudden they decide they actually weren’t ok with things they initially said were fine. And they respond with “But you should’ve known I wasn’t fine!”',
      ],
    },
    {
      subheader: 'What makes a Pisces happy?',
      body: [
        'Pisces are happiest when submerged in something oceanic. They experience an immeasurable amount of pleasure when they feel like they’re being swallowed up by something larger than themselves. Pisces enjoy the immersion of sensory experience: the feeling of sand beneath their toes, the smell of a lover’s skin, the music of a summer breeze. They like to feel like they’re dissolving.',
        'Many Pisces like to be around other Pisces, or other at least people they have a deep emotional connection with.',
        'It is easy for a Pisces to lose themselves in other people. Creative work grounds them. Pisces must learn that a main contributor for their happiness is finding an outlet for their creative expression. Pisces are always looking to the future. They always have hope. Pisces can acknowledge the past as they put things behind them and move forward.',
      ],
    },
  ],
};
