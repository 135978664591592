import React from 'react';
import { useParams } from 'react-router-dom';
import cn from 'classnames';

import Api from 'api';
import PageLayout from 'landing-page/views/PageLayout';

import styles from './PasswordResetPage.module.scss';

function PasswordResetPage() {
  const [password, setPassword] = React.useState<string>('');
  const [passwordCopy, setPasswordCopy] = React.useState<string>('');
  const [shouldValidate, setShouldValidate] = React.useState<boolean>(false);
  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const { token } = useParams();

  const submit = (event) => {
    event.preventDefault();
    setShouldValidate(true);

    if (password === passwordCopy && password.length >= 6 && token) {
      Api.resetPassword(token, password);
      setSubmitted(true);
    } else if (password !== passwordCopy) {
      setErrorMessage('Passwords do not match');
    } else if (password.length < 6) {
      setErrorMessage('Password must be at least 6 characters');
    }
  };

  const handlePasswordChange = (e: React.BaseSyntheticEvent) => {
    setPassword(e.currentTarget.value);
  };

  const handleConfirmPasswordChange = (e: React.BaseSyntheticEvent) => {
    setPasswordCopy(e.currentTarget.value);
  };

  return (
    <div className="floating-container">
      <div className="floating-container-inner">
        {!submitted ? (
          <div>
            <h1>Reset yr password</h1>
            <div className="error-message"> {errorMessage} </div>
            <form className="password-reset" onSubmit={submit}>
              <input
                autoComplete="new-password"
                name="password"
                type="password"
                className={cn(styles.passwordInput, {
                  error: shouldValidate && password != passwordCopy,
                })}
                value={password}
                onChange={handlePasswordChange}
                placeholder="New password"
              />
              <input
                autoComplete="new-password"
                name="passwordCopy"
                type="password"
                className={cn(styles.passwordInput, {
                  error: shouldValidate && password != passwordCopy,
                })}
                value={passwordCopy}
                onChange={handleConfirmPasswordChange}
                placeholder="Confirm password"
              />
              <button className={styles.submitButton} type="submit" >Submit</button>
            </form>
          </div>
        ) : (
          <div>
            <h1>Congrats on your new password!</h1>
            <h2>Your password has been reset</h2>
          </div>
        )}
      </div>
    </div>
  );
}

export default PageLayout(PasswordResetPage);
