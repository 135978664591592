export type PlanetImagesObj = {
  planet: string;
  x: number;
  y: number;
  height: string;
  width: string;
};

export const planetImageInfoOne = [
  {
    planet: 'Sun',
    x: 60,
    y: -45,
    height: '40px',
    width: '40px',
  },
  {
    planet: 'Moon',
    x: -55,
    y: 0,
    height: '20px',
    width: '20px',
  },
];

export const planetImageInfoTwo = [
  {
    planet: 'Sun',
    x: 60,
    y: -0,
    height: '40px',
    width: '40px',
  },
  {
    planet: 'Moon',
    x: -55,
    y: -32,
    height: '20px',
    width: '20px',
  },
];

export const planetsForImageOne = [{ name: 'Ascendant' }, { name: 'Sun' }, { name: 'Moon' }];

export const planetObjsForImageOne = {
  Ascendant: {
    imageHeight: 21,
    imageWidth: 12,
    x: -180,
    y: -10.500000000000028,
  },
  Sun: {
    imageHeight: 21,
    imageWidth: 21,
    x: 157,
    y: -70,
  },
  Moon: {
    imageHeight: 21,
    imageWidth: 16,
    x: -182,
    y: 12,
  },
};

export const planetObjsForImageTwo = {
  Ascendant: {
    imageHeight: 21,
    imageWidth: 12,
    x: -180,
    y: -10.500000000000028,
  },
  Sun: {
    imageHeight: 21,
    imageWidth: 21,
    x: 163,
    y: 30,
  },
  Moon: {
    imageHeight: 21,
    imageWidth: 16,
    x: -170,
    y: -80,
  },
};
