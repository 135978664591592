import * as React from 'react';
import { Helmet } from 'react-helmet';
import PageLayout from 'landing-page/views/PageLayout';
import {
  itunesStoreURL,
  playStoreUrl,
  ITUNES_CAMPAIGN_TOKENS,
  PLAY_STORE_CAMPAIGN_TOKENS,
} from '../constants';

import FooterForm from 'landing-page/components/FooterForm';
import { Features } from '../features';
import { PressFeatures } from '../press-features';
import { sendClickEvent } from 'analytics';

class InvitePage extends React.Component {
  scrollAway = (_) => {
    window.scroll(0, window.innerHeight);
  };

  invitepageClick(action: string) {
    return () => {
      sendClickEvent('Invite', action);
    };
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Add me on Co – Star: AI-powered astrology</title>
          <meta
            name="description"
            content="Someone wants to see your chart, understand which planets are affecting your life right now, and maybe see your compatibility (but probably not) (but maybe)."
          />
          <meta property="og:image" content={require('../../../images/launch-hero-desktop.png')} />
          <meta property="og:title" content="Add me on Co – Star: AI-powered astrology" />
        </Helmet>
        <div className="launch-hero">
          <h4>Co–Star is AI-powered astrology</h4>

          <h3 className="subtitle">
            Someone wants to see your chart, understand which planets are affecting your life right
            now, and maybe see your compatibility (but probably not) (but maybe).
          </h3>

          <div className="header-btns">
            <a
              href={itunesStoreURL(ITUNES_CAMPAIGN_TOKENS.SITE_MARKETING)}
              className="btn sign-up white"
            >
              DOWNLOAD iOS
            </a>
            <a
              href={playStoreUrl(PLAY_STORE_CAMPAIGN_TOKENS.SITE_MARKETING)}
              className="btn sign-up black"
            >
              DOWNLOAD ANDROID
            </a>
            <div>
              <a tabIndex={1} onClick={this.scrollAway} className="btn white learn-more">
                <span className="or">or&nbsp;</span>
                <span>LEARN MORE</span>
              </a>
            </div>
          </div>

          <img
            className="launch-hero-image-desktop"
            src={require('../../../images/launch-hero-desktop.png')}
          />
          <img
            className="launch-hero-image-mobile"
            src={require('../../../images/launch-hero-mobile.gif')}
          />
        </div>

        <div className="container dark-container">
          <div className="container-inner">
            <div className="section-title">WHAT IS THIS</div>
            <div className="what-is-this">
              <div className="image-desc">
                <img src={require('../../../images/moon.png')} />
              </div>
              <p>
                Access to astrology this accurate has historically been restricted to those with
                access to personal astrologers—now these predictions can be anyone's.
              </p>
            </div>
            <div className="what-is-this">
              <div className="image-desc">
                <img src={require('../../../images/hand.png')} />
              </div>
              <p>
                Our powerful natural-language engine uses NASA data, coupled with the methods of
                professional astrologers, to algorithmically generate insights about your
                personality and your future.
              </p>
            </div>
            <div className="what-is-this">
              <div className="image-desc">
                <img src={require('../../../images/skull.png')} />
              </div>
              <p>
                Astrology puts our temporary bodies in context with our universe's vastness,
                allowing irrationality to invade our techno-rationalist ways of living.
              </p>
            </div>
          </div>
        </div>
        <Features />
        <PressFeatures />
        <FooterForm formLocation="Invite" />
      </div>
    );
  }
}

export default PageLayout(InvitePage);
