import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Api, {
  NewProspectiveUser,
  ProspectiveUserCreate,
  PlacesSuggestions,
  PlacesSuggestion,
} from 'api';
import Channel from 'channel';
import { fromNullable, none, Option, some } from 'fp-ts/lib/Option';
import { debounce } from 'underscore';
import { useUser } from 'context/userContext';
import { sendSignUpFormEvent, sendSignUpFormError, sendFormEventSuccess } from 'analytics';
import { ProgressBar } from './progress';
import CaptchaModal from '../landing-page/components/CaptchaModal';

export enum ProspectiveUserViewState {
  TYPING,
  LOADING,
  SUBMITTED,
}

type ProspectiveUserFormData = {
  place: string;
  date: string;
  lat: Option<number>;
  lon: Option<number>;
  time: string;
  email: string;
};

export const ProspectiveUserForm = (props: ProspectiveUserProps) => {
  const [viewState, setViewState] = useState<ProspectiveUserViewState>(
    ProspectiveUserViewState.TYPING,
  );
  const [formData, setFormData] = useState<ProspectiveUserFormData>({
    place: '',
    date: '',
    lat: none,
    lon: none,
    time: '',
    email: '',
  });
  const [showCaptcha, setShowCaptcha] = useState<boolean>(false);
  const [progress, setProgress] = useState<Channel<number>>(new Channel());
  const [response, setResponse] = useState<Option<NewProspectiveUser>>(none);
  const [formHistory, setFormHistory] = useState<Array<string>>([]);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [placeSuggestions, setPlaceSuggestions] = useState<Array<string>>([]);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const { userData, setUserData } = useUser();
  const { formLocation } = props;

  const handleInputChange = (event) => {
    if (event.target.value != '') {
      event.target.classList.add('full');
    } else {
      event.target.classList.remove('full');
    }
    const stateChange = {
      [event.target.name]: event.target.value,
    };
    setFormData({ ...formData, ...stateChange });
  };

  const validateInput = (event) => {
    event.target.classList.remove('error');
    if (!event.target.validity.valid) {
      event.target.classList.add('error');
    }
  };

  const saveToFormHistory = (event) => {
    setFormHistory(formHistory.concat([`${event.target.getAttribute('name')}:${event.type}`]));
  };

  const submitForm = (event) => {
    event.preventDefault();
    if (buttonDisabled) {
      return;
    }
    setButtonDisabled(true);
    sendSignUpFormEvent('Submit', formLocation);

    if (!event.target.checkValidity()) {
      for (const i of event.target.elements) {
        validateInput({
          target: i,
        });
      }
      setButtonDisabled(false);
      return;
    }

    setShowCaptcha(true);
  };

  const hideCaptcha = () => {
    setShowCaptcha(false);
  };

  const saveForm = () => {
    setViewState(ProspectiveUserViewState.LOADING);
    setShowCaptcha(false);

    let progressVal = 0;

    const interval = setInterval(() => {
      progressVal += 20;
      progress.sender.send(progressVal);

      if (progressVal >= 80) {
        clearInterval(interval);
      }
    }, 100);

    if (formData.email.endsWith('@costarastrology.com')) {
      setViewState(ProspectiveUserViewState.TYPING);
      setError('Please enter a valid email');
      return;
    }

    let birthTime;

    if (formData.time == '') {
      birthTime = '12:00';
    } else {
      // regex pulled from input[name=time].pattern
      const mbTimeParsed = fromNullable(
        new RegExp(/(\d?\d):(\d\d)( (AM|PM|am|pm))?/).exec(formData.time),
      );

      mbTimeParsed.foldL(
        () => console.error('Could not parse time'),
        (timeParsed) => {
          let hour = parseInt(timeParsed[1]);
          const pm = timeParsed[4] ? timeParsed[4].toUpperCase() == 'PM' : false;
          const am = timeParsed[4] ? timeParsed[4].toUpperCase() == 'AM' : false;
          if (pm && hour != 12) {
            hour += 12;
          } else if (am && hour == 12) {
            hour = 0;
          }

          let hourString = `0${hour}`;
          hourString = hourString.substring(hourString.length - 2);
          birthTime = `${hourString}:${timeParsed[2]}`;
        },
      );
    }

    const time = `${formData.date}T${birthTime}`;

    const birthCoords = some((lat) => (lon) => ({ lat, lon }))
      .ap_(formData.lat)
      .ap_(formData.lon)
      .toNullable();

    const prospectiveUserCreate: ProspectiveUserCreate = {
      email: formData.email,
      birthPlace: formData.place,
      birthLocalTime: time,
      birthCoords,
    };

    fromNullable(props.endpoint)
      .alt(some(Api.createProspectiveUser.bind(Api)))
      .ap_(some(prospectiveUserCreate))
      .foldL(
        () => console.error('Could not get a post endpoint function'),
        (p) => {
          p.then((newUser) => {
            sendFormEventSuccess(formLocation);
            setUserData({
              email: formData.email,
              place: formData.place,
              time: formData.time || '12:00',
              date: formData.date,
              birthCoords,
              prospectiveUserId: newUser.id,
            });
            setViewState(ProspectiveUserViewState.SUBMITTED);
            setResponse(some(newUser));
          }).catch((e) => {
            setButtonDisabled(false);
            if (e.status === 409) {
              sendSignUpFormError(formLocation, 'duplicate');
              setViewState(ProspectiveUserViewState.TYPING);
              setError('We already have this email in our system');
            } else {
              sendSignUpFormError(formLocation, 'server error');
              setViewState(ProspectiveUserViewState.TYPING);
              setError('Something went wrong, please try again.');
            }
          });
        },
      );
  };

  let debouncedPlaceInputFn: Option<ReturnType<typeof debounce>> = none;

  /* Query the API for city suggestions when the user starts typing into the
   * Place input.
   */
  const handlePlaceInput = (event) => {
    event.persist();
    if (debouncedPlaceInputFn === none) {
      debouncedPlaceInputFn = some(
        debounce((ev) => {
          const input = ev.target;
          if (input.value === '') {
            setShowSuggestions(false);
          } else {
            Api.getPlacesSuggestions(input.value)
              .then((suggestions: PlacesSuggestions) => {
                setPlaceSuggestions(suggestions.items);
                setShowSuggestions(true);
                setError('');
              })
              .catch(() => {
                setShowSuggestions(false);
                setError('Something went wrong when loading city suggestions, please try again.');
              });
          }
        }, 250),
      );
    }
    debouncedPlaceInputFn.foldL(
      () => {},
      (fn) => fn(event),
    );
  };

  /* Select the given Place Suggestion. */
  const selectPlace = (place: PlacesSuggestion) => {
    setFormData({ ...formData, lat: some(place.lat), lon: some(place.lng), place: place.name });
    setShowSuggestions(false);
  };

  /* Close the City Suggestions dropdown without selecting an item & cancel
   * any pending API requests.
   */
  const closeSuggestions = () => {
    debouncedPlaceInputFn.foldL(
      () => {},
      (fn) => fn.cancel(),
    );
    setShowSuggestions(false);
    setFormData({ ...formData, lat: none, lon: none });
  };

  const renderSuggestions = () => {
    const suggestions = placeSuggestions;
    const elements: Array<JSX.Element> = [];

    suggestions.forEach((val) => {
      elements.push(
        <li key={val.name}>
          <a
            href="#"
            onClick={(ev) => {
              ev.preventDefault();
              selectPlace(val);
            }}
          >
            {val.name}
          </a>
        </li>,
      );
    });
    const dropdownClass =
      showSuggestions && placeSuggestions.length !== 0
        ? 'dropdown-menu show places-suggestions'
        : 'hidden';

    return <ul className={dropdownClass}>{elements}</ul>;
  };

  const scrollAway = (_) => {
    window.scroll(0, window.innerHeight);
  };

  switch (viewState) {
    case ProspectiveUserViewState.TYPING:
      return (
        <div className={`email-form ${props.className}`}>
          {error && <div className="error-message">{error}</div>}

          <form name="emailForm" onChange={saveToFormHistory} onSubmit={submitForm} noValidate>
            <div className="user-data info">
              <div className="group">
                <span> I was born in&nbsp;</span>
                <div className="input-group">
                  <input
                    required
                    value={formData.place}
                    onChange={handleInputChange}
                    onBlur={validateInput}
                    onFocus={saveToFormHistory}
                    onInput={handlePlaceInput}
                    type="text"
                    placeholder="a city"
                    name="place"
                    id="place"
                  />
                  {renderSuggestions()}
                </div>
              </div>
              <div className="group">
                <span>&nbsp;on&nbsp;</span>
                <div>
                  <input
                    required
                    value={formData.date}
                    onChange={handleInputChange}
                    onBlur={validateInput}
                    onFocus={(ev) => {
                      saveToFormHistory(ev);
                      closeSuggestions();
                    }}
                    type="date"
                    placeholder="yyyy-mm-dd"
                    pattern="\d\d\d\d-\d\d-\d\d"
                    min="1500-01-01"
                    max="2500-12-31"
                    name="date"
                    id="date"
                    className={formData.date ? 'full' : ''}
                  />
                </div>
              </div>
              <div className="group">
                <span>&nbsp;at&nbsp;</span>
                <div>
                  <input
                    type="time"
                    value={formData.time}
                    onChange={handleInputChange}
                    onBlur={validateInput}
                    onFocus={(ev) => {
                      saveToFormHistory(ev);
                      closeSuggestions();
                    }}
                    placeholder="hh:mm AM"
                    pattern="(\d?\d):(\d\d)( (AM|PM|am|pm))?"
                    name="time"
                    id="birthTime"
                    className={formData.time ? 'full' : ''}
                  />
                </div>
                <span id="period">.</span>
              </div>

              <div className="group">
                <span> My email is&nbsp;</span>
                <div>
                  <input
                    type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    onBlur={validateInput}
                    onFocus={(ev) => {
                      saveToFormHistory(ev);
                      closeSuggestions();
                    }}
                    required
                    placeholder="email address"
                    name="email"
                    id="email"
                  />
                </div>
                <span id="period">.</span>
              </div>
            </div>
            <div className="header-btns">
              {props.learnMore != false && (
                <a tabIndex={1} onClick={scrollAway} className="btn white learn-more">
                  <span className="or">or&nbsp;</span>
                  <span>LEARN MORE</span>
                </a>
              )}
              <input
                type="submit"
                className={
                  props.submitClass || `btn ${props.learnMore == false ? 'white' : 'sign-up black'}`
                }
                tabIndex={0}
                value={props.submit || 'GET UR CHART'}
                disabled={buttonDisabled}
              />
            </div>
          </form>
          {showCaptcha ? <CaptchaModal onSuccess={saveForm} closeModal={hideCaptcha} /> : ''}
        </div>
      );
    case ProspectiveUserViewState.LOADING:
      return <ProgressBar message="Calculating natal chart..." progress={progress.listener} />;
    case ProspectiveUserViewState.SUBMITTED:
      return response.fold(<div />, (r) => (
        <Navigate
          to={`/natal-chart/${r.id}`}
          state={{
            analysis: r.analysis,
          }}
        />
      ));
  }
};
export interface ProspectiveUserProps {
  learnMore?: boolean;
  natalChart?: boolean;
  className?: string;
  formName?: string;
  formLocation: string;
  submit?: string;
  submitClass?: string;
  endpoint?: (data: ProspectiveUserCreate) => Promise<NewProspectiveUser>;
}
