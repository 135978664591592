import React, { FC } from 'react';
import { Option } from 'fp-ts/lib/Option';
import UserTestingSignupForm from './UserTestingSignUpForm';
import { SubmitSignupForm } from './UserTestingSignUpTypes';

const QUESTIONS = [
  'Do you love the Co-Star app?',
  'Do you use it on a daily or weekly basis?',
  'Would you like to participate in a ~1h user test?',
];

const MAIN_EXPLANATION = `
  We'd love to invite you to join us on a video call for an interview about how you use Co–Star. 
  Talking to people about their user experience helps us improve the app for everyone. 
  Sign up below with your email, a brief description of who you are, and we'll get back to you soon.
`;

interface UserTestingSignupFormContainerProps {
  onSubmitForm: SubmitSignupForm;
  mbError: Option<string>;
}

const viewQuestions = (questions: Array<string>): JSX.Element => (
  <div className="explanation-questions">
    {questions.map((q) => (
      <p key={q}>{q}</p>
    ))}
  </div>
);

const UserTextExplanation: React.FC = () => (
  <div className="explanation-section body-font-basics">
    {viewQuestions(QUESTIONS)}

    <p>{MAIN_EXPLANATION}</p>

    <p>This is not a paid opportunity.</p>
  </div>
);

const ErrorState: React.FC<{ msg: string }> = ({ msg }) => (
  <div className="error-text-section">
    <h6>{msg}</h6>
  </div>
);

const UserSignupFormContainer: FC<UserTestingSignupFormContainerProps> = ({
  onSubmitForm,
  mbError,
}) => (
  <div className="launch-hero">
    <div className="user-test-signup-page-container floating-container">
      <div className="floating-container-inner">
        <h1>Sign up for a user test</h1>
        <UserTextExplanation />
        <UserTestingSignupForm onSubmit={onSubmitForm} />
        {mbError.foldL(
          () => (
            <div />
          ),
          (e) => (
            <ErrorState msg={e} />
          ),
        )}
      </div>
    </div>
  </div>
);

export default UserSignupFormContainer;
