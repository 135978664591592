import React from 'react';
import { Helmet } from 'react-helmet';

// components
import PageLayout from 'landing-page/views/PageLayout';
import SectionPadding from 'landing-page/components/SectionPadding';
import AstroIntuitionHeader from '../AstroIntuitionHeader';
import AstroIntuitionContainer from '../AstroIntuitionContainer';
import AstroIntuitionFooter from '../AstroIntuitionFooter';

import styles from '../AstroIntuition.module.scss';

const WhatIsAstrology: React.FC = () => (
  <>
    <Helmet>
      <title>What is astrology? | Co-Star</title>
      <meta
        name="description"
        content="Everything in the solar system is constantly moving. Astrology describes our lived experience in the context of this motion."
      />
    </Helmet>
    <div className="floating-container">
      <AstroIntuitionHeader>
        <h1>What is astrology?</h1>
      </AstroIntuitionHeader>
      <AstroIntuitionContainer theme="transparent">
        <h3>Astrology & astronomy were a single discipline for thousands of years.</h3>
        <SectionPadding verticalPadding="2rem" />
        <p>
          Until the 1700s, the study of the stars' motion (now known as astronomy) was the same as
          the study of the stars' effects (now known as astrology). Astrology places a human's lived
          experience into the context of an enormous, endlessly moving universe.
        </p>
      </AstroIntuitionContainer>
      <AstroIntuitionContainer theme="light">
        <h4>We begin with astronomy. </h4>
        <SectionPadding verticalPadding="3rem" />
        <h3>Back in school, you were taught the Sun was at the center of the solar system.</h3>
        <img
          src={require('../../../../../images/Alpha_Test_3.gif')}
          alt="heliocentric animation of sun earth and mars"
        />
        <h4>HELIOCENTRIC MODEL OF THE UNIVERSE</h4>
        <h4>(SUN IN CENTER)</h4>
        <SectionPadding verticalPadding="3rem" />
        <h3>But until the 1500s, humans thought of Earth as the center of the solar system.</h3>
        <img
          src={require('../../../../../images/Geocentric.gif')}
          alt="geocentric animation of sun earth and mars"
        />
        <h4>GEOCENTRIC MODEL OF THE UNIVERSE</h4>
        <h4>(EARTH IN THE CENTER)</h4>
      </AstroIntuitionContainer>
      <AstroIntuitionContainer theme="dark">
        <h3>
          Both models accurately describe the motion of the Sun and the planets, so both are correct
          representations.
        </h3>
        <SectionPadding verticalPadding="2rem" />

        <img
          src={require('../../../../../images/HelioGeo.gif')}
          className={styles.helio}
          alt="animation of helio and geo centric planet motion"
        />
        <div className={styles.sideBySide}>
          <div className={styles.captionContainer}>
            <h4 id="left" className={styles.white}>
              Heliocentric
            </h4>
            <p>What we would see if we were standing directly on the Sun</p>
          </div>
          <h4>VS</h4>
          <div className={styles.captionContainer}>
            <h4 id="right" className={styles.white}>
              Geocentric
            </h4>
            <p>What we see when we look at the sky from our point of view on Earth</p>
          </div>
        </div>
        <SectionPadding verticalPadding="3rem" />
        <h3>Astrology views the sky from the perspective of Earth.</h3>
        <SectionPadding verticalPadding="1rem" />
      </AstroIntuitionContainer>
      <AstroIntuitionContainer theme="transparent">
        <SectionPadding verticalPadding="2rem" />
        <h3>The zodiac is a ring of constellations at the same latitude as our solar system.</h3>
        <img
          src={require('../../../../../images/ZodiacRing_Black.png')}
          className={styles.zodiacRingImg}
          alt="zodiac ring with sun earth and mars"
        />

        <SectionPadding verticalPadding="2rem" />
        <p>
          There are dozens of other constellations, but these are the ones that the planets appear
          to move through.
        </p>
      </AstroIntuitionContainer>
      <AstroIntuitionContainer theme="dark">
        <h3>
          From the perspective of Earth, each planet appears to be in front of one of these
          constellations at all times.
        </h3>
        <SectionPadding verticalPadding="2rem" />
        <p>
          Over days (or months, depending on the planet), each planet moves across the sky,
          appearing in front of first one zodiac sign, and then another. From our perspective on
          Earth, it completes one full circle once it has moved through all twelve zodiac signs, and
          starts back at the beginning.
        </p>
        <img
          id="constellations"
          src={require('../../../../../images/horizon-constellations.png')}
          alt="horizon with jupiter in aquarius capricorn constellation and mercury in sagittarius"
          className={styles.wideImage}
        />
      </AstroIntuitionContainer>
    </div>
    <AstroIntuitionFooter />
  </>
);

export default PageLayout(WhatIsAstrology);
