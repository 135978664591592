export type ResourceSections =
  | 'Addiction'
  | 'Crisis Hotlines'
  | 'Mental Health Resources'
  | 'Domestic Violence'
  | 'Eating Disorders'
  | 'Find a Therapist'
  | 'Sexual Violence';

export const resourceSections: ResourceSections[] = [
  'Addiction',
  'Crisis Hotlines',
  'Mental Health Resources',
  'Domestic Violence',
  'Eating Disorders',
  'Find a Therapist',
  'Sexual Violence',
];

export type ResourceItem = {
  title: string;
  url: string;
  phoneNumber?: string;
};

export type ResourceItems = {
  [key: string]: ResourceItem[];
};

export const resourceItems: ResourceItems = {
  addiction: [
    {
      title: 'Substance Abuse and Mental Health Services Administration',
      url: 'https://www.samhsa.gov/find-help/national-helpline',
      phoneNumber: '800-662-4357',
    },
  ],
  crisisHotlines: [
    {
      title: '1in6 - Sexual Assault Hotline for Sexually Abused / Assaulted Men',
      url: 'https://1in6.org',
      phoneNumber: '800-656-4673',
    },
    {
      title: 'Asian Pacific Institute on Gender-Based Violence - Various Crisis Hotlines',
      url: 'https://www.api-gbv.org/get-help/',
    },
    {
      title: 'Love is Respect - Domestic Violence Hotline',
      url: 'https://www.loveisrespect.org/get-relationship-help-24-7-365/',
      phoneNumber: '866-331-9474',
    },
    {
      title: 'National Coalition Against Domestic Violence - Hotline',
      url: 'https://ncadv.org/get-help',
    },
    {
      title: 'National Domestic Violence Hotline',
      url: 'https://www.thehotline.org/what-to-expect-when-you-contact-us/',
      phoneNumber: '800-799-7233',
    },
    {
      title: 'National Eating Disorders Association - Helpline',
      url: 'https://www.nationaleatingdisorders.org/help-support/contact-helpline',
      phoneNumber: '800-931-2237',
    },
    {
      title: 'National Human Trafficking Hotline',
      url: 'https://humantraffickinghotline.org/en',
      phoneNumber: '888-373-7888',
    },
    {
      title: 'National Teen Dating Abuse',
      url: 'https://www.thehotline.org/news/new-national-teen-dating-abuse-helpline/',
      phoneNumber: '866-331-9474',
    },
    {
      title: 'Rape, Abuse, & Incest National Network - Sexual Assault Hotline',
      url: 'https://www.rainn.org',
      phoneNumber: '800-656-4673',
    },
    {
      title: 'Strong Hearts - Domestic Violence Hotline for Native Americans',
      url: 'https://strongheartshelpline.org',
      phoneNumber: '844-762-8483',
    },
    {
      title: 'Suicide & Crisis Lifeline - Crisis Hotline',
      url: 'https://988lifeline.org',
      phoneNumber: 'Text 988',
    },
    {
      title: 'Therapy for Black Men - Various Crisis Hotlines',
      url: 'https://therapyforblackmen.org/get-help-now/',
    },
    {
      title: 'The Trevor Project - Reach a Counselor for LGBTQ Youth',
      url: 'https://www.thetrevorproject.org/get-help/',
      phoneNumber: '866-488-7386',
    },
    {
      title: 'Trans Lifeline - Crisis Hotline',
      url: 'https://translifeline.org/hotline/',
      phoneNumber: '877-565-8860',
    },
  ],
  domesticViolence: [
    {
      title: 'Battered Women’s Justice Project - General Information on Domestic Violence',
      url: 'https://bwjp.org',
    },
    {
      title: 'Love is Respect - Information on Abusive Relationships',
      url: 'https://www.loveisrespect.org',
    },
    {
      title: 'Strong Hearts - Information on Abusive Relationships Among Native Americans',
      url: 'https://strongheartshelpline.org/abuse',
    },
  ],
  eatingDisorders: [
    {
      title: 'National Alliance for Eating Disorders - Find Treatment',
      url: 'https://www.allianceforeatingdisorders.com/find-treatment/',
    },
    {
      title: 'National Alliance for Eating Disorders - Find Support',
      url: 'https://www.allianceforeatingdisorders.com/eating-disorder-support-groups-and-programs/',
    },
    {
      title: 'National Eating Disorders Association - Free & Low Cost Support',
      url: 'https://www.nationaleatingdisorders.org/free-low-cost-support',
    },
    {
      title: 'Project Heal - Find Support + Treatment',
      url: 'https://www.theprojectheal.org',
    },
  ],
  findATherapist: [
    {
      title: 'Black Mental Health Alliance',
      url: 'https://blackmentalhealth.com/connect-with-a-therapist/',
    },
    {
      title: 'Pride Counseling - LGBTQ',
      url: 'https://www.pridecounseling.com/get-started/',
    },
    {
      title: 'Therapy for Black Girls',
      url: 'https://therapyforblackgirls.com',
    },
    {
      title: 'Therapy for Black Men',
      url: 'https://therapyforblackmen.org/therapists/',
    },
    {
      title: 'Therapy for Latinx',
      url: 'https://www.therapyforlatinx.com/',
    },
  ],
  mentalHealthResources: [
    {
      title: 'Bad Bitches Have Bad Days Too',
      url: 'https://www.badbitcheshavebaddaystoo.com/',
    },
    {
      title: 'Mind - UK-based',
      url: 'https://www.mind.org.uk',
    },
    {
      title: 'Mental Health UK',
      url: 'https://mentalhealth-uk.org',
    },
    {
      title: 'NAMI - Finding Help',
      url: 'https://www.nami.org/Your-Journey/Kids-Teens-and-Young-Adults/Teens/Finding-Help',
    },
    {
      title: 'Self Care Is For Everyone - Resources page',
      url: 'https://selfcareisforeveryone.com/pages/resources',
    },
    {
      title: 'The Gen Z Collective',
      url: 'https://www.genzcollective.com/health-national-resources',
    },
    {
      title: 'The JED Foundation - Mental Health Resource Center',
      url: 'https://jedfoundation.org/mental-health-resource-center/',
    },
    {
      title: 'The Trevor Project - Find LGBTQ Resources',
      url: 'https://www.thetrevorproject.org/resources/',
    },
  ],
  sexualViolence: [
    {
      title: '1in6 - Sexual Assault Hotline for Sexually Abused / Assaulted Men',
      url: 'https://1in6.org',
    },
    {
      title: 'Joyful Heart Foundation - Resources for Survivors',
      url: 'https://www.joyfulheartfoundation.org/resources',
    },
    {
      title: 'National Sexual Violence Resource Center',
      url: 'https://www.nsvrc.org/find-help',
    },
    {
      title: 'Planned Parenthood - Services',
      url: 'https://www.plannedparenthood.org/get-care/our-services',
    },
  ],
};
