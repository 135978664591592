import React from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import Helmet from 'react-helmet';
import PageLayout from 'landing-page/views/PageLayout';
import Breadcrumbs from 'landing-page/components/Breadcrumbs';
import { redirectToAppropriateAppstore, getMobileOperatingSystem } from 'utils';
import { sendClickEvent } from 'analytics';
import {
  itunesStoreURL,
  ITUNES_CAMPAIGN_TOKENS,
  playStoreUrl,
  PLAY_STORE_CAMPAIGN_TOKENS,
} from '../../../constants';

import AskTheStarsImg from './AskTheStarsImg';

import styles from './AskTheStars.module.scss';

const crumbs = [
  {
    name: 'Premium',
    path: '/advanced-readings',
  },
  {
    name: 'Ask the stars',
    path: '/advanced-readings/ask-the-stars',
  },
];

const AskTheStars = () => {
  const navigate = useNavigate();
  const APP_STORE_URL = itunesStoreURL(ITUNES_CAMPAIGN_TOKENS.SITE_SHOP);
  const PLAY_STORE_URL = playStoreUrl(PLAY_STORE_CAMPAIGN_TOKENS.SITE_SHOP);
  const isMobile = getMobileOperatingSystem();
  const handleClick = () => {
    sendClickEvent('AskTheStarsPDP', 'Download');
    if (isMobile) {
      redirectToAppropriateAppstore(getMobileOperatingSystem(), APP_STORE_URL, PLAY_STORE_URL);
    } else {
      window.open('https://apps.apple.com/us/app/co-star/id1264782561');
    }
  };

  return (
    <>
      <Helmet>
        <title>Ask the stars, get your answers | Co-Star</title>
        <meta
          name="description"
          content="Do they like you back? Are you ready for a committed relationship? Are you making progress in your career? Pose your deepest question to the stars and get answers."
        />
      </Helmet>
      <div className={cn(styles.pageContainer, 'floating-container')}>
        <Breadcrumbs crumbs={crumbs} />
        <div className={styles.innerPageContainer}>
          <div className={styles.desktopColumn}>
            <div className={styles.headerContainer}>
              <h3> Answers to your deepest, darkest questions</h3>
            </div>
            <AskTheStarsImg mobile />
            <div className={styles.bodyContainer}>
              <p>
                Enter the void and ask the stars—so you can find the answers you’re looking for.
                In-depth insights and advice based on your natal chart, the real-time movements of
                the planets in the sky, and the 4,000-year-old system of astrology.
              </p>
              <div className={styles.button}>
                <button className="btn black" type="button" onClick={handleClick}>
                  TRY IT OUT IN APP
                </button>
              </div>
            </div>
          </div>
          <AskTheStarsImg mobile={false} />
        </div>
      </div>
    </>
  );
};

export default PageLayout(AskTheStars);
