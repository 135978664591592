import React from 'react';
import cn from 'classnames';

import styles from './Input.module.scss';

/**
 * @name Input
 *
 * @type {React.FC}
 * @description This is a resuable input component that's compatible with react-hook-form
 *
 *
 */

export interface InputProps extends Omit<React.InputHTMLAttributes<unknown>, 'tabIndex'> {
  label?: string;
  tabIndex?: string;
  name: string;
  error?: boolean;
}

const Input = React.forwardRef(
  ({ name, label, className, tabIndex, error = false, ...props }: InputProps, ref) => (
    <>
      {label && (
        <label htmlFor={name} className={styles.label}>
          <span>{label}</span>
        </label>
      )}
      <input
        name={name}
        tabIndex={tabIndex ? parseInt(tabIndex, 10) : undefined}
        className={cn(className, (error ? ' error' : ''))}
        ref={ref}
        {...props}
      />
      <span className={styles.errorMessage}>{error?.message}</span>
    </>
  ),
);

export default Input;
