// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WXz\\+Wz5-OXOblILLE0TOYw\\=\\={width:100%}.ipG8v1gJ0bELLzHg6Bsivw\\=\\={font-family:\"Akkurat-Mono\",\"Andale Mono\",sans-serif;font-size:12px;color:#575657;letter-spacing:0;line-height:16px;text-transform:uppercase;text-decoration:none;border:1px solid #252525;text-align:center;margin:20px auto 10px;cursor:pointer;padding:10px 0px;border-radius:0px;background-color:#141414;color:#fbfbfb;height:2.4rem;width:100%}", "",{"version":3,"sources":["webpack://./src/landing-page/pages/PasswordResetPage/PasswordResetPage.module.scss"],"names":[],"mappings":"AAMA,6BACE,UAAA,CAGF,4BACE,mDAPkB,CAQlB,cAAA,CACA,aAXS,CAYT,gBAAA,CACA,gBAAA,CACA,wBAAA,CACA,oBAAA,CACA,wBAAA,CACA,iBAAA,CACA,qBAAA,CACA,cAAA,CACA,gBAAA,CACA,iBAAA,CACA,wBAxBS,CAyBT,aAtBS,CAuBT,aAAA,CACA,UAAA","sourcesContent":["$offBlack: #141414;\n$lightBlack: #252525;\n$darkGray: #575657;\n$offWhite: #fbfbfb;\n$subtitleFontStack: 'Akkurat-Mono', 'Andale Mono', sans-serif;\n\n.passwordInput {\n  width: 100%;\n}\n\n.submitButton {\n  font-family: $subtitleFontStack;\n  font-size: 12px;\n  color: $darkGray;\n  letter-spacing: 0;\n  line-height: 16px;\n  text-transform: uppercase;\n  text-decoration: none;\n  border: 1px solid $lightBlack;\n  text-align: center;\n  margin: 20px auto 10px;\n  cursor: pointer;\n  padding: 10px 0px;\n  border-radius: 0px;\n  background-color: $offBlack;\n  color: $offWhite;\n  height: 2.4rem;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"passwordInput": "WXz+Wz5-OXOblILLE0TOYw==",
	"submitButton": "ipG8v1gJ0bELLzHg6Bsivw=="
};
export default ___CSS_LOADER_EXPORT___;
