import React, { useEffect, useState } from 'react';
import * as _ from 'underscore';
import { AstroAnalysis } from 'api';
import * as R from 'ramda';
import { Planet, Sign } from '../../astrology';
import { AspectFocusedNatalChartGenerator, NatalChartProps } from '../../natal-chart-generator';

export const NatalChart = ({
  chartData,
  redirectOnPlanetImageClick,
  whitePlanetImages,
  isAC
}: NatalChartProps) => {
  const [natalChartD3, setNatalChartD3] = useState<AspectFocusedNatalChartGenerator | null>(null);

  let DOMnode;
  const natalChartGenerationData = {
    chartData,
    redirectOnPlanetImageClick,
    whitePlanetImages,
    isAC
  };


  useEffect(() => {
    natalChartD3?.destroy();
    setNatalChartD3(new AspectFocusedNatalChartGenerator(DOMnode, natalChartGenerationData));
  }, [chartData]);

  return <div ref={(node) => (DOMnode = node)} className="natal-chart-container" />;
};

export function preloadChartImage(url: string): Promise<string> {
  return new Promise((resolve, reject) => {
    if (navigator.userAgent.indexOf('Prerender') == -1) {
      const test: HTMLImageElement = new Image();
      test.src = url;
      test.addEventListener('load', () => {
        resolve(test.src);
      });
      test.onerror = () => reject();
    } else {
      resolve(url);
    }
  });
}

export const analysisToChart = (astroAnalysis: AstroAnalysis): { [K in Planet]: Sign } =>
  R.map((analysis) => analysis.position.sign, astroAnalysis.planets);
