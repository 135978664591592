import React from 'react';
import AdvancedChartAttribution from 'landing-page/pages/AdvancedChart/AdvancedChartAttributions';
import { testimonials, attributions } from './Testimonals';
import styles from './TestimonialItem.module.scss';

type TestimonialItemProps = {
  idx: number;
};

const TestimonialItem = ({ idx }: TestimonialItemProps) => (
  <div className={styles.container}>
    <div className={styles.contents}>
      <p>{testimonials[idx]}</p>
      <div className={styles.iconAttribution}>
        <img src={require(`../../../../../../images/icon-${idx + 1}.png`)} />
        <div className={styles.attributions}>
          <AdvancedChartAttribution planet="Sun" sign={attributions[idx].Sun} />
          <AdvancedChartAttribution planet="Moon" sign={attributions[idx].Moon} />
          <AdvancedChartAttribution planet="Ascendant" sign={attributions[idx].Ascendant} />
        </div>
      </div>
    </div>
  </div>
);

export default TestimonialItem;
