import { Sign, Planet } from 'astrology';
import { ApiBase } from 'api-base';
import { UserTestingSignupFormData } from './landing-page/pages/UserTestingSignUp/UserTestingSignUpTypes';

declare let API_BASE_URL: string;

export class Api extends ApiBase {
  constructor(base: string) {
    super(base);
  }

  defaultHeaders(): { [header: string]: string } {
    return {};
  }

  public createProspectiveUser(data: ProspectiveUserCreate): Promise<NewProspectiveUser> {
    return this.jsonReq('/prospective_user', {
      method: 'POST',
      body: data,
    });
  }

  public updateProspectiveUser(
    token: string,
    data: ProspectiveUserCreate,
  ): Promise<NewProspectiveUser> {
    return this.jsonReq(`/prospective_user/${token}/`, {
      method: 'PUT',
      body: data,
    });
  }

  public createProspectiveUserReading(
    data: ProspectiveUserReadingCreate,
  ): Promise<NewProspectiveUserReading> {
    return this.jsonReq('/prospective_user/v2/paid_relationship_reading?TOKEN=88f3c428-7432-11ee-b962-0242ac120002', {
      method: 'POST',
      body: data,
    });
  }

  public unsubscribeProspectiveUser(puid: string, hash: string): Promise<{}> {
    return this.jsonReq(`/prospective_user/${puid}/unsubscribe/${hash}`, {
      method: 'PUT',
    });
  }

  public getProspectiveUserAstroAnalysis(prospectiveUserId: number): Promise<AstroAnalysis> {
    return this.jsonReq(`/prospective_user/${prospectiveUserId}/astro_analysis`);
  }

  public getProspectiveUserReading(readingUuid: string): Promise<NewProspectiveUserReading> {
    return this.jsonReq(`/prospective_user/paid_relationship_reading/${readingUuid}`, {
      method: 'GET',
    });
  }

  public getPublicUserAstroAnalysis(username: string): Promise<AstroAnalysis> {
    return this.jsonReq(`/public/user/astro_analysis?userName=${username}`);
  }

  public getBirthedEntityAstroAnalysis(
    birthedEntityId: number,
    authToken: string,
  ): Promise<AstroAnalysis> {
    return this.jsonReq(`/birthed_entity/${birthedEntityId}/astro_analysis`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  }

  public sendFeedback(data: Feedback): Promise<{}> {
    return this.jsonReq('/feedback', {
      method: 'POST',
      body: data,
    });
  }

  public getImageSignature(filename: string): Promise<UploadSignature> {
    return this.jsonReq(`/image_upload_signature/${filename}`);
  }

  public migrateFBUser(targetUserId: string, deadUserId: string, token: string): Promise<{}> {
    return this.jsonReq(`/user/migrate/${targetUserId}/${deadUserId}/${token}`, {
      method: 'GET',
    });
  }

  public resetPassword(token: string, newPassword: string): Promise<{}> {
    return this.jsonReq(`/user/password_reset/${token}`, {
      method: 'POST',
      body: {
        password: newPassword,
      },
    });
  }

  public signupForUserTesting(fi: UserTestingSignupFormData): Promise<{}> {
    return this.jsonReq('/user_testing_signup', {
      method: 'POST',
      body: fi,
    });
  }

  public createTestFlightRequestUser(email: string): Promise<{}> {
    return this.jsonReq(`/test_flight_request/${encodeURIComponent(email)}`, {
      method: 'POST',
    });
  }

  public getPlacesSuggestions(query: string): Promise<PlacesSuggestions> {
    return this.jsonReq(`/autocomplete/${encodeURIComponent(query)}`, {
      method: 'GET',
    });
  }
}

export interface AstroAnalysis {
  planets: { [K in Planet]: Analysis };
  houseCusps: number[];
  natalAspects: NatalAspect[];
}

export interface NatalAspect {
  planetA: Planet;
  planetB: Planet;
  aspect: Aspect;
}

export interface Aspect {
  angle: number;
  name: string;
}

export interface Analysis {
  position: AstroPosition;
  text: string;
}

export interface AstroPosition {
  sign: Sign;
  house: number;
  radians: number;
  degreesInSign: Arc;
}

export interface Arc {
  degree: number;
  minute: number;
  second: number;
}

export interface PaidReadingItem {
  paidReadingAttribution: {
    [K in Planet]: Sign;
  };
  paidReadingCategory: string;
  paidReadingText: string;
}

export interface ProspectiveUserCreate {
  email: string;
  birthPlace: string;
  birthLocalTime: string;
  birthCoords: Coordinates | null;
}

export interface ProspectiveUserReadingCreate {
  prospectiveUserId: number | null;
  birthPlace: string;
  birthLocalTime: string;
  birthCoords: Coordinates | null;
}

export interface Coordinates {
  lat: number;
  lon: number;
}

export interface NewProspectiveUser {
  id: number;
  analysis: AstroAnalysis;
}

export interface NewProspectiveUserReading {
  analysis: AstroAnalysis;
  paidReading: PaidReadingItem[];
  prospectiveUserId: number;
  readingUuid: string;
  birthDay: string;
  birthTime: string;
  birthPlace: string;
}

export interface Feedback {
  feedback: string;
  mood: string | null;
  email: string | null;
}

export interface UploadSignature {
  filename: string;
  bucket: string;
  formFields: { [key: string]: string };
}

export interface PlacesSuggestions {
  items: PlacesSuggestion[];
}

export interface PlacesSuggestion {
  name: string;
  lat: number;
  lng: number;
}

export default new Api(API_BASE_URL);
