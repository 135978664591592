import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Api, { AstroAnalysis, NewProspectiveUserReading } from 'api';
import { fromNullable } from 'fp-ts/lib/Option';
import { SelfManagingProgressBar } from 'components/progress';
import NotFoundPage from 'landing-page/pages/NotFoundPage';
import PublicNatalChartPage from 'landing-page/pages/NatalChartPages/PublicNatalChartPage';
import ProspectiveUserChartPage from 'landing-page/pages/NatalChartPages/ProspectiveUserChartPage';
import PageLayout from 'landing-page/views/PageLayout';
import NatalChartHelmet from './NatalChartHelmet';
import { WebData } from '../../../../server/request';

import '../../../../../style/public-chart-page-style.scss';

export interface NatalChartPageState {
  remoteAnalysis: WebData<AstroAnalysis>;
}

/**
 * This class encapsulates the STATE handling for pages that show charts.
 * A chart page is something that draws a chart and has planet explanation alongside it.
 * The key common features of handling this state are
 *    1. fetching remote chart analysis data
 *    2. Window resizing and shaping based on chart generation
 *    3. Setting information about S3 chart url data
 *
 * The class is parameterized
 *   1. A way to identify the user in question (in this case, either an ID or a username)
 *   2. A view that is a function of this state handling provided by this class
 *   3. A function to fetch the user's chart data, which is the basis of rendering the view. These views are quite similar
 *      only differ in things like footer, form text, etc (and are thus parameterized on them)
 *
 */

export const NatalChartPage = () => {
  const [remoteAnalysis, setRemoteAnalysis] = useState<'pending' | 'error' | 'sucess'>('pending');
  const [astroAnalysis, setAstroAnalysis] = useState<AstroAnalysis | null>(null);
  const { username, prospectiveUserId } = useParams() as {
    username: string | undefined;
    prospectiveUserId: string | undefined;
  };
  const [identifier, setIdentifier] = useState(username || prospectiveUserId);
  const location = useLocation();

  const fetchAstroAnalysis = (puId: string) => {
    if (username) return Api.getPublicUserAstroAnalysis(puId);
    return Api.getProspectiveUserAstroAnalysis(parseInt(puId, 10));
  };

  useEffect(() => {
    if (prospectiveUserId !== identifier) {
      setIdentifier(prospectiveUserId);
    }
  }, [identifier, prospectiveUserId]);

  useEffect(() => {
    if (location.state?.analysis && location.state.analysis !== astroAnalysis) {
      setAstroAnalysis(location.state.analysis);
    }
    if (remoteAnalysis === 'pending' && !astroAnalysis && identifier) {
      fetchAstroAnalysis(identifier)
        .then((analysis) => {
          setAstroAnalysis(analysis);
          setRemoteAnalysis('sucess');
        })
        .catch((err) => {
          setRemoteAnalysis('error');
        });
    }
  }, [identifier, location]);

  useEffect(() => {
    if (astroAnalysis) {
      setTimeout(setSize, 0);
      window.addEventListener('resize', setSize);
    }
  }, [astroAnalysis]);

  // TO DO: refacor this so it doesn't use document.getElementByID -- bad practice for react
  const setSize = () => {
    const mbEl = fromNullable(document.getElementById('chartbox'));
    mbEl.foldL(
      () => console.error('could not get element chartbox'),
      (el) => {
        const windowHeight = window.innerHeight * 0.8;
        const height = windowHeight;
        const mbNewDim = fromNullable(el.parentNode)
          .map((p) => p.offsetWidth)
          .map((w) => Math.min(height, w));
        mbNewDim.foldL(
          () => console.error('could not create new dim'),
          (newDim) => {
            el.style.width = `${newDim}px`;
            el.style.height = `${newDim}px`;
          },
        );
      },
    );
  };

  if (remoteAnalysis === 'pending') return <SelfManagingProgressBar message="Drawing" />;
  if (remoteAnalysis === 'error') return <NotFoundPage />;

  if (astroAnalysis) {
    return (
      <div className="chart-page">
        <NatalChartHelmet username={username} remoteAnalysis={astroAnalysis} />
        {username ? (
          <PublicNatalChartPage remoteAnalysis={astroAnalysis} username={username} />
        ) : (
          <ProspectiveUserChartPage
            remoteAnalysis={astroAnalysis}
            prospectiveUserId={parseInt(prospectiveUserId, 10)}
          />
        )}
      </div>
    );
  }
  return <div />;
};

export default PageLayout(NatalChartPage);
