export const data = {
  birthDay: '1988-02-20',
  birthPlace: 'Bridgetown, Barbados',
  birthTime: '08:50:00',
  prospectiveUserId: 10449848,
  readingUuid: 'b8829ea4-cf9e-4950-b6f5-92b00ec87ed2',
  analysis: {
    houseCusps: [
      0.26475237073228497, 0.7673772900696322, 1.2700022094069794, 1.7726271287443272,
      2.317199760603577, 2.8617723924628278, 3.406345024322078, 3.908969943659425,
      4.411594862996773, 4.91421978233412, 5.45879241419337, 6.003365046052621,
    ],
    natalAspects: [
      { aspect: { angle: 0, name: 'Conjunction' }, planetA: 'Mars', planetB: 'Saturn' },
      { aspect: { angle: 0, name: 'Conjunction' }, planetA: 'Mars', planetB: 'Uranus' },
      { aspect: { angle: 0, name: 'Conjunction' }, planetA: 'Moon', planetB: 'Venus' },
      { aspect: { angle: 0, name: 'Conjunction' }, planetA: 'Saturn', planetB: 'Uranus' },
      {
        aspect: { angle: 1.0471975511965976, name: 'Sextile' },
        planetA: 'Neptune',
        planetB: 'Pluto',
      },
      {
        aspect: { angle: 1.5707963267948966, name: 'Square' },
        planetA: 'Mercury',
        planetB: 'Pluto',
      },
      {
        aspect: { angle: 0.7853981633974483, name: 'Semi-square' },
        planetA: 'Mercury',
        planetB: 'Saturn',
      },
      {
        aspect: { angle: 0.7853981633974483, name: 'Semi-square' },
        planetA: 'Mercury',
        planetB: 'Uranus',
      },
      {
        aspect: { angle: 0.7853981633974483, name: 'Semi-square' },
        planetA: 'Mars',
        planetB: 'Pluto',
      },
      {
        aspect: { angle: 0.7853981633974483, name: 'Semi-square' },
        planetA: 'Saturn',
        planetB: 'Pluto',
      },
      {
        aspect: { angle: 0.7853981633974483, name: 'Semi-square' },
        planetA: 'Uranus',
        planetB: 'Pluto',
      },
    ],
    planets: {
      Ascendant: {
        context: [],
        position: {
          degreesInSign: { degree: 15, minute: 10, second: 9 },
          house: 1,
          radians: 0.26475237073228497,
          sign: 'Aries',
        },
        text: '',
        valueIds: [],
      },
      Jupiter: {
        context: [],
        position: {
          degreesInSign: { degree: 26, minute: 38, second: 13 },
          house: 1,
          radians: 0.46490676685150006,
          sign: 'Aries',
        },
        text: '',
        valueIds: [],
      },
      Mars: {
        context: [],
        position: {
          degreesInSign: { degree: 28, minute: 43, second: 39 },
          house: 9,
          radians: 4.690180337247922,
          sign: 'Sagittarius',
        },
        text: '',
        valueIds: [],
      },
      Mercury: {
        context: [],
        position: {
          degreesInSign: { degree: 13, minute: 17, second: 26 },
          house: 11,
          radians: 5.467955884617681,
          sign: 'Aquarius',
        },
        text: '',
        valueIds: [],
      },
      Moon: {
        context: [],
        position: {
          degreesInSign: { degree: 11, minute: 6, second: 43 },
          house: 12,
          radians: 0.19394226886249422,
          sign: 'Aries',
        },
        text: '',
        valueIds: [],
      },
      Neptune: {
        context: [],
        position: {
          degreesInSign: { degree: 9, minute: 29, second: 49 },
          house: 9,
          radians: 4.878145276280612,
          sign: 'Capricorn',
        },
        text: '',
        valueIds: [],
      },
      Pluto: {
        context: [],
        position: {
          degreesInSign: { degree: 12, minute: 34, second: 15 },
          house: 7,
          radians: 3.8845980441901267,
          sign: 'Scorpio',
        },
        text: '',
        valueIds: [],
      },
      Saturn: {
        context: [],
        position: {
          degreesInSign: { degree: 0, minute: 31, second: 42 },
          house: 9,
          radians: 4.721611683067104,
          sign: 'Capricorn',
        },
        text: '',
        valueIds: [],
      },
      Sun: {
        context: [],
        position: {
          degreesInSign: { degree: 1, minute: 6, second: 29 },
          house: 11,
          radians: 5.778926394796692,
          sign: 'Pisces',
        },
        text: '',
        valueIds: [],
      },
      Uranus: {
        context: [],
        position: {
          degreesInSign: { degree: 0, minute: 12, second: 54 },
          house: 9,
          radians: 4.716141789118984,
          sign: 'Capricorn',
        },
        text: '',
        valueIds: [],
      },
      Venus: {
        context: [],
        position: {
          degreesInSign: { degree: 12, minute: 56, second: 23 },
          house: 12,
          radians: 0.22584297398431646,
          sign: 'Aries',
        },
        text: '',
        valueIds: [],
      },
    },
  },
  paidReading: [
    {
      paidReadingAttribution: { Moon: 'Aries', Sun: 'Pisces' },
      paidReadingCategory: "Where you're coming from",
      paidReadingText:
        "Your family of origin was a lot. It put a backbone inside of you, but it also created disruptive activity along your many fault lines. You survived by convincing yourself that you like to avoid responsibility.\nYou have constructed a personal narrative based on the idea that it is necessary to be brave. This had the unfortunate side-effect of making you feel like you had to keep challenging yourself, no matter what. You twisted yourself around someone else's truth. You used other people's suffering as a drug to mute your own. This has been the backbone of your depth.\nYou have not yet discovered that your need for friction can lead to pointless confrontations. This is why you are drawn to volatile people. It's as if you confuse pulling away with survival.",
    },
    {
      paidReadingAttribution: { Mars: 'Sagittarius', Venus: 'Aries' },
      paidReadingCategory: 'In a relationship',
      paidReadingText:
        "You need your relationship to feel open and direct. When you're feeling good, you're a chaser, because you keep things playful. But you also go through phases during which you brandish your obstinance like an elaborate lance. This is the source of your underlying frustration.\nDespite your real desire to grow, you've never been able to manage your powerful desire. You want quick, passionate love, yet you also demand enduring loyalty. You want to let someone chase you, but you don't know how.\nYou believe love should be spontaneous and playful. Intimacy scares you. You correct for this by tracking people down with the tenacious instinct of a bloodhound. You need friction so badly that you start fights for no reason. When this fails, you become a samurai who has lost its master.",
    },
    {
      paidReadingAttribution: { Mars: 'Sagittarius' },
      paidReadingCategory: 'When things go wrong',
      paidReadingText:
        "Your relationship can start to feel stifling. No matter what you've told yourself, you are not a sledgehammer, you just don't know how to experiment with feeling free while in a relationship. This makes you conflate conflict with electricity. Rather than playing fun games, you demand undivided attention from them, then act like it's smothering you.\nYou built your identity around the idea that you are called by duty to learn and grow through the process of having relationships. You can be judgmental. You get bored and go looking elsewhere for a spark. You have told yourself the story that bickering is foreplay, but this is just a rationalization for your tendency to devalue the mundane moments.\nWhen things start to feel challenging, you claw at the relationship, until it bleeds. When they pick a fight, all you have for them is ranting and raving. You don't want to be hard to please. You'd like to be committed. There is nothing wrong with you, you just need someone who can return the force of your ravenous desire.",
    },
    {
      paidReadingAttribution: { Moon: 'Aries' },
      paidReadingCategory: 'What you need',
      paidReadingText:
        "You need to be with someone who demands that you sleep when you're tired and eat when you're hungry. You need someone who will be charmed by your mood swings. You need someone who isn’t scared to challenge you.\nSelf-discipline can be a fun game too. You need someone who can see right through the childish petulance you perform when you don't get your way, but who also knows the difference between your bold feelings and your uncontrollable impulses. You need someone who demands patience.\nYour practice of taking things personally has nothing to do with them. You need someone who will encourage you to disentangle your needs from your wants, who will understand your temperament, and who takes pleasure in appreciating your straight-forward emotions.",
    },
    {
      paidReadingAttribution: { Sun: 'Pisces' },
      paidReadingCategory: 'Your mission',
      paidReadingText:
        "You don't have to live your life becoming a container for other people's pain. It's possible to want to be alone and also want to be connected to other people. But first, you have to get real about your need for closeness.\nYou are ready to walk the path. Walk toward people without feigning vulnerability, without forcing intimacy, without brandishing your merciless scalpel.\nIt will be hard for you to remember that transcendence doesn't have to be a solitary experience. You will be tempted to run to your favorite hiding places. You will hide, and you will escape.\nPeople will be able to see the areas you haven’t perfected. All of your cracks and fissures and brokenness. They will see the loops that you get stuck in. When this happens, you will have to swim up to the surface. Feel everything. And when you feel like intimacy is a scary monster waiting to eat you in the dark, take all the armor off your heart, and face it this way.",
    },
  ],
};
