export const testimonials = [
  "Very true, and very relatable. It's good to remember what is the overall goal of my life, and who I was like as a kid, where are the cracks, what have I been ignoring.",
  'It definitely did help me with some guidelines and kind of perspective on the way I approach my relationship.',
  "Definitely went very into detail but it resonated a lot…it made sense. And that's one thing I love about Co–Star…it’s not generic.",
  'This is beautiful and very accurate to how I am...this feels like a different reading than when I first paid for it, but maybe I just completely forgot/did not understand it the first time, I get it now.',
];

export const attributions = [
  {
    Sun: 'Virgo',
    Moon: 'Aries',
    Ascendant: 'Virgo',
  },
  {
    Sun: 'Virgo',
    Moon: 'Cancer',
    Ascendant: 'Scorpio',
  },
  {
    Sun: 'Gemini',
    Moon: 'Gemini',
    Ascendant: 'Gemini',
  },
  {
    Sun: 'Scorpio',
    Moon: 'Leo',
    Ascendant: 'Aries',
  },
];
