export const ITUNES_STORE_BASE_URL = 'https://itunes.apple.com/us';
const ITUNES_COSTAR_APP_URL_EXT = 'app/co-star';

const ITUNES_COSTAR_APP_ID = '1264782561';
const ITUNES_PT = '118755375';
const ITUNES_MT = 8;

export enum ITUNES_CAMPAIGN_TOKENS {
  SITE_MARKETING = 'site-marketing',
  SITE_SHOP = 'site_shop',
  SITE_EDUCATION = 'site_education',
  SITE_USER_PROFILE = 'site_user_profile',
  IG_LINK_IN_BIO = 'ig_link_in_bio',
  TWITTER_LINK_IN_BIO = 'twitter_link_in_bio',
  TIK_TOK_LINK_IN_BIO = 'tik_tok_link_in_bio',
  WEEKLY_EMAIL = 'weekly_email',
}

export const itunesStoreURL = (campaignToken: ITUNES_CAMPAIGN_TOKENS): string => {
  const params = `pt=${ITUNES_PT}&ct=${campaignToken}&mt=${ITUNES_MT.toString()}`;
  const url = `${ITUNES_STORE_BASE_URL}/${ITUNES_COSTAR_APP_URL_EXT}/id${ITUNES_COSTAR_APP_ID}`;
  return `${url}?${params}`;
};

export enum PLAY_STORE_CAMPAIGN_TOKENS {
  SITE_MARKETING = 'costarwebsite',
  SITE_SHOP = 'site_shop',
  SITE_EDUCATION = 'site_education',
  SITE_USER_PROFILE = 'site_user_profile',
  IG_LINK_IN_BIO = 'ig_link_in_bio',
  TWITTER_LINK_IN_BIO = 'twitter_link_in_bio',
  TIK_TOK_LINK_IN_BIO = 'tik_tok_link_in_bio',
  WEEKLY_EMAIL = 'weekly_email',
}

export const playStoreUrl = (campaingToken: PLAY_STORE_CAMPAIGN_TOKENS): string =>
  `https://play.google.com/store/apps/details?id=com.costarastrology&utm_source=${campaingToken}`;
