import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet';

// components
import PageLayout from 'landing-page/views/PageLayout';
import SectionPadding from 'landing-page/components/SectionPadding';
import ZodiacRing from '../../../components/ZodiacRing';

import AstroIntuitionHeader from '../AstroIntuitionHeader';
import AstroIntuitionContainer from '../AstroIntuitionContainer';
import AstroIntuitionFooter from '../AstroIntuitionFooter';
import AstroIntuitionTable from '../AstroIntuitionTable';

import OrbitAnimation from './OrbitAnimation';

// types/data
import {
  planets,
  planetDescriptors,
  signs,
  signDescriptors,
  planetsForImageOne,
  planetObjsForImageOne,
  planetsForImageTwo,
  planetObjsForImageTwo,
} from './NatalChartTypes';

// styles
import styles from './NatalChart.module.scss';
import NatalChartScrollBox from './NatalChartScrollBox';
import NatalChartScrollContainer from './NatalChartScrollContainer';

const NatalChart: React.FC = () => {
  const [scrollDisabled, setScrollDisabled] = useState(true);

  const scrollBox = useRef(null);

  const handleScroll = () => {
    const screenHeight = window.innerHeight;
    if (scrollBox.current.getBoundingClientRect().bottom <= screenHeight + 40) {
      setScrollDisabled(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  });

  return (
    <>
      <Helmet>
        <title>Natal Chart: How does astrology work? | Co-Star</title>
        <meta
          name="description"
          content="The natal chart (a.k.a. birth chart) is a map of the universe at the exact place and time that someone was born."
        />
      </Helmet>
      <div className="floating-container">
        <div className={styles.pageContainer}>
          <AstroIntuitionHeader>
            <h1>Natal Chart</h1>
          </AstroIntuitionHeader>
          <AstroIntuitionContainer theme="transparent">
            <h3>
              The natal chart (a.k.a. birth chart) is a map of the universe at the exact time and
              place that you were born.
            </h3>
            <SectionPadding verticalPadding="2rem" />
            <p>
              Astrologers use this map to understand who you are. But, technically speaking, this
              map is pure astronomy: it describes the location of the planets at a specific moment
              in time.
            </p>
          </AstroIntuitionContainer>
          <AstroIntuitionContainer theme="white">
            <h3>
              The planets are shown from the perspective of Earth, against the ring of
              constellations called the zodiac.
            </h3>
            <SectionPadding verticalPadding="2rem" />
            <h4>this is an example natal chart</h4>
            <img src={require('../../../../../images/natal-chart-with-planets.png')} />
            <img src={require('../../../../../images/arrow.svg')} className={styles.arrow} />
            <h3>The sign names are constellations</h3>
            <SectionPadding verticalPadding=".5rem" />
            <p>
              From the perspective of the Earth, at any given time, each planet is in front of one
              of these constellations.
            </p>
            <SectionPadding verticalPadding="1.5rem" />
            <img src={require('../../../../../images/natal-chart-with-planets.png')} />
            <img src={require('../../../../../images/arrow.svg')} className={styles.arrow} />

            <h3>These symbols are the planets</h3>
            <SectionPadding verticalPadding=".5rem" />
            <p>
              For example, if Mars was in front of the Libra constellation when you were born, your
              Mars is in Libra.
            </p>
          </AstroIntuitionContainer>
          <AstroIntuitionContainer theme="dark">
            <p>
              Let’s look at how we can create a natal chart for a person. We will name this example
              person Nic. Nic was born at 7pm CST on June 13, 1990 in Milwaukee.
            </p>
            <SectionPadding verticalPadding="2rem" />
            <h3>
              Since it is evening time, the Sun is just setting on the western horizon. If we look
              to the eastern horizon, we see the constellation of Scorpio and we see the Moon.
            </h3>

            <img
              src={require('../../../../../images/astro-baby-3.png')}
              className={styles.wideImage}
              alt="horizon with baby and scorpio constellation rising"
              id="astro-baby"
            />
            <p>With just this information, we can draw their chart like this:</p>
            <ZodiacRing
              startingSign="Leo"
              className={styles.wideImage}
              planets={planetsForImageOne}
              planetObjs={planetObjsForImageOne}
              astroBaby
            />
            <p style={{ fontStyle: 'italic' }}>
              The constellations (or “zodiac signs”) are always 30° apart and in the same order. If
              we know where one zodiac sign is, we know where all of the other zodiac signs are,
              too.
            </p>
            <SectionPadding verticalPadding="4rem" />
            <h3>All of the other planets are in the sky too.</h3>
            <SectionPadding verticalPadding="2rem" />
            <p>
              Let’s add Mercury and Venus into the picture now. The orbiting balls show their
              movement around the Sun.
              <br />
              <br />
              The symbols ☿ (Mercury) and ♀ (Venus) on the chart show where they are in their orbits
              when Nic was born.
            </p>
            <SectionPadding verticalPadding="2rem" />
            <div className={styles.orbitContainer}>
              <ZodiacRing
                startingSign="Leo"
                planets={planetsForImageTwo}
                planetObjs={planetObjsForImageTwo}
                className={cn(styles.orbitZodiac)}
                centerImage
              />
              <OrbitAnimation />
            </div>
          </AstroIntuitionContainer>
          <AstroIntuitionContainer theme="transparent">
            <p>
              This is all interesting astronomy, which anyone at NASA could understand and agree
              with. But what do we do with this natal chart? What does it mean?
            </p>
            <SectionPadding verticalPadding="1.5rem" />
            <h3>
              <span style={{ fontWeight: 'bold' }}>Astrology</span> defines how we interpret the{' '}
              <span style={{ fontWeight: 'bold' }}>astronomy.</span>
            </h3>
            <SectionPadding verticalPadding="1.5rem" />
            <p>For example, what can we say about someone whose Mars is in Gemini?</p>
          </AstroIntuitionContainer>
          <NatalChartScrollContainer theme="dark-parallax-sticky" ref={scrollBox}>
            <NatalChartScrollBox scrollDisabled={scrollDisabled} />
            <img
              src={require('../../../../../images/Gemini+Mars.png')}
              className={styles.gemini}
              alt="gemini constellation with mars"
            />
            <img
              src={require('../../../../../images/Earth2.png')}
              className={styles.earth}
              alt="earth"
            />
          </NatalChartScrollContainer>
          <AstroIntuitionContainer theme="white">
            <h3>
              In astrology, every combination of planet and constellation – or zodiac sign – has a
              specific meaning.
            </h3>
          </AstroIntuitionContainer>
          <AstroIntuitionContainer theme="light">
            <div className={styles.astroTableContainer}>
              <div className={styles.astroTable}>
                <h3>The planets are the verbs.</h3>
                <SectionPadding verticalPadding="1rem" />
                <AstroIntuitionTable columnOneData={planets} columnTwoData={planetDescriptors} />
              </div>
              <div className={styles.astroTable}>
                <h3>The signs are the adverbs.</h3>
                <SectionPadding verticalPadding="1rem" />
                <AstroIntuitionTable
                  columnOneData={signs}
                  columnTwoData={signDescriptors}
                  theme="signs"
                />
              </div>
            </div>
            <SectionPadding verticalPadding="1rem" />
            <h3>So:</h3>
            <SectionPadding verticalPadding="1rem" />
            <div className={styles.highlightedText}>
              <p>
                <span className={styles.blue}>Mars</span> in{' '}
                <span className={styles.red}>Gemini</span>
              </p>
              <p>=</p>
              <p>
                "I <span className={styles.blue}>take action</span> in a{' '}
                <span className={styles.red}>curious, chaotic</span> way."
              </p>
            </div>
          </AstroIntuitionContainer>
        </div>
      </div>
      <AstroIntuitionFooter />
    </>
  );
};

export default PageLayout(NatalChart);
