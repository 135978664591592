import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import '../../../style/jobs.scss';
import PageLayout from 'landing-page/views/PageLayout';
import { PressFeatures } from '../press-features';
import FooterForm from 'landing-page/components/FooterForm';
import { sendClickEvent } from 'analytics';

interface JobInterface {
  APICall: { [key: string]: any };
  error: String;
}
class JobsPage extends React.Component<JobInterface> {
  constructor(props) {
    super(props);

    this.state = {
      APICall: [],
      error: '',
    };
  }

  jobspageClick(action: string) {
    return () => {
      sendClickEvent('Jobs', action);
    };
  }

  componentDidMount() {
    fetch('https://boards-api.greenhouse.io/v1/boards/costar/departments')
      .then((res) => res.json())
      .then((data) => {
        this.setState({ APICall: data.departments });
      })
      .catch((err) => this.setState({ error: err.message }));
  }

  render() {
    const applyDataMap = {
      // Engineering
      'Engineering Manager': 'apply-mobile-mgr',
      'iOS Engineer': 'apply-ios',
      'Android Engineer': 'apply-android',
      'Haskell Engineer': 'apply-fs',
      'DevOps Engineer - Data': 'apply-data-eng',

      // Product
      'Head of Product': 'apply-head-of-product',
      'Product Lead, Personalization': 'apply-product-lead',
      'Product Lead, Social': 'apply-product-mgr',
      'User Experience Researcher': 'apply-researcher',
      'Product Analyst': 'apply-product-analyst',

      // Content
      'Project Manager, Content': 'apply-sr-content-strategist',
      'Astrology Writer': 'apply-astrology-writer',
      'Technical Copy Editor': 'apply-technical-copy',
      Copywriter: 'apply-copywriter',

      // Operations
      'Business Operations Manager': 'apply-business-operations',
      Accountant: 'apply-accountant',

      // Brand
      'TikTok Resident Creator': 'apply-tiktok',
    };
    const { APICall, error } = this.state;
    return (
      <div>
        <Helmet>
          <title> Jobs | Co – Star: Hyper-Personalized, Real-Time Horoscopes</title>
        </Helmet>
        <div className="container static-container">
          <div className="floating-container-inner">
            <h1> Jobs </h1>
            <h3>
              {' '}
              We’re hacking tech, culture, and consciousness to build new practices for reflection &
              collectivity.{' '}
            </h3>
            <p>
              {' '}
              <span className="smallcaps">
                Five years after launching, someone, somewhere downloads Co–Star every 5 seconds.
              </span>{' '}
              Big Sean has <a href="https://youtu.be/dAl8qGIrcSI?t=203">rapped</a> about us, Ariana
              Grande &{' '}
              <a href="https://www.12up.com/posts/kevin-durant-posts-cryptic-instagram-story-amid-free-agency-and-injury-rumors-01dcsry062kv">
                Kevin Durant
              </a>{' '}
              post screenshots to their IGs, and we’ve been{' '}
              <a href="https://apps.apple.com/us/story/id1458814851">App of the Day</a> twice.
              Merging 4,000 years of astrological tradition with NASA data interpreted by humans and
              assembled by machines, Co–Star decodes sky and earth to create a new model for
              relating to ourselves and each other.{' '}
            </p>
            <p>
              {' '}
              <span className="smallcaps">
                We’re merging the resonance of the humanities with the precision of technology.
              </span>{' '}
              Every day, we generate millions of hyper-personalized horoscopes using rules-based
              natural language generation that combines state-of-the-art AI models like GPT with the
              insights of staff poets. Inspired by poetry, psychoanalysis, and astrology, Co–Star is
              the first synthetic text to be called{' '}
              <a href="https://www.vanityfair.com/style/2019/05/co-star-astrology-app-notifications-founder">
                wildly beautiful
              </a>
              ,{' '}
              <a href="https://gizmodo.com/android-users-can-finally-get-in-on-co-star-s-brutally-1841067701">
                brutally honest
              </a>
              , or comparable to{' '}
              <a href="https://gizmodo.com/android-users-can-finally-get-in-on-co-star-s-brutally-1841067701">
                therapy.
              </a>
            </p>
            <p>
              {' '}
              <span className="smallcaps">It’s still early days.</span> We’re building out the
              social side of the app with features like your top friends for the day, asynchronized
              messaging, and groups, developed in tandem with practicing group therapists. We've
              raised $21,000,000 from the people behind companies like Twitter, Discord, and Snap to
              build thoughtful new features, increase reliability as we scale, and develop new
              models for humans &amp; their relationships.
            </p>

            <h3> Benefits &amp; Perks</h3>

            <ul>
              <li>Fully covered $0 deductible healthcare + vision, dental, and therapy</li>
              <li>401(k) + 5% matching </li>
              <li>Unlimited vacation + 2 week company-wide recharge at the end of each year</li>
              <li>Equipment budget</li>
              <li>12 weeks 100% paid parental leave</li>
              <li>Readings with in-house astrologers</li>
            </ul>

            <p>
              {' '}
              <Link to="/team">
                <h4>Meet the team &rarr;</h4>
              </Link>{' '}
            </p>

            <h3>Current openings</h3>
            <p>
              {' '}
              While we're based in{' '}
              <a
                href="https://www.google.com/maps/place/401+Broadway,+New+York,+NY+10013/"
                target="_blank"
                rel="noreferrer"
              >
                NYC
              </a>
              , we're currently working remotely and are open to hiring remote candidates.{' '}
            </p>

            {error !== '' && <div>{error}</div>}
            {APICall.map((item) => (
              <>
                {item.name === 'Engineering' && (
                  <>
                    <h4>{item.name}</h4>
                    <p>
                      We'll pay you $5,000 to refer amazing engineers, product managers, and data
                      scientists. Make sure they tell us in writing you referred them when they
                      apply. Once they've worked for us for 180 days, we'll send you a check for
                      $5,000. Simple.{' '}
                    </p>

                    <div className="jobsOpeningTable">
                      {item.jobs.map((job) => (
                        <div className="jobsOpeningBox">
                          {job.title}
                          <br />
                          <a
                            className="jobsApplyLink"
                            href={job.absolute_url}
                            onClick={this.jobspageClick(applyDataMap[`${job.title}`])}
                          >
                            Apply &#62;
                          </a>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </>
            ))}
            {APICall.map((item) => (
              <>
                {item.name === 'Product' && (
                  <>
                    <h4>{item.name}</h4>
                    <div className="jobsOpeningTable">
                      {item.jobs.map((job) => (
                        <div className="jobsOpeningBox">
                          {job.title}
                          <br />
                          <a
                            className="jobsApplyLink"
                            href={job.absolute_url}
                            onClick={this.jobspageClick(applyDataMap[`${job.title}`])}
                          >
                            Apply &#62;
                          </a>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </>
            ))}
            {APICall.map((item) => (
              <>
                {item.name === 'Content' && (
                  <>
                    <h4>{item.name}</h4>
                    <div className="jobsOpeningTable">
                      {item.jobs.map((job) => (
                        <div className="jobsOpeningBox">
                          {job.title}
                          <br />
                          <a
                            className="jobsApplyLink"
                            href={job.absolute_url}
                            onClick={this.jobspageClick(applyDataMap[`${job.title}`])}
                          >
                            Apply &#62;
                          </a>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </>
            ))}
            {APICall.map((item) => (
              <>
                {item.name === 'Operations' && (
                  <>
                    <h4>{item.name}</h4>
                    <div className="jobsOpeningTable">
                      {item.jobs.map((job) => (
                        <div className="jobsOpeningBox">
                          {job.title}
                          <br />
                          <a
                            className="jobsApplyLink"
                            href={job.absolute_url}
                            onClick={this.jobspageClick(applyDataMap[`${job.title}`])}
                          >
                            Apply &#62;
                          </a>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </>
            ))}
            {APICall.map((item) => (
              <>
                {item.name === 'Brand (Community)' && (
                  <>
                    <h4>{item.name}</h4>
                    <div className="jobsOpeningTable">
                      {item.jobs.map((job) => (
                        <div className="jobsOpeningBox">
                          {job.title}
                          <br />
                          <a
                            className="jobsApplyLink"
                            href={job.absolute_url}
                            onClick={this.jobspageClick(applyDataMap[`${job.title}`])}
                          >
                            Apply &#62;
                          </a>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </>
            ))}
            <h3> Benefits &amp; culture </h3>

            <ul>
              <li>
                <span className="smallcaps">Diversity and inclusion.</span> We prioritize the safety
                &amp; well-being of our team, and do not tolerate discrimination of any kind.
                Co–Star is open and respectful to all identities and intersections of identity, and
                employees are expected to uphold these values and practices.
              </li>
              <li>
                <span className="smallcaps">Top notch benefits.</span> Unlimited vacation (plus a
                two week company-wide recharge at the end of each year), $0 deductible fully-covered
                healthcare (including therapy, vision, and dental), 401k, and generous equity.
                Everyone is empowered to buy books, attend conferences, and pursue stuff that makes
                their jobs easier.
              </li>
              <li>
                <span className="smallcaps">Cross-functional collaboration.</span> We believe that
                complex problems are best solved by people with different orientations. Writers work
                directly with AI models. Engineers give presentations on communities they've been a
                part of. Designers collaborate closely with practicing therapists. Everyone gets a
                reading from a staff astrologer when they start.{' '}
              </li>
              <li>
                <span className="smallcaps">Hypothesis-driven experimentation. </span> We ideate
                collectively, test rigorously, and iterate quickly. We interview users to validate
                hypotheses, ship MVPs fast and often, A/B test everything, and use data to inform
                our decisions.
              </li>
              <li>
                <span className="smallcaps">
                  Work with a thoughtful, open and tight-knit group.
                </span>{' '}
                Every single member of the team has deep knowledge of and experience in something
                relevant to building Co–Star, be it astrology, community, or world-building.{' '}
              </li>
            </ul>

            <h3> In the press </h3>

            <div className="jobsPressTable">
              <div className="jobsPressBox">
                <span className="smallcaps">
                  Co–Star, the Astrology App Conquering the Millennial Lock Screen
                </span>
                <br />
                “Co–Star’s edge is that it uses A.I. to organize avalanches of information on a
                daily basis.”
                <br />
                <a
                  href="https://www.vanityfair.com/style/2019/05/co-star-astrology-app-notifications-founder"
                  target="_blank"
                  rel="noreferrer"
                >
                  Vanity Fair
                </a>
              </div>
              <div className="jobsPressBox">
                <span className="smallcaps">Co–Star raises $5 million</span>
                <br />
                “Nothing scales like a horoscope.”
                <br />
                <a
                  href="https://techcrunch.com/2019/04/17/co-star-astrology-app-seed-round/"
                  target="_blank"
                  rel="noreferrer"
                >
                  TechCrunch
                </a>
              </div>
              <div className="jobsPressBox">
                <span className="smallcaps">
                  Venture Capital Is Putting Its Money Into Astrology
                </span>
                <br />
                “Astrology is having a cultural moment, and for investors, that translates to dollar
                signs.”
                <br />
                <a
                  href="https://www.nytimes.com/2019/04/15/style/astrology-apps-venture-capital.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  The New York Times
                </a>
              </div>
              <div className="jobsPressBox">
                <span className="smallcaps">This Emptiness Is Normal</span>
                <br />
                “How Co–Star translated astrology into practical tools for better understanding who
                we are and how we relate to each other.”
                <br />
                <a
                  href="https://www.youtube.com/watch?v=gFJlk0so6P8&t=4s"
                  target="_blank"
                  rel="noreferrer"
                >
                  Design Matters 19
                </a>
              </div>
            </div>

            <p>
              {' '}
              <Link to="/about/">
                <h4>Learn more about Co–Star &rarr;</h4>
              </Link>{' '}
            </p>
          </div>
        </div>

        <PressFeatures />
        <FooterForm formLocation="Jobs" />
      </div>
    );
  }
}

export default PageLayout(JobsPage);
