import React from 'react';
import cn from 'classnames';
import { Planet, planetData, Sign } from '../../../astrology';

import styles from './AdvancedChart.module.scss';

type AdvancedChartAttributionProps = {
  planet: Planet;
  sign: Sign;
};

const AdvancedChartAttribution = ({ planet, sign }: AdvancedChartAttributionProps) => (
  <div className={cn(styles.attributionItem, { [styles.ascendant]: planet === 'Ascendant' })}>
    <span id={planet}>{planetData[planet].symbol}</span>
    <p>{sign}</p>
  </div>
);

export default AdvancedChartAttribution;
