import React from 'react';
import { Helmet } from 'react-helmet';
import { planetData } from 'astrology';
import { AstroAnalysis } from 'api';

type NatalChartHelmetProps = {
  username: string | undefined;
  remoteAnalysis: AstroAnalysis;
};

const NatalChartHelmet = ({ username = undefined, remoteAnalysis }: NatalChartHelmetProps) => {
  if (username) {
    return (
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="og:title"
          content={`@${username} | Co — Star: Hyper-Personalized, Real-Time Horoscopes`}
        />
        <meta
          property="og:description"
          content={`${planetData.Sun.symbol} ${remoteAnalysis.planets.Sun.position.sign} ${planetData.Moon.symbol} ${remoteAnalysis.planets.Moon.position.sign} ${planetData.Ascendant.symbol} ${remoteAnalysis.planets.Ascendant.position.sign}
         , personalized natal chart for @${username}`}
        />
      </Helmet>
    );
  }
  return (
    <Helmet>
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="og:title" content="Co — Star: Hyper-Personalized, Real-Time Horoscopes" />
      <meta
        property="og:description"
        content="Hyper-personalized, real-time horoscopes. Based on detailed analysis of the current skies in conjunction with your natal chart, our algorithms can predict who you are, how you exist in the world, and your future."
      />
      <meta property="og:image" content="" />
    </Helmet>
  );
};

export default NatalChartHelmet;
