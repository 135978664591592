import React from 'react';
import { redirectToAppropriateAppstore, getMobileOperatingSystem } from 'utils';
import { sendClickEvent } from 'analytics';
import {
  itunesStoreURL,
  ITUNES_CAMPAIGN_TOKENS,
  playStoreUrl,
  PLAY_STORE_CAMPAIGN_TOKENS,
} from '../../../../constants';

import styles from './AdvancedReadingsProductCard.module.scss';

type AdvancedReadingsProductCardProps = {
  imageURL: string;
  name: string;
};

const AdvancedReadingsProductCard = ({ imageURL, name }: AdvancedReadingsProductCardProps) => {
  const APP_STORE_URL = itunesStoreURL(ITUNES_CAMPAIGN_TOKENS.SITE_SHOP);
  const PLAY_STORE_URL = playStoreUrl(PLAY_STORE_CAMPAIGN_TOKENS.SITE_SHOP);
  const isMobile = getMobileOperatingSystem();
  const handleClick = () => {
    sendClickEvent('PremiumReport', name);
    if (isMobile) {
      redirectToAppropriateAppstore(getMobileOperatingSystem(), APP_STORE_URL, PLAY_STORE_URL);
    } else {
      window.open('https://apps.apple.com/us/app/co-star/id1264782561');
    }
  };
  const onKeyDown = (e) => {
    if (['keydown', 'keypress'].includes(e.type) && ['Enter', ' '].includes(e.key)) {
      handleClick();
    }
  };
  return (
    <div
      className={styles.container}
      onClick={handleClick}
      onKeyDown={onKeyDown}
      role="button"
      tabIndex={0}
    >
      <img src={imageURL} alt="" />
    </div>
  );
};

export default AdvancedReadingsProductCard;
