import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import Api from 'api';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import Input from '../Input';
import ContactFormModal from './ContactFormModal';

import 'react-phone-number-input/style.css';

import styles from './ContactForm.module.scss';

import {
  contactFormTypeOptions,
  contactFormChildrenOptions,
  placeholderCopy,
  bugCopy,
  generalFeedbackCopy,
} from './ContactFormTypes';

type ContactFormProps = {
  darkMode: boolean;
  utmMedium: string | undefined;
};

const ContactForm = ({ darkMode = false, utmMedium }: ContactFormProps) => {
  const {
    register,
    handleSubmit,
    control,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const [feedbackType, setFeedbackType] = useState('default');
  const [submitted, setSubmitted] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState('');

  const handleSelectChange = (event) => {
    setFeedbackType(event.target.value);
    clearErrors();
  };

  const validateInput = (event) => {
    event.target.classList.remove('error');
    if (!event.target.validity.valid) {
      event.target.classList.add('error');
    }
  };

  const onSubmit = (data, event) => {
    setButtonDisabled(true);

    if (!event.target.checkValidity()) {
      for (const i of event.target.elements) {
        validateInput({
          target: i,
        });
      }
    }

    const { email } = data;

    // format feedback message to display keys&values of feedback
    const feedbackMessage = Object.keys(data).reduce((str, key, i) => {
      // conditional check as we'll populate email directly to req body
      // if (key !== 'email') {
      // quick check to see if linebreak is needed for formatted feedback msg
      const addLineBreak = i !== Object.keys(data).length - 1 ? '\n' : '';
      str += `${key}: ${data[key]}${addLineBreak}`;
      // }
      return str;
    }, `Feedback Type: ${feedbackType} \nuser metadata (only applicable for app contact form submission): ${utmMedium} \n`);

    Api.sendFeedback({
      feedback: feedbackMessage,
      email: email || utmMedium || null,
      mood: null,
    })
      .then((_) => {
        setSubmitted(true);
        setButtonDisabled(false);
        setModalOpen(false);
      })
      .catch((_) => {
        setSubmitted(false);
        setError('Something went wrong, please try again.');
        setButtonDisabled(false);
        setModalOpen(false);
      });
  };

  const selectComponentOptions = contactFormTypeOptions.reduce(
    (arr, op, i) => {
      arr.push(
        <option value={op.toLowerCase().replace(/ /g, '-')} key={i + 1}>
          {op}
        </option>,
      );
      return arr;
    },
    [
      <option disabled value="default" key={0}>
        Please select your issue
      </option>,
    ],
  );

  const emailNotRequired = feedbackType === 'feedback' || feedbackType === 'other';
  let textAreaCopy = generalFeedbackCopy;

  if (feedbackType === 'bug') textAreaCopy = bugCopy;

  const formComponents =
    feedbackType === 'default'
      ? null
      : contactFormChildrenOptions[feedbackType].map((opt, i) => {
          if (opt === 'message') {
            return (
              <textarea
                {...register('message', { required: true })}
                placeholder={textAreaCopy}
              />
            );
          }
          if (opt.includes('phone-number')) {
            return (
              <PhoneInputWithCountry
                name={opt}
                placeholder={placeholderCopy[opt]}
                international
                control={control}
                rules={{ required: true }}
                key={opt}
                className={errors[opt] ? ' error' : ''}
                required
              />
            );
          }
          if (opt === 'email') {
            return (
              <Input
                type="email"
                placeholder={placeholderCopy[opt]}
                className="email"
                key={opt}
                onChange={validateInput}
                error={errors[opt]}
                {...register(opt, {
                  required: !emailNotRequired,
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Invalid email address',
                  },
                })}
                required={!emailNotRequired}
              />
            );
          }
          return (
            <Input
              type="text"
              placeholder={placeholderCopy[opt]}
              className="email"
              key={opt}
              onChange={validateInput}
              error={errors[opt]}
              {...register(opt, {
                // weird conditional for username being optional only with FB login selected
                required: !(feedbackType === 'facebook-login' && opt === 'username'),
              })}
              required={!(feedbackType === 'facebook-login' && opt === 'username')}
            />
          );
        });

  const showModal = emailNotRequired && !getValues('email');

  const submitButton = (
    <input className="submit black btn" type="submit" disabled={buttonDisabled} value="send" />
  );

  const openModal = (
    <button className="submit black btn" type="button" onClick={() => setModalOpen(true)}>
      SEND
    </button>
  );

  const showButton = feedbackType !== 'default';

  const button = showModal ? openModal : submitButton;

  return (
    <div
      className={cn('floating-container', {
        [styles.darkContainer]: darkMode === true,
      })}
    >
      <div
        className={cn('floating-container-inner', {
          [styles.dark]: darkMode === true,
        })}
      >
        {error && <div className="error-message">{error}</div>}
        {!submitted ? (
          <div>
            <h2>How can we help?</h2>
            <form name="feedbackForm" id="contact-form" onSubmit={handleSubmit(onSubmit)}>
              <select name="feedback-type" onChange={handleSelectChange} defaultValue="default">
                {selectComponentOptions}
              </select>
              {formComponents}
              {showButton && button}
              <ContactFormModal
                closeModal={() => setModalOpen(false)}
                buttonDisabled={buttonDisabled}
                isOpen={modalOpen}
              />
            </form>
            <p>
              Press? Please email{' '}
              <a href="mailto:press@costarastrology.com">press@costarastrology.com</a>
              <br />
              Shop? Please email{' '}
              <a href="mailto:shop@costarastrology.com">shop@costarastrology.com</a>.
            </p>
          </div>
        ) : (
          <h4>
            Thanks for your feedback!
            <br /> If you submitted a request for a password update, phone number change, FB login
            issue, or bug, someone will follow up soon
          </h4>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
