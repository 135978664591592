import React from 'react';

type SectionPaddingProps = {
  verticalPadding: string;
};

const SectionPadding = ({ verticalPadding }: SectionPaddingProps) => (
  <div
    style={{
      width: '100%',
      padding: `${verticalPadding} 0`,
    }}
  />
);

export default SectionPadding;
