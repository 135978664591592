import React from 'react';
import cn from 'classnames';

import themeStyles from './AstroIntuitionContainer.module.scss';
import styles from '../AstroIntuition.module.scss';

type ContainerTheme =
  | 'dark'
  | 'light'
  | 'white'
  | 'transparent'
  | 'footer'
  | 'dark-orb'
  | 'transparent-noPadding'
  | 'zodiac-light'
  | 'zodiac-dark'
  | 'zodiac-transparent'
  | 'zodiac-white'
  | 'dark-parallax-sticky';

type AstroIntuitionContainerProps = {
  theme: ContainerTheme;
  children: React.ReactNode;
  id?: string;
};

const innerContainerStyles = cn('container-inner', styles.containerInner);

const AstroIntuitionContainer = ({ theme, children, id = '' }: AstroIntuitionContainerProps) => (
  <div
    className={cn(themeStyles.base, 'container', {
      [themeStyles.dark]: theme.includes('dark'),
      [themeStyles.light]: theme.includes('light'),
      [themeStyles.white]: theme.includes('white'),
      [themeStyles.transparent]: theme.includes('transparent') || theme === 'footer',
      [themeStyles.footer]: theme === 'footer',
      [themeStyles.sticky]: theme.includes('sticky'),
      [themeStyles.darkOrb]: theme === 'dark-orb',
      [themeStyles.noPadding]: theme.includes('noPadding'),
    })}
    id={id}
  >
    <div
      className={cn(
        {
          [styles.footer]: theme === 'footer',
          [themeStyles.parallax]: theme.includes('parallax'),
          [themeStyles.noPadding]: theme.includes('noPadding'),
          [themeStyles.zodiacPage]: theme.includes('zodiac'),
        },
        innerContainerStyles,
      )}
    >
      {children}
    </div>
  </div>
);

AstroIntuitionContainer.defaultProps = {
  id: '',
};

export default AstroIntuitionContainer;
