// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../../../../images/ac-modal-border.png";
import ___CSS_LOADER_URL_IMPORT_1___ from "../../../../../../images/you-in-love-card-bg.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eeKcr5rnrDo\\+FJGF34mXUQ\\=\\={background-origin:border-box,border-box !important;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") !important;background-position:center,center !important;background-repeat:no-repeat,no-repeat !important;background-color:rgba(0,0,0,0);background-size:contain,contain;width:315px;height:493px}._8rbTIpwszmSjSGU70fn7\\+g\\=\\={padding:40px 0}._8rbTIpwszmSjSGU70fn7\\+g\\=\\= img{max-width:260px;margin-left:20px;margin-bottom:24px}._8rbTIpwszmSjSGU70fn7\\+g\\=\\= h3{padding:0 30px 5px;font-size:22px;line-height:26px}._5m5kDMwR-GU5iRrlWm96uQ\\=\\={border-radius:3px;margin-top:15px;border-color:rgba(0,0,0,0) !important}", "",{"version":3,"sources":["webpack://./src/landing-page/pages/Shop/AdvancedReadings/YouInLoveProductCard/YouInLoveProductCard.module.scss"],"names":[],"mappings":"AAAA,6BACE,kDAAA,CACA,2GAAA,CAEA,4CAAA,CACA,gDAAA,CACA,8BAAA,CACA,+BAAA,CACA,WAAA,CACA,YAAA,CAGF,8BACE,cAAA,CACA,kCACE,eAAA,CACA,gBAAA,CACA,kBAAA,CAEF,iCACE,kBAAA,CACA,cAAA,CACA,gBAAA,CAIJ,6BACE,iBAAA,CACA,eAAA,CACA,qCAAA","sourcesContent":[".backgroundContainer {\n  background-origin: border-box, border-box !important;\n  background-image: url('../../../../../../images/ac-modal-border.png'),\n    url('../../../../../../images/you-in-love-card-bg.png') !important;\n  background-position: center, center !important;\n  background-repeat: no-repeat, no-repeat !important;\n  background-color: transparent;\n  background-size: contain, contain;\n  width: 315px;\n  height: 493px;\n}\n\n.contentContainer {\n  padding: 40px 0;\n  img {\n    max-width: 260px;\n    margin-left: 20px;\n    margin-bottom: 24px;\n  }\n  h3 {\n    padding: 0 30px 5px;\n    font-size: 22px;\n    line-height: 26px;\n  }\n}\n\n.button {\n  border-radius: 3px;\n  margin-top: 15px;\n  border-color: transparent !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backgroundContainer": "eeKcr5rnrDo+FJGF34mXUQ==",
	"contentContainer": "_8rbTIpwszmSjSGU70fn7+g==",
	"button": "_5m5kDMwR-GU5iRrlWm96uQ=="
};
export default ___CSS_LOADER_EXPORT___;
