export type AspectObject = {
  title: string;
  goodness: string;
  degrees: number;
  meaning: string;
  importance: string;
  imageHeight: string;
};

export const AspectObjects: AspectObject[] = [
  {
    title: 'Conjunction',
    goodness: 'Neutral',
    degrees: 0,
    meaning: 'Same same',
    importance: 'Most important',
    imageHeight: '16px',
  },
  {
    title: 'Opposition',
    goodness: 'Neutral',
    degrees: 180,
    meaning: 'Mirror opposites',
    importance: 'Most important',
    imageHeight: '15px',
  },
  {
    title: 'Square',
    goodness: 'Friction / Frustration',
    degrees: 90,
    meaning: 'Conflict',
    importance: 'Very important',
    imageHeight: '15px',
  },
  {
    title: 'Trine',
    goodness: 'Harmonious & Friendly',
    degrees: 120,
    meaning: 'Harmony',
    importance: 'Very important',
    imageHeight: '15px',
  },
  {
    title: 'Sextile',
    goodness: 'Harmonious & Friendly',
    degrees: 60,
    meaning: 'Supportive',
    importance: 'Pretty important',
    imageHeight: '17px',
  },
  {
    title: 'Semisextile',
    goodness: 'Harmonious & Friendly',
    degrees: 30,
    meaning: 'Growth',
    importance: 'Not too important',
    imageHeight: '11px',
  },
  {
    title: 'Semisquare',
    goodness: 'Friction / Frustration',
    degrees: 45,
    meaning: 'Friction',
    importance: 'Not too important',
    imageHeight: '15px',
  },
  {
    title: 'Quincunx',
    goodness: 'Friction / Frustration',
    degrees: 150,
    meaning: 'Adjustment',
    importance: 'Not too important',
    imageHeight: '11px',
  },
];

export const planetsForImageOne = [{ name: 'Ascendant' }, { name: 'Sun' }, { name: 'Moon' }];

export const planetObjsForImageOne = {
  Ascendant: {
    imageHeight: 21,
    imageWidth: 12,
    x: -180,
    y: -10.500000000000028,
  },
  Sun: {
    imageHeight: 21,
    imageWidth: 21,
    degree: 17,
  },
  Moon: {
    imageHeight: 21,
    imageWidth: 16,
    degree: 190,
  },
};

export const aspectsForImageOne = [['Moon', 'Sun', 180]];

export const planetObjsForImageTwo = {
  Ascendant: {
    imageHeight: 21,
    imageWidth: 12,
    x: -180,
    y: -10.500000000000028,
  },
  Sun: {
    imageHeight: 21,
    imageWidth: 21,
    degree: 17,
  },
  Mars: {
    imageHeight: 17,
    imageWidth: 18,
    degree: 11,
  },
};

export const planetsForImageTwo = [{ name: 'Ascendant' }, { name: 'Sun' }, { name: 'Mars' }];

export const planetObjsForImageThree = {
  Ascendant: {
    imageHeight: 21,
    imageWidth: 12,
    x: -180,
    y: -10.500000000000028,
  },
  Sun: {
    imageHeight: 21,
    imageWidth: 21,
    degree: 17,
  },
  Venus: {
    imageHeight: 21,
    imageWidth: 13,
    degree: 257,
  },
  Jupiter: {
    imageHeight: 21,
    imageWidth: 13,
    degree: 137,
  },
  Moon: {
    imageHeight: 21,
    imageWidth: 16,
    degree: 190,
  },
};

export const planetsForImageThree = [
  { name: 'Ascendant' },
  { name: 'Sun' },
  { name: 'Venus' },
  { name: 'Jupiter' },
  { name: 'Moon' },
];

export const aspectsForImageThree = [
  ['Sun', 'Jupiter', 120],
  ['Jupiter', 'Venus', 120],
  ['Sun', 'Venus', 120],
];

export const planetObjsForImageFour = {
  Ascendant: {
    imageHeight: 21,
    imageWidth: 12,
    x: -180,
    y: -10.500000000000028,
    planetPoints: [-245, -185, -2],
  },
  Mars: {
    imageHeight: 17,
    imageWidth: 18,
    degree: 315,
  },
  Venus: {
    imageHeight: 21,
    imageWidth: 13,
    degree: 45,
  },
  Jupiter: {
    imageHeight: 21,
    imageWidth: 13,
    degree: 135,
  },
  Uranus: {
    imageHeight: 21,
    imageWidth: 14,
    degree: 225,
  },
};

export const aspectsForImageFour = [
  ['Jupiter', 'Venus', 90],
  ['Venus', 'Mars', 90],
  ['Mars', 'Uranus', 90],
  ['Uranus', 'Jupiter', 90],
];

export const planetsForImageFour = [
  { name: 'Ascendant' },
  { name: 'Uranus' },
  { name: 'Venus' },
  { name: 'Jupiter' },
  { name: 'Mars' },
];
