import React from 'react';

import cn from 'classnames';
import styles from '../AstroIntuition.module.scss';

// const innerContainerStyles = cn('container-inner', styles.containerInner, styles.header);

type AstroIntuitionHeaderProps = {
  children: React.ReactNode;
};

const AstroIntuitionHeader = ({ children }: AstroIntuitionHeaderProps) => (
  <div className={cn(styles.header, 'floating-container-inner')}>{children}</div>
);

export default AstroIntuitionHeader;
