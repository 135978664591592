export const columnOne = [
  '1ST HOUSE',
  '2ND HOUSE',
  '3RD HOUSE',
  '4TH HOUSE',
  '5TH HOUSE',
  '6TH HOUSE',
  '7TH HOUSE',
  '8TH HOUSE',
  '9TH HOUSE',
  '10TH HOUSE',
  '11TH HOUSE',
  '12TH HOUSE',
];

export const columnTwo = [
  'Self-image, the impressions you make on others',
  'Your personal resources, what makes you feel safe and secure',
  'What you know and your everyday surroundings, including siblings and familiar patterns',
  'Home life, family, close relationships, the past and its effect on you',
  'Pleasure & creativity, self-expression, fun, children',
  'Productivity, service, routines',
  'Partnership and committed relationships, what you bring into your world through other people',
  'How you relate to other people’s resources and things out of your control, including beginnings and endings, transformations, crises',
  'Open-mindedness, philosophy, exchange of culture and ideas, expanded consciousness, travel',
  'Your public self and commitments, career, how you wanted to be remembered, your legacy',
  'Your social world, friends & acquaintances, how you relate to groups of people',
  'Your unconscious, dreams, fantasies',
];

export const housesItemsCopy = [
  {
    header: 'Independence vs Harmony',
    houses: ['1st', '7th'],
    blurbs: [
      'How you assert yourself to others',
      'How you function in close relationships, and how you use those relationships to learn about yourself',
    ],
  },
  {
    header: 'Stability vs Trust',
    houses: ['2nd', '8th'],
    blurbs: [
      'Material resources, and the identity you construct from them',
      'The transformation or reshaping of your sense of self',
    ],
  },
  {
    header: 'Facts vs Ideas',
    houses: ['3rd', '9th'],
    blurbs: [
      'How you express what you know, how you communicate with those you are close to or see frequently',
      'How you increase what you know, how you expand your horizons, often in a more abstract sense than that of the 3rd house',
    ],
  },
  {
    header: 'Private life vs Public life',
    houses: ['4th', '10th'],
    blurbs: [
      'Your domestic world, your relationship with your (chosen) family, and the pull of the past',
      'How you go about achieving your goals, how you work, and what you hope to gain in the future',
    ],
  },
  {
    header: 'The Self vs the Collective',
    houses: ['5th', '11th'],
    blurbs: [
      'Creative self-expression and personal pleasure',
      'Shared goals, idealism, and prioritizing the food of a group above the self',
    ],
  },
  {
    header: 'Survival vs Transcendence',
    houses: ['6th', '12th'],
    blurbs: [
      'How we can be helpful to those we love, day-to-day routines, and a need for order',
      'A  more spiritual kind of service, or the work we need to do to protect and strengthen our own psychic space',
    ],
  },
];

export const planetsForImageTwo = [{ name: 'Ascendant' }, { name: 'Sun' }, { name: 'Moon' }];

export const planetObjsForImageTwo = {
  Ascendant: {
    imageHeight: 21,
    imageWidth: 12,
    x: -180,
    y: -10.500000000000028,
  },
  Sun: {
    imageHeight: 21,
    imageWidth: 21,
    x: 162,
    y: -50,
  },
  Moon: {
    imageHeight: 21,
    imageWidth: 16,
    x: -180,
    y: -50,
  },
};

export const aspectsForImageTwo = [
  [90, 270],
  [0, 180],
];

export const housePathsForImageTwo = [{ startAngle: 0, endAngle: 2 * Math.PI }];

export const housePathsForImageThree = [
  { startAngle: 0, endAngle: Math.PI / 2 },
  { startAngle: Math.PI / 2, endAngle: Math.PI },
  { startAngle: Math.PI, endAngle: (3 * Math.PI) / 2 },
  { startAngle: (3 * Math.PI) / 2, endAngle: 2 * Math.PI },
];

export const planetsForImageThree = [{ name: 'Ascendant' }];

export const planetObjsForImageThree = {
  Ascendant: {
    imageHeight: 21,
    imageWidth: 12,
    x: -180,
    y: -10.500000000000028,
  },
};

export const aspectsForImageThree = [
  [30, 210],
  [60, 240],
  [120, 300],
  [150, 330],
];
