import * as _ from 'underscore';

export class Channel<T> {
  private listeners: ((val: T) => void)[] = [];

  public readonly sender: Channel.Supplier<T> = {
    send: (val) => {
      this.listeners.forEach((listener) => listener(val));
    },
  };

  public readonly listener: Channel.Listener<T> = {
    receive: (cb) => {
      this.listeners.unshift(cb);
      return () => {
        this.listeners = _.without(this.listeners, cb);
      };
    },
  };
}

export module Channel {
  export interface Supplier<T> {
    send: (val: T) => void;
  }

  export interface Listener<T> {
    receive: (cb: (val: T) => void) => () => void;
  }
}

export default Channel;
