import React from 'react';
import Modal from 'react-modal';
import cn from 'classnames';

import styles from './ContactForm.module.scss';

type ContactFormModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  buttonDisabled: boolean;
};

const ContactFormModal = ({ isOpen, closeModal, buttonDisabled }: ContactFormModalProps) => {
  Modal.setAppElement('#app');
  return (
    <Modal
      isOpen={isOpen}
      portalClassName="CaptchaModalPortal"
      style={{ content: { inset: '50% 0 auto' } }}
    >
      <div className={styles.modalContainer}>
        <p className="body-font-basics">We appreciate your feedback!</p>{' '}
        <p className="subtitle body-font-basics">
          You are welcome to submit anonymously, but if you provide your email address, we can
          follow up with you.
        </p>
        <div className={styles.modalButtonsContainer}>
          <input
            className={cn('black btn', styles.modalButton)}
            type="submit"
            disabled={buttonDisabled}
            form="contact-form"
            value="submit anonymously"
          />
          <button
            type="button"
            className={cn('white btn learn-more', styles.modalButton)}
            onClick={closeModal}
          >
            GO BACK
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ContactFormModal;
