import React from 'react';
import { WebData } from 'server/request';
import { AstroAnalysis } from 'api';
import CoreSignSymbols from '../../../components/CoreSymbols';

const UserNamesAndSignsSection: React.FC<{
  remoteAnalysis: AstroAnalysis;
  username: string;
}> = ({ username, remoteAnalysis }) => (
  <div className="user-name-and-sign-section">
    <div className="names-section">
      <div className="names username">@{username}</div>
    </div>
    <CoreSignSymbols analysis={remoteAnalysis} />
  </div>
);

export default UserNamesAndSignsSection;
