import React from 'react';
import { ProspectiveUserForm } from 'components/form';

const BirthInfoFormArea: React.FC = () => (
  <div>
    <h6 className="form-info">Make your own chart.</h6>
    <ProspectiveUserForm
      className="off-white chart-desktop-signup-form"
      learnMore={false}
      formLocation="ProspectiveUserChart"
      natalChart
    />
    <button
      className="mobile-signup-scroll-btn btn sign-up black"
      type="button"
      onClick={(_) => {
        window.scroll(0, document.body.scrollHeight);
      }}
    >
      SIGN UP
    </button>
  </div>
);

export default BirthInfoFormArea;
