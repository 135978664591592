export const AstroIntuitionPages = [
  'what-is-astrology',
  'what-is-a-natal-chart',
  'zodiac-sun-signs',
  'rising-sign',
  'houses',
  'house-systems',
  'aspects',
  'transits-orbs',
];

export type AstroIntuitionLocation = typeof AstroIntuitionPages;
