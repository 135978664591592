import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  itunesStoreURL,
  ITUNES_CAMPAIGN_TOKENS,
  playStoreUrl,
  PLAY_STORE_CAMPAIGN_TOKENS,
} from './constants';
import { sendClickEvent } from 'analytics';

export class Features extends React.Component<{}, {}> {
  scrollAway = (_) => {
    window.scroll(0, window.innerHeight);
  };

  featuresClick(action: string) {
    return () => {
      sendClickEvent('Features', action);
    };
  }

  render() {
    return (
      <div>
        <div className="container feature">
          <img
            className="feature-image-desktop feature-image-desktop-chart"
            src={require('../../images/launch-chart-desktop.png')}
          />
          <img
            className="feature-image-mobile feature-image-mobile-chart"
            src={require('../../images/launch-chart-mobile.png')}
          />
          <div className="feature-text">
            <h1>Understand your birth chart </h1>
            <p>
              Unlike the broad and vague magazine horoscopes that only use your sun sign, we use a
              complete picture of the sky when and where you were born to generate your full birth
              chart.
            </p>
            <h4>
              <Link to="/natal-chart" onClick={this.featuresClick('GetYourNatalChart')}>
                Get your chart online {'>'}
              </Link>
            </h4>
          </div>
        </div>
        <div className="container feature">
          <img
            className="feature-image-desktop feature-image-desktop-compat"
            src={require('../../images/launch-compat-desktop.png')}
          />
          <img
            className="feature-image-mobile feature-image-mobile-compat"
            src={require('../../images/launch-compat-mobile.png')}
          />
          <div className="feature-text">
            <h1> Better together </h1>
            <p>
              Keep track of your friends, see what's up with them astrologically if they’re having a
              bad day, and, of course, see whether you’re fated to fall in love.
            </p>
            <h4>
              <a
                href={itunesStoreURL(ITUNES_CAMPAIGN_TOKENS.SITE_MARKETING)}
                onClick={this.featuresClick('DownloadiOS')}
              >
                Download iOS {'>'}
              </a>
            </h4>
            <h4>
              <a
                href={playStoreUrl(PLAY_STORE_CAMPAIGN_TOKENS.SITE_MARKETING)}
                onClick={this.featuresClick('DownloadAndroid')}
              >
                Download Android {'>'}
              </a>
            </h4>
          </div>
        </div>
        <div className="container feature">
          <img
            className="feature-image-desktop feature-image-desktop-update"
            src={require('../../images/launch-update-desktop.png')}
          />
          <img
            className="feature-image-mobile feature-image-mobile-update"
            src={require('../../images/launch-update-mobile.png')}
          />
          <div className="feature-text">
            <h1> Real-time insights, as the planets move </h1>
            <p>
              We use NASA data to know exactly where the stars are, and proprietary technology to
              generate super-accurate horoscopes. Know what to look for as the stars move—starting
              now.
            </p>
            <h4>
              <Link to="/natal-chart" onClick={this.featuresClick('SignUpNatalChart')}>
                {' '}
                Sign up to get your horoscope by email {'>'}
              </Link>
            </h4>
          </div>
        </div>
      </div>
    );
  }
}
