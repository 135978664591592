import React from 'react';
import { Helmet } from 'react-helmet';

// components
import PageLayout from 'landing-page/views/PageLayout';
import SectionPadding from 'landing-page/components/SectionPadding';

import AstroIntuitionHeader from '../AstroIntuitionHeader';
import AstroIntuitionContainer from '../AstroIntuitionContainer';
import AstroIntuitionFooter from '../AstroIntuitionFooter';

import AspectItem from './AspectItem';
import ZodiacRing from '../../../components/ZodiacRing';

// types
import {
  AspectObjects,
  planetObjsForImageOne,
  planetsForImageOne,
  aspectsForImageOne,
  planetObjsForImageTwo,
  planetsForImageTwo,
  planetObjsForImageThree,
  planetsForImageThree,
  aspectsForImageThree,
  planetsForImageFour,
  planetObjsForImageFour,
  aspectsForImageFour,
} from './AspectTypes';

// styles
import styles from './Aspects.module.scss';

const Aspects: React.FC = () => {
  const aspectItems = AspectObjects.map((el) => {
    const { title, goodness, importance, meaning, degrees, imageHeight } = el;
    return (
      <AspectItem
        title={title}
        goodness={goodness}
        importance={importance}
        meaning={meaning}
        degrees={degrees}
        key={title}
        imageHeight={imageHeight}
      />
    );
  });
  return (
    <>
      <Helmet>
        <title>Aspects: How does astrology work? | Co-Star</title>
        <meta
          name="description"
          content=" In astrology, the angles (or aspects) between planets tell us how those planets will relate to each other in our lives."
        />
      </Helmet>
      <div className="floating-container">
        <AstroIntuitionHeader>
          <h1>Aspects</h1>
        </AstroIntuitionHeader>
        <AstroIntuitionContainer theme="transparent">
          <h3>
            Planets always make angles to each other. Some angles are more important than others. In
            astrology, these special angles are called aspects.
          </h3>
        </AstroIntuitionContainer>
        <AstroIntuitionContainer theme="dark">
          <h3>If two planets are directly opposite from each other, it means they’re...</h3>
          <ZodiacRing
            planets={planetsForImageOne}
            planetObjs={planetObjsForImageOne}
            aspects={aspectsForImageOne}
            fillInBackground
            startingSign="Leo"
            className={styles.wideImage}
          />
          <h3>...opposites.</h3>
        </AstroIntuitionContainer>
        <AstroIntuitionContainer theme="light">
          <h3>A conjunction is when two planets appear to be on top of each other.</h3>
          <SectionPadding verticalPadding="1rem" />
          <p>
            (They aren't actually – they just look like they are from the perspective of Earth.){' '}
          </p>
          <SectionPadding verticalPadding="2rem" />
          <ZodiacRing
            planets={planetsForImageTwo}
            planetObjs={planetObjsForImageTwo}
            fillInBackground
            startingSign="Leo"
            aspects
            className={styles.wideImage}
          />
          <h3>When this happens, the energy of the two planets unites and blends.</h3>
          <SectionPadding verticalPadding="1.5rem" />
          <p className={styles.normal}>For example:</p>
          <SectionPadding verticalPadding=".5rem" />
          <h4>GEMINI MARS</h4>
          <p>"I take action in a curious, chaotic, witty way."</p>
          <SectionPadding verticalPadding="1rem" />

          <h4>GEMINI SUN</h4>
          <p>"I am fundamentally curious, chaotic, witty."</p>
          <SectionPadding verticalPadding="2rem" />
          <p className={styles.normal}>
            Who you are is fused with the way you take action – for better and for worse.
          </p>
        </AstroIntuitionContainer>
        <AstroIntuitionContainer theme="dark">
          <h3>When two planets are 120° apart, they form a trine.</h3>
          <SectionPadding verticalPadding="2rem" />
          <p>Planets in trine like each other and get along.</p>
          <ZodiacRing
            planets={planetsForImageThree}
            planetObjs={planetObjsForImageThree}
            aspects={aspectsForImageThree}
            fillInBackground
            startingSign="Leo"
            className={styles.wideImage}
          />
        </AstroIntuitionContainer>
        <AstroIntuitionContainer theme="transparent">
          <h3>
            When two planets are at two corners of a square, they are said to make a Square to each
            other.
          </h3>
          <SectionPadding verticalPadding="1.5rem" />
          <p>
            Planets don’t like each other when they’re square; it’s an aspect that brings friction
            and discomfort.
          </p>
          <ZodiacRing
            planets={planetsForImageFour}
            planetObjs={planetObjsForImageFour}
            aspects={aspectsForImageFour}
            fillInBackground
            startingSign="Leo"
            className={styles.wideImage}
          />
        </AstroIntuitionContainer>
        <AstroIntuitionContainer theme="white">
          <h3>Here are the most important aspects:</h3>
          <SectionPadding verticalPadding="2rem" />
          <div className={styles.itemContainer}>{aspectItems}</div>
        </AstroIntuitionContainer>
      </div>
      <AstroIntuitionFooter />
    </>
  );
};

export default PageLayout(Aspects);
