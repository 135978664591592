import React from 'react';
import { Helmet } from 'react-helmet';

// components
import PageLayout from 'landing-page/views/PageLayout';
import AstroIntuitionHeader from '../AstroIntuitionHeader';
import AstroIntuitionContainer from '../AstroIntuitionContainer';
import AstroIntuitionFooter from '../AstroIntuitionFooter';
import SectionPadding from 'landing-page/components/SectionPadding';
import TransitsZodiacRing from '../Transits/TransitsZodiacRing';

// data/types
import { planetsForImageOne, planetObjsForImageOne } from './OrbTypes';

// styles
import styles from './Orbs.module.scss';

const Orbs = () => (
  // <>
  //   <Helmet>
  //     <title>Orbs: How does astrology work? | Co-Star</title>
  //     <meta
  //       name="description"
  //       content="In astrology, an orb is a buffer zone around an angle between two planets that defines what is close enough to count as an aspect."
  //     />
  //   </Helmet>
  //   <div className="floating-container">
  //     <AstroIntuitionHeader>
  //       <h1>Orbs</h1>
  //     </AstroIntuitionHeader>
  //     <AstroIntuitionContainer theme="transparent">
  //       <h3>An orb is a buffer zone around an angle to define what we count as an aspect.</h3>
  //     </AstroIntuitionContainer>
  <AstroIntuitionContainer theme="light">
    <h4>
      When two planets make a transit, the planets are only exactly in aspect with each other for an
      instant.
    </h4>
    <SectionPadding verticalPadding="1rem" />
    <h3>An orb is just a buffer zone for what counts as an aspect.</h3>
    <div className={styles.orbContainer}>
      <div className={styles.leftSideOrbContainer}>
        <TransitsZodiacRing
          planets={planetsForImageOne}
          planetObjs={planetObjsForImageOne}
          fillInBackground
          orbs
          orbitAnimation="black"
          startingSign="Leo"
          className={styles.orbZodiacRing}
        />
        <div className={styles.orbsCaptionSecond}>
          <p>Solar return ends at 3° after being exact.</p>
        </div>
        <div className={styles.orbsCaptionSecond}>
          <p>Solar return starts 3° before being exact.</p>
        </div>
      </div>
    </div>
    <SectionPadding verticalPadding="2rem" />
    <p>
      Different astrologers use different orbs for different purposes, ranging from 1° to 10° on
      either side.
    </p>
  </AstroIntuitionContainer>
  // </div>
  // <AstroIntuitionFooter />
  // </>
);

export default Orbs;
