import { Sign } from 'astrology';
import React from 'react';
import { Link } from 'react-router-dom';

import AstroIntuitionContainer from '../../AstroIntuitionContainer';

import styles from './ZodiacSignPageFooter.module.scss';
import { displayYear } from '../ZodiacSignTypes';

const abbreviatedDates = {
  Aries: 'Mar 20 – Apr 19',
  Taurus: 'Apr 19 – May 20',
  Gemini: 'May 20 - June 21',
  Cancer: 'June 21 - July 22',
  Leo: 'July 22 - Aug 22',
  Virgo: 'Aug 22 - Sep 22',
  Libra: 'Sep 22 - Oct 23',
  Scorpio: 'Oct 23 - Nov 22',
  Sagittarius: 'Nov 22 - Dec 21',
  Capricorn: 'Dec 21 - Jan 20',
  Aquarius: 'Jan 19 - Feb 18',
  Pisces: 'Feb 18 - Mar 20',
};

type ZodiacSignPageFooterProps = {
  sign: Sign;
};

const ZodiacSignPageFooter = ({ sign }: ZodiacSignPageFooterProps) => {
  const zodiacItems = Object.keys(abbreviatedDates).map((el) => {
    const isCurrentSign = el === sign;
    const theme = isCurrentSign ? styles.current : styles.title;
    const item = (
      <div className={styles.item}>
        <div className={theme}>
          <h6>{el}</h6> {!isCurrentSign && <span>➝</span>}
        </div>
        <p>{abbreviatedDates[el]}</p>
      </div>
    );
    if (!isCurrentSign)
      return (
        <Link to={`/zodiac-signs/${el.toLowerCase()}-sign`} key={`${el}-link`}>
          {item}
        </Link>
      );
    return item;
  });
  return (
    <AstroIntuitionContainer theme="zodiac-white">
      <div className={styles.footerContainer}>
        <h4 className={styles.footerHeader}>all zodiac sun signs — {displayYear}</h4>
        <div className={styles.itemContainer}>{zodiacItems}</div>
      </div>
    </AstroIntuitionContainer>
  );
};

export default ZodiacSignPageFooter;
