import Plausible from 'plausible-tracker'

const plausible = Plausible({
  domain: 'costarastrology.com',
  trackLocalhost: true,
})

const sendPlausibleEvent = (
  eventName: string,
  customProperties?: {
    readonly [propName: string]: string | number | boolean;
  },
  callback?: () => void,
): void => {
  // DNT is no longer utilized and not recommended to be used as a standard industry-wide.
  // However, our privacy policy says that we respect the usage of DNT.
  // While the use of plausible satisfies the privacy-focused specification of DNT,
  // it most likely does not satisfy the user's expectation of DNT. Therefore, for the
  // small fraction of users still using DNT and still on a browser that supports it, just
  // refrain from tracking anything.
  if (navigator.doNotTrack == "1") {
    return;
  }
  plausible.trackEvent(eventName, {
    callback: callback,
    props: customProperties
  })
}


/*
  FYIs:
  
  1. Some pages list "screen" which is more of a "screen section/general page genre" than it is a specific page.
     It can be things like "Header" or "Footer" or "Download".
  2. This analytics file was converted from an older set of analytics which were themselves converted from an
     even older set of analytics. Some things, like why Signupform and Adv Chart form are their own network calls,
     are lost to the annals of history. However the author of this current format opted to change as little outside
     of this analytics file as possible, so the API largely stayed the same which provides for these slightly
     different form event calls.
  3. Some of the page urls are edited for both privacy and clarity in viewing
*/

export const sendPageViewEvent = (location: string): void => {
  let processedLocation = location
  if (location.endsWith('/')) {
    processedLocation = location.substring(0, location.length - 1)
  }

  let customProperties = {}
  if (/\/password-reset\/.+/.test(processedLocation)) {
    // we don't care about a specific generated page other than in rare security scenarios - and for those we can check
    // server logs for URL requests instead of looking at our analytics overview
    customProperties = {
      url: '/password-reset/{generated}'
    }
  } else if (/\/natal-chart\/[0-9]+/.test(processedLocation)) {
    // this is a generated natal chart, lump them all into one and include the number as a prop
    customProperties = {
      url: '/natal-chart/{generated}',
      natalChartId: processedLocation.substring(processedLocation.lastIndexOf("\/") + 1)
    }
  } else {
    customProperties = {
      url: processedLocation
    }
  }

  // sending "pageview" lets plausible track it as a pageview event with custom properties
  // but with our own supplied url instead of the default page url
  sendPlausibleEvent("pageview", customProperties)
};

export const sendFormEvent = (form: string, action: string, pageType?: string): void => {
  if (pageType) {
    sendPlausibleEvent(
      `FormInteraction`,
      {
        form: form,
        action: action,
        pageType: pageType
      }
    )
  } else {
    sendPlausibleEvent(
      `FormInteraction`,
      {
        form: form,
        action: action
      }
    )
  }
};

export const sendSignUpFormEvent = (action: string, page: string): void => {
  sendFormEvent('SignUpForm', action, page)
};

export const sendFormEventError = (form: string, errorType: string, pageType?: string): void => {
  if (pageType) {
    sendPlausibleEvent(`FormError`,
      {
        form: form,
        errorType: errorType,
        pageType: pageType
      })
  } else {
    sendPlausibleEvent(`FormError`,
      {
        form: form,
        errorType: errorType
      })
  }
};

export const sendSignUpFormError = (page: string, errorType: string): void => {
  sendFormEventError('SignUp', errorType, page)
};

export const sendFormEventSuccess = (form: string): void => {
  sendFormEvent(form, 'Success')
};

export const sendOutboundLinkEvent = (
  destination: string,
  callback: () => void,
): void => {
  sendPlausibleEvent("outboundLinkTap", {
    destination: destination
  }, callback)
};

export const sendClickEvent = (screen: string, action: string): void => {
  sendPlausibleEvent("NavigationClick", {
    action: action,
    pageType: screen
  })
};
