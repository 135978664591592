import React from 'react';
import FooterForm from 'landing-page/components/FooterForm';
import { FeedbackFooter } from '../../../views/footers/feedback-footer';

type ProspectiveUserFooterProps = {
  shouldShowForm: boolean;
  userId: number;
  activeSimple: boolean;
};

const ProspectiveUserFooter = ({
  shouldShowForm,
  userId,
  activeSimple,
}: ProspectiveUserFooterProps) => {
  if (shouldShowForm) {
    return (
      <div className="signup-footer-container">
      <FooterForm formLocation="ProspectiveUserChartFooter" />

      </div>
    );
  }
  return <FeedbackFooter userId={userId} />;
};

export default ProspectiveUserFooter;
