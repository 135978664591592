import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import PageLayout from 'landing-page/views/PageLayout';
import { ProspectiveUserForm } from '../../../../components/form';
import { Features } from '../../../features';
import { PressFeatures } from '../../../press-features';
import FooterForm from 'landing-page/components/FooterForm';

const NatalChartLandingPage: React.FC = () => (
  <div>
    <Helmet>
      <title>Free Natal Chart | Co – Star: Hyper-Personalized, Real-Time Horoscopes</title>
      <meta
        name="description"
        content="A natal chart indicates your character traits, behavioral tendencies, hidden desires, and the directions your life might take. The data informing our algorithms - and your horoscopes - comes from NASA: if you can find out what time you were born, we can do the rest."
      />
    </Helmet>
    <div className="chart-container natal-chart-landing-container">
      <div className="chart-info">
        <div className="chart-message">
          <h6 className="form-info">
            Generate your full natal chart and get a personalized horoscope reading by email every
            Monday, for free.
          </h6>
          <ProspectiveUserForm
            className="off-white"
            formLocation="NatalChart"
            learnMore={false}
            natalChart
          />
        </div>
        <div className="hero-arrow">&darr;</div>
      </div>

      <div className="chart-container-outer landing-page-chart">
        <div className="chart-picture-container">
          <div className="simple-chart">
            <img src={require('../../../../../images/chart.png')} />
          </div>
        </div>
        <div className="switch-btn">
          <Link to="/natal-chart/19">View sample</Link>
        </div>
      </div>
    </div>
    <div className="container dark-container">
      <div className="container-inner">
        <div className="section-title">WHAT'S A NATAL CHART?</div>
        <div className="what-is-this">
          <div className="image-desc">
            <img src={require('../../../../../images/moon.png')} />
          </div>
          <p>
            A natal chart (a.k.a. birth chart) is an astronomical snapshot of the stars based on the
            exact day, time, and place you were born.
          </p>
        </div>
        <div className="what-is-this">
          <div className="image-desc">
            <img src={require('../../../../../images/hand.png')} />
          </div>
          <p>
            Using NASA data, we calculate the location of each planet, along with the sign of the
            zodiac and house it was in at the moment of your birth. If you can find out what time
            you were born, we can do the rest.
          </p>
        </div>
        <div className="what-is-this">
          <div className="image-desc">
            <img src={require('../../../../../images/skull.png')} />
          </div>
          <p>
            In astrology, a natal chart reading indicates your character traits, behavioral
            tendencies, hidden desires, and the directions your life might take.
          </p>
        </div>
      </div>
    </div>

    <Features />
    <PressFeatures />
    <FooterForm formLocation="NatalChartFooter" />
  </div>
);

export default PageLayout(NatalChartLandingPage);
