import * as React from 'react';

export const FeedbackFooter: React.SFC<{ userId: number }> = ({ userId }) => (
  <div className="signup-footer-container">
    <div className="container early-beta">
      <div className="container-inner">
        <div className="section-title">
          <div className="beta-copy">
            <span> WHAT DO YOU THINK OF THIS ANALYSIS OF UR CHART? </span>
          </div>
          <FeedbackEmojiLink
            happinessLevel={HappinessLevel.SAD}
            userId={userId}
          />
          <FeedbackEmojiLink
            happinessLevel={HappinessLevel.EH}
            userId={userId}
          />
          <FeedbackEmojiLink
            happinessLevel={HappinessLevel.HAPPY}
            userId={userId}
          />
        </div>
      </div>
    </div>
  </div>
);

const FeedbackEmojiLink: React.SFC<{
  happinessLevel: HappinessLevel;
  userId: number;
}> = ({ happinessLevel, userId }) => (
  <a className="feedback-link" href={getFeedbackUrl(happinessLevel, userId)}>
    {getFeedbackEmojiImage(happinessLevel)}
  </a>
);

const getFeedbackEmojiImage = (happinessLevel: HappinessLevel): JSX.Element => {
  switch (happinessLevel) {
    case HappinessLevel.SAD:
      return <img src={require('../../../../images/sad.png')} alt="Amazing!" />;
    case HappinessLevel.EH:
      return (
        <img src={require('../../../../images/medium.png')} alt="Amazing!" />
      );
    case HappinessLevel.HAPPY:
      return (
        <img src={require('../../../../images/happy.png')} alt="Amazing!" />
      );
  }
};

enum HappinessLevel {
  HAPPY = 'headbobble',
  EH = 'eh',
  SAD = 'sad',
}
const getFeedbackUrl = (
  happinessLevel: HappinessLevel,
  userId: number,
): string =>
  `/feedback?utm_source=feedback&utm_content=${happinessLevel}&utm_campaign=natalchart&utm_medium=${userId.toString()}`;
