import React from 'react';
import { useParams } from 'react-router-dom';

import Api from 'api';
import PageLayout from '../views/PageLayout';

function ProspectiveUserUnsubscribePage() {
  const [msg, setMsg] = React.useState<string>('');
  const { puid, hash } = useParams() as { puid: string; hash: string };

  React.useEffect(() => {
    Api.unsubscribeProspectiveUser(puid, hash)
      .then(() => {
        setMsg(
          'You have been unsubscribed from the Co—Star weekly horoscope email. Please allow up to 10 business days for us to remove you from all our systems.',
        );
      })
      .catch((_) => {
        setMsg('There was an error unsubscribing.');
      });
  }, []);

  return (
    <div className="floating-container">
      <div className="floating-container-inner">
        <div>
          <h1 className="subtitle">Unsubscribe</h1>
          <p>{msg}</p>
        </div>
      </div>
    </div>
  );
}

export default PageLayout(ProspectiveUserUnsubscribePage);
