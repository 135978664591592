import React from 'react';
import cn from 'classnames';

import { planetData } from 'astrology';
import { Bio as BioType } from './teamData';
import '../../../../style/team.scss';
import styles from './Bio.module.scss';

interface Props {
  person: BioType;
}

const Bio = ({ person }: Props) => {
  return (
    <div className="container about" id={person.hyphenName}>
      <img className="about-image" src={person.abstractImageOne} />
      <img className="about-image" src={person.abstractImageTwo} />
      <img className="about-image" src={person.abstractImageThree} />

      <div className={cn('about-box', styles.card)}>
        <div className={person.image ? styles.cardHeader : undefined}>
          {person.image ? (
            <img className={styles.teamHeadshot} src={person.image} alt={person.name} />
          ) : null}
          <span className={styles.smallcaps}>{person.title}</span>
          <h2>{person.name}</h2>
          <div className="SunMoonRisingContainer">
            {person.sun && (
              <>
                <img src={planetData.Sun.imgDataUrl} className="SunMoonRising" alt={person.sun} />
                {person.sun} &nbsp;
              </>
            )}
            {person.moon && (
              <>
                <img src={planetData.Moon.imgDataUrl} className="SunMoonRising" alt={person.moon} />
                {person.moon} &nbsp;
              </>
            )}
            {person.ascendant && (
              <>
                <span className="SunMoonRisingNoBreak">
                  <img
                    src={planetData.Ascendant.imgDataUrl}
                    className="SunMoonRising"
                    alt={person.ascendant}
                  />
                  {person.ascendant}
                </span>
              </>
            )}
          </div>
        </div>

        <div className={styles.bio}>
          {person.biography.map((sentence, idx) => (
            <>
              <p key={idx}>{sentence}</p>
              <br />
            </>
          ))}
        </div>

        <div className="DoDont-parent">
          <div>
            <span className={styles.smallcaps}>DO</span>
            {person.dos.map((item, idx) => (
              <div key={idx}>{item}</div>
            ))}
          </div>

          <div>
            <span className={styles.smallcaps}>DON'T</span>
            {person.dont.map((item, idx) => (
              <div key={idx}>{item}</div>
            ))}
          </div>
        </div>

        {person.astroTraining ? (
          <>
            <hr className={styles.astroHeader} />
            <div className={cn(styles.astroHeader, styles.smallcaps)}>ASTROLOGICAL TRAINING</div>
            <br />
            <ul className={styles.list}>
              {person.astroTraining.map((at, idx) => (
                <li key={idx}>{at}</li>
              ))}
            </ul>
          </>
        ) : null}

        <br />

        {person.favoriteAstroBooks ? (
          <>
            <div className={cn(styles.astroHeader, styles.smallcaps)}>FAVORITE ASTROLOGY BOOKS</div>
            <br />
            <ul className={styles.list}>
              {person.favoriteAstroBooks.map((fab, idx) => (
                <li key={idx}>{fab}</li>
              ))}
            </ul>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Bio;
