import React from 'react';
import AdvancedChartHeader from 'landing-page/pages/AdvancedChart/AdvancedChartHeader';
import AdvancedChartReadingItem from 'landing-page/pages/AdvancedChart/AdvancedChartReadingItem';
import { data } from './SampleReadingData';

const SampleReading = () => {
  const { birthDay, birthTime, birthPlace } = data;
  const birthData = { birthDay, birthPlace, birthTime };

  const readingItems = data.paidReading.map((el, i) => (
    <AdvancedChartReadingItem content={el} key={i} />
  ));

  return (
    <>
      <AdvancedChartHeader analysis={data.analysis} birthData={birthData} />
      {readingItems}
    </>
  );
};

export default SampleReading;
