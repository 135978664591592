import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { sendPageViewEvent } from 'analytics';

/**
 * @name RouterWrapper
 *
 * @type {React.FC}
 * @description This component wraps our Routes to:
 *  1) send info to analytics when the browser location changes
 *  2) ensure scroll to top when naviagating to a new page
 *  3) we use useEffect instead of LayoutEffect to ensure even when rendering changes
 *     a modal on hover, we don't scoll back to top
 *
 */

const RouterWrapper = ({ children }) => {
  const { pathname } = useLocation();
  useEffect(() => {
    sendPageViewEvent(pathname);
    window.scrollTo(0, 0);
  }, [pathname]);
  return children;
};

export default RouterWrapper;
