import React from 'react';
import { Sign } from 'astrology';

const CoreSignSymbol: React.FC<{ symbol: string; sign: Sign }> = ({ symbol, sign }) => (
  <div className="core-sign-and-giph">
    {symbol}
    <div className="core-sign-and-giph__text">{sign.toString()}</div>
  </div>
);

export default CoreSignSymbol;
