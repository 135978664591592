import React from 'react';
import { useLocation } from 'react-router';

import { getParameterByName } from 'utils';
import Api from 'api';
import PageLayout from 'landing-page/views/PageLayout';

function FeedbackPage() {
  // replacement for RouteComponentProps in v6 of react-router
  const location = useLocation();
  const isRated = /app-feedback/.test(location.pathname);

  const [feedback, setFeedback] = React.useState('');
  const [submitted, setSubmitted] = React.useState(false);
  const [unratedFeedback] = React.useState(!isRated);

  const feedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const submit = (event) => {
    event.preventDefault();

    setSubmitted(true);

    if (feedback != '') {
      let mood = getParameterByName('utm_content');
      mood = mood && mood.charAt(0).toUpperCase() + mood.slice(1);

      Api.sendFeedback({
        feedback,
        mood,
        email: getParameterByName('utm_medium'),
      });
    }
  };

  return (
    <div className="floating-container">
      <div className="floating-container-inner">
        {!submitted ? (
          <div>
            <h2>
              {unratedFeedback
                ? ' Thank you for rating. '
                : ' Tell us what you think. '}
            </h2>
            <p>
              {unratedFeedback
                ? " We'd appreciate any more feedback you have. "
                : " We'd appreciate any feedback you have. "}
            </p>
            <form name="feedbackForm" onSubmit={submit}>
              <textarea
                name="feedback"
                onChange={feedbackChange}
                value={feedback}
                placeholder="We'd love any feedback you have about what you love, what you hate, or your thoughts on astrology and the twenty-first century in general. If you need help, please email horoscopes@costarastrology.com instead."
              />
              <input className="submit black btn" type="submit" value="send" />
            </form>
            <p className="postscript">
              Need help? Please email{' '}
              <a href="mailto:horoscopes@costarastrology.com">
                horoscopes@costarastrology.com
              </a>
              <br />
              Press?{' '}
              <a href="mailto:press@costarastrology.com">
                press@costarastrology.com
              </a>
            </p>
          </div>
        ) : (
          <h4>Thanks for your feedback!</h4>
        )}
      </div>
    </div>
  );
}

export default PageLayout(FeedbackPage);
