import React, { useRef } from 'react';
import useD3 from 'hooks/useD3';
import * as _ from 'underscore';
import * as d3 from 'd3';

import { fromNullable } from 'fp-ts/lib/Option';
import { curry } from 'fp-ts/lib/function';

import { natalChartOrderedSigns, planetData } from '../../../../astrology';

type ZodiacRingPropTypes = {
  startingSign: string;
  planets: [];
  planetObjs: Record<string, unknown>;
  fillInBackground?: boolean;
  orbitAnimation?: boolean | 'white' | 'black';
  aspects?: [];
  orbs?: number | boolean;
  className: string;
};

const font = require('!!url-loader!../../../../../images/fonts/Akkurat-Mono.ttf');

function signToAngle(sign) {
  const i = natalChartOrderedSigns.indexOf(sign) * toRad(30);
  return i;
}

function toRad(angle) {
  return (angle * Math.PI) / 180;
}

function toDeg(rad) {
  return (rad * 180) / Math.PI;
}

function rotateBy90(angle) {
  return angle - Math.PI / 2;
}

const TransitsZodiacRing = ({
  startingSign,
  planets,
  planetObjs,
  className,
  aspects = [],
  orbs = false,
  fillInBackground = false,
  orbitAnimation = false,
}: ZodiacRingPropTypes) => {
  const orderedSigns: string[] = [];
  const indexOfStartingSign = natalChartOrderedSigns.indexOf(startingSign);
  orderedSigns.push(...natalChartOrderedSigns.slice(indexOfStartingSign));
  orderedSigns.push(...natalChartOrderedSigns.slice(0, indexOfStartingSign));

  const angleGen = d3.pie();
  let data = angleGen([1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]);

  data = orderedSigns.map((sign, i) => ({
    ...data[i],
    name: sign,
  }));

  const ref = useD3((svg) => {
    const height = 500;
    const width = 500;
    const radius = Math.min(width, height) / 2;
    const t0 = Date.now();

    svg.attr('viewBox', `${(-1 * width) / 2} ${(-1 * height) / 2} ${width} ${height}`);
    svg
      .append('defs')
      .append('style')
      .attr('type', 'text/css')
      .text(`@font-face {font-family: 'Akkurat-Mono'; src: url(${font});}`);

    svg = svg.append('g');

    svg
      .selectAll('rect')
      .data([1])
      .enter()
      .append('rect')
      .attr('x', 0 - width / 2)
      .attr('y', 0 - height / 2)
      .attr('width', width)
      .attr('height', height)
      .attr('fill', 'transparent');

    const outerArc = d3
      .arc()
      .innerRadius(radius - 55)
      .outerRadius(radius - 20)
      .startAngle((d, _) => d.startAngle)
      .endAngle((d) => d.endAngle);

    function isUpsideDown(endAngle): boolean {
      // js mod is kinda busted for negative numbers?
      const modAngle = endAngle % (Math.PI * 2);
      const newAngle = (toDeg(modAngle) + 360) % 360;
      return newAngle > 90 && newAngle < 270;
    }

    svg
      .selectAll('.sign-path')
      .data(data)
      .enter()
      .append('path')
      .attr('class', 'sign-path')
      .attr('d', outerArc)
      .style('fill', '#000')
      .style('stroke', '#d6d6d6')
      .attr('stroke-width', '1px')
      .each(function (d, i) {
        // A regular expression that captures all in between the start of a string (denoted by ^)
        // and the first capital letter L
        const firstArcSection = /(^.+?)L/;

        // The [1] gives back the expression between the () (thus not the L as well)
        // which is exactly the arc statement

        /* TODO: I have no idea how to fix this particular case of
                                          "'this' implicitly has type 'any' because it does not have a type annotation."
                                          So for now suppressing with TS-Ignore
                                        */
        // @ts-ignore
        let newArc = firstArcSection.exec(d3.select(this).attr('d'))[1];
        // Replace all the commas so that IE can handle it -_-
        // The g after the / is a modifier that "find all matches rather than stopping after the first match"
        newArc = newArc.replace(/,/g, ' ');
        if (isUpsideDown(d.endAngle)) {
          const startLoc = /M(.*?)A/; // Everything between the capital M and first capital A
          const middleLoc = /A(.*?)0 0 1/; // Everything between the capital A and 0 0 1
          const endLoc = /0 0 1 (.*?)$/; // Everything between the 0 0 1 and the end of the string (denoted by $)
          // Flip the direction of the arc by switching the start and end point (and sweep flag)
          const newStart = fromNullable(endLoc.exec(newArc)).map((v) => v[1]);
          const newEnd = fromNullable(startLoc.exec(newArc)).map((v) => v[1]);
          const middleSec = fromNullable(middleLoc.exec(newArc)).map((v) => v[1]);

          const mkNewArc = (start: string, end: string, middleSec: string) =>
            `M${start}A${middleSec}0 0 0 ${end}`;

          newArc = newStart.map(curry(mkNewArc)).ap_(newEnd).ap_(middleSec).getOrElse(newArc);

          // Build up the new arc notation, set the sweep-flag to 0
        }
        // Create a new invisible arc that the text can flow along
        svg
          .append('path')
          .attr('class', 'hidden-donut-arc')
          .attr('id', `signArc_${i}`)
          .attr('d', newArc)
          .style('fill', 'none')
          .attr('transform', 'rotate(3)');
      })
      .attr('transform', 'rotate(3)');
    const middleArc = d3
      .arc()
      .innerRadius(radius - 60)
      .outerRadius(radius - 55)
      .startAngle(0)
      .endAngle(2 * Math.PI);
    svg.append('path').attr('d', middleArc).style('fill', '#f7f7f7').style('stroke', '#d6d6d6');

    if (fillInBackground) {
      const centerArea = d3
        .arc()
        .innerRadius(0)
        .outerRadius(radius - 66)
        .startAngle(0)
        .endAngle(2 * Math.PI);
      svg.append('path').attr('d', centerArea).style('fill', '#f7f7f7');
    }

    svg
      .selectAll('.sign-text')
      .data(data)
      .enter()
      .append('text')
      .attr('class', 'sign-text')
      .attr('dy', (d, _) => {
        const pos = isUpsideDown(d.endAngle) ? -12 : 23;
        return pos;
      })
      .style('fill', '#f7f7f7')
      .style('font-family', 'Akkurat-Mono')
      .style('font-size', '14px')
      .style('letter-spacing', '.1em')
      .append('textPath')
      .attr('startOffset', '50%')
      .style('text-anchor', 'middle')
      .attr('xlink:href', (_, i) => `#signArc_${i}`)
      .text((d) => d.name.toUpperCase());

    const pathData = [
      { startAngle: -0.5 * Math.PI, endAngle: 0.5 * Math.PI },
      { startAngle: 90 * (Math.PI / 180), endAngle: 270 * (Math.PI / 180) },
    ];

    const arc = d3
      .arc()
      .innerRadius(radius - 90)
      .outerRadius(radius - 60)
      .startAngle((d, _) => d.startAngle)
      .endAngle((d) => d.endAngle);

    const path = svg.selectAll('.house-path').data(pathData).enter();

    path
      .append('path')
      .attr('class', 'house-path')
      .attr('d', arc)
      .style('fill', '#fff')
      .style('stroke', 'transparent');

    svg
      .selectAll('.aspects')
      .data(aspects)
      .enter()
      .append('line')
      .attr('x1', (d) => d[0])
      .attr('x2', (d) => d[2])
      .attr('y1', (d) => d[1])
      .attr('y2', (d) => d[3])
      .attr('id', (d, i) => i)
      .attr('stroke', '#000')
      .attr('stroke-dasharray', '4, 4');

    svg
      .selectAll('.aspect-labels')
      .data(aspects)
      .enter()
      .append('text')
      .attr('text-anchor', 'end')
      .attr('xlink:href', (d, i) => `#${i}`)
      .attr('x', (d) => (d[0] + d[2]) / 2)
      .attr('y', (d) => (d[1] + d[3]) / 2)
      .text((d, i) => {
        if (i === 0) {
          return `      180° (MIRROR OPPOSITE)`;
        }
        return `90° (CONFLICT)`;
      })
      .attr('padding-bottom', '.5rem')
      .attr('margin-right', '3rem')
      .style('font-family', 'Akkurat-Mono')
      .style('font-size', '14px')
      .style('letter-spacing', '.1em')
      .attr('transform', (d, i) => {
        const x = (d[0] + d[2]) / 2;
        const y = (d[1] + d[3]) / 2;
        if (aspects.length === 1 && i === 0) {
          return `rotate(-51.5, ${x - 45}, ${y - 75})`;
        }
        if (i === 0) {
          return `rotate(-19.5, ${x - 45}, ${y - 195})`;
        }
        if (i === 1) {
          return `rotate(-65, ${x - 20}, ${y - 20})`;
        }
        return `rotate(30, ${x - 43}, ${y + 50})`;
      });

    if (orbs) {
      const rad = toRad(17);
      planetObjs.Sun.x = (radius - 75) * Math.cos(-1 * rad) - planetObjs.Sun.imageWidth / 2;
      planetObjs.Sun.y = (radius - 75) * Math.sin(-1 * rad) - planetObjs.Sun.imageHeight / 2;
    }

    svg
      .selectAll('.planet-labels')
      .data(planets)
      .enter()
      .append('image')
      .attr('class', 'planet-labels')
      .attr('x', (d) => planetObjs[d.name].x)
      .attr('y', (d) => planetObjs[d.name].y)
      .attr('width', (d) => planetObjs[d.name].imageWidth)
      .attr('height', (d) => planetObjs[d.name].imageHeight)
      .attr('xlink:href', (d) => {
        const str = planetData[d.name].imgDataUrl;
        return str;
      });

    if (orbs) {
      const linePoints = [];
      const point1x = (radius - 90) * Math.cos(-1 * toRad(17));
      const point1y = (radius - 90) * Math.sin(-1 * toRad(17));
      linePoints.push([point1x, point1y]);

      const point2x = (radius - 5) * Math.cos(-1 * toRad(14));
      const point2Y = (radius - 5) * Math.sin(-1 * toRad(14));
      linePoints.push([point2x, point2Y]);

      const point3x = (radius - 5) * Math.cos(-1 * toRad(20));
      const point3Y = (radius - 5) * Math.sin(-1 * toRad(20));

      linePoints.push([point3x, point3Y]);

      const lineGen = d3.line();
      const orbLines = lineGen(linePoints);

      svg.append('path').attr('d', orbLines).attr('fill', ' rgba(26, 145, 255, 0.5)');

      const textXY = [point1x - 160, point1y + 5];

      const fillColor = fillInBackground ? '#575757' : '#fff';

      svg
        .append('text')
        .attr('x', textXY[0])
        .attr('y', textXY[1])
        .style('font-family', 'Akkurat-Mono')
        .style('letter-spacing', '.1em')
        .style('font-size', '14px')
        .style('fill', fillColor)
        .style('direction', 'ltr')
        .text(`17° GEMINI SUN`);
    }

    if (orbitAnimation) {
      const container = svg
        .append('g')
        .attr('class', 'container')
        .attr('x', 0 - width / 2)
        .attr('y', 0 - height / 2);

      const orbit = container.append('g').attr('id', 'orbit');

      const orbitAnimationColor = orbitAnimation === 'white' ? '#fff' : '#000';

      orbit
        .append('circle')
        .attr('class', 'orbit')
        .attr('r', radius - 5)
        .attr('stroke', orbitAnimationColor)
        .attr('stroke-width', '1.5px')
        .attr('fill', 'transparent');

      orbit
        .append('circle')
        .attr('r', 5)
        .attr('cx', radius - 5)
        .attr('cy', 0)
        .attr('stroke', orbitAnimationColor)
        .attr('fill', orbitAnimationColor);

      d3.timer(() => {
        const delta = Date.now() - t0;

        if (planets[1].name === 'Saturn') {
          const angle = -60 + (-delta * -4) / -200;
          svg.selectAll('#orbit').attr('transform', (d) => `rotate(${angle})`);
        } else {
          const angle = -80 + (-delta * -10) / -200;
          svg.selectAll('#orbit').attr('transform', (d) => `rotate(${angle})`);
        }
      });
    }
  }, []);

  return (
    <svg ref={ref} className={className}>
      <g className="area" />
    </svg>
  );
};

export default TransitsZodiacRing;
