import React from 'react';
import { AstroAnalysis } from 'api';
import { planetData } from '../../../astrology';
import CoreSignSymbol from './CoreSymbolItem';

const CoreSignSymbols: React.FC<{ analysis: AstroAnalysis }> = ({ analysis }) => (
  <div className="core-signs-section">
    <CoreSignSymbol symbol={planetData.Sun.symbol} sign={analysis.planets.Sun.position.sign} />
    <CoreSignSymbol symbol={planetData.Moon.symbol} sign={analysis.planets.Moon.position.sign} />
    <CoreSignSymbol
      symbol={planetData.Ascendant.symbol}
      sign={analysis.planets.Ascendant.position.sign}
    />
  </div>
);

export default CoreSignSymbols;
