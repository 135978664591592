import React, {createContext, useState, useContext, useMemo} from 'react';

const UserContext = createContext();

const UserProvider = ({children}) => {

  const [userData, setUserData] = useState(null);
  const value = useMemo(() => ({
    userData, setUserData
  }), [userData])

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}

export default UserProvider;