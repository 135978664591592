import * as React from 'react';
import Channel from 'channel';
import { interval, Subscription } from 'rxjs';
import { filter, scan, takeUntil } from 'rxjs/operators';

const viewProgressBar = (percent: number, message: string) => (
  <div className="loader-wrapper">
    <h4 className="progress-message">{message}</h4>
    <div className="loader">
      <div className="progress-bar" style={{ width: `${percent}%` }} />
    </div>
  </div>
);

/** **********************************************************
 State Managing Renderings
 *********************************************************** */

export interface ProgressBarProps {
  message: string;
  progress: Channel.Listener<number>;
}

export interface ProgressBarState {
  percent: number;
  unreceive: () => void;
}

export class ProgressBar extends React.Component<
  ProgressBarProps,
  ProgressBarState
> {
  constructor(props) {
    super(props);

    this.state = {
      percent: 0,
      unreceive: props.progress.receive((val) => {
        this.setState({
          percent: val,
        });
      }),
    };
  }

  render() {
    return viewProgressBar(this.state.percent, this.props.message);
  }

  componentWillUnmount() {
    this.state.unreceive();
  }
}

/** **********************************************************
 SELF Managing Renderings
 *********************************************************** */

export interface SelfManagingProgressBarProps {
  message: string;
}

export interface SelfManagingProgressBarState {
  percent: number;
}

export class SelfManagingProgressBar extends React.Component<
  SelfManagingProgressBarProps,
  SelfManagingProgressBarState
> {
  private subscription!: Subscription;

  constructor(props) {
    super(props);

    this.state = {
      percent: 0,
    };
  }

  componentDidMount() {
    this.runDefaultIndicator();
  }

  runDefaultIndicator = () => {
    const source$ = interval(100);
    const numInterCount = source$.pipe(scan((acc, _) => acc + 1, 0));
    const fiveIncCount = numInterCount.pipe(filter((val) => val > 5));
    const timeSub = source$
      .pipe(takeUntil(fiveIncCount))
      .subscribe(this.updateProg);
    this.subscription = timeSub;
  };

  updateProg = (sourceVal: number) => {
    const percent = sourceVal + 20;
    this.setState({ percent });
  };

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  render() {
    return viewProgressBar(this.state.percent, this.props.message);
  }
}
