import * as React from 'react';

import PageLayout from 'landing-page/views/PageLayout';

const BetaPage = () => (
  <div>
    <div className="floating-container">
      <div className="floating-container-inner">
        <div>
          <h1>Join the beta</h1>
          <br />
          <p>
            {' '}
            User feedback plays a crucial role in our work to improve the app. Our beta
            test community volunteers to help make Co—Star a better place for everyone. We’d love it if you
            considered joining our beta testing program.
          </p>
          <br />
          <h4>What is a beta test?</h4>
          <p>
            Beta tests are opportunities to try out and give feedback on new app features before
            they’re released to the public.
          </p>
          <br />
          <h4>How do I sign up?</h4>
          <p>
            Fill out this survey if you would like to join the testing community. We’ll email you
            with next steps if you seem like a good fit.
          </p>
          <br />
          <p>Thanks for taking the time to help us out.</p>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSfqHxJiJwe3QXgUiNFQ4EmdiKqZEQk-0mgB42PiMiqfJR2NHg/viewform?usp=sf_link">
            <button type="button" className="black btn">
              GET STARTED
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default PageLayout(BetaPage);
