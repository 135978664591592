import React from 'react';
import Helmet from 'react-helmet';
import PageLayout from 'landing-page/views/PageLayout';

import AdvancedReadingsProductCard from './AdvancedReadingsProductCard';
import AdvancedReadingsCardContainer from './AdvancedReadingsCardContainer';
import YouInLoveProductCard from './YouInLoveProductCard';
import AskTheStarsProductCard from './AskTheStarsProductCard';

import styles from './AdvancedReadings.module.scss';

const AdvancedReadings = () => {
  const ErosCard = (
    <AdvancedReadingsProductCard
      imageURL={require('../../../../../images/eros-shop-card.png')}
      name="ErosCard"
    />
  );
  const ManualAddCard = (
    <AdvancedReadingsProductCard
      imageURL={require('../../../../../images/manual-add-shop-card.png')}
      name="ManualAddCard"
    />
  );
  return (
    <>
      <Helmet>
        <title>Astrology Readings | Co-Star</title>
        <meta
          name="description"
          content="Co—Star's astrology readings provide insight and deeper understanding of you, your friends, your partner in relationships."
        />
      </Helmet>
      <div className="floating-container">
        <div className={styles.innerContainer}>
          <div className={styles.header}>
            <h1>Astrological Chart Readings</h1>
            <h3>
              Unlock personalized reports and exclusive features that allow you to go deeper into
              the astrology of who you are and how you relate to others.
            </h3>
          </div>
          <div className={styles.cardsContainer}>
            <AdvancedReadingsCardContainer
              card={<AskTheStarsProductCard />}
              description="In-depth insights and advice based on your natal chart, the real-time movements of the planets in the sky, and the 4,000-year-old system of astrology."
            />
            <AdvancedReadingsCardContainer
              card={<YouInLoveProductCard />}
              description="You in Love reading: Get a personalized report that describes your relationship
                patterns based on your birth chart."
            />
            <AdvancedReadingsCardContainer
              card={ErosCard}
              description="Eros: Subscribe with a partner to receive daily updates and activities about your
                relationship and compatibility."
            />
            <AdvancedReadingsCardContainer
              card={ManualAddCard}
              description="Manually added charts: Add friends who don’t have the app in order to access their
                birth chart and see your compatibility."
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PageLayout(AdvancedReadings);
