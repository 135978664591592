export const cutouts = [
  require(`../../../../images/cutouts/cutout-anise-01.png`),
  require(`../../../../images/cutouts/cutout-moth.png`),
  require(`../../../../images/cutouts/cutout-peony.png`),
  require(`../../../../images/cutouts/cutout-cactus.png`),
  require(`../../../../images/cutouts/cutout-fruit-02.png`),
  require(`../../../../images/cutouts/cutout-pluto.png`),
  require(`../../../../images/cutouts/cutout-sculpture-02.png`),
];

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
