import React from 'react';
import Footer from '../../components/Footer';
import { sendClickEvent } from 'analytics';

/**
 * @name PageLayout
 * @description HOC that wraps all page components to render standard styling/layout
 *
 */

// TODO - move if needed elsewhere?

const PageLayout = (
  // changed from class component to be compatible with FC too
  BodyComponent: React.ComponentType,
) =>
  function (props) {
    const footerClick = (action: string) => {
      sendClickEvent('Footer', action);
    };
    return (
      <div>
        <BodyComponent {...props} />
        <Footer onFooterClick={footerClick} />
      </div>
    );
  };

export default PageLayout;
