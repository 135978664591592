import React from 'react';
import Helmet from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import PageLayout from 'landing-page/views/PageLayout';
import { Sign } from 'astrology';
import SectionPadding from 'landing-page/components/SectionPadding';
import ACBuyButton from 'landing-page/pages/NatalChartPages/ACBuyButton';
import {
  dates,
  displayYear,
  plural,
  symbols,
  elements,
  modalities,
  rulingPlanets,
  traits,
  celebs,
  careers,
  about,
  romance,
  friendship,
  metadata,
} from '../ZodiacSignTypes';

import AstroIntuitionContainer from '../../AstroIntuitionContainer';
import AstroIntuitionFooter from '../../AstroIntuitionFooter';

import ZodiacConstellation from '../ZodiacConstellation';
import ZodiacListItem from '../ZodiacListItem';
import ZodiacCopyItem from '../ZodiacCopyItem';
import ZodiacSignPageFooter from '../ZodiacSignPageFooter';

import styles from './ZodiacSignPage.module.scss';
import { sendClickEvent } from 'analytics';

type ZodiacSignPageProps = {
  sign: Sign;
};

const ZodiacSignPage = ({ sign }: ZodiacSignPageProps) => {
  const signKey = sign.toLowerCase();
  const introCopy = {
    symbol: symbols[signKey],
    dates: `${dates[displayYear][signKey]} for ${displayYear}`,
    element: elements[signKey],
    modality: modalities[signKey],
    'ruling-planet': rulingPlanets[signKey],
  };

  const navigate = useNavigate();

  const handleClick = () => {
    sendClickEvent(`ZodiacSign_${sign}`, 'AdvChart');
    navigate('/advanced-readings/you-in-love');
  };

  const buttonSigns = ['Aries', 'Taurus', 'Gemini', 'Cancer', 'Leo', 'Virgo'];

  const ACButtonTheme = buttonSigns.includes(sign);

  const introItems = Object.keys(introCopy).map((el, i) => {
    const title = el.toLowerCase().replace(/-/g, ' ');
    const isDates = el === 'dates';

    return (
      <ZodiacListItem body={introCopy[el]} subheader={title} dates={isDates} key={`intro-${i}`} />
    );
  });

  const traitItems = traits[signKey].map((el, i) => (
    <ZodiacListItem body={el} key={`trait-${i}`} />
  ));
  const celebItems = celebs[signKey].map((el, i) => (
    <ZodiacListItem body={el} key={`celeb-${i}`} />
  ));
  const careerItems = careers[signKey].map((el, i) => (
    <ZodiacListItem body={el} key={`careers-${i}`} />
  ));

  const aboutItems = about[signKey].map((el, i) => (
    <ZodiacCopyItem header={el.subheader} body={el.body} key={`about-${i}`} />
  ));
  const romanceItems = romance[signKey].map((el, i) => (
    <ZodiacCopyItem header={el.subheader} body={el.body} key={`romance-${i}`} />
  ));
  const friendshipItems = friendship[signKey].map((el, i) => (
    <ZodiacCopyItem header={el.subheader} body={el.body} key={`friendship-${i}`} />
  ));

  return (
    <>
      <Helmet>
        <title>{sign} Zodiac Sign: Personality Traits, In Love, Friendship & More | Co–Star</title>
        <meta name="description" content={metadata[signKey]} />
      </Helmet>

      <div className={cn('floating-container', styles.zodiacFloatingContainer)}>
        <AstroIntuitionContainer theme="zodiac-light">
          <div className={styles.signHeader}>
            <h1>{sign}</h1>
          </div>
          <div className={styles.introItems}>
            <ZodiacConstellation sign={sign} />
            {introItems}
          </div>
          <div className={styles.listContainer}>
            <h3>{sign} traits</h3>
            <div className={styles.items}>{traitItems}</div>
          </div>
          <div className={styles.listContainer}>
            <h3>Famous {plural[signKey]}</h3>
            <div className={styles.items}>{celebItems}</div>
          </div>
          <div className={styles.listContainer}>
            <h3>Best careers</h3>
            <div className={styles.items}>{careerItems}</div>
          </div>

          <img
            src={require('../../../../../../images/cutouts/cutout-peony.png')}
            className={cn(styles.cutout, styles.peony)}
            id="peony"
            alt=""
          />
        </AstroIntuitionContainer>
        <AstroIntuitionContainer theme="zodiac-dark">
          <div className={styles.header}>
            <h2>About {sign}</h2>
          </div>
          {aboutItems}
          <img
            src={require('../../../../../../images/cutouts/cutout-tulip.png')}
            className={cn(styles.cutout, styles.tulip)}
            alt=""
          />
        </AstroIntuitionContainer>
        <AstroIntuitionContainer theme="zodiac-transparent">
          <div className={styles.header}>
            <h2>{sign} in Sex, Love, and Romance</h2>
            <br />
            <br />
            <p>
              This is most relevant to {sign} Venus and Mars, though {sign} Suns may identify with
              some traits.
            </p>
          </div>

          {romanceItems}
          <div className={styles.buttonContainer}>
            <ACBuyButton transparent={!ACButtonTheme} onClick={handleClick} zodiac />
          </div>

          <img
            src={require('../../../../../../images/cutouts/cutout-moth.png')}
            className={cn(styles.cutout, styles.moth)}
            alt=""
          />
        </AstroIntuitionContainer>
        <AstroIntuitionContainer theme="zodiac-dark">
          <div className={styles.header}>
            <h2>{sign} in Friendship</h2>
          </div>
          {friendshipItems}
        </AstroIntuitionContainer>
        <ZodiacSignPageFooter sign={sign} />
        <SectionPadding verticalPadding="2rem" />
        <AstroIntuitionFooter />
      </div>
    </>
  );
};

export default PageLayout(ZodiacSignPage);
