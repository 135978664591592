import React from 'react';
import cn from 'classnames';
import { AspectObject } from './AspectTypes';
import styles from './Aspects.module.scss';

type AspectItemProps = AspectObject;

const AspectItem = ({
  title,
  goodness,
  degrees,
  meaning,
  importance,
  imageHeight,
}: AspectItemProps) => {
  const highlightStyles = cn(styles.values, styles.goodness, {
    [styles.red]: goodness === 'Friction / Frustration',
    [styles.yellow]: goodness === 'Neutral',
    [styles.green]: goodness === 'Harmonious & Friendly',
  });
  return (
    <div className={styles.aspectItem}>
      <div className={styles.aspectTitle}>
        <img
          src={require(`../../../../../images/${title.toLowerCase()}.png`)}
          className={styles.aspectIcon}
          style={{ height: imageHeight }}
          alt={`${title} glyph`}
        />
        <h3>{title}</h3>
      </div>
      <div className={highlightStyles}>
        <p>{goodness}</p>
      </div>
      <p>
        Degrees: <span className={styles.values}> {degrees}°</span>
      </p>
      <p>
        Meaning: <span className={styles.values}> {meaning}</span>
      </p>
      <p>
        Importance: <span className={styles.values}> {importance}</span>
      </p>
    </div>
  );
};
export default AspectItem;
