// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jKyt4vs-5leFBePBAffKRg\\=\\={padding-top:12px;padding-left:2px;margin-bottom:-10px;align-self:flex-start;font-size:12px;font-family:\"AkkuratPro-Regular\"}.ojAmHAYW3vqwL5g1Uelu3w\\=\\={font-family:\"AkkuratPro-Regular\",Arial,sans-serif;color:#d0021b;font-size:12px;margin-top:10px}", "",{"version":3,"sources":["webpack://./src/landing-page/components/Input/Input.module.scss"],"names":[],"mappings":"AAAA,4BACE,gBAAA,CACA,gBAAA,CACA,mBAAA,CACA,qBAAA,CAEA,cAAA,CACA,gCAAA,CAGF,4BACE,iDAAA,CACA,aAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".label {\n  padding-top: 12px;\n  padding-left: 2px;\n  margin-bottom: -10px;\n  align-self: flex-start;\n  // font-style: italic;\n  font-size: 12px;\n  font-family: 'AkkuratPro-Regular';\n}\n\n.errorMessage {\n  font-family: 'AkkuratPro-Regular', Arial, sans-serif;\n  color: #d0021b;\n  font-size: 12px;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "jKyt4vs-5leFBePBAffKRg==",
	"errorMessage": "ojAmHAYW3vqwL5g1Uelu3w=="
};
export default ___CSS_LOADER_EXPORT___;
