import React, { useRef } from 'react';
import useD3 from 'hooks/useD3';
import * as _ from 'underscore';
import * as d3 from 'd3';

import { fromNullable } from 'fp-ts/lib/Option';
import { curry } from 'fp-ts/lib/function';

import { natalChartOrderedSigns, planetData } from '../../../../astrology';

type PlanetArrayObj = {
  name: string;
};

type ZodiacRingPropTypes = {
  startingSign: string;
  planets: PlanetArrayObj[];
  planetObjs: Record<string, unknown>;
  fillInBackground?: boolean;
  aspects?: (string | number)[][] | boolean;
  housePaths: [] | undefined;
  showHouses: boolean;
  className: string;
  shiftZodiac: number;
};

const font = require('!!url-loader!../../../../../images/fonts/Akkurat-Mono.ttf');

function signToAngle(sign) {
  const i = natalChartOrderedSigns.indexOf(sign) * toRad(30);
  return i;
}

function toRad(angle) {
  return (angle * Math.PI) / 180;
}

function toDeg(rad) {
  return (rad * 180) / Math.PI;
}

function rotateBy90(angle) {
  return angle - Math.PI / 2;
}

const HousesZodiacRing = ({
  startingSign,
  planets,
  planetObjs,
  className,
  housePaths,
  aspects = false,
  fillInBackground = false,
  showHouses = false,
  shiftZodiac = 4,
}: ZodiacRingPropTypes) => {
  const orderedSigns: string[] = [];
  const indexOfStartingSign = natalChartOrderedSigns.indexOf(startingSign);
  orderedSigns.push(...natalChartOrderedSigns.slice(indexOfStartingSign));
  orderedSigns.push(...natalChartOrderedSigns.slice(0, indexOfStartingSign));

  const angleGen = d3.pie();
  let data = angleGen([1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]);

  data = orderedSigns.map((sign, i) => ({
    ...data[i],
    name: sign,
  }));

  const ref = useD3((svg) => {
    const height = 500;
    const width = 500;
    const radius = Math.min(width, height) / 2;

    svg.attr('viewBox', `${(-1 * width) / 2} ${(-1 * height) / 2} ${width} ${height}`);
    svg
      .append('defs')
      .append('style')
      .attr('type', 'text/css')
      .text(`@font-face {font-family: 'Akkurat-Mono'; src: url(${font});}`);

    svg = svg.append('g');

    svg
      .selectAll('rect')
      .data([1])
      .enter()
      .append('rect')
      .attr('x', 0 - width / 2)
      .attr('y', 0 - height / 2)
      .attr('width', width)
      .attr('height', height)
      .attr('fill', 'transparent');

    const outerArc = d3
      .arc()
      .innerRadius(radius - 55)
      .outerRadius(radius - 20)
      .startAngle((d, _) => d.startAngle)
      .endAngle((d) => d.endAngle);

    function isUpsideDown(endAngle): boolean {
      // js mod is kinda busted for negative numbers?
      const modAngle = endAngle % (Math.PI * 2);
      const newAngle = (toDeg(modAngle) + 360) % 360;
      return newAngle > 90 && newAngle < 270;
    }

    svg
      .selectAll('.sign-path')
      .data(data)
      .enter()
      .append('path')
      .attr('class', 'sign-path')
      .attr('d', outerArc)
      .style('fill', '#000')
      .style('stroke', '#d6d6d6')
      .each(function (d, i) {
        // A regular expression that captures all in between the start of a string (denoted by ^)
        // and the first capital letter L
        const firstArcSection = /(^.+?)L/;

        // The [1] gives back the expression between the () (thus not the L as well)
        // which is exactly the arc statement

        /* TODO: I have no idea how to fix this particular case of
                                          "'this' implicitly has type 'any' because it does not have a type annotation."
                                          So for now suppressing with TS-Ignore
                                        */
        // @ts-ignore
        let newArc = firstArcSection.exec(d3.select(this).attr('d'))[1];
        // Replace all the commas so that IE can handle it -_-
        // The g after the / is a modifier that "find all matches rather than stopping after the first match"
        newArc = newArc.replace(/,/g, ' ');
        if (isUpsideDown(d.endAngle)) {
          const startLoc = /M(.*?)A/; // Everything between the capital M and first capital A
          const middleLoc = /A(.*?)0 0 1/; // Everything between the capital A and 0 0 1
          const endLoc = /0 0 1 (.*?)$/; // Everything between the 0 0 1 and the end of the string (denoted by $)
          // Flip the direction of the arc by switching the start and end point (and sweep flag)
          const newStart = fromNullable(endLoc.exec(newArc)).map((v) => v[1]);
          const newEnd = fromNullable(startLoc.exec(newArc)).map((v) => v[1]);
          const middleSec = fromNullable(middleLoc.exec(newArc)).map((v) => v[1]);

          const mkNewArc = (start: string, end: string, middleSec: string) =>
            `M${start}A${middleSec}0 0 0 ${end}`;

          newArc = newStart.map(curry(mkNewArc)).ap_(newEnd).ap_(middleSec).getOrElse(newArc);

          // Build up the new arc notation, set the sweep-flag to 0
        }
        // Create a new invisible arc that the text can flow along
        svg
          .append('path')
          .attr('class', 'hidden-donut-arc')
          .attr('id', `signArc_${i}`)
          .attr('d', newArc)
          .style('fill', 'none')
          .attr('transform', `rotate(${shiftZodiac})`);
      })
      .attr('transform', `rotate(${shiftZodiac})`);

    const middleArc = d3
      .arc()
      .innerRadius(radius - 60)
      .outerRadius(radius - 55)
      .startAngle(0)
      .endAngle(2 * Math.PI);
    svg.append('path').attr('d', middleArc).style('fill', '#f7f7f7').style('stroke', '#d6d6d6');

    if (fillInBackground) {
      const centerArea = d3
        .arc()
        .innerRadius(0)
        .outerRadius(radius - 66)
        .startAngle(0)
        .endAngle(2 * Math.PI);
      svg.append('path').attr('d', centerArea).style('fill', '#f7f7f7');
    }

    const group = svg.append('g').attr('class', 'sign-group');
    group
      .selectAll('.sign-text')
      .data(data)
      .enter()
      .append('g')
      .append('text')
      .attr('class', 'sign-text')
      .attr('dy', (d, _) => {
        const pos = isUpsideDown(d.endAngle) ? -12 : 23;
        return pos;
      })
      .style('fill', '#f7f7f7')
      .style('font-family', 'Akkurat-Mono')
      .style('font-size', '14px')
      .style('letter-spacing', '.1em')
      .append('textPath')
      .attr('startOffset', '50%')
      .style('text-anchor', 'middle')
      .attr('xlink:href', (_, i) => `#signArc_${i}`)
      .text((d) => d.name.toUpperCase());

    if (shiftZodiac !== 4) {
      group.attr('transform', `rotate(-8)`);
    }

    let pathData = housePaths;

    const houses = ['9', '8', '7', '6', '5', '4', '3', '2', '1', '12', '11', '10'];

    if (!housePaths) {
      pathData = angleGen([1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]);
      pathData = houses.map((house, i) => ({
        ...data[i],
        text: house,
      }));
    }

    const arc = d3
      .arc()
      .innerRadius(radius - 90)
      .outerRadius(radius - 60)
      .startAngle((d, _) => d.startAngle)
      .endAngle((d) => d.endAngle);

    const path = svg.selectAll('.house-path').data(pathData).enter();

    path
      .append('path')
      .attr('class', 'house-path')
      .attr('d', arc)
      .style('fill', '#fff')
      .style('stroke', '#d6d6d6')
      .each(function (d, i) {
        // A regular expression that captures all in between the start of a string (denoted by ^)
        // and the first capital letter L
        const firstArcSection = /(^.+?)L/;
        // The [1] gives back the expression between the () (thus not the L as well)
        // which is exactly the arc statement

        /* TODO: I have no idea how to fix this particular case of
                                          "'this' implicitly has type 'any' because it does not have a type annotation."
                                          So for now suppressing with TS-Ignore
                                        */
        // @ts-ignore
        if (!housePaths) {
          let newArc = firstArcSection.exec(d3.select(this).attr('d'))[1];
          // Replace all the commas so that IE can handle it -_-
          // The g after the / is a modifier that "find all matches rather than stopping after the first match"
          newArc = newArc.replace(/,/g, ' ');
          if (isUpsideDown(d.endAngle)) {
            const startLoc = /M(.*?)A/; // Everything between the capital M and first capital A
            const middleLoc = /A(.*?)0 0 1/; // Everything between the capital A and 0 0 1
            const endLoc = /0 0 1 (.*?)$/; // Everything between the 0 0 1 and the end of the string (denoted by $)
            // Flip the direction of the arc by switching the start and end point (and sweep flag)
            const newStart = fromNullable(endLoc.exec(newArc)).map((v) => v[1]);
            const newEnd = fromNullable(startLoc.exec(newArc)).map((v) => v[1]);
            const middleSec = fromNullable(middleLoc.exec(newArc)).map((v) => v[1]);

            const mkNewArc = (start: string, end: string, middleSec: string) =>
              `M${start}A${middleSec}0 0 0 ${end}`;

            newArc = newStart.map(curry(mkNewArc)).ap_(newEnd).ap_(middleSec).getOrElse(newArc);

            // Build up the new arc notation, set the sweep-flag to 0
          }
          // Create a new invisible arc that the text can flow along
          svg
            .append('path')
            .attr('class', 'hidden-house-donut-arc')
            .attr('id', `houseArc_${i}`)
            .attr('d', newArc)
            .style('fill', 'none');
        }
      });

    svg
      .selectAll('.house-text')
      .data(pathData)
      .enter()
      .append('text')
      .attr('class', 'house-text')
      .attr('dy', (d, _) => {
        const pos = isUpsideDown(d.endAngle) ? -12 : 23;
        return pos;
      })
      .style('fill', '#00000')
      .style('font-family', 'Akkurat-Mono')
      .style('font-size', '14px')
      .style('letter-spacing', '.1em')
      .append('textPath')
      .attr('startOffset', '50%')
      .style('text-anchor', 'middle')
      .attr('xlink:href', (_, i) => `#houseArc_${i}`)
      .text((d) => d.text);

    const generatePlanetCoords = (planet, deg) => {
      const rad = toRad(deg);
      planetObjs[planet].x = (radius - 75) * Math.cos(-1 * rad) - planetObjs[planet].imageWidth / 2;
      planetObjs[planet].y =
        (radius - 75) * Math.sin(-1 * rad) - planetObjs[planet].imageHeight / 2;
    };

    const findAspectCoord = (axis, deg) => {
      const rad = toRad(deg);
      const cosOrSin = axis === 'x' ? Math.cos : Math.sin;
      return (radius - 90) * cosOrSin(-1 * rad);
    };

    if (aspects) {
      let housesAspects = [];
      if (!housePaths) {
        housesAspects = [
          [30, 210],
          [60, 240],
          [120, 300],
          [150, 330],
        ];
      }

      svg
        .selectAll('.aspects')
        .data(aspects)
        .enter()
        .append('line')
        .attr('x1', (d) => findAspectCoord('x', d[0]))
        .attr('x2', (d) => findAspectCoord('x', d[1]))
        .attr('y1', (d) => findAspectCoord('y', d[0]))
        .attr('y2', (d) => findAspectCoord('y', d[1]))
        .attr('stroke', '#000')
        .attr('stroke-dasharray', '4, 4');

      svg
        .selectAll('.aspects')
        .data(housesAspects)
        .enter()
        .append('line')
        .attr('x1', (d) => findAspectCoord('x', d[0]))
        .attr('x2', (d) => findAspectCoord('x', d[1]))
        .attr('y1', (d) => findAspectCoord('y', d[0]))
        .attr('y2', (d) => findAspectCoord('y', d[1]))
        .attr('stroke', '#a6a6a6')
        .attr('stroke-dasharray', '3, 3');
    }

    svg
      .selectAll('.planet-labels')
      .data(planets)
      .enter()
      .append('image')
      .attr('class', 'planet-labels')
      .attr('x', (d) => planetObjs[d.name].x)
      .attr('y', (d) => planetObjs[d.name].y)
      .attr('width', (d) => planetObjs[d.name].imageWidth)
      .attr('height', (d) => planetObjs[d.name].imageHeight)
      .attr('xlink:href', (d) => {
        const str = planetData[d.name].imgDataUrl;
        return str;
      });
  }, []);

  return (
    <svg ref={ref} className={className}>
      <g className="area" />
    </svg>
  );
};

export default HousesZodiacRing;
