import React from 'react';

import { Link } from 'react-router-dom';

import styles from './ZodiacListItem.module.scss';

type ZodiacItemProps = {
  body: string;
  subheader?: string;
  dates?: boolean;
};
const ZodiacItem = ({ body, subheader, dates }: ZodiacItemProps) => (
  <div className={styles.item}>
    {subheader && <h4>{subheader}</h4>}
    {!dates && <p>{body}</p>}
    {dates && (
      <div>
        <p>{body}</p>
        <p>
          <Link to="/download">Get the app</Link> to check your birth year.
        </p>
      </div>
    )}
  </div>
);

ZodiacItem.defaultProps = {
  subheader: undefined,
  dates: false,
};

export default ZodiacItem;
