import React from 'react';
import { Sign } from 'astrology';

// repeating 11 images
const picMap = {
  0: require('../../../../images/cutouts/cutout-crystal.png'),
  1: require('../../../../images/cutouts/cutout-fruit.png'),
  2: require('../../../../images/cutouts/cutout-magpie.png'),
  3: require('../../../../images/cutouts/cutout-moth.png'),
  4: require('../../../../images/cutouts/cutout-peony.png'),
  5: require('../../../../images/cutouts/cutout-pluto.png'),
  6: require('../../../../images/cutouts/cutout-stone2.png'),
  7: require('../../../../images/cutouts/cutout-tulip.png'),
  8: require('../../../../images/cutouts/cutout-twig.png'),
  9: require('../../../../images/cutouts/cutout-wing.png'),
  10: require('../../../../images/cutouts/cutout-sculpture.png'),
};

let count = 0;
const getAbstractImages = () => ({
  abstractImageOne: picMap[count++ % 11],
  abstractImageTwo: picMap[count++ % 11],
  abstractImageThree: picMap[count++ % 11],
});

export interface Bio {
  abstractImageOne: string;
  abstractImageThree: string;
  abstractImageTwo: string;
  ascendant?: Sign;
  astroTraining?: string[];
  biography: string[];
  dont: string[];
  dos: string[];
  favoriteAstroBooks?: string[];
  hyphenName: string;
  image?: string;
  moon?: Sign;
  name: string;
  sun: Sign;
  title: string;
}

/**
 * Template below for new bios:
  {
    hyphenName: '',
    image: null,
    name: '',
    sun: '',
    moon: '',
    ascendant: '',
    title: '',
    biography: [''],
    dos: [],
    dont: [],
    astroTraining: null,
    favoriteAstroBooks: null,
  }
 **/

export const bios: Bio[] = [
  {
    hyphenName: 'banu-guler',
    image: require('../../../../images/Team-Banu.png'),
    name: 'Banu Guler',
    sun: 'Scorpio',
    moon: 'Leo',
    ascendant: 'Cancer',
    title: 'Founder & CEO',
    biography: [
      'A child of Pakistani and Turkish immigrants, Banu forged her identity walking a tightrope between scientific pragmatism and old traditions of coffee ground readings, palmistry, and astrology. These days she thinks of astrology as 4,000 years of observed correlations between predictable planetary orbits and human behavioral patterns.',
      'Banu studied psychology at NYU. She is an older sister to a Leo and a Capricorn.',
    ],
    dos: ['Anne Carson', 'The International Dark-Sky Association', 'Spreadsheets'],
    dont: ['Improper grammar', 'Pastel gradients', 'Excess'],
  },
  {
    hyphenName: 'chris-meyers',
    image: require('../../../../images/Team-Chris.png'),
    name: 'Chris Meyers',
    sun: 'Aquarius',
    moon: 'Aquarius',
    ascendant: 'Gemini',
    title: 'Design',
    biography: [
      'As a teen in Minnesota, Chris was fixated on fishing. He tracked moon phases, seasons, locations in an attempt to catch the biggest fish in the state. He sees everything as a puzzle to solve, messing around with different pieces of information until he finds a solution. This is what drives his interest in digital design. Throughout his career, he’s worked on apps for companies like SoulCycle and XXL Magazine. Chris was lead designer at Prolific Interactive, an agency acquired by The We Company, before joining Co—Star as Design Lead.',
    ],
    dos: ['Hockey', 'Fantasy lore', 'Board games'],
    dont: ['Pollen', 'Overhand knots', 'Beat around the bush'],
  },
  {
    hyphenName: 'rin-park',
    image: require('../../../../images/Team-rin-park.png'),
    name: 'Rin Park',
    sun: 'Libra',
    moon: 'Leo',
    ascendant: 'Aries',
    title: 'Design',
    biography: [
      'Rin grew up in Manila, tossing leftover snacks at monkeys, and Seoul, before moving to New York six years ago to study at the School of Visual Arts. She loved the UI/UX class she took there, and ended up pursuing that as a career - though her real dream is to open a cafe overlooking the beach in Spain. Outside of her work as a designer at Co—Star, her hobby is finding new hobbies, whether that’s building mechanical keyboards or weaving Pop Art-esque rugs.',
    ],
    dos: ['Rabbit holes', 'Handmade objects', 'Heat'],
    dont: ['Fancy restaurants', 'Silent treatment', '"Aesthetically pleasing"'],
  },
  {
    hyphenName: 'valentine-wilson',
    image: require('../../../../images/Team-Valentine.png'),
    name: 'Valentine Wilson',
    sun: 'Libra',
    moon: 'Libra',
    ascendant: 'Sagittarius',
    title: 'Product',
    biography: [
      'Valentine spent her childhood modeling Ebola outbreaks using Lego townspeople. It’s this interest in data and community that led her to study computer science in college. Feeling nihilistic after a rough breakup in college, she created a match-making algorithm to prove to herself that love still existed. This started a campus-wide meme and launched her career in product management. She is still figuring out if love exists, and is continuing this exploration at Co—Star.',
    ],
    dos: ['Crowdsourcing', 'Hypoallergenic', 'Make-believe'],
    dont: ['Monotone', 'Open Cabinets', 'Toe-shoes'],
  },
  {
    hyphenName: 'tracy-jennings',
    image: require('../../../../images/Team-Tracy.png'),
    name: 'Tracy Jennings',
    sun: 'Gemini',
    moon: 'Leo',
    ascendant: 'Libra',
    title: 'Product',
    biography: [
      'In a previous life, Tracy studied ancient Roman history at Notre Dame and Oxford, poring over epitaphs and other inscriptions in those gigantic tomes you always thought were just for display in libraries. Deciding to lean into her Gemini generalist tendencies, she pivoted both professionally and geographically, moving from England to Brooklyn and taking up a role at an education start-up. Since then, she has worked to wrangle US university data into insights, and to optimize the discoverability of sound from libraries of music samples. Tracy vividly remembers reading horoscopes from Cosmo at track meets in high school; as Product Lead for Personalization at Co—Star, she’s finally come full-circle.',
    ],
    dos: ['Dark dancefloors', 'Radical inclusion', 'Graph paper'],
    dont: ['Stale air', 'Unchecked assumptions', 'Microwaved fish'],
  },
  {
    hyphenName: 'stephanie-bravo-lopez',
    image: require('../../../../images/Team-stephanie-bravo-lopez.png'),
    name: 'Stephanie Bravo Lopez',
    sun: 'Scorpio',
    moon: 'Virgo',
    ascendant: 'Cancer',
    title: 'Operations',
    biography: [
      'Stephanie was born and raised in New York City where she grew up learning about the stars and planets through folkloric Mexican dance. In college, majoring in Educational Studies and Hispanic Studies, she lived with friends who were self-proclaimed astrology experts. They, along with her sister, created her natal chart and taught her everything she knows about astrology. Stephanie loves everything dance, music, podcasts, and enjoys finding hidden gems in NYC. At Co—Star, she works assisting with recruiting and operations projects. ',
    ],
    dos: ['Intersectionality', 'Trash reality TV', 'Mexican food'],
    dont: ['Cockiness', 'Birds', 'Winter in NYC'],
  },
  {
    hyphenName: 'calvin-hu',
    name: 'Calvin Hu',
    sun: 'Aries',
    title: 'Engineering',
    biography: [
      'When Calvin was born, his parents took him to an astrologer who said his luck would turn around someday. It did: his video game pirating hobby as a teen led him to eventually study computer science at Columbia. Now, at Co—Star, he runs everything Android related. He has a mildly popular faceless Twitter account and spends his free time sailing the Hudson. He hopes to be remembered for saving the world, even though it hasn’t happened yet.',
    ],
    dos: ['Naruto', '1970’s motorbike', 'Playstation', 'Every condiment on pizza'],

    dont: ['Xbox', 'Hasty drivers'],
  },

  {
    hyphenName: 'kelly-wang',
    image: require('../../../../images/Team-Kelly.png'),
    name: 'Kelly Wang',
    sun: 'Aries',
    moon: 'Virgo',
    ascendant: 'Taurus',
    title: 'Engineering',
    biography: [
       'Kelly grew up in Mountain View, a sunny town in the armpit of the Bay Area. She majored in Computer Science (booooo) and minored in English (yay!!!!) in college, where she nursed a distrust for tech culture that is still alive and thriving today. Her free time is spent collecting secrets, consuming celebrity gossip, and wallowing. If Kelly were stuck on a deserted island, she would give up immediately and ask the showrunners to take her home.',
    ],
    dos: ['Fitness goals', 'Gossip', 'Tao Te Ching'],

    dont: ['Losing', 'Repetitive tasks', 'Two-dimensional characters'],
  },
  {
    hyphenName: 'sam-zweig',
    image: require('../../../../images/Team-Sam.png'),
    name: 'Sam Zweig',
    sun: 'Gemini',
    moon: 'Libra',
    ascendant: 'Scorpio',
    title: 'Engineering',
    biography: [
      'As a high schooler in Palo Alto, Sam ran Linux because he didn’t trust Microsoft or the government. He studied computer science at UC Santa Cruz where he would occasionally go get lost in the woods with friends. He’s been in two post-punk bands, but probably no one has ever heard of either of them. His biggest pride in life – other than Co—Star’s devops – is his dog Yuri (like the astronaut), who is not very well trained.',
    ],
    dos: ['20th century guitar', 'DIY Punk', 'Mount Shasta'],

    dont: [
      'Artificial cherry flavor',
      'Most birds (except crows)',
      'New buildings built to look like old ones',
    ],
  },
  {
    hyphenName: 'hal-keller',
    image: require('../../../../images/Team-Hal.png'),
    name: 'Hal Keller',
    sun: 'Capricorn',
    moon: 'Libra',
    ascendant: 'Leo',
    title: 'Engineering Manager',
    biography: [
      'As a child, Hal’s self-proclaimed life goal was to become the editor of French Vogue, but they think there’s as much aesthetic satisfaction to be found in good code as there is in high fashion. At college in New Mexico, they studied mathematics, philosophy, and creative writing before pivoting to software development. They have since pursued a master’s in computer science, acted as a shepherd for the backend services of a fintech company, and developed a deep love of functional programming and type safety. At Co—Star, they’re treating coding like any other expressive art form as one of the team’s Haskell engineers.', //TODO: Engineering Managers?
    ],
    dos: ['Whitespace', 'Pink stucco', 'Mary Karr'],

    dont: ['“It is what it is”', 'Twizzlers', 'Kantian aesthetics'],
  },
  {
    hyphenName: 'tendai-gwini',
    image: require('../../../../images/Team-Tendai.png'),
    name: 'Tendai Gwini',
    sun: 'Gemini',
    moon: 'Scorpio',
    ascendant: 'Sagittarius',
    title: 'Engineering',
    biography: [
      'Growing up in Zimbabwe, Tendai spent his time scavenging online for the house tunes that were making the rounds in underground clubs all over the world. All this time online ended up with him learning how to build scripts to help him download the tracks he wanted. He has always thought of the rhythm in house music as being a universal language that can get anyone tapping their feet and moving their body. Now he is bringing another universal language to life at Co-Star as part of the iOS team.',
    ],
    dos: ['House music', 'Formula 1', 'Medium rare'],

    dont: ['Long layovers', 'Cookie pop ups', 'Camera on the dance floor '],
  },
  {
    hyphenName: 'noah-emmet',
    image: require('../../../../images/Team-Emmet.png'),
    name: 'Noah Emmet',
    sun: 'Taurus',
    moon: 'Aquarius',
    ascendant: 'Capricorn',
    title: 'Engineering',
    biography: [
      'Noah Emmet began life at a young age and continued to exist up until at least the time of this writing.',
    ],
    dos: ['Redundancy', 'Cache invalidation', 'Redundancy'],

    dont: ['Cache invalidation', 'Off-by-one errors'],
  },
  {
    hyphenName: 'jonathan-garnaas-holmes',
    image: require('../../../../images/Team-Jonathan-Garnaas-Holmes.png'),
    name: 'Jonathan Garnaas-Holmes',
    sun: 'Aquarius',
    moon: 'Aries',
    ascendant: 'Gemini',
    title: 'Engineering Manager',
    biography: [
      'Jonathan’s last name comes from the name of a meteor site in Norway, the Gardnos crater. This, he thinks, is a sign that he was destined to end up at Co—Star. He’s worked in app development for ten years, taking a brief break in the middle to teach at a pre-school where he learned that he is great at talking to children about how they feel. He spends his time watching (and attempting to perform) improv, and caring lovingly for his three guinea pigs, Flora, Winnie, and Klaus.',
    ],
    dos: ['Sci Fi', 'Clay', 'Rock Climbing'],

    dont: ['Synthetic polymers', 'Apathy', 'Olives'],
  },
  {
    hyphenName: 'mitchell-bohman',
    image: require('../../../../images/Team-Mitchell.png'),
    name: 'Mitchell Bohman',
    sun: 'Gemini',
    moon: 'Pisces',
    ascendant: 'Sagittarius',
    title: 'Data Science',
    biography: [
      'Ever since an old man told him that “words are like stencils that make cutouts of the world,” Mitchell has had a hard time not reading all books, fiction and non-fiction alike, as if they were instruction manuals. At Co—Star he does all operations data-related, BI to NLP, but science fiction is likely to blame for his conceit that astrology is the original predictive analytics.',
    ],
    dos: ['Deadlifts', 'Real distinctions', 'Bocage'],

    dont: ['Greed', 'Apocalypses', 'French gardens'],
  },
  {
    hyphenName: 'ryan-zhang',
    image: require('../../../../images/Team-Ryan_600x600.png'),
    name: 'Ryan Zhang',
    sun: 'Taurus',
    moon: 'Aquarius',
    ascendant: 'Virgo',
    title: 'Data Science',
    biography: [
      'As a 14-year-old in Beijing, Ryan’s long-standing interest in aviation gave him the opportunity to take pictures of aircrafts from airport control towers for magazines. He moved to the US in 2011 to study photography before changing his major to math. He then went on to get his masters in Data Science at NYU. Ryan still loves airplanes, and is in the process of getting his pilot’s license. He currently lives in Brooklyn with his five-year-old corgi named Pudding.',
    ],
    dos: ['Up in the Air', 'Straightforward', 'Middle of nowhere'],

    dont: ['Instruction manuals', 'Tacos ', 'Large crowds'],
  },
  {
    hyphenName: 'kat-selvocki',
    image: require('../../../../images/Team-katselvocki.png'),
    name: 'Kat Selvocki',
    sun: 'Leo',
    moon: 'Libra',
    ascendant: 'Virgo',
    title: 'Engineering',
    biography: [
      "Kat has reinvented her life and her career at least four times since she thought she was going to double-major in Computer Science in college, but refused to take a Visual Basic class; she really likes the current iteration and plans to keep it for a while. She can frequently be found exploring, whether that's on trails in the Pacific Northwest or in whatever technology she's currently testing. An avid reader, one of Kat’s great passions is the romance novel - the only genre in which happy endings are still guaranteed. She also experienced brief internet fame for having a sewer rat come out of her toilet when she lived in Brooklyn.",
    ],
    dos: ['Neon', 'Oolong', 'Endless new hobbies'],

    dont: ['True crime', 'Late nights', 'Back-in angle parking'],
  },
  {
    hyphenName: 'hanna-hurr',
    image: require('../../../../images/Team-Hanna.png'),
    name: 'Hanna Hurr',
    sun: 'Pisces',
    moon: 'Sagittarius',
    ascendant: 'Leo',
    title: 'Head of Content',
    biography: [
      'Hanna is Head of Content at Co—Star and has been practicing astrology since 2018. She grew up in Finland and has been living in the US since 2009. Before joining Co—Star she founded and edited Mask Magazine. ',
    ],
    dos: ['Morning raves', 'Manifesting', 'Adaptogens'],

    dont: ['Moving to Mars', 'Be polite', 'Air fryer'],
    astroTraining: [
      'Has been studying astrology since 2018',
      'Online courses with Bernadette Brady, Darrelyn Gunzburg, Chris Brennan, and others.',
      'Especially interested in psychological & relationship astrology',
    ],
    favoriteAstroBooks: [
      <>
        <span>The Eagle and the Lark</span> by Bernadette Brady
      </>,
      <>
        <span>On the Heavenly Spheres: A Treatise on Traditional Astrology</span> by Helena Avelar
        and Luis Ribeiro
      </>,
      <>
        <span>The Inner Sky</span> by Steven Forrest
      </>,
      <>
        <span>Water & Fire; Earth & Air</span> by Darby Costello
      </>,
    ],
  },
  {
    hyphenName: 'anna-gelb',
    image: require('../../../../images/Team-Anna.png'),
    name: 'Anna Gelb',
    sun: 'Leo',
    moon: 'Virgo',
    ascendant: 'Leo',
    title: 'Brand',
    biography: [
      'Anna grew up in Seattle in the blissful in-between of the grunge era and the Amazon boom. Before coming to Co—Star she spent a decade curating a roving dinner series on farms and iconic public spaces all over the world. Anna loves to organize. She’s organized adult show and tell, seed exchanges, CSAs, and currently organizes a subscription service for kitchen pantry products. At Co–Star, she uses her organizational prowess to project manage for the brand team.',
    ],
    dos: ['Orcas', 'Hot springs', 'Love island'],
    dont: ['Root vegetables', 'Stand up comedy', 'Gentleman farmers'],
  },
  {
    hyphenName: 'nalani-tran',
    image: require('../../../../images/Team-Nalani.png'),
    name: 'Nalani Tran',
    sun: 'Leo',
    moon: 'Gemini',
    ascendant: 'Sagittarius',
    title: 'Brand',
    biography: [
      'Nalani was born a cyborg and grew up between North Carolina and Texas. They run TikTok at Co—Star, which means they spend a lot of time walking around New York talking to strangers about love.',
    ],
    dos: ['Electronics tinkering', '25mm lashes', 'Dance Dance Revolution'],
    dont: ['Being starstruck', 'Thought terminating cliches', 'Self-isolation'],
  },

  {
    hyphenName: 'paris-parker-loan',
    image: require('../../../../images/Team-Paris.png'),
    name: 'Paris Parker-Loan',
    sun: 'Leo',
    moon: 'Aries',
    ascendant: 'Capricorn',
    title: 'Brand',
    biography: [
      "Paris grew up outside of DC, where she embraced her most Capricorn qualities by joining every club and team in high school. She has chilled out since moving to New York for college. A lifelong culture sponge, Paris keeps up with all the articles, newsletters, forums, podcasts, and social media drama from every corner of the Internet. Now she gets paid to do this as Co—Star's social strategy manager.",
    ],
    dos: ['Pants', 'Movie theaters', 'Daydreaming'],
    dont: ['Mayonnaise', 'Slow Wi-fi', 'The subway stalling underground'],
  },

  {
    hyphenName: 'nicole-ivey',
    image: require('../../../../images/Team-Nicole.png'),
    name: 'Nicole Ivey',
    sun: 'Libra',
    moon: 'Virgo',
    ascendant: 'Capricorn',
    title: 'COO',
    biography: [
      'Nicole Ivey is an experienced educator, leader, entrepreneur, and health and somatic practitioner. Post graduation from Yale with a degree in economics, Nicole ran global teams at Google, stood-up Google sales first EDI teams, and ran a sustainable chicken farm. After graduation from Harvard Business School in the top of her class, Nicole started Village: The Wholehearted School. Village served over 400 students both in-person and virtually, providing critical support for families across the pandemic, granting nearly $300K+ in scholarships in 2.5 years. In 2022, Nicole joined the Co–Star team, where she brings order to the chaos as COO.',
    ],
    dos: ['Dresses', 'Trash magazines', 'Sunrise'],
    dont: ['Projection', 'Fluorescent lights', 'Late nights'],
  },
  {
    hyphenName: 'emily-batt',
    image: require('../../../../images/Team-Emily.png'),
    name: 'Emily Batt',
    sun: 'Aquarius',
    moon: 'Leo',
    ascendant: 'Virgo',
    title: 'HEAD OF STRATEGY',
    biography: [
      'Emily decided to study physics after overhearing a conversation about quantum mechanics in a suburban mall. It sparked a lifelong fascination with how things connect. Her journey has led from writing code on oceanography vessels to studying depressed medieval monks to working on Swiss alpine farms. Along the way, she earned three patents as a mechanical engineer before discovering that software offered an even faster path from idea to impact. After leading product teams at Kayak and Google, Emily pursued an MBA and a MS Engineering at Harvard, helped found a microfluidics manufacturing company, and invested in frontier tech startups as a venture capitalist. Today as Head of Strategy, she focuses on product and business—always guided by that original love of understanding how systems work.',
    ],
    dos: ['GPS directions', 'Anything iambic', 'Condensation'],
    dont: ['Brunch', 'Scary movies', 'Counter clockwise'],
  },

  {
    hyphenName: 'jared-ramirez',
    image: require('../../../../images/Team-Jared.png'),
    name: 'Jared Ramirez',
    sun: 'Aries',
    moon: 'Capricorn',
    ascendant: 'Libra',
    title: 'ENGINEERING',
    biography: [
      'Jared arrived as a surprise to everyone, on Easter Sunday, when his parents were visiting family in Sacramento. Growing up as the middle child in a bustling bilingual Mexican-American household in California, he is no stranger to joyful chaos. Jared’s large extended family’s tamale-making gatherings, involving hundreds of tamales and strong wills, have prepared him well for the world of software development. Jared stumbled into programming almost by accident, helping a classmate with her computer science homework, which led to his first job when her grateful father hired him. This early start in the industry allowed Jared to explore his other passions in college, delving into history and philosophy. When not immersed in a good book or writing elegant code in functional programming languages like Haskell, Jared can be found pushing his limits in action flow sports. Despite dislocating his shoulder nine times, he remains an avid surfer, climber, skier, and kayaker. As Head of Engineering at Replenysh, he guided the team through multiple pivots, rebuilding the tech stack from scratch each time. Through this he learned Haskell, which eventually landed him at Co-Star.',
    ],
    dos: ['Honk your car horn', 'Break out of thought loops', 'Eat plants'],
    dont: ['Look at phone for prolonged periods', 'Finish boring books', 'Rigid thinking'],
  },
  {
    hyphenName: 'amelia-quint',
    image: require('../../../../images/Team-Amelia.png'),
    name: 'Amelia Quint',
    sun: 'Scorpio',
    moon: 'Gemini',
    ascendant: 'Sagittarius',
    title: 'SENIOR CONTENT PRODUCER',
    biography: [
      'Amelia has been studying and practicing astrology for over a decade, but her fascination with the occult sprouted much earlier. Growing up in the humid woods of South Carolina, she spent recess hunting for four-leaf clovers and devouring library books on mythology. Born with a grand mutable cross, she channels her unrelenting curiosity into making noise: classical piano at six, guitar at thirteen, rock bands in her mid-20s, podcasting at 30. She believes in the power of a great conversation and thinks a good astrology reading is exactly that.',
    ],
    dos: ['Open worlds', 'Signature scents', 'Hermeticism'],
    dont: ['Dogma', 'Red herrings', 'Standard issue'],
    astroTraining: [
      'Has been practicing astrology professionally since 2011.',
      'Her work honors astrology’s ancient roots while incorporating modern techniques.',
      'Especially interested in relationship astrology, eclipse cycles, and asteroids.',
    ],
    favoriteAstroBooks: [
      <>
        <span>Planets in Transit</span> by Robert Hand
      </>,
      <>
        <span>Asteroid Goddesses</span> by Demetra George
      </>,
      <>
        <span>The Rulership Book</span> by Rex E. Bills
      </>,
    ],
  },

].map((b) => ({ ...b, ...getAbstractImages() }));

// Below lists are intentionally ordered
export const leadership: string[] = [
  'banu-guler',
  'nicole-ivey',
  'hanna-hurr',
  'emily-batt'
];

export const staffAstrologicalExperts: string[] = [
  'hanna-hurr',
  'amelia-quint'
];

export const advisoryBoard: string[] = [];

export const TEAM_SIZE = bios.length;
