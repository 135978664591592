// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Igjn3Dw7Ht0I7yLex3fCHg\\=\\={max-width:90vw}.MNf8FxIPUaDPOS7ZJv64UA\\=\\={min-height:120px}.zU8Q-XP5Rqc7Qy8Li5CIrQ\\=\\={width:100px;height:100px;margin-right:20px;margin-bottom:20px;float:left}.h45SKH00YHK9E4Y9gJj21A\\=\\=>p{margin:0 !important}.SUw5xNdtZufWTQw8BonTDQ\\=\\={font-family:Akkurat-Mono,\"Andale Mono\",sans-serif;font-size:12px;line-height:16px;text-transform:uppercase}.SXrFAZOuPg-C\\+-GgQTNpSw\\=\\={color:#a6a6a6}.MGODUlmsA71PvwiNabDVHA\\=\\={list-style:initial;padding-left:1rem;max-width:500px}.MGODUlmsA71PvwiNabDVHA\\=\\= li span{font-style:italic}", "",{"version":3,"sources":["webpack://./src/landing-page/pages/TeamPage/Bio.module.scss"],"names":[],"mappings":"AAIA,4BACE,cAAA,CAGF,4BACE,gBAAA,CAGF,4BACE,WAAA,CACA,YAAA,CACA,iBAAA,CACA,kBAAA,CACA,UAAA,CAGF,8BACE,mBAAA,CAGF,4BACE,iDAAA,CACA,cAAA,CACA,gBAAA,CACA,wBAAA,CAGF,6BACE,aAAA,CAGF,4BACE,kBAAA,CACA,iBAAA,CACA,eAAA,CAEE,oCACE,iBAAA","sourcesContent":["$gray: #d6d6d6;\n$lightGray: #f7f7f7;\n$offWhite: #fbfbfb;\n\n.card {\n  max-width: 90vw;\n}\n\n.cardHeader {\n  min-height: 120px;\n}\n\n.teamHeadshot {\n  width: 100px;\n  height: 100px;\n  margin-right: 20px;\n  margin-bottom: 20px;\n  float: left;\n}\n\n.bio > p {\n  margin: 0 !important;\n}\n\n.smallcaps {\n  font-family: Akkurat-Mono, 'Andale Mono', sans-serif;\n  font-size: 12px;\n  line-height: 16px;\n  text-transform: uppercase;\n}\n\n.astroHeader {\n  color: #a6a6a6;\n}\n\n.list {\n  list-style: initial;\n  padding-left: 1rem;\n  max-width: 500px;\n  li {\n    span {\n      font-style: italic;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "Igjn3Dw7Ht0I7yLex3fCHg==",
	"cardHeader": "MNf8FxIPUaDPOS7ZJv64UA==",
	"teamHeadshot": "zU8Q-XP5Rqc7Qy8Li5CIrQ==",
	"bio": "h45SKH00YHK9E4Y9gJj21A==",
	"smallcaps": "SUw5xNdtZufWTQw8BonTDQ==",
	"astroHeader": "SXrFAZOuPg-C+-GgQTNpSw==",
	"list": "MGODUlmsA71PvwiNabDVHA=="
};
export default ___CSS_LOADER_EXPORT___;
