/* Component & Utility Functions for Proper Handling of Anchor Links &
 * Scrolling to Anchors on Page Loads.
 */
import * as React from 'react';

/* If the URL contains a hash, attempt to scroll to the element with that ID.
 *
 * We use this function in `componentDidMount` to make anchor tags work in iOS
 * browsers.
 */
export function scrollToLocationHash(): void {
  if (window.location.hash !== '') {
    const elementId = window.location.hash.replace(/^#/, '');
    scrollToElementWithId(elementId);
  }
}

/* The AnchorLink component renders an `a` element that scrolls to an element &
 * updates the URL hash when clicked.
 *
 * Normally, you can simply use anchor tags & links to achieve this, but that
 * does not work consistently on iOS so we leverage JS to do the scrolling.
 */
export class AnchorLink extends React.Component<AnchorLinkProps, {}> {
  // Scroll to the target element & update the page URL.
  scrollToTarget(ev: React.MouseEvent<HTMLAnchorElement>): void {
    ev.preventDefault();

    scrollToElementWithId(this.props.targetId);
    const newUrl = `${window.location.origin + window.location.pathname}#${
      this.props.targetId
    }`;
    history.pushState({}, '', newUrl);
  }

  // Render the anchor link & it's contents.
  render(): JSX.Element {
    return (
      <a
        href={`#${this.props.targetId}`}
        onClick={this.scrollToTarget.bind(this)}
      >
        {this.props.children}
      </a>
    );
  }
}

export interface AnchorLinkProps {
  // The ID of the element to scroll to.
  targetId: string;
}

// Smooth scroll to the given element ID if it exists on the page.
export function scrollToElementWithId(targetId: string): void {
  const element = document.getElementById(targetId);
  if (element !== null) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}
