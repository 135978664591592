import React from 'react';
import { Link } from 'react-router-dom';
import { Sign } from 'astrology';
import { dates, displayYear } from '../ZodiacSignTypes';

import styles from './ZodiacNavItem.module.scss';

type ZodiacNavItemProps = {
  sign: Sign;
};

const ZodiacNavItem = ({ sign }: ZodiacNavItemProps) => (
  <Link to={`/zodiac-signs/${sign.toLowerCase()}-sign`}>
    <div className={styles.signGridChild}>
      <img
        className={styles.signIndexImage}
        src={require(`../../../../../../images/zodiac-signs/small/${sign}.png`)}
      />
      <span className={styles.signTitle}>{sign}</span>
      <br />
      <span className={styles.signDates}>{dates[displayYear][sign.toLowerCase()]} for {displayYear}</span>
    </div>
  </Link>
);

export default ZodiacNavItem;
