import React from 'react';
import { useParams } from 'react-router-dom';

import Api from 'api';
import PageLayout from '../views/PageLayout';

function MigrateFBPage() {
  const [msg, setMsg] = React.useState<string>('');
  const { targetUserId, deadUserId, token } = useParams() as {
    targetUserId: string;
    deadUserId: string;
    token: string;
  };

  const onConfirm = () => {
    setMsg('Migration in progress...');
    Api.migrateFBUser(targetUserId, deadUserId, token)
      .then(() => {
        setMsg(
          'You have successfully verified your phone number. Please restart the app for the changes to go into effect. You will need to use the ‘Forgot my password’ link on the login page to reset your password next time you log in.',
        );
      })
      .catch((_) => {
        setMsg('There was an error merging your accounts.');
      });
  };

  return (
    <div className="floating-container">
      <div className="floating-container-inner">
        <div>
          <h1>Merge Accounts</h1>
          {msg ? (
            <>
              <br />
              <p>{msg}</p>
            </>
          ) : null}
          <br />
          <button type="button" onClick={onConfirm}>
            Confirm
          </button>
          <br />
          <p>
            Note: this will permanently remove the phone number from the other
            account associated with it.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PageLayout(MigrateFBPage);
