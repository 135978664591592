import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Sign } from 'astrology';
import PageLayout from 'landing-page/views/PageLayout';
import SectionPadding from 'landing-page/components/SectionPadding';
import { signs } from './ZodiacSignTypes';

import styles from './ZodiacSigns.module.scss';

import ZodiacNavItem from './ZodiacNavItem';
import AstroIntuitionFooter from '../AstroIntuitionFooter';
import AstroIntuitionHeader from '../AstroIntuitionHeader';
import AstroIntuitionContainer from '../AstroIntuitionContainer';

const ZodiacSignsPage = () => {
  const navItems = signs.map((sign: Sign) => <ZodiacNavItem sign={sign} key={sign} />);

  return (
    <>
      <Helmet>
        <title>Zodiac Signs: Personality Traits, Qualities, Quirks | Co–Star</title>
        <meta
          name="description"
          content="Our Sun sign, Moon sign, Rising sign, along with all the planets and the houses combined tell the most complete story of who we are. Start here with a review of Sun sign archetypes."
        />
      </Helmet>
      <div className="floating-container">
        <AstroIntuitionHeader>
          <h1>Zodiac Sun Signs</h1>
        </AstroIntuitionHeader>
        <AstroIntuitionContainer theme="transparent-noPadding">
          <br />
          <h3>
            The part of astrology that you’re probably most familiar with is the Sun Sign. When you
            say “I am a Pisces”, you are referring to your Sun Sign being in Pisces.
          </h3>
          <SectionPadding verticalPadding="2rem" />
          <p>
            It corresponds to the zodiac sign the Sun appeared to be in front of when you are born.
            Because the Earth completes one revolution around the Sun each year, the Sun moves
            through all the Zodiac Sun Signs in the same order every year. For example, everyone
            born right before the Spring Equinox (currently in early March) is always a Pisces.
            <br />
            <span className={styles.italic}>
              *Note that there is an offset between Sun Signs in the Tropical and Sidereal zodiacs;
              we use the <Link to="/faq/#about-astro">Tropical zodiac.</Link>
            </span>
          </p>
        </AstroIntuitionContainer>
        <AstroIntuitionContainer theme="transparent-noPadding">
          <div className={styles.signGrid}>{navItems}</div>
          <img
            className={styles.cutout}
            alt=""
            src={require('../../../../../images/cutouts/cutout-peony.png')}
          />
        </AstroIntuitionContainer>
      </div>
      <AstroIntuitionFooter />
    </>
  );
};

export default PageLayout(ZodiacSignsPage);
