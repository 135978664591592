import React from 'react';
import cn from 'classnames';

import styles from './AstroIntuitionTable.module.scss';

type AstroIntuitionTableProps = {
  columnOneData: string[];
  columnTwoData: string[];
  theme: string | undefined;
};

const AstroIntuitionTable = ({
  columnOneData,
  columnTwoData,
  theme = '',
}: AstroIntuitionTableProps) => {
  const children = columnOneData.map((item, i) => {
    let className = '';
    if (item.includes('MARS')) className = styles.blue;
    if (item.includes('GEMINI')) className = styles.red;
    return (
      <tr className={className} key={`table-row-${item}`}>
        <td>{item}</td>
        <td>{columnTwoData[i]}</td>
      </tr>
    );
  });
  const tableStyles = cn(styles.table, {
    [styles.signs]: theme === 'signs',
    [styles.dark]: theme === 'dark',
  });
  return (
    <table className={tableStyles}>
      <tbody>{children}</tbody>
    </table>
  );
};

export default AstroIntuitionTable;
