import React, { useState } from 'react';
import * as _ from 'underscore';
import PlanetAnalysisBlock from '../PlanetAnalysisBlock';
import { Planet, planetData } from '../../../../astrology';
import { Analysis } from '../../../../api';

const PlanetExplanations: React.FC<{
  analysis: { [K in Planet]: Analysis };
  showAC: boolean;
}> = ({ analysis, showAC }) => (
  <div className="chart-description">
    <div className="chart-description-detail">
      {_.sortBy(Object.keys(analysis), (key) => planetData[key].order).map((key, idx) => (
        <PlanetAnalysisBlock
          planet={key as Planet}
          showAC={showAC}
          analysis={analysis[key]}
          key={idx}
        />
      ))}
      <div className="analysis-block empty">{/* empty for alignment */}</div>
    </div>
  </div>
);

export default PlanetExplanations;
