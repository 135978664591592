import React from 'react';
import styles from './YouInLove.module.scss';

const YouInLoveImg = ({ mobile = false }: { mobile: boolean }) => {
  if (mobile)
    return (
      <div className={styles.mobileImgContainer}>
        <img src={require('../../../../../images/you-in-love-blurb.png')} />
      </div>
    );
  return (
    <div className={styles.desktopImgContainer}>
      <img src={require('../../../../../images/you-in-love-blurb-desktop.png')} />
    </div>
  );
};

export default YouInLoveImg;
