import React from 'react';

import styles from './HousesItem.module.scss';

type HousesItemProps = {
  header: string;
  houses: string[];
  blurbs: string[];
};

const HousesItem = ({ header, houses, blurbs }: HousesItemProps) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <h3>{header}</h3>
    </div>
    <div className={styles.innerContainer}>
      <div className={styles.innerColumn}>
        <h4>Private Self</h4>
        <p>
          <span>{houses[0]} House</span>
        </p>
        <p>{blurbs[0]}</p>
      </div>
      <div className={styles.innerColumn}>
        <h4>Public Persona</h4>
        <p>
          <span>{houses[1]} House</span>
        </p>
        <p>{blurbs[1]}</p>
      </div>
    </div>
  </div>
);

export default HousesItem;
