import React from 'react';
import cn from 'classnames';

import SectionPadding from 'landing-page/components/SectionPadding';

// styles
import styles from './NatalChart.module.scss';

type NatalChartScrollBoxProps = {
  scrollDisabled: boolean;
};

const NatalChartScrollBox = ({ scrollDisabled }: NatalChartScrollBoxProps) => (
  <div
    className={cn(styles.scrollBox, {
      [styles.disableScroll]: scrollDisabled,
    })}
  >
    <SectionPadding verticalPadding="1rem" />
    <h3>Imagine it’s late at night and you look up.</h3>
    <SectionPadding verticalPadding="20vh" />
    <h3>
      You see a scary red ball in front of some stars in the shape of a couple of fun guys holding
      hands.
    </h3>
    <SectionPadding verticalPadding="20vh" />
    <h3>You’ve seen this before–you’ve seen this once every couple years, in fact.</h3>
    <SectionPadding verticalPadding="20vh" />
    <h3>
      During this time, your own scary red energy feels a little more fun & playful, just like two
      years ago.
    </h3>
    <SectionPadding verticalPadding="20vh" />
    <h3>
      Could the red ball be associated with anger and aggression? Could the two fun guys be
      associated with mischief and play?
    </h3>
    <SectionPadding verticalPadding="20vh" />
  </div>
);

export default NatalChartScrollBox;
