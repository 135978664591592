import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import Helmet from 'react-helmet';
import { useUser } from 'context/userContext';
import Api, { NewProspectiveUserReading } from 'api';
import useShopify, {
  useShopifyProduct,
  useShopifyCheckout,
  addCheckoutAttrs,
} from 'hooks/useShopify';
import PageLayout from 'landing-page/views/PageLayout';
import Breadcrumbs from 'landing-page/components/Breadcrumbs';
import AdvancedChartForm from 'landing-page/components/AdvancedChartForm';
import AdvancedReadingsCardContainer from '../AdvancedReadings/AdvancedReadingsCardContainer';
import AdvancedReadingsProductCard from '../AdvancedReadings/AdvancedReadingsProductCard';
import AskTheStarsProductCard from '../AdvancedReadings/AskTheStarsProductCard';
import YouInLoveImg from './YouInLoveImg';
import ACSampleModal from './SampleReadingModal/SampleReadingModal';
import TestimonialItem from './TestimonialItem';

import styles from './YouInLove.module.scss';

const YouInLove = () => {
  const { userData } = useUser();
  const [puId, setPuId] = useState(userData?.prospectiveUserId || null);
  const [paidReading, setPaidReading] = useState<NewProspectiveUserReading | null>(null);
  const [checkoutURL, setCheckoutURL] = useState<string>('');
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  // crumb objects for breadcrumbs
  const crumbs = [
    {
      name: 'Premium',
      path: '/advanced-readings',
    },
    {
      name: 'Reading: You in Love',
      path: '/advanced-readings/you-in-love',
    },
  ];

  const shopifyClient = useShopify(
    'co-star-astrology.myshopify.com',
    process.env.SHOPIFY_ACCESS_TOKEN,
  );
  const acProduct = useShopifyProduct(shopifyClient, process.env.AC_ID);
  const checkout = useShopifyCheckout(shopifyClient);

  const ErosProductCard = (
    <AdvancedReadingsProductCard
      imageURL={require('../../../../../images/eros-shop-card.png')}
      name="ErosCard"
    />
  );

  const ManualAddCard = (
    <AdvancedReadingsProductCard
      imageURL={require('../../../../../images/manual-add-shop-card.png')}
      name="ManualAddCard"
    />
  );

  useEffect(() => {
    if (checkout !== null && shopifyClient && paidReading) {
      shopifyClient.checkout
        .addLineItems(checkout.id, { variantId: acProduct.variants[0].id, quantity: 1 })
        .then((co) => {
          shopifyClient.checkout.fetch(checkout.id).then((data) => {
            if (data.lineItems[0].quantity > 1) {
              shopifyClient.checkout
                .updateLineItems(checkout.id, [{ id: data.lineItems[0].id, quantity: 1 }])
                .then((data) => {
                  setCheckoutURL(checkout.webUrl);
                });
            } else {
              setCheckoutURL(checkout.webUrl);
            }
          });
        });

      const input = {
        customAttributes: [
          {
            key: 'readingURL',
            value: `https://www.costarastrology.com/advanced-readings/${paidReading.readingUuid}`,
          },
        ],
      };
      addCheckoutAttrs(shopifyClient, checkout.id, input);
    }
  }, [checkout, acProduct, shopifyClient, paidReading, puId]);

  // disable body scroll if modal is open
  useEffect(() => {
    if (isOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'unset';
  }, [isOpen]);

  return (
    <>
      <Helmet>
        <title>Love Astrology Reading | Co-Star</title>
        <meta
          name="description"
          content=" Co—Star's personalized love astrology reading equips you with insight on what you need, where you’re coming from, and more about how you are in relationships."
        />
      </Helmet>
      <div className={cn(styles.pageContainer, styles.youInLovePage, 'floating-container')}>
        <Breadcrumbs crumbs={crumbs} />
        <div className={styles.innerPageContainer}>
          <div className={styles.desktopColumn}>
            <div className={styles.headerContainer}>
              <h3>Reading: You in Love</h3>
              <p>$9.00</p>
            </div>
            <YouInLoveImg mobile />
            <h4 onClick={toggleModal} className={styles.modalLink}>
              VIEW SAMPLE READING
            </h4>
            <div className={styles.bodyContainer}>
              <p>
                We need a few details in order to generate a personalized reading based on your
                birth chart.
              </p>
              <div>
                <AdvancedChartForm
                  birthData={userData || {}}
                  setPaidReading={setPaidReading}
                  shopURL={checkoutURL}
                />
              </div>
              <p>You will receive an email with information on how to access your reading.</p>
            </div>
          </div>
          <YouInLoveImg mobile={false} />
        </div>
        <div className={styles.testimonialsContainer}>
          <TestimonialItem idx={0} />
          <TestimonialItem idx={1} />
          <TestimonialItem idx={2} />
          <TestimonialItem idx={3} />
        </div>
        <div className={styles.darkContainer}>
          <h2>More readings</h2>
          <div className={styles.cardsContainer}>
            <AdvancedReadingsCardContainer
              card={ManualAddCard}
              description="Manually added charts: Add friends who don’t have the app in order to access their
                birth chart and see your compatibility."
            />
            <AdvancedReadingsCardContainer
              card={<AskTheStarsProductCard />}
              description="In-depth insights and advice based on your natal chart, the real-time movements of the planets in the sky, and the 4,000-year-old system of astrology."
            />
            <AdvancedReadingsCardContainer
              card={ErosProductCard}
              description="Eros: Subscribe with a partner to receive daily updates and activities about your
                relationship and compatibility."
            />
          </div>
        </div>
        <ACSampleModal isOpen={isOpen} closeModal={toggleModal} />
      </div>
    </>
  );
};

export default PageLayout(YouInLove);
