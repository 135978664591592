// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HEsc51-qfN-ByJnneWHCCg\\=\\={background-color:#141414;width:100%;height:153px;max-width:500px;margin-top:30px;margin-bottom:40px;display:flex;align-items:center;justify-content:center}.HEsc51-qfN-ByJnneWHCCg\\=\\= img{width:fit-content;margin:10px !important}", "",{"version":3,"sources":["webpack://./src/landing-page/pages/AstroIntuition/ZodiacSigns/ZodiacConstellation/ZodiacConstellation.module.scss"],"names":[],"mappings":"AAAA,4BACE,wBAAA,CACA,UAAA,CACA,YAAA,CACA,eAAA,CACA,eAAA,CACA,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,gCACE,iBAAA,CACA,sBAAA","sourcesContent":[".container {\n  background-color: #141414;\n  width: 100%;\n  height: 153px;\n  max-width: 500px;\n  margin-top: 30px;\n  margin-bottom: 40px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  img {\n    width: fit-content;\n    margin: 10px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "HEsc51-qfN-ByJnneWHCCg=="
};
export default ___CSS_LOADER_EXPORT___;
