import { AstroAnalysis } from 'api';
import React from 'react';
import AdvancedChartAttribution from './AdvancedChartAttributions';
import { months } from './AdvancedChartTypes';

import styles from './AdvancedChart.module.scss';

const AdvancedChartHeader: React.FC<{
  analysis: AstroAnalysis;
  birthData: { birthTime: string; birthPlace: string; birthDay: string };
}> = ({ analysis, birthData }) => {
  const { planets } = analysis;
  const { birthDay, birthPlace, birthTime } = birthData;

  let [year, month, day] = birthDay.split('-');

  month = months[parseInt(month, 10) - 1];
  day = day[0] === '0' ? day[1] : day;

  const parseTime = (string) => {
    const splitTime = string.split(':');
    let hours = parseInt(splitTime[0], 10);
    const minutes = splitTime[1];
    const amOrPm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12;
    return `${hours.toString()}:${minutes}${amOrPm}`;
  };

  const time = parseTime(birthTime);

  const attributions = [
    <AdvancedChartAttribution planet="Sun" sign={planets.Sun.position.sign} key="Sun" />,
    <AdvancedChartAttribution planet="Moon" sign={planets.Moon.position.sign} key="Moon" />,
    <AdvancedChartAttribution
      planet="Ascendant"
      sign={planets.Ascendant.position.sign}
      key="Rising"
    />,
  ];
  return (
    <div className={styles.headerContainer}>
      <h3>You in Love 🔒</h3>
      <div className={styles.headerBirthInfo}>
        <p>
          {month} {day}, {year} at {time}
          <br />
          {birthPlace}
        </p>
      </div>
      <div className={styles.headerAttributions}>{attributions}</div>
      ***
    </div>
  );
};

export default AdvancedChartHeader;
