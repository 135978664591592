export const planetsForImageOne = [{ name: 'Ascendant' }, { name: 'Sun' }, { name: 'Moon' }];

export const planetObjsForImageOne = {
  Ascendant: {
    imageHeight: 21,
    imageWidth: 12,
    x: -180,
    y: -10.500000000000028,
  },
  Sun: {
    imageHeight: 21,
    imageWidth: 21,
    x: 157,
    y: -70,
  },
  Moon: {
    imageHeight: 21,
    imageWidth: 16,
    x: -182,
    y: 12,
  },
};
