import React from 'react';
import { WebData } from 'server/request';
import { AstroAnalysis } from 'api';
import AddUserInfoSection from './AddUserInfoSection';
import UserNamesAndSignsSection from './UsernamesAndSignsSection';

const ConnectToAppInfoSection: React.FC<{
  username: string;
  remoteAnalysis: AstroAnalysis;
}> = ({ username, remoteAnalysis }) => (
  <div className="public-chart-page__explanation-section-container">
    <UserNamesAndSignsSection username={username} remoteAnalysis={remoteAnalysis} />
    <AddUserInfoSection username={username} />
  </div>
);

export default ConnectToAppInfoSection;
