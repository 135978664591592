import React from 'react';
import { Link } from 'react-router-dom';

import styles from './HouseSystemsCarousel.module.scss';

type HouseSystemsCarouselItemProps = {
  quote: string;
  name: string;
  image: string;
  link: string;
};

const HouseSystemsCarouselItem = ({ quote, name, image, link }: HouseSystemsCarouselItemProps) => (
  <a href={link} target="_blank" rel="noreferrer">
    <div className={styles.itemContainer}>
      <p>"{quote}"</p>
      <div className={styles.itemTitle}>
        <h3>— {name}</h3>
        <img
          src={require(`../../../../../images/houses/astrologers/${image}.png`)}
          alt={image}
          className={styles.itemImage}
        />
      </div>
    </div>
  </a>
);

export default HouseSystemsCarouselItem;
