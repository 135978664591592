import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import cn from 'classnames';
import { useLocation } from 'react-router';

import { scrollToElementWithId } from 'landing-page/components/anchor-link';
import PageLayout from 'landing-page/views/PageLayout';

import AstroIntuitionHeader from '../AstroIntuitionHeader';
import AstroIntuitionContainer from '../AstroIntuitionContainer';
import AstroIntuitionFooter from '../AstroIntuitionFooter';

import HouseSystemsCarousel from '../HouseSystemsCarousel';

import { porphyryQuotes, wholeSignQuotes, placidusQuotes } from './HouseSystemsTypes';

import styles from './HouseSystems.module.scss';

const HouseSystems = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        scrollToElementWithId(hash.substring(1));
      }, 100);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>House Systems: How does astrology work? | Co–Star</title>
        <meta
          name="description"
          content="Three of the most popular methods for dividing up astrology house systems are Prophyry, Placidus & Whole Sign. We explain the differences and highlight astrologers’ favorites."
        />
      </Helmet>
      <div className="floating-container">
        <AstroIntuitionHeader>
          <h1>House Systems</h1>
        </AstroIntuitionHeader>
        <AstroIntuitionContainer theme="transparent">
          <h3>
            There are many different ways of calculating houses. Porphyry is our favorite way of
            doing it (and the way we showed you).
          </h3>
        </AstroIntuitionContainer>
        <AstroIntuitionContainer theme="white" id="porphyry">
          <h2>Porphyry</h2>
          <img
            src={require('../../../../../images/houses/pageOne/porp.gif')}
            className={styles.housesGifs}
            alt="gif of porphyry house system"
          />
          <div className={styles.textContainer}>
            <p>
              Porphyry is the default house system used at Co–Star. At the time of birth, the
              precise degree of the sign corresponding with the MC, or Midheaven — aka, where the
              highest point of the Sun’s path through the sky (the ecliptic) intersects with the
              meridian — is determined, as is the precise degree of the sign rising on the Eastern
              Horizon (the Ascendant).
              <br />
              The degree of the sign corresponding with the MC marks the cusp of the 10th house. The
              degree of the Ascendant marks the cusp of the 1st house. The rest of the space between
              the MC and the Ascendant is divided into three equal parts.
              <br />
              The Descendant and the IC (Imum Coeli, or “bottom of the sky”) are taken to be 180°
              from (i.e., directly opposite) the Ascendant and the MC, respectively, and the space
              between them is also divided into three equal parts.
              <br />
              <br />
              Porphyry prioritizes the angles of the chart (the Ascendant, the Descendant, the IC,
              and the MC) as the 1st, 7th, 4th, and 10th house cusps, respectively, and then
              determines the other houses from these. Because many people see these four angles as
              the most powerful parts of the natal chart, they prefer to use the Porphyry system
              rather than one like Placidus, in which the angles may fall between houses.
            </p>
          </div>
          <HouseSystemsCarousel quotes={porphyryQuotes} />
        </AstroIntuitionContainer>
        <AstroIntuitionContainer theme="dark" id="whole-sign">
          <h2>Whole Sign</h2>
          <img
            src={require('../../../../../images/houses/pageOne/wholesign.gif')}
            className={styles.housesGifs}
            alt="gif of whole sign house system"
          />

          <div className={styles.textContainer}>
            <p>
              The Whole Sign house system is the oldest form of house division, and remained the
              preferred method of determining houses for about a thousand years after its inception.
              Whole Sign takes the entire span of the zodiac sign that appears on the Ascendant at
              the time of a person’s birth as the 1st house. The following sign makes up the 2nd
              house, the next the 3rd house, and so on. All houses are of equal size (30°) in the
              Whole Sign system.
              <br />
              <br />
              Whole Sign houses are very easy to calculate and use. More complicated phenomena such
              as intercepted signs cannot occur using the Whole Sign system.
            </p>
          </div>
          <HouseSystemsCarousel quotes={wholeSignQuotes} theme="dark" />
        </AstroIntuitionContainer>
        <AstroIntuitionContainer theme="light" id="placidus">
          <h2>Placidus</h2>
          <img
            src={require('../../../../../images/houses/pageOne/placidus.gif')}
            className={styles.housesGifs}
            alt="gif of placidus house system"
          />
          <div className={cn(styles.textContainer, styles.darkGrey)}>
            <p>
              The most popular house system in use today is Placidus. Placidus is a time-based
              method of calculating the houses that was devised during the Renaissance. It works by
              marking the cusps of the houses in two-hour increments from your time of birth. The
              degree of the zodiac sign rising on the Eastern Horizon at your time of birth marks
              the cusp of the 1st house. After roughly two hours, another measurement is made to see
              where that degree is now: that marks the cusp of the 12th house. This continues for
              twelve hours, until the 7th house cusp has been marked. The angles of these six houses
              are then extended to make up the angles of the six remaining houses. Because each
              house is proportioned according to the amount of time the signs spend on the horizon
              from the perspective of earth, this generally results in houses of unequal size. The
              further your birth place is from the equator, the greater the distortion of the
              houses.
              <br />
              <br />
              The Placidus system does not work for extremely northern or southern latitudes
              (greater than 66°N or 66°S) because certain degrees of the zodiac never touch the
              horizon in those regions. If you were born at a latitude exceeding 66°N or 66°S, you
              will not be able to calculate your chart using the Placidus house system.
              <br />
              <br />
              Originally, it was popular in England, possibly because the Catholic Church banned it.
              It later became the default because while the underlying math is complex, it’s
              relatively simple to represent & reproduce the Placidus system in table form.
            </p>
          </div>
          <HouseSystemsCarousel quotes={placidusQuotes} theme="light" />
        </AstroIntuitionContainer>
      </div>
      <AstroIntuitionFooter />
    </>
  );
};

export default PageLayout(HouseSystems);
